import type { SequenceNumberOrder } from '../../../types/pricingPlans';

import { ItemSequenceNumber } from '../../../common/queries/pricingPlans/pricingPlans.types';

/**
 * Creates an array of objects representing the initial order of sequence numbers
 * for the given pricing plans. If any pricing plan has a sequence number of 0,
 * the sequence numbers are incremented by 1, otherwise they remain unchanged
 */
export const createInitialSequenceOrder = (pricingPlans: Array<ItemSequenceNumber>): Array<SequenceNumberOrder> => {
  const isNullable = pricingPlans.find((p) => p.sequenceNumber === 0);
  return pricingPlans.map((pricingPlan) => ({
    oldNumber: pricingPlan.sequenceNumber,
    newNumber: isNullable ? pricingPlan.sequenceNumber + 1 : pricingPlan.sequenceNumber,
    isCurrentItem: false,
  }));
};
