import { useLocation, useNavigate } from 'react-router-dom';

import type { ClientSubTab } from '../../../pages/ClientListPage/shared/types';

import { ClientNEXTInspectSubTab, ClientTabHumanReadable, ClientWholesaleSubTab } from '../../../pages/ClientListPage/shared/types';

export type UseNavigator = {
  navigateToClientsNextInspect: (subTab: ClientNEXTInspectSubTab) => void;
  navigateToClientsWholesale: (subTab: ClientWholesaleSubTab) => void;
  navigateToClientSettings: (client: string, from?: ClientNEXTInspectSubTab) => void;
  navigateToPricingPlans: (planId: string, groupCode: string) => void;
  navigateToDamageGuidelines: (planId: string, from?: ClientNEXTInspectSubTab) => void;
  navigateToCreateClientSettings: (from?: ClientNEXTInspectSubTab) => void;
  navigateToModelPlan: (planId: string) => void;
  navigateToTab: (tab: ClientTabHumanReadable, subTab: ClientSubTab, replace?: boolean) => void;
};

export const useNavigator = (): UseNavigator => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToClientsNextInspect = (subTab: ClientNEXTInspectSubTab): void => navigate(`/clients/next-inspect/${subTab}`);
  const navigateToClientsWholesale = (subTab: ClientWholesaleSubTab): void => navigate(`/clients/wholesale/${subTab}`);

  const navigateToClientSettings = (client: string, from?: ClientNEXTInspectSubTab): void =>
    navigate(`/client-settings/${client}`, {
      state: { from },
    });
  const navigateToPricingPlans = (planId: string, groupCode: string): void => navigate(`/clients/${groupCode}/pricing-plans/${planId}`);
  const navigateToDamageGuidelines = (planId: string, from?: ClientNEXTInspectSubTab): void =>
    navigate(`/damage-guidelines/${planId}`, {
      state: { from },
    });
  const navigateToCreateClientSettings = (from?: ClientNEXTInspectSubTab): void =>
    navigate('/client-settings', {
      state: { from },
    });
  const navigateToModelPlan = (planId: string): void => navigate(`/model-plans/${planId}`);
  const navigateToTab = (tab: ClientTabHumanReadable, subTab: ClientSubTab, replace?: boolean): void => {
    const { search } = location;

    navigate(`/clients/${tab}/${subTab}${search}`, { replace });
  };

  return {
    navigateToClientsNextInspect,
    navigateToClientsWholesale,
    navigateToClientSettings,
    navigateToPricingPlans,
    navigateToDamageGuidelines,
    navigateToCreateClientSettings,
    navigateToModelPlan,
    navigateToTab,
  };
};
