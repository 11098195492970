import { FormikProps } from 'formik';

import { Client } from '../../../../../../../../types/clients';

export const isStep2Edited = (formik: FormikProps<Partial<Client>>): boolean => {
  return (
    !!formik.values.groupName ||
    !!formik.values.sellerAccount ||
    !!formik.values.groupCode ||
    !!formik.values.sourceGroupCode ||
    !!formik.values.isMitchellPaint ||
    !!formik.values.isMitchellPart ||
    !!formik.values.isMitchellRetail
  );
};

export const isWizardStep2Visible = (formik: FormikProps<Partial<Client>>): boolean => {
  return !!formik.values.pricingType;
};
