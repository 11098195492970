import { Field, FieldTypes } from '../../types/field';

export const parseFormik = <T>(values: Record<string, unknown>, fields: Field[]): T => {
  const parsed = { ...values };

  for (const { name, type } of fields) {
    const propertyParts = name.split('.');
    const fieldName = propertyParts.pop()!;

    // Traverse the object hierarchy based on the property name parts
    let obj = parsed;

    for (const part of propertyParts) {
      // If the property doesn't exist or is not an object, initialize it as an empty object
      if (typeof obj[part] !== 'object' || obj[part] === null) {
        obj[part] = {};
      }

      // Move to the next level of the nested object
      obj = obj[part] as Record<string, unknown>;
    }

    const value = obj[fieldName];

    // If the field type is NUMBER and the value is an empty string, replace it with null
    if (type === FieldTypes.NUMBER && value === '') {
      obj[fieldName] = null;
    }
  }

  return parsed as unknown as T;
};
