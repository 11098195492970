import { ClientNEXTInspectSubTab, ClientSubTab, ClientTab, ClientTabHumanReadable, ClientType, ClientWholesaleSubTab } from './types';
import { Client } from '../../../types/clients';
import { TabOption } from '../../../types/field';

export const GROUP_CODE_MAX_LENGTH = 4;

export const DEFAULT_PAGINATION = {
  pageIndex: 0,
  pageSize: 10,
};

export const ClientTabs = {
  [ClientTab.NextInspect]: 'NEXT Inspect',
  [ClientTab.Wholesale]: 'Wholesale',
} as const;

export const ClientSubTabs = {
  [ClientNEXTInspectSubTab.ACTIVE_MANHEIM]: 'Active - Manheim',
  [ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED]: 'Active - Client Sourced',
  [ClientNEXTInspectSubTab.DEMO]: 'Demo',
  [ClientWholesaleSubTab.ACCOUNTS]: 'Accounts',
  [ClientWholesaleSubTab.MODEL_PLANS]: 'Model plans',
} as const;

export const CLIENT_TABS = [
  { label: 'NEXT Inspect', value: ClientTab.NextInspect },
  { label: 'Wholesale', value: ClientTab.Wholesale },
];

export const CLIENT_SUB_TABS = [
  { label: 'Active - Manheim', value: ClientNEXTInspectSubTab.ACTIVE_MANHEIM },
  { label: 'Active - Client Sourced', value: ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED },
  { label: 'Demo', value: ClientNEXTInspectSubTab.DEMO },
  { label: 'Accounts', value: ClientWholesaleSubTab.ACCOUNTS },
  { label: 'Model plans', value: ClientWholesaleSubTab.MODEL_PLANS },
];

export const CLIENT_SETTINGS_TABS = [
  { label: 'Dealer', value: ClientType.DEALER },
  { label: 'Consumer', value: ClientType.CONSUMER },
];

export const HUMAN_READABLE_TO_CLIENT_TAB = {
  [ClientTabHumanReadable.NextInspect]: ClientTab.NextInspect,
  [ClientTabHumanReadable.Wholesale]: ClientTab.Wholesale,
};

export const CLIENT_TAB_TO_HUMAN_READABLE = {
  [ClientTab.NextInspect]: ClientTabHumanReadable.NextInspect,
  [ClientTab.Wholesale]: ClientTabHumanReadable.Wholesale,
};

export const CLIENT_NEXT_INSPECT_SUB_TABS: Array<TabOption<ClientNEXTInspectSubTab>> = [
  { label: 'Active - Manheim', value: ClientNEXTInspectSubTab.ACTIVE_MANHEIM },
  { label: 'Active - Client Sourced', value: ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED },
  { label: 'Demo', value: ClientNEXTInspectSubTab.DEMO },
];

export const CLIENT_WHOLESALE_SUB_TABS: Array<TabOption<ClientWholesaleSubTab>> = [
  { label: 'Accounts', value: ClientWholesaleSubTab.ACCOUNTS },
  { label: 'Model plans', value: ClientWholesaleSubTab.MODEL_PLANS },
];

export const TAB_TO_SUB_TABS = {
  [ClientTab.NextInspect]: CLIENT_NEXT_INSPECT_SUB_TABS,
  [ClientTab.Wholesale]: CLIENT_WHOLESALE_SUB_TABS,
};

export const TAB_TO_DEFAULT_SUB_TAB = {
  [ClientTab.NextInspect]: CLIENT_NEXT_INSPECT_SUB_TABS[0],
  [ClientTab.Wholesale]: CLIENT_WHOLESALE_SUB_TABS[0],
};

export const CLIENT_MITCHELL_AND_CALC_FIELDS_TO_REMOVE: Array<keyof Client> = [
  'damageQty',
  'damageSize',
  'isMitchellPaint',
  'isMitchellPart',
  'isMitchellRetail',
];

export const SUB_TAB_TO_CAN_FILTER: Record<ClientSubTab, boolean> = {
  [ClientNEXTInspectSubTab.ACTIVE_MANHEIM]: true,
  [ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED]: true,
  [ClientNEXTInspectSubTab.DEMO]: false,
  [ClientWholesaleSubTab.ACCOUNTS]: true,
  [ClientWholesaleSubTab.MODEL_PLANS]: true,
};
