import { FilterIcon, PlusIcon } from '@prism2/icons-react/prism';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DeleteLineItemModal } from './components/DeleteLineItemModal/DeleteLineItemModal';
import { HistoryModal } from './components/HistoryModal/HistoryModal';
import { LineItemModal } from './components/LineItemModal/LineItemModal';
import { PricingItemModalMode } from './components/LineItemModal/shared/types';
import { SubHeader } from './components/SubHeader/SubHeader';
import { useGetNavigation } from './hooks/useGetNavigation';
import { usePricingPlans } from './hooks/usePricingPlans';
import { usePricingPlansSource } from './hooks/usePricingPlansSource';
import { useSyncPricingPlanTableWithSearchParams } from './hooks/useSyncPricingPlanTableWithSearchParams';
import { useUpdateBackfillStatus } from './hooks/useUpdateBackfillStatus';
import { pricingPlansColsFactory } from './shared/columns';
import { useModelPlan } from '../../api/hooks/useModelPlan/useModelPlan';
import { useSmartModalVisibility } from '../../common/hooks/useSmartModalVisibility';
import { CommonTable } from '../../components/CommonTable/CommonTable';
import { ErrorWrapper } from '../../components/ErrorWrapper/ErrorWrapper';
import { SwitchField } from '../../components/FormField/SwitchField/SwitchField';
import { Page } from '../../components/Page/Page';
import { Pagination } from '../../components/Pagination/Pagination';
import { config } from '../../config';
import { QueryKey } from '../../constants/queries';
import { ClientGroupType } from '../../types/clients';
import { ColorThemes } from '../../types/common';
import { PricingPlan } from '../../types/pricingPlans';
import { useClient } from '../ClientListPage/hooks/useClient';
import { ClientNEXTInspectSubTab } from '../ClientListPage/shared/types';

export const PricingPlansPage: FC = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { planId, groupCode } = useParams();

  const { pagination, setPagination, isFilter, setFilters, toggleFilter, filters, sorting, setSorting, debouncedFilters } =
    useSyncPricingPlanTableWithSearchParams();

  const [modalLineItem, setModalLineItem] = useState<PricingPlan | undefined>();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<PricingItemModalMode>(PricingItemModalMode.ADD);

  const onUpdateItem = useCallback((lineItem: PricingPlan) => {
    setModalLineItem(lineItem);
    setModalMode(PricingItemModalMode.UPDATE);
    setIsOpenModal(true);
    void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_PRICING_PLAN_ITEM] });
    void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
  }, []);

  const onCopyItem = useCallback((lineItem: PricingPlan) => {
    setModalLineItem(lineItem);
    setModalMode(PricingItemModalMode.COPY);
    setIsOpenModal(true);
    void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_PRICING_PLAN_ITEM] });
    void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
  }, []);

  const onAddItem = () => {
    setModalMode(PricingItemModalMode.ADD);
    setIsOpenModal(true);
    void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
  };

  const onRemoveLineItemHandler = (lineItem: PricingPlan) => {
    setModalLineItem(lineItem);
    setIsDeleteOpen(true);
    void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
  };

  const onHistoryButtonClicked = () => {
    setIsHistoryModalOpen(true);
  };

  const {
    isFetching: clientIsFetching,
    data: clientData,
    error: clientError,
  } = useClient({ groupCode: groupCode ?? '', planId: planId ?? '' });

  const { pageIndex, pageSize } = pagination;

  const {
    isFetching: modelPlanIsFetching,
    data: modelPlanData,
    error: modelPlanError,
  } = useModelPlan({ planId: planId ?? '', enabled: !groupCode });

  // TODO: change "|| ClientGroupType.Wholesale" if NEXT Inspect will have model plans
  const groupType = clientData?.groupType || modelPlanData?.groupType || ClientGroupType.Wholesale;

  const {
    isFetching: pricingPlansIsFetching,
    data: pricingPlansData,
    error: pricingPlansError,
  } = usePricingPlans({ planId: planId!, groupType, pageIndex, pageSize, sorting, debouncedFilters });

  const { onModalClose, setIsOpenModal, isModalOpen } = useSmartModalVisibility();

  const isBasicTier = planId === config.BASIC_TIER.PLAN_ID;
  const { data: pricingPlansSourceData } = usePricingPlansSource(isBasicTier);

  const isAccountPlan = Boolean(groupCode);
  const [enabledForBackfill, setEnableForBackfill] = useUpdateBackfillStatus({
    planId: planId!,
    groupCode: groupCode!,
    initialValue: clientData?.enabledForBackfill ?? false,
  });
  const { navigateToClientSettings, navigateToClientList } = useGetNavigation(groupType, groupCode);

  const isNextInspectClient = clientData?.groupType === ClientGroupType.NextInspect;
  const isModelPlan = !groupCode;
  const title = isModelPlan ? 'Model plans' : 'Pricing plans';
  const columns = pricingPlansColsFactory(onUpdateItem, onCopyItem, onRemoveLineItemHandler, isNextInspectClient);

  const table = useReactTable({
    data: pricingPlansData?.items || [],
    columns: columns as ColumnDef<PricingPlan>[],
    pageCount: pricingPlansData?.count ? Math.ceil(pricingPlansData?.count / pagination.pageSize) : 0,
    state: {
      pagination,
      sorting,
      columnFilters: filters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowCanExpand: () => true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: (newFilters) => setFilters({ newFilters }),
    manualSorting: true,
    manualFiltering: true,
    enableSortingRemoval: true,
    sortDescFirst: true,
    enableMultiSort: false,
    debugTable: true,
    manualPagination: true,
    paginateExpandedRows: true,
  });

  const fetchError = pricingPlansError || clientError || modelPlanError;
  const isPageLoading = pricingPlansIsFetching || clientIsFetching || modelPlanIsFetching;
  return (
    <Page id="pricing-plans" title={title} isLoading={isPageLoading}>
      <ErrorWrapper error={fetchError}>
        <HistoryModal planId={planId!} isOpen={isHistoryModalOpen} onClose={() => setIsHistoryModalOpen(false)} />
        <LineItemModal
          isOpen={isModalOpen}
          mode={modalMode}
          sourceGroupCodes={pricingPlansSourceData?.copiedFromBasicTier ?? []}
          groupType={groupType}
          groupCode={groupCode!}
          planId={planId!}
          onClose={() => onModalClose()}
          setIsOpenModal={setIsOpenModal}
          lineItem={modalLineItem}
        />
        <DeleteLineItemModal
          sourceGroupCodes={pricingPlansSourceData?.copiedFromBasicTier ?? []}
          isOpen={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          lineItemToDelete={modalLineItem!}
          groupType={groupType}
        />

        <div className="sticky top-0 z-10 bg-white pt-2">
          <div className="h-10 flex justify-between">
            <SubHeader
              isModelPlan={isModelPlan}
              isNextInspectClient={isNextInspectClient}
              clientData={clientData}
              modelPlanData={modelPlanData}
            />
            <button data-testid="history-btn" className="prism-btn" onClick={onHistoryButtonClicked}>
              History
            </button>
          </div>
          <div className="w-full flex justify-between flex-wrap">
            <div className="self-start my-2 flex items-center gap-4" data-testid="group-buttons">
              <button onClick={navigateToClientList} data-testid="client-list-btn" className="prism-btn">
                Client list
              </button>
              {isNextInspectClient && (
                <button
                  onClick={() => navigateToClientSettings(clientData?.client as string, ClientNEXTInspectSubTab.ACTIVE_MANHEIM)}
                  data-testid="client-settings-btn"
                  className="prism-btn"
                  disabled={!clientData?.client}
                >
                  Client settings
                </button>
              )}
              <button onClick={onAddItem} data-testid="add-line-item-btn" className="prism-btn">
                <PlusIcon />
                Add line item
              </button>
              <button
                onClick={toggleFilter}
                data-testid="filter-btn"
                title="Filter line items"
                className={classNames('prism-btn', { 'prism-btn bg-blue-100': isFilter })}
              >
                <FilterIcon className="h-6" />
              </button>
              {!isPageLoading && isAccountPlan && !isNextInspectClient && (
                <SwitchField
                  label="Active"
                  name="enableForBackfill"
                  value={enabledForBackfill}
                  colorTheme={ColorThemes.GREEN}
                  onChange={(value: boolean) => setEnableForBackfill(value)}
                />
              )}
            </div>
            <div className="my-2">
              <Pagination table={table} />
            </div>
          </div>
        </div>
        <div className="border-x" data-testid="pricing-plans-table">
          <CommonTable isFilter={isFilter} table={table} thStyle="sticky top-[104px] z-10" />
        </div>
      </ErrorWrapper>
    </Page>
  );
};
