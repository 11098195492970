import { InfoIcon } from '@prism2/icons-react/prism';
import { useState } from 'react';

export interface InfoTipProps {
  text: string;
}

/**
 * Shows an information tip text when the info icon is hovered
 */
export const InfoTip = ({ text }: InfoTipProps) => {
  const [show, setShow] = useState<boolean>(false);

  const onMouseLeave = (): void => setShow(false);
  const onMouseEnter = (): void => setShow(true);

  return (
    <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
      <InfoIcon data-testid="info-tip-icon" className="cursor-pointer ml-2 text-primary-dark w-4 h-4" />
      {show && (
        <div className={`absolute inline-block rounded px-5 py-2 z-20 shadow w-full text-center bg-blue-800 text-white text-xs`}>
          {text}
        </div>
      )}
    </div>
  );
};
