import { ColumnSort } from '@tanstack/react-table';

import { removePrefixOfFieldId } from './removePrefixOfFieldId';

/**
 * Append a query sort parameters to a URL if the value is not undefined
 */
export const appendSortToURL = (url: URL, sort?: ColumnSort[]): void => {
  if (sort?.length) {
    url.searchParams.append('sortBy', removePrefixOfFieldId(sort[0].id));
    url.searchParams.append('order', sort[0].desc ? 'desc' : 'asc');
  }
};
