import { useQuery } from '@tanstack/react-query';

import type { FetchHistoryResponse } from '../../../common/queries/pricingPlans/pricingPlans.types';

import { useDebounceValue } from '../../../common/hooks/useDebounceValue';
import { fetchHistory } from '../../../common/queries/pricingPlans/pricingPlans.api';
import { QueryKey } from '../../../constants/queries';

type UseHistoryProps = Readonly<{
  planId: string;
  from: Date | null;
  to: Date | null;
  search: string;
  limit: number;
  offset: number;
  isOpen: boolean;
}>;

type UseHistory = Readonly<{
  data: FetchHistoryResponse | undefined;
  error: Error | null;
  isFetching: boolean;
  isError: boolean;
}>;

export const useHistory = ({ planId, isOpen, from, to, search, limit, offset }: UseHistoryProps): UseHistory => {
  const debouncedSearch = useDebounceValue(search);

  const { isFetching, isError, data, error } = useQuery<FetchHistoryResponse, Error>({
    queryKey: [QueryKey.FETCH_HISTORY_OF_PRICING_PLAN, planId, from, to, debouncedSearch, offset],
    queryFn: () =>
      fetchHistory({
        planId,
        from,
        to,
        search: debouncedSearch,
        offset,
        limit,
      }),
    staleTime: 0,
    enabled: isOpen,
  });

  return { isFetching, isError, data, error };
};
