import { PaginationState } from '@tanstack/react-table';

import type { ClientSettingsTableData } from './types';
import type { CategoryCode, Client, ClientDTO } from '../../../types/clients';
import type { ClientSettings } from '../../../types/clientSettings';
import type { Field, OptionsType } from '../../../types/field';

import { categoryCodeOptions } from './fields';

export const getFirstValueFromString = (str: string): number => {
  const strValue = str.split(',')[0].trim();
  return Number(strValue);
};

export const mapClientSettingsToTableData = (clientSettings?: ClientSettings[]): ClientSettingsTableData[] =>
  clientSettings
    ? (clientSettings.map((clientSetting) => ({
        ...clientSetting,
        sellerAccounts: clientSetting.sellerAccounts?.join(', '),
      })) as ClientSettingsTableData[])
    : [];

export const mapClientToWholesaleRowInitialValues = (client: Client): Partial<ClientDTO> => {
  const categoryCodeArray: OptionsType[] = categoryCodeOptions.filter((item) => client.categoryCodes?.includes(item.value as CategoryCode));

  return {
    enabledForBackfill: client.enabledForBackfill,
    planId: client.planId,
    groupType: client.groupType,
    groupCode: client.groupCode,
    groupName: client.groupName,
    client: client.client,
    sourceGroupCode: client.sourceGroupCode,
    pricingType: client.pricingType,
    manufacturerCode: client.manufacturerCode,
    categoryCodes: categoryCodeArray,
    inspectionType: client.inspectionType,
    planDescription: client.planDescription,
    bodyRate: client.bodyRate,
    partRate: client.partRate,
    paintRate: client.paintRate,
    mechanicalRate: client.mechanicalRate,
    isMitchellPart: client.isMitchellPart,
    isMitchellPaint: client.isMitchellPaint,
    isMitchellRetail: client.isMitchellRetail,
    isModelPricing: client.isModelPricing,
    shouldHideItem: client.shouldHideItem,
    shouldRepairItem: client.shouldRepairItem,
    shouldHideImage: client.shouldHideImage,
  } as ClientDTO;
};

export const mapClientToNextInspectRowInitialValues = (client: Client): Partial<Client> => {
  return {
    planId: client.planId,
    groupType: client.groupType,
    groupCode: client.groupCode,
    groupName: client.groupName,
    client: client.client,
    sourceGroupCode: client.sourceGroupCode,
    pricingType: client.pricingType,
    planDescription: client.planDescription,
    bodyRate: client.bodyRate,
    partRate: client.partRate,
    paintRate: client.paintRate,
    mechanicalRate: client.mechanicalRate,
    isMitchellPart: client.isMitchellPart,
    isMitchellPaint: client.isMitchellPaint,
    isMitchellRetail: client.isMitchellRetail,
    damageQty: client.damageQty,
    damageSize: client.damageSize,
  };
};

export const getFlagsDefaultValues = (flags: Field[]) => {
  return flags.reduce(
    (fieldsDefaultValues, { name, defaultChecked }) => {
      fieldsDefaultValues[name] = defaultChecked!;
      return fieldsDefaultValues;
    },
    {} as Record<string, boolean>,
  );
};

export const calculatePageCount = (pagination: PaginationState, data?: { count?: number }): number => {
  return data?.count ? Math.ceil(data.count / pagination.pageSize) : 0;
};
