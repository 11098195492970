import {
  damageCodeField,
  deletePricingPlansModalFields,
  pricingPlansFormFieldsNextInspect,
  pricingPlansFormFieldsWholesale,
} from '../shared/fields';

/**
 * Replaces the field names in the given error message with their corresponding human-readable labels
 */
export const replaceFieldsNameWithLabelInMessage = (message: string): string => {
  [damageCodeField, ...pricingPlansFormFieldsNextInspect, ...pricingPlansFormFieldsWholesale, ...deletePricingPlansModalFields].forEach(
    ({ name, label }) => {
      if (!label) return;
      const regexp = new RegExp(`"${name}"`, 'g');
      message = message.replace(regexp, `"${label}"`);
    },
  );
  return message;
};
