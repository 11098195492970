import { PropsValue } from 'chakra-react-select';
import React, { useEffect, useState } from 'react';

import { useDebounceValue } from '../../../../common/hooks/useDebounceValue';
import { OptionsType } from '../../../../types/field';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

type UseDebouncedSelectValue = Readonly<{
  uiValue: PropsValue<OptionsType> | null;
  setUiValue: SetState<PropsValue<OptionsType> | null>;
  setInnerFormikValue: SetState<PropsValue<OptionsType> | null>;
  debouncedFormikValue: PropsValue<OptionsType> | null;
}>;

type UseDebouncedSelectValueParams = {
  formikBasedValue?: PropsValue<OptionsType>;
  options: OptionsType[];
  debounceDelay?: number;
};

export const useDebouncedSelectValue = ({
  options,
  debounceDelay,
  formikBasedValue,
}: UseDebouncedSelectValueParams): UseDebouncedSelectValue => {
  const [uiValue, setUiValue] = useState<PropsValue<OptionsType> | null>(null);
  const [innerFormikValue, setInnerFormikValue] = useState<PropsValue<OptionsType> | null>(null);

  useEffect(() => {
    setUiValue(formikBasedValue ?? null);
  }, [options]);

  useEffect(() => {
    if (!formikBasedValue) {
      return;
    }
    setUiValue(formikBasedValue);
  }, [formikBasedValue]);

  const debouncedFormikValue = useDebounceValue(innerFormikValue, debounceDelay || 600);

  return {
    uiValue,
    setUiValue,
    setInnerFormikValue,
    debouncedFormikValue,
  };
};
