import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import { toast } from 'react-toastify';

import { deletePricingPlans } from '../../../../common/queries/pricingPlans/pricingPlans.api';
import { BlockScreenLoading } from '../../../../components/BlockScreenLoading/BlockScreenLoading';
import { Modal } from '../../../../components/Modal/Modal';
import { config } from '../../../../config';
import phrases from '../../../../constants/en_US.json';
import { QueryKey } from '../../../../constants/queries';
import { ClientGroupType } from '../../../../types/clients';
import { PricingPlan } from '../../../../types/pricingPlans';
import { deletePricingPlansModalFields } from '../../shared/fields';
import { ClientsTrackNotification } from '../ClientsTrackNotification/ClientsTrackNotification';

export interface DeleteLineItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  lineItemToDelete: PricingPlan;
  sourceGroupCodes: string[];
  groupType: ClientGroupType;
}

export const DeleteLineItemModal: FC<DeleteLineItemModalProps> = ({
  isOpen,
  onClose,
  lineItemToDelete,
  sourceGroupCodes,
  groupType,
}): JSX.Element => {
  // temporary solution
  const { lineItem: lineItemPhrases } = phrases as Record<'lineItem', Record<string, Record<'success' | 'error', string>>>;

  const showError = useCallback((msg?: string) => toast(msg ?? lineItemPhrases.delete.error, { type: 'error' }), []);
  const showSuccess = useCallback((msg?: string) => toast(msg ?? lineItemPhrases.delete.success, { type: 'success' }), []);
  const queryClient = useQueryClient();

  const isBasicTier = lineItemToDelete?.planId === config.BASIC_TIER.PLAN_ID;

  const { mutate: removeLineItem, isLoading: isDeletingLoading } = useMutation({
    mutationFn: (values: Partial<PricingPlan>) =>
      deletePricingPlans({
        planId: values.planId!,
        itemCode: values.itemCode!,
        severityCode: values.severityCode!,
        subItemCode: values.subItemCode!,
        actionCode: values.actionCode!,
        damageCode: values.damageCode!,
        sequenceNumber: values.sequenceNumber,
        groupType,
      }),
    onError: (e: Error) => showError(e.message),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_PRICING_PLANS] });
      showSuccess('Line item has been removed');
      onClose();
    },
  });
  return (
    <Modal isOpen={isOpen} sizeClass="max-w-sm" onClose={onClose}>
      <div data-testid="delete-line-item-modal" className="flex flex-col">
        <div className="prism-heading-2 self-center" data-testid="title">
          Do you want to delete this line item?
        </div>
        <div className="flex flex-col justify-between ">
          {isBasicTier && (
            <div className="flex justify-center mt-3 w-full max-h-90">
              <ClientsTrackNotification sourceGroupCodes={sourceGroupCodes} />
            </div>
          )}
          <div className="grid justify-items-start justify-center my-4" data-testid="line-item-details">
            {deletePricingPlansModalFields.map((field) => (
              <div key={field.name} data-testid={field.name}>
                <strong>{field.label}</strong>:&nbsp;{lineItemToDelete?.[field.name as keyof PricingPlan] as string}
              </div>
            ))}
          </div>
        </div>

        <div className="flex gap-4 items-center justify-center">
          <button data-testid="cancel-btn" className="prism-btn" onClick={onClose}>
            Cancel
          </button>
          <button
            data-testid="confirm-btn"
            className="prism-btn fill"
            onClick={() => removeLineItem(lineItemToDelete)}
            disabled={isDeletingLoading}
          >
            Confirm
          </button>
        </div>
        {isDeletingLoading && <BlockScreenLoading />}
      </div>
    </Modal>
  );
};
