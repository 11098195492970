import type { BaseFieldProps } from '../../../types/field';

import { Label } from '../Label/Label';

export const BaseField = ({ formik, name, label, disabled, children }: BaseFieldProps): JSX.Element => {
  return (
    <div data-testid={`${name}-field`}>
      {label && <Label name={name} label={label} disabled={disabled} />}
      {children}
      {formik?.errors[name] && formik.touched[name] && (
        <span data-testid={`${name}-error`} className="text-red-500">
          {formik.errors[name] as string}
        </span>
      )}
    </div>
  );
};
