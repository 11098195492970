import { FC, ReactNode } from 'react';

type FormButtonsProps = {
  readonly isSaveButtonDisabled: boolean;
  onCancelButtonClick: () => void;
  children?: ReactNode;
};

export const FormButtons: FC<FormButtonsProps> = ({
  isSaveButtonDisabled,
  onCancelButtonClick,
  children,
}: FormButtonsProps): JSX.Element => {
  return (
    <div data-testid="button-actions" className="flex flex-wrap justify-end gap-4 mt-10">
      {children}
      <button onClick={onCancelButtonClick} type="reset" data-testid="cancel-btn" className="prism-btn w-20">
        Cancel
      </button>
      <button type="submit" data-testid="save-btn" className="prism-btn fill w-20 flex justify-center" disabled={isSaveButtonDisabled}>
        Save
      </button>
    </div>
  );
};
