export enum QueryKey {
  FETCH_CLIENTS = 'clients',
  FETCH_CLIENT_ITEM = 'client-item',
  FETCH_PRICING_PLANS = 'pricing-plans',
  FETCH_PRICING_PLAN_ITEM = 'pricing-plan-item',
  FETCH_HISTORY_OF_PRICING_PLAN = 'history-pricing-plan',
  FETCH_DAMAGE_GUIDELINES = 'damage-guidelines',
  FETCH_DAMAGE_GUIDELINE_IMAGE = 'damage-guideline-image',
  ART_PROXY = 'art-proxy',
  CLIENT_SETTINGS_PROXY = 'client-settings-proxy',
  FETCH_CLIENT_SETTINGS_ACCOUNTS = 'fetch-client-settings-accounts',
  FETCH_PRICING_PLANS_SOURCE = 'pricing-plans-source',
  FETCH_GROUP_DESCRIPTION = 'group-description',
  FETCH_CLIENT_SETTINGS_JSON_SCHEMA = 'fetch-client-settings-json-schema',
  FETCH_MODEL_PLANS = 'model-plans',
  FETCH_MODELS = 'models',
  FETCH_MAKES = 'makes',
  FETCH_TRIMS = 'trims',
  FETCH_TRIMS_OPTIONS = 'trims-options',
  FETCH_MODEL_PLAN_ITEM = 'model-plan-item',
  FETCH_PLAN_DESCRIPTION = 'plan-description',
  VALIDATE = 'validate-model-plan',
}

export enum ArtProxyAction {
  ITEM_SEARCH = 'itemSearch',
  ITEM_BY_ID = 'itemById',
  ITEM_DAMAGES = 'itemDamages',
  ITEM_DAMAGE_SEVERITIES = 'itemDamageSeverities',
  ITEM_DAMAGE_SEVERITY_ACTIONS = 'itemDamageSeverityActions',
  CHARGEABILITIES = 'chargeabilities',
  ACTIONS = 'actions',
  SEVERITIES = 'severities',
}
