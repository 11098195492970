import { FormikProps } from 'formik';
import { SingleValue } from 'react-select';

import type { Field } from '../../../types/field';

import { ClientGroupType } from '../../../types/clients';
import { OptionsType } from '../../../types/field';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { useItemDescriptionSearch } from '../hooks/useItemDescriptionSearch';
import { PricingPlanFormikValues } from '../shared/types';

export interface UseItemSearchFieldParams {
  groupCode: string;
  formik: FormikProps<Partial<PricingPlanFormikValues>>;
  groupType: ClientGroupType;
  mode: PricingItemModalMode;
}
type UseItemSearchField = Partial<Field>;

export const useItemSearchField = ({ formik, groupCode, mode, groupType }: UseItemSearchFieldParams): UseItemSearchField => {
  const { data: options, isFetching, setSearchInput: onInputChange } = useItemDescriptionSearch(groupCode);

  const onChange = getItemSearchOnChange({ formik, groupType });
  const formikBasedValue = getItemSearchFormikBasedValue(formik);

  return {
    options,
    isFetching,
    onInputChange,
    formikBasedValue,
    onChange,
    disabled: mode === PricingItemModalMode.UPDATE && groupType === ClientGroupType.Wholesale,
  };
};

export interface UseItemSearchOnChangeParams {
  formik: FormikProps<Partial<PricingPlanFormikValues>>;
  groupType: ClientGroupType;
}
type UseItemSearchOnChange = (option: SingleValue<OptionsType>) => void;

export const getItemSearchOnChange = ({ formik, groupType }: UseItemSearchOnChangeParams): UseItemSearchOnChange => {
  const isNextInspect = groupType === ClientGroupType.NextInspect;

  return (option: SingleValue<OptionsType>): void => {
    const { itemSearch, itemDescription } = formik.values;
    const unsetValues = {
      ...(isNextInspect
        ? {
            severityDescription: '',
            actionDescription: '',
            actionCode: '',
            damageDescription: '',
            damageCode: '',
            chargeableFlag: '',
          }
        : {}),
    };

    formik.setValues({
      ...formik.values,
      ...unsetValues,
      ...getItemSearchValueFromOption(option),
      ...updateItemDescriptionByItemSearchChange({ option, itemDescription, itemSearch }),
    });
  };
};

export const getItemSearchValueFromOption = (option: SingleValue<OptionsType>) => {
  if (!option?.value) {
    return { itemCode: '', subItemCode: '', itemSearch: '' };
  }
  const [itemCode, subItemCode] = (option.value as string).split('_');
  return { itemCode, subItemCode, itemSearch: option.label };
};

export const getItemSearchFormikBasedValue = (formik: FormikProps<Partial<PricingPlanFormikValues>>) => {
  const { itemCode, subItemCode, itemSearch } = formik.values;
  if (!itemCode || !subItemCode || !itemSearch) {
    return null;
  }
  return { value: `${itemCode}_${subItemCode}`, label: itemSearch };
};

export const updateItemDescriptionByItemSearchChange = ({
  option,
  itemDescription,
  itemSearch,
}: {
  option: OptionsType | null;
  itemDescription?: string;
  itemSearch?: string;
}) => {
  {
    // if we remove itemSearch, and we don't edit itemDescription we should also clean itemDescription
    if (option === null && itemDescription === itemSearch) {
      return { itemDescription: '' };
    } else {
      // if itemSearch filled and itemDescription empty we should set itemDescription like option.label
      if (itemDescription === '') {
        return { itemDescription: option?.label };
      }
      // if itemDescription equal itemSearch we should set itemDescription like option.label
      if (itemDescription === itemSearch) {
        return { itemDescription: option?.label };
      }
    }
  }

  return { itemDescription };
};
