import { Route, Routes } from 'react-router-dom';

import { AuthComponent } from './common/modules/auth/AuthComponent';
import { routesMap, RouteType } from './config';

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthComponent />}>
        {routesMap.map(({ path, element }: RouteType) => (
          <Route path={path} element={element} key={path} />
        ))}
      </Route>
    </Routes>
  );
};
