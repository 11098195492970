export enum ImageUploadStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
  NONE = 'none',
}

/**
 * Image wrapper
 * @interface Image
 * @member {string} id - unique identifier for dnd component
 * @member {string} s3Key - S3 identifier
 * @member {string} objectUrl - local identifier
 * @member {File} file - local file
 */
export interface Image {
  id: string;
  status: ImageUploadStatus;
  s3Key?: string;
  objectUrl?: string;
  file?: File;
}

export interface GuidelineItem {
  header: string;
  subHeader: string;
  images: Image[];
  isDeleted?: boolean;
}
