import { FormikProps, FormikValues } from 'formik';

import { FieldsGroup, FormFieldsGroup } from './components/FormFieldsGroup';
import { Field, FieldTypes } from '../../types/field';
import { FormField } from '../FormField/FormField';

export type GenFormField = FieldsGroup | Field;
export type GenFormFields = GenFormField[];

export interface GenFormConfig {
  fields: GenFormField[];
  container: string;
}

export type GenFormFieldsConfig = Record<string, Partial<Field>>;

export interface FormGeneratorProps {
  formik: FormikProps<FormikValues>;
  config: GenFormConfig;
  fieldsConfig?: GenFormFieldsConfig;
}

export const FormGenerator = ({ formik, config, fieldsConfig }: FormGeneratorProps): JSX.Element => {
  const getFieldComponent = (field: GenFormField, index: number): JSX.Element => {
    switch (field.type) {
      case FieldTypes.GROUP:
        const group = field as FieldsGroup;
        return <FormFieldsGroup key={group.label ?? index} {...group} fieldsConfig={fieldsConfig} formik={formik} />;
      default:
        return <FormField key={field.name} {...field} {...fieldsConfig?.[field.name]} formik={formik} />;
    }
  };
  return <div className={config.container}>{config.fields.map(getFieldComponent)}</div>;
};
