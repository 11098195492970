import { ClockIcon, DollarOIcon } from '@prism2/icons-react/prism';
import { FC } from 'react';

export type FieldIconType = 'dollar' | 'clock';

export interface FieldIconProps {
  type: FieldIconType;
}

export const FieldIcon: FC<FieldIconProps> = ({ type }: FieldIconProps): JSX.Element => {
  const icon = type === 'clock' ? <ClockIcon /> : <DollarOIcon />;
  return (
    <div className="flex rounded-l justify-center border-r-transparent items-center border p-1 w-9 bg-primary text-white border-primary">
      {icon}
    </div>
  );
};
