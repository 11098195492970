import { FormikProps } from 'formik';

import { usePartCostRetailFlag } from './usePartCostRetailFlag';
import { CurrencyFields } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { PricingPlan } from '../../../types/pricingPlans';

// For pricing plan Next Inspect
export const useUSDPartCostRetailFlag = (formik: FormikProps<Partial<PricingPlan>>): void => {
  const { partCost } = formik.values.usd as CurrencyFields;
  usePartCostRetailFlag(formik, 'usd', partCost);
};
