import { FormikProps, FormikValues, useFormik } from 'formik';

import { Client, ClientGroupType } from '../../../types/clients';
import { validationSchemaNextInspectClientRow, validationSchemaWholesaleClientRow } from '../shared/schema';
import { mapClientToNextInspectRowInitialValues, mapClientToWholesaleRowInitialValues } from '../shared/utils';

export interface UseClientTableRowFormikParams {
  client: Client;
  groupType: ClientGroupType;
  onSubmit: () => void;
}

export const useClientTableRowFormik = ({ client, groupType, onSubmit }: UseClientTableRowFormikParams): FormikProps<Partial<Client>> => {
  const isNextInspectClient = groupType === ClientGroupType.NextInspect;
  const flatFeeFlag = isNextInspectClient ? !(client.isMitchellPaint || client.isMitchellRetail || client.isMitchellPart) : false;

  const validationSchema = isNextInspectClient ? validationSchemaNextInspectClientRow : validationSchemaWholesaleClientRow;
  const initialValues = isNextInspectClient ? mapClientToNextInspectRowInitialValues(client) : mapClientToWholesaleRowInitialValues(client);

  return useFormik<FormikValues>({
    initialValues: { ...initialValues, flatFeeFlag },
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });
};
