import { TrashIcon } from '@prism2/icons-react/prism';
import { FC, useId, useState } from 'react';
import { toast } from 'react-toastify';

import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import phrases from '../../../../constants/en_US.json';
import { GuidelineItem, Image } from '../../shared/types';
import { Dropzone } from '../Dropzone/Dropzone';
import { GuidelineTextEdit } from '../GuidelineTextEdit/GuidelineTextEdit';

export interface GuidelineSectionProps {
  onChange: (value: Partial<GuidelineItem>) => void;
  onDelete: () => void;
  isEdit: boolean;
  canDelete: boolean;
  damageGuideLineItem: GuidelineItem;
}

export const GuidelineSection: FC<GuidelineSectionProps> = ({
  onChange,
  isEdit,
  onDelete,
  canDelete,
  damageGuideLineItem,
}): JSX.Element => {
  const toastId = useId();
  const [isRemoveGuidelineModalOpen, setIsRemoveGuidelineModalOpen] = useState(false);
  const onDeleteSection = () => {
    if (!canDelete) {
      toast.error(phrases.DeleteDamageGuideLinesError, { toastId });
      return;
    }
    setIsRemoveGuidelineModalOpen(true);
  };

  return (
    <>
      <DialogModal
        data-testid="guideline-remove-modal"
        isOpen={isRemoveGuidelineModalOpen}
        onClose={() => setIsRemoveGuidelineModalOpen(false)}
        submitButtonText="Confirm"
        onSubmitButton={onDelete}
      >
        <div data-testid="gudieline-remove-modal-title" className="prism-heading-2 self-center">
          Do you want to delete this section?
        </div>
        <div className="grid justify-items-start justify-center my-4">
          <GuidelineTextEdit isDisabled={true} placeholder="Header" value={damageGuideLineItem.header} />
        </div>
      </DialogModal>

      <div data-testid="section" className="border rounded flex flex-col mb-6 py-4 px-6">
        <div className="flex flex-col w-full gap-y-2">
          <div className="flex w-full gap-x-3 relative">
            {isEdit && (
              <button data-testid="section-remove-btn" className="prism-btn opacity-1 absolute right-0 -top-1" onClick={onDeleteSection}>
                <TrashIcon />
              </button>
            )}
            <div className="mt-5 px-0 py-4 flex w-full flex-col gap-2">
              <GuidelineTextEdit
                placeholder="Header"
                value={damageGuideLineItem.header}
                onValueChange={(value) => onChange({ header: value })}
                isDisabled={!isEdit}
              />
              <GuidelineTextEdit
                placeholder="Sub-header"
                value={damageGuideLineItem.subHeader}
                onValueChange={(value) => onChange({ subHeader: value })}
                isDisabled={!isEdit}
              />
            </div>
          </div>
        </div>
        <Dropzone onChange={(images: Image[]) => onChange({ images })} images={damageGuideLineItem.images} isDisabled={!isEdit} />
      </div>
    </>
  );
};
