import type {
  ExportDamageGuidelinesParams,
  ExportDamageGuidelinesResponse,
  FetchDamageGuidelineImagesParams,
  FetchDamageGuidelineImagesResponse,
  FetchDamageGuidelinesParams,
  FetchDamageGuidelinesResponse,
  UpdateDamageGuidelinesParams,
  UpdateDamageGuidelinesResponse,
  UploadDamageGuidelineImageParams,
  UploadDamageGuidelineImageResponse,
} from './guides.types';

import { config } from '../../../config';
import { fetchJson } from '../../utilities/api/fetchJson';
import { fetchMultipart } from '../../utilities/api/fetchMultipart';

export const fetchDamageGuidelines = (params: FetchDamageGuidelinesParams): Promise<FetchDamageGuidelinesResponse> => {
  const { planId, guideType } = params;
  const url = new URL(`${config.API_URL}/damage-guidelines/${planId}/${guideType}`);
  return fetchJson(url, 'GET');
};

export const fetchDamageGuidelineImages = (params: FetchDamageGuidelineImagesParams): Promise<FetchDamageGuidelineImagesResponse> => {
  const { key } = params;
  const url = new URL(`${config.API_URL}/damage-guidelines/images?imageName=${key}`);
  return fetchJson(url, 'GET');
};

export const updateDamageGuidelines = async (params: UpdateDamageGuidelinesParams): Promise<UpdateDamageGuidelinesResponse> => {
  const { planId, guideType, guide } = params;
  const url = new URL(`${config.API_URL}/damage-guidelines/${planId}/${guideType}`);
  return fetchJson(url, 'PUT', guide);
};

export const uploadDamageGuidelineImage = async (params: UploadDamageGuidelineImageParams): Promise<UploadDamageGuidelineImageResponse> => {
  const { file } = params;
  return fetchMultipart({
    url: new URL(`${config.API_URL}/damage-guidelines/images`),
    method: 'POST',
    files: { file },
  });
};

export const exportDamageGuidelines = async (params: ExportDamageGuidelinesParams): Promise<ExportDamageGuidelinesResponse> => {
  const { planId, clientName } = params;
  const url = new URL(`${config.API_URL}/damage-guidelines/${planId}/export`);
  return fetchJson(url, 'POST', { clientName });
};
