interface Serializable {
  toString(): string;
}

/**
 * Simple helper function to append a query parameter to a URL if the value is not undefined
 */
export const appendToQueryIfExists = <T extends Serializable>(url: URL, key: string, value: T | undefined): void => {
  if (value !== undefined && String(value) !== '') {
    url.searchParams.append(key, value.toString());
  }
};
