import { BaseField } from './BaseField/BaseField';
import { NumberField } from './NumberField/NumberField';
import { SelectField } from './SelectField/SelectField';
import { SwitchField } from './SwitchField/SwitchField';
import { TabField } from './TabField/TabField';
import { TextField } from './TextField/TextField';
import { BaseFieldProps, FieldTypes } from '../../types/field';

export const FormField = (props: BaseFieldProps): JSX.Element => {
  const { type, name } = props;

  switch (type) {
    case FieldTypes.TEXT:
      return <TextField {...props} key={name} />;
    case FieldTypes.NUMBER:
      return <NumberField {...props} key={name} />;
    case FieldTypes.SELECT:
      return <SelectField {...props} key={name} />;
    case FieldTypes.SWITCH:
      return <SwitchField {...props} key={name} />;
    case FieldTypes.TABS:
      return <TabField {...props} key={name} />;
    default:
      return <BaseField {...props} />;
  }
};
