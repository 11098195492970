import * as yup from 'yup';

import type { CommonWholesaleFormValues } from '../shared/types';

import { validationSchemaAddWholesale } from '../../../../../shared/schema';

export const validationSchemaBuilder = (builder: CommonWholesaleFormValues) => {
  return validationSchemaAddWholesale.shape({
    sourceGroupCode: builder.shouldCopyPricingPlan === true ? yup.string() : yup.string().required(),
  });
};

export const getValidationSchema = () => {
  return yup.lazy(validationSchemaBuilder);
};
