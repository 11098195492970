import type { OptionsType } from '../../../../../../types/field';
import type { YearForm } from '../modelPlan.form.types';
import type { FormikProps, FormikValues } from 'formik';

import { getYearOptions } from './getYearOptions';

export const getYearsOptionsWithDisabled = (formik: FormikProps<FormikValues>, currentYear?: number): Array<OptionsType> => {
  const { year, years } = formik.values;

  const selectedYears = [year, ...((years as YearForm[])?.map((item) => item.year) || [])];

  return getYearOptions(currentYear)?.map((option: OptionsType) => ({
    ...option,
    isDisabled: selectedYears.includes(option.value as string),
  }));
};
