import { Tab } from '@headlessui/react';
import { Fragment, memo } from 'react';

import type { TabOption } from '../../types/field';

export interface TabMenuProps<T> {
  selectedTab: TabOption<T>;
  onTabChange: (tab: TabOption<T>) => void;
  tabs: TabOption<T>[];
  container?: string;
  size?: number;
}

const getButtonClassNames = (selected: boolean, isDisabled = false, size = 56) => {
  const baseClasses = `w-${size} px-5 box-border font-medium first:rounded-r last:rounded-l`;
  if (selected) {
    return `${baseClasses} bg-blue-800 text-white z-10`;
  }
  if (isDisabled) {
    return `${baseClasses} text-gray-400 cursor-not-allowed border-gray-400 z-100`;
  }
  return `${baseClasses} text-blue-800 hover:text-gray-700 hover:bg-blue-100 first:rounded-l last:rounded-r`;
};

const TabMenu = <T,>({ tabs, selectedTab, onTabChange, size, container = '' }: TabMenuProps<T>) => {
  const idx = tabs.findIndex((tab) => tab.label === selectedTab.label);

  return (
    <div data-testid="tab-menu" className={`flex flex-wrap ${container}`}>
      <Tab.Group selectedIndex={idx}>
        <Tab.List className="h-full flex flex-row rounded border border-blue-800">
          {tabs.map((tab) => {
            return (
              <Tab key={tab.label} as={Fragment}>
                {({ selected }) => {
                  return (
                    <button
                      data-testid={`tab-${tab.label}`}
                      className={getButtonClassNames(selected, tab.isDisabled, size)}
                      onClick={() => !tab.isDisabled && onTabChange(tab)}
                    >
                      {tab.label}
                    </button>
                  );
                }}
              </Tab>
            );
          })}
        </Tab.List>
      </Tab.Group>
    </div>
  );
};

const MemoizedTabMenu = memo(TabMenu) as typeof TabMenu;
export { MemoizedTabMenu as TabMenu };
