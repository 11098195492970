import { Fragment, useEffect, useState } from 'react';

import { ItemSequenceNumber, PricingPlanItem } from '../../../../common/queries/pricingPlans/pricingPlans.types';
import { parseFormik } from '../../../../common/utilities/parseFormik';
import { BlockScreenLoading } from '../../../../components/BlockScreenLoading/BlockScreenLoading';
import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import { FormButtons } from '../../../../components/FormButtons/FormButtons';
import { FormGenerator } from '../../../../components/FormGenerator/FormGenerator';
import { TabMenu } from '../../../../components/TabMenu/TabMenu';
import { ClientGroupType } from '../../../../types/clients';
import { PricingPlan } from '../../../../types/pricingPlans';
import { useCommonWholesaleForm } from '../../hooks/useCommonWholesaleForm';
import { useCommonWholesaleFormik } from '../../hooks/useCommonWholesaleFormik';
import { useCommonWholesaleMutation } from '../../hooks/useCommonWholesaleMutation';
import { useCurrencyFlatFeeFlag } from '../../hooks/useCurrencyFlatFeeFlag';
import { useCurrencyPartCostRetailFlag } from '../../hooks/useCurrencyPartCostRetailFlag';
import { useCurrencyTab } from '../../hooks/useCurrencyTab';
import { useSequences } from '../../hooks/useSequences';
import { pricingPlansFormFieldsWholesale } from '../../shared/fields';
import { PRICING_LINE_ITEM_CURRENCY_TABS } from '../LineItemModal/shared/constants';
import { CurrencyTab, PricingItemModalMode } from '../LineItemModal/shared/types';
import {
  changedCurrencyUsdFields,
  getStatusCurrencyCadFields,
  removeFieldsFromFormikForCurrentClient,
} from '../LineItemModal/shared/utils';
import { SequenceList } from '../SequenceList/SequenceList';

export type CommonWholesaleFormProps = Readonly<{
  pricingPlanItem?: PricingPlanItem;
  mode: PricingItemModalMode;
  groupType: ClientGroupType;
  groupCode: string;
  planId: string;
  onClose: () => void;
  itemSearch?: string;
}>;

export const CommonWholesaleForm = ({
  onClose,
  mode,
  groupType,
  groupCode,
  planId,
  itemSearch,
  pricingPlanItem,
}: CommonWholesaleFormProps): JSX.Element => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const uniqueKey: Partial<PricingPlan> = {
    itemCode: pricingPlanItem?.itemCode ?? '',
    subItemCode: pricingPlanItem?.subItemCode ?? '',
    severityCode: pricingPlanItem?.severityCode ?? '',
    actionCode: pricingPlanItem?.actionCode ?? '',
    damageCode: pricingPlanItem?.damageCode ?? '',
    sequenceNumber: pricingPlanItem?.sequenceNumber ?? 0,
  };

  const onSubmit = () => {
    if (isOpenConfirmationWindow && !isSequenceViewVisible) {
      setIsConfirmationModalOpen(true);
    } else {
      saveFields();
    }
  };

  const onCloseHandler = () => {
    if (mode === PricingItemModalMode.UPDATE) {
      onClose();
      return;
    }

    if (isSequenceViewVisible) {
      setIsSequenceViewVisible(false);
      formik.resetForm();
    } else {
      onClose();
    }
  };

  const { currencyTab, setCurrencyTab, onTabChange } = useCurrencyTab();
  const formik = useCommonWholesaleFormik({ pricingPlanItem, mode, onSubmit, itemSearch });
  const form = useCommonWholesaleForm({ groupType: ClientGroupType.Wholesale, groupCode, formik, currencyTab, mode });
  const { setInitialSequences, sequenceRowItems, onChangeSequenceOrder } = useSequences({ formik, mode });
  const [isSequenceViewVisible, setIsSequenceViewVisible] = useState(false);
  const { isUpdateLoading, isCreateLoading, mutate } = useCommonWholesaleMutation({
    mode,
    formik,
    onSuccess: onClose,
    setInitialSequences,
  });

  useEffect(() => {
    setIsSequenceViewVisible(Boolean(formik.values.sequenceNumbersOrder) && Array.isArray(formik.values.sequenceNumbersOrder));
  }, [formik.values.sequenceNumbersOrder]);

  const onFormReset = (): void => {
    formik.resetForm();
    setIsConfirmationModalOpen(false);
  };

  const saveFields = () => {
    const currentClientValues = removeFieldsFromFormikForCurrentClient(formik.values, groupType);
    const parsedValues = parseFormik<Partial<PricingPlanItem>>(currentClientValues, pricingPlansFormFieldsWholesale);
    const isOrderChanged = !!formik.values.sequenceNumbersOrder?.find((item) => item.oldNumber !== item.newNumber);
    const isModeUpdate = mode === PricingItemModalMode.UPDATE;
    const sequenceNumbersOrder = isOrderChanged && isModeUpdate ? formik.values.sequenceNumbersOrder : undefined;

    const body = {
      ...parsedValues,
      ...(isModeUpdate && { uniqueKey }),
      sequenceNumbersOrder: isModeUpdate ? sequenceNumbersOrder : formik.values.sequenceNumbersOrder,
      groupType,
      planId,
    };

    mutate(body);
  };

  const onConfirmationModalClose = (): void => {
    setIsConfirmationModalOpen(false);
  };

  const onCloseConfirmationModal = (): void => {
    saveFields();
  };

  const openSequenceNumberView = () => {
    // Set items of sequence numbers in setInitialSequences if we don't have sequenceNumbersOrder property in formik
    if (!formik.values.sequenceNumbersOrder) {
      const { sequenceNumber } = formik.values;
      setInitialSequences([
        ...(pricingPlanItem?.sequenceNumbers || []).map((item: ItemSequenceNumber) =>
          // Set item Description field from formik if we changed it
          item.sequenceNumber === sequenceNumber ? { ...item, itemDescription: formik.values.itemDescription! } : item,
        ),
      ]);
    }

    setIsSequenceViewVisible(!isSequenceViewVisible);
  };

  const onConfirmationModalSubmit = (): void => {
    setIsConfirmationModalOpen(false);
    setCurrencyTab(PRICING_LINE_ITEM_CURRENCY_TABS[1]);
  };

  useCurrencyFlatFeeFlag(formik, currencyTab);
  useCurrencyPartCostRetailFlag(formik, currencyTab);

  const isCurrencyTabUsd = currencyTab.value === CurrencyTab.USD;
  const isCurrencyCadFieldsEmpty = getStatusCurrencyCadFields(formik);
  const isChangedCurrencyUsdFields = changedCurrencyUsdFields(formik);
  const isOpenConfirmationWindow = isCurrencyTabUsd && isChangedCurrencyUsdFields && !isCurrencyCadFieldsEmpty;
  const isShowEditSequenceNumbersBtn = mode === PricingItemModalMode.UPDATE;
  const isTabVisible = !isSequenceViewVisible;
  const isLoading = isUpdateLoading || isCreateLoading;
  const isSaveButtonDisabled = !formik.dirty || !formik.isValid || isLoading;
  const isEditSequenceNumbersBtnDisabled = (pricingPlanItem?.sequenceNumbers || []).length <= 1 || !formik.values.itemDescription;

  return (
    <Fragment>
      <DialogModal
        data-testid="confirmation-modal-window"
        isOpen={isConfirmationModalOpen}
        onClose={onConfirmationModalClose}
        onCancelButton={onCloseConfirmationModal}
        onSubmitButton={onConfirmationModalSubmit}
        submitButtonText="YES"
        cancelButtonText="NO"
      >
        <div className="text-center mb-7 prism-heading-2 self-center" data-testid="confirmation-modal-title">
          You have added or updated values for USD.
          <br />
          Do you also want to add/update values for CAD?
        </div>
      </DialogModal>

      {isTabVisible && (
        <div className="pb-1">
          <TabMenu tabs={PRICING_LINE_ITEM_CURRENCY_TABS} onTabChange={onTabChange} selectedTab={currencyTab} size={24} container="h-9" />
        </div>
      )}

      <form
        data-testid="common-form"
        className="border-dashed border-2 p-8 react-select-reset"
        onSubmit={formik.handleSubmit}
        onReset={onFormReset}
      >
        <div>
          <>
            {isSequenceViewVisible ? (
              <SequenceList onChangeSequenceOrder={onChangeSequenceOrder} sequenceRowItems={sequenceRowItems} />
            ) : (
              <FormGenerator data-testid="common-wholesale-form" formik={formik} config={form.config} fieldsConfig={form.fieldsConfig} />
            )}
          </>
        </div>

        <FormButtons isSaveButtonDisabled={isSaveButtonDisabled} onCancelButtonClick={onCloseHandler}>
          {isShowEditSequenceNumbersBtn && (
            <button
              type="button"
              data-testid="edit-sequence-number-btn"
              className="prism-btn w-60 flex justify-center"
              onClick={openSequenceNumberView}
              disabled={isEditSequenceNumbersBtnDisabled}
            >
              {isSequenceViewVisible ? 'Edit line item' : 'Edit sequence number'}
            </button>
          )}
        </FormButtons>
        {isLoading && <BlockScreenLoading />}
      </form>
    </Fragment>
  );
};
