import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useEffect, useId, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { GuidelineExportModal } from './components/GuidelineExportModal/GuidelineExportModal';
import { GuidelineSection } from './components/GuidelineSection/GuidelineSection';
import { GUIDE_TYPES } from './shared/constants';
import { GuidelineItem, Image, ImageUploadStatus } from './shared/types';
import { isDifference, mapGuidelineItemsToRawSections, mapRawSectionsToGuidelineItems } from './shared/utils';
import { useNavigator } from '../../common/modules/navigation/useNavigator';
import { fetchDamageGuidelines, updateDamageGuidelines, uploadDamageGuidelineImage } from '../../common/queries/guides/guides.api';
import { FetchDamageGuidelinesResponse } from '../../common/queries/guides/guides.types';
import { DialogModal } from '../../components/DialogModal/DialogModal';
import { ErrorWrapper } from '../../components/ErrorWrapper/ErrorWrapper';
import { Page } from '../../components/Page/Page';
import phrases from '../../constants/en_US.json';
import { QueryKey } from '../../constants/queries';
import { ClientNEXTInspectSubTab } from '../ClientListPage/shared/types';

export const DamageGuidelinesPage: FC = (): JSX.Element => {
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [guidelineItems, setGuidelineItems] = useState<GuidelineItem[]>([]);
  const [guideType, setGuideType] = useState(GUIDE_TYPES[0]);
  const [nextGuideType, setNextGuideType] = useState(guideType);
  const [path, setPath] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const { id: planId } = useParams();
  const queryClient = useQueryClient();
  const { navigateToClientsNextInspect } = useNavigator();
  const location = useLocation();
  const from = (location.state as { from?: ClientNEXTInspectSubTab })?.from ?? ClientNEXTInspectSubTab.ACTIVE_MANHEIM;
  const toastId = useId();
  const canDelete = guidelineItems.filter((item) => !item.isDeleted).length >= 2;
  const showError = (msg?: string) => toast(msg ?? `Can't find data`, { type: 'error' });

  const { isFetching, data, error } = useQuery<FetchDamageGuidelinesResponse, Error>({
    queryKey: [QueryKey.FETCH_DAMAGE_GUIDELINES, guideType, planId],
    queryFn: () =>
      fetchDamageGuidelines({
        planId: planId ?? '',
        guideType,
      }),
    onError: () => {
      navigateToClientsNextInspect(from);
      showError(phrases.damageGuideLinesNotFound);
    },
    keepPreviousData: true,
    staleTime: Infinity,
  });

  /** extracts images that have been added but not yet uploaded */
  const getNewImages = (): Image[] => {
    return guidelineItems.reduce<Image[]>((acc, item) => {
      return [...acc, ...item.images.filter((image) => image.status !== ImageUploadStatus.SUCCESS && image.file)];
    }, []);
  };

  /** uploads array of images and updates image state */
  const uploadNewImages = async (images: Image[]): Promise<void> => {
    let isFailed = false;
    await Promise.all(
      images.map(async (image) => {
        try {
          const result = await uploadDamageGuidelineImage({ file: image.file as File });
          image.status = ImageUploadStatus.SUCCESS;
          image.s3Key = result.image;
        } catch (e) {
          image.status = ImageUploadStatus.FAIL;
          isFailed = true;
        }
      }),
    );
    if (isFailed) {
      throw new Error('Cannot upload all provided files');
    }
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const imagesToUpload = getNewImages();
      await uploadNewImages(imagesToUpload);
      const sections = mapGuidelineItemsToRawSections(guidelineItems);
      const guide = { version: data?.version ?? 0, sections };
      await updateDamageGuidelines({ guide, guideType, planId: planId ?? '' });
    },
    onError: (e: Error) => toast.error(e.message ?? phrases.updateDamageGuideLinesError),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_DAMAGE_GUIDELINES] });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      toast.info(phrases.updateDamageGuideLinesSuccess);
      setIsEdit(false);
    },
  });

  const onCreate = (): void => {
    setGuidelineItems((sections) => [...sections, { header: '', subHeader: '', images: [] }]);
  };

  const onDelete = (index: number): void => {
    setGuidelineItems((sections) =>
      sections.map((item, i) => {
        if (i === index) return { ...item, isDeleted: true };
        return item;
      }),
    );
  };

  const onChange = (index: number, value: Partial<GuidelineItem>): void => {
    setGuidelineItems((sections) =>
      sections.map((item, i) => {
        if (i === index) return { ...item, ...value } as GuidelineItem;
        return item;
      }),
    );
  };

  const onCancel = (): void => {
    setGuidelineItems(mapRawSectionsToGuidelineItems(data?.sections));
    setIsEdit(false);
  };

  const onSave = (): void => {
    if (!guidelineItems.length) {
      toast.error(phrases.saveDamageGuideLinesError, { toastId });
      return;
    }

    mutate();
  };

  const onGuideTypeChange = (guideType: string) => {
    const currentSections = mapGuidelineItemsToRawSections(guidelineItems);
    const initialSections = mapGuidelineItemsToRawSections(mapRawSectionsToGuidelineItems(data?.sections));

    if (isDifference(currentSections, initialSections)) {
      setNextGuideType(guideType);
      setIsDiscardModalOpen(true);
      return;
    }

    onCancel();
    setGuideType(guideType);
  };

  const onRedirect = (path: string) => {
    const currentSections = mapGuidelineItemsToRawSections(guidelineItems);
    const initialSections = mapGuidelineItemsToRawSections(mapRawSectionsToGuidelineItems(data?.sections));

    if (isDifference(currentSections, initialSections)) {
      setPath(path);
      setIsDiscardModalOpen(true);
      return;
    }

    navigateToClientsNextInspect(from);
  };

  const onConfirm = (guideType: string) => {
    onCancel();
    if (path) {
      return navigateToClientsNextInspect(from);
    }
    setGuideType(guideType);
  };

  const onDiscardModalClose = (): void => {
    setIsDiscardModalOpen(false);
  };

  const onDiscardModalSubmit = (): void => {
    onConfirm(nextGuideType);
  };

  useEffect(() => {
    setGuidelineItems(mapRawSectionsToGuidelineItems(data?.sections ?? []));
  }, [data]);

  const isPageLoading = isFetching || isLoading;

  return (
    <Page id="damage-guidelines" title="Damage guidelines" isLoading={isPageLoading}>
      <ErrorWrapper error={error}>
        <GuidelineExportModal isOpen={isOpenExport} onClose={() => setIsOpenExport(false)} />

        <DialogModal
          isOpen={isDiscardModalOpen}
          onClose={onDiscardModalClose}
          onSubmitButton={onDiscardModalSubmit}
          submitButtonText="Discard"
        >
          <div className="items-center prism-heading-2 self-center mb-8">If you switch to another tab you need to discard changes</div>
        </DialogModal>

        <div className="sticky top-0 z-10 bg-white">
          <ul data-testid="guide-tabs" className="list-none justify-center flex mb-4">
            {GUIDE_TYPES.map((type) => (
              <li
                data-testid={`${type}-tab`}
                onClick={() => onGuideTypeChange(type)}
                className={classNames('prism-tab cursor-pointer mx-2', { selected: guideType === type })}
                key={type}
              >
                {type}
              </li>
            ))}
          </ul>

          <div className="flex items-start gap-4 mb-3 h-14" data-testid="group-buttons">
            <button onClick={() => onRedirect(`/`)} data-testid="client-list-btn" className="prism-btn">
              Client list
            </button>
            <button onClick={() => setIsOpenExport(true)} data-testid="export-btn" className="prism-btn">
              Export
            </button>
            <button onClick={() => setIsEdit(true)} data-testid="edit-btn" className={classNames('prism-btn', { hidden: isEdit })}>
              Edit
            </button>
            <button onClick={onCreate} data-testid="add-btn" className={classNames('prism-btn', { hidden: !isEdit })}>
              Add new section
            </button>
            <button onClick={onCancel} data-testid="cancel-btn" className={classNames('prism-btn w-20', { hidden: !isEdit })}>
              Cancel
            </button>
            <button onClick={onSave} data-testid="save-btn" className={classNames('prism-btn w-20', { hidden: !isEdit })}>
              Save
            </button>
          </div>
        </div>

        <div className="w-full">
          <div data-testid="guideline-sections">
            {guidelineItems.map(
              (guidelineItem, index) =>
                !guidelineItem.isDeleted && (
                  <GuidelineSection
                    canDelete={canDelete}
                    key={index}
                    isEdit={isEdit}
                    onChange={(value: Partial<GuidelineItem>) => onChange(index, value)}
                    onDelete={() => onDelete(index)}
                    damageGuideLineItem={guidelineItem}
                  />
                ),
            )}
          </div>
        </div>
      </ErrorWrapper>
    </Page>
  );
};
