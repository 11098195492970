import { FormikProps } from 'formik';
import * as yup from 'yup';

import { CreateModelPlanFormValues } from '../../shared/modelPlan.form.types';
import { useModelPlanFormik } from '../../shared/useModelPlanFormik';

export const INIT_DATA: CreateModelPlanFormValues = {
  make: '',
  model: '',
  year: '',
  trims: [],
  isDefault: false,
  years: [],
};

export const createModelPlanValidationSchema = yup.object().shape({
  year: yup.string().matches(/^[0-9]+$/, 'Only numbers are allowed for this field'),
  make: yup.string().required('Make is required'),
  model: yup.string().required('Model is required'),
  trims: yup.array().of(
    yup.object({
      label: yup.string(),
      value: yup.string(),
    }),
  ),
  isDdefault: yup.boolean(),
  years: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      year: yup.string().required('Year is required'),
      trims: yup.array().of(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        }),
      ),
      isDefault: yup.boolean(),
    }),
  ),
  sourcePlanId: yup.string(),
});

export interface UseModelPlanFormikParams {
  onSubmit: (values: CreateModelPlanFormValues) => void;
}

export type UseModelPlanFormik = FormikProps<CreateModelPlanFormValues>;

export const useCreateModelPlanFormik = ({ onSubmit }: UseModelPlanFormikParams): UseModelPlanFormik =>
  useModelPlanFormik({ onSubmit, initialValues: INIT_DATA, validationSchema: createModelPlanValidationSchema });
