import { FC } from 'react';

import { BaseFieldProps } from '../../../types/field';
import { Loading } from '../../Loading/Loading';
import { FieldIcon, FieldIconType } from '../Icon/Icon';
import { Label } from '../Label/Label';
import { getValueByName } from '../utils';

export interface NumberFieldProps extends BaseFieldProps {
  labelClass?: string;
  classContainer?: string;
  disabled?: boolean;
  isFetching?: boolean;
  icon?: FieldIconType;
  placeholder?: string;
  validateOnChange?: boolean;
}

export const NumberField: FC<NumberFieldProps> = ({
  formik,
  name,
  label,
  disabled,
  isFetching,
  classContainer,
  icon,
  onChange,
  placeholder,
  validateOnChange,
  required,
}: NumberFieldProps): JSX.Element => {
  const disabledStyles = disabled ? 'text-gray-500 bg-gray-50 border-gray-300' : '';
  const showPlaceholder = isFetching ? '' : placeholder ?? 'N/A';
  const inputStyles =
    '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ' +
    `${icon ? 'rounded-r' : 'rounded'} w-full ${disabledStyles}`;

  const handleChange = (newValue: string): void => {
    if (validateOnChange) {
      formik?.setFieldTouched(name, true);
    }

    const newValueNumber = newValue === '' ? null : Number(newValue);

    if (onChange) {
      return onChange(newValueNumber as never);
    }

    return formik?.setFieldValue(name, newValueNumber);
  };

  return (
    <div className={`relative ${classContainer ?? ''}`}>
      {label && <Label name={name} label={label} disabled={disabled} required={required} />}
      {isFetching && (
        <div className="absolute top-8 right-4">
          <Loading />
        </div>
      )}
      <div className="flex">
        {icon && <FieldIcon type={icon} />}
        <input
          disabled={disabled}
          data-testid={`${name}-field`}
          type="number"
          className={inputStyles}
          name={name}
          placeholder={showPlaceholder}
          value={(getValueByName(name, formik) as unknown as string | number) ?? ''}
          onChange={(event) => handleChange(event.target.value)}
          onBlur={formik?.handleBlur}
        />
      </div>

      {formik?.errors[name] && formik.touched[name] && (
        <span className="text-red-500" data-testid={`${name}-error`}>
          {formik.errors[name] as string}
        </span>
      )}
    </div>
  );
};
