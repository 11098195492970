import { ColumnFilter, SortingState } from '@tanstack/react-table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

export type ParamConfig<T = unknown> = {
  type: 'string' | 'boolean' | 'number';
  default?: T;
};

type InferString<T extends ParamConfig<unknown>> = T['type'] extends 'string' ? string : never;
type InferBoolean<T extends ParamConfig<unknown>> = T['type'] extends 'boolean' ? boolean : never;
type InferNumber<T extends ParamConfig<unknown>> = T['type'] extends 'number' ? number : never;

export type InferTypeFromConfig<T extends ParamConfig<unknown>> = InferString<T> | InferBoolean<T> | InferNumber<T>;

type ConvertFilterToSearchParams = { filters: ColumnFilter[]; paramsDefinition: ParamConfigMap };

type SortSearchParam = Record<`sort-${string}`, 'desc' | 'asc'>;
export const convertSortToSearchParams = ({ sorting }: { sorting: SortingState }): SortSearchParam => {
  const params = {} as SortSearchParam;

  sorting.forEach((sort) => {
    const id: keyof SortSearchParam = `sort-${sort.id}`;
    params[id] = sort.desc ? 'desc' : 'asc';
  });

  return params;
};

export const convertFiltersDefinitionsToSearchParams = <T extends ParamConfigMap>({
  filters,
  paramsDefinition,
}: ConvertFilterToSearchParams): Partial<StateTypeFromConfig<T>> => {
  const params = {} as StateTypeFromConfig<T>;

  Object.entries(paramsDefinition).forEach(([paramName, paramDefinition]) => {
    const filter = filters.find((filter) => filter.id === paramName);

    if (!filter) {
      params[paramName as keyof StateTypeFromConfig<T>] = paramDefinition.default as InferTypeFromConfig<T[keyof T]>;
      return;
    }

    params[paramName as keyof StateTypeFromConfig<T>] = filter.value as InferTypeFromConfig<T[keyof T]>;
  });

  return params;
};

export type ParamConfigMap = Record<string, ParamConfig<unknown>>;

export type StateTypeFromConfig<T extends ParamConfigMap> = {
  [K in keyof T]: InferTypeFromConfig<T[K]>;
};

export type SearchParamsStateType = Record<string, ParamConfig<unknown>>;

export const getAllCurrentParams = (searchParams: URLSearchParams) => {
  const allUrlParams: Record<string, string | number | boolean> = {};
  searchParams.forEach((value, key) => {
    const currentValue = allUrlParams[key];
    if (!currentValue) {
      allUrlParams[key] = value;
    }
  });
  return allUrlParams;
};

const paramToBool = (paramName: string, paramDefinition: ParamConfig, searchParams: URLSearchParams) => {
  const paramValue = searchParams.get(paramName);
  if (paramValue === 'true') return true;
  if (paramValue === 'false' || paramValue === '0') return false;

  return paramDefinition.default;
};

const paramToValue = (paramName: string, paramDefinition: ParamConfig, searchParams: URLSearchParams) => {
  const paramValue = searchParams.get(paramName);
  if (paramValue) {
    return paramDefinition.type === 'number' ? Number(paramValue) : paramValue;
  }
  return paramDefinition.default;
};

const getValues = (paramsDefinition: SearchParamsStateType, searchParams: URLSearchParams) => {
  const values: Record<string, unknown> = {};
  for (const [paramName, paramDefinition] of Object.entries(paramsDefinition)) {
    if (paramDefinition.type === 'boolean') {
      values[paramName] = paramToBool(paramName, paramDefinition, searchParams);
    } else {
      const value = paramToValue(paramName, paramDefinition, searchParams);
      if (!value) {
        continue;
      }
      values[paramName] = value;
    }
  }
  return values;
};

export type UseSearchParamsStateType<T extends ParamConfigMap> = {
  searchParamsState: StateTypeFromConfig<T>;
  setSearchParamsState: (newValues: Partial<StateTypeFromConfig<T>>, skipSync: boolean) => void;
};

export const useSearchParamsState = <T extends ParamConfigMap>(paramsDefinition?: T): UseSearchParamsStateType<T> => {
  const [searchParamsState, setSearchParamsState] = useState<Partial<StateTypeFromConfig<T> | null>>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);

  const skipSyncWithUrl = useRef(false);

  const setState = useCallback(
    (params: Partial<StateTypeFromConfig<T>>, skipSync: boolean) => {
      setSearchParamsState(params);
      skipSyncWithUrl.current = skipSync;
    },
    [setSearchParamsState],
  );

  const values = paramsDefinition ? getValues(paramsDefinition, searchParams) : {};

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }

    if (!searchParamsState) {
      return;
    }

    const allCurrentParams = getAllCurrentParams(searchParams);
    const combinedParams = { ...allCurrentParams, ...searchParamsState };

    Object.entries(searchParamsState as Record<string, unknown>).forEach(([key, value]) => {
      if (typeof value === 'undefined') {
        delete combinedParams[key];
      }
    });

    setSearchParams(combinedParams as URLSearchParamsInit, { replace: true });
  }, [searchParamsState]);

  return { searchParamsState: values as StateTypeFromConfig<T>, setSearchParamsState: setState };
};
