import { useMutation } from '@tanstack/react-query';
import { FormikValues, useFormik } from 'formik';
import { useEffect, useId, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { exportDamageGuidelines } from '../../../../common/queries/guides/guides.api';
import { ExportDamageGuidelinesParams } from '../../../../common/queries/guides/guides.types';
import { BlockScreenLoading } from '../../../../components/BlockScreenLoading/BlockScreenLoading';
import { FormField } from '../../../../components/FormField/FormField';
import { Modal } from '../../../../components/Modal/Modal';
import phrases from '../../../../constants/en_US.json';
import { FieldTypes } from '../../../../types/field';

export interface GuidelineExportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const initData: ExportDamageGuidelinesParams = {
  planId: '',
  clientName: '',
};

const validationSchema = yup.object({
  groupName: yup.string().required(''),
});

export const GuidelineExportModal = ({ isOpen, onClose }: GuidelineExportModalProps) => {
  const toastId = useId();
  const { id: planId } = useParams();
  const [exportUrl, setExportUrl] = useState('');

  const { mutate, data, isLoading } = useMutation({
    mutationFn: (params: ExportDamageGuidelinesParams) => exportDamageGuidelines(params),
    onError: (e: Error) => toast(e.message, { type: 'error' }),
  });

  useEffect(() => {
    setExportUrl(data?.url ?? '');
  }, [data]);

  const formik = useFormik<FormikValues>({
    initialValues: initData,
    validateOnChange: true,
    validationSchema,
    onSubmit: () => {
      mutate({ planId: planId ?? '', clientName: formik.values?.groupName as string });
    },
  });

  const onCancel = () => {
    onClose();
    setExportUrl('');
    formik.resetForm();
  };

  const onCopy = (text: string) => {
    void navigator.clipboard.writeText(text);
    onCancel();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    toast(phrases.copy, { type: 'success', toastId });
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <div data-testid="guideline-export-modal" className="flex flex-col">
        <div data-testid="guideline-export-modal-title" className="prism-heading-2 text-center self-center mb-8">
          Export damage guideline
        </div>
        {exportUrl ? (
          <div className="flex flex-col">
            <span className="mb-4">
              Link to pull request:
              <a data-testid="guideline-export-modal-link" className="prism-link" href={exportUrl} target="blank">
                {exportUrl}
              </a>
            </span>
            <button onClick={() => onCopy(exportUrl)} data-testid="guideline-export-modal-copy-btn" className="prism-btn">
              Copy
            </button>
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit} onReset={() => formik.resetForm()}>
            <div className="mb-8">
              <FormField name="groupName" label="Group name" type={FieldTypes.TEXT} key="groupName" formik={formik} required={true} />
            </div>
            <div className="flex gap-4 items-center justify-center">
              <button type="submit" data-testid="guideline-export-modal-submit-btn" className="prism-btn">
                Export
              </button>
              <button data-testid="guideline-export-modal-cancel-btn" className="prism-btn" onClick={onCancel}>
                Cancel
              </button>
              {isLoading && <BlockScreenLoading />}
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};
