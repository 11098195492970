import type { FieldsGroup } from '../../../components/FormGenerator/components/FormFieldsGroup';
import type { GenFormFields } from '../../../components/FormGenerator/FormGenerator';

import { noOptionsMessage } from '../../../common/utilities/noOptionsMessage';
import { Field, FieldTypes } from '../../../types/field';

export const damageCodeField = {
  name: 'damageCode',
  label: 'Damage Code',
  disabled: true,
  type: FieldTypes.TEXT,
};

const repairHours: Field = {
  name: 'repairHours',
  label: 'Hours',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'clock',
};

const repairDollarsUsd: Field = {
  name: 'usd.repairDollars',
  label: 'Dollars',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'dollar',
};

const repairDollarsCad: Field = {
  ...repairDollarsUsd,
  name: 'cad.repairDollars',
};

const flatFeeFlag: Field = {
  name: 'flatFeeFlag',
  label: 'Flat fee',
  type: FieldTypes.SWITCH,
  vertical: true,
};

const paintHours: Field = {
  name: 'paintHours',
  label: 'Hours',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'clock',
};

const paintDollars: Field = {
  name: 'paintDollars',
  label: 'Dollars',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'dollar',
};

const paintDollarsUsd: Field = {
  ...paintDollars,
  name: 'usd.paintDollars',
};

const paintDollarsCad: Field = {
  ...paintDollars,
  name: 'cad.paintDollars',
};

const partHours: Field = {
  name: 'partHours',
  label: 'Hours',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'clock',
};

const partDollars: Field = {
  name: 'partDollars',
  label: 'Dollars',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'dollar',
};

const partDollarsUsd: Field = {
  ...partDollars,
  name: 'usd.partDollars',
};

const partDollarsCad: Field = {
  ...partDollars,
  name: 'cad.partDollars',
};

const partCostUsd: Field = {
  name: 'usd.partCost',
  label: 'Cost',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'dollar',
};

const partCostCad: Field = {
  ...partCostUsd,
  name: 'cad.partCost',
};

const costRetailFlagUsd: Field = {
  name: 'usd.partCostRetailFlag',
  label: 'Cost retail flag',
  disabled: false,
  type: FieldTypes.SELECT,
  placeholder: 'N/A',
  options: [
    { value: '', label: 'N/A' },
    { value: 'C', label: 'Cost' },
    { value: 'R', label: 'Retail' },
  ],
};

const costRetailFlagCad: Field = {
  ...costRetailFlagUsd,
  name: 'cad.partCostRetailFlag',
};

export const artChargeableFlag: Field = {
  name: 'chargeableFlag',
  label: 'Chargeable',
  disabled: true,
  type: FieldTypes.TEXT,
  required: true,
};

const paintHoursMultiplier: Field = {
  name: 'paintHoursMultiplier',
  label: 'Paint hours',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'clock',
};

const partHoursMultiplier: Field = {
  name: 'partHoursMultiplier',
  label: 'Part hours',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'clock',
};

const repairHoursMultiplier: Field = {
  name: 'repairHoursMultiplier',
  label: 'Repair hours',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'clock',
};

const partCostMultiplier: Field = {
  name: 'partCostMultiplier',
  label: 'Part cost',
  disabled: false,
  type: FieldTypes.NUMBER,
  icon: 'dollar',
};

// NEXT INSPECT GROUPS
const repairGroupNextInspect: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [repairHours, repairDollarsUsd, flatFeeFlag],
  label: 'Repair',
  classes: {
    mainContainer: 'flex flex-col w-28',
    label: 'mb-3',
    fieldsContainer: 'flex flex-col gap-3',
  },
};

const partGroupNextInspect: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [partHours, partCostUsd, costRetailFlagUsd],
  label: 'Part',
  classes: {
    mainContainer: 'flex flex-col w-28',
    label: 'mb-3',
    fieldsContainer: 'flex flex-col gap-3',
  },
};

const multipliersLeftColGroupNextInspect: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [repairHoursMultiplier, paintHoursMultiplier],
  classes: {
    mainContainer: 'flex flex-col w-28',
    fieldsContainer: 'flex flex-col gap-3',
  },
};

const multipliersRightColGroupNextInspect: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [partCostMultiplier, partHoursMultiplier],
  classes: {
    mainContainer: 'flex flex-col w-28',
    fieldsContainer: 'flex flex-col gap-3',
  },
};

const multipliersGroupNextInspect: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [multipliersLeftColGroupNextInspect, multipliersRightColGroupNextInspect],
  label: 'Multipliers',
  classes: {
    mainContainer: 'flex flex-col w-64',
    label: 'mb-3',
    fieldsContainer: 'flex flex-row gap-6',
  },
};
const paintGroupNextInspect: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [paintHours],
  label: 'Paint',
  classes: {
    mainContainer: 'flex flex-col',
    label: 'mb-3',
    fieldsContainer: 'flex flex-row gap-2 w-28',
  },
};
export const nextInspectFormFields: GenFormFields = [
  repairGroupNextInspect,
  paintGroupNextInspect,
  partGroupNextInspect,
  multipliersGroupNextInspect,
];
export const nextInspectFormFieldsGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: nextInspectFormFields,
  classes: {
    fieldsContainer: 'grid grid-cols-2 md:grid-cols-5 gap-3 mt-16 mb-8 ',
  },
};

// WHOLESALE GROUPS
const repairGroupWholesale: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [repairHours, repairDollarsUsd],
  label: 'Repair',
  classes: {
    mainContainer: 'flex flex-col w-28',
    label: 'mb-3',
    fieldsContainer: 'flex flex-col gap-3',
  },
};

const repairGroupWholesaleUsd: FieldsGroup = {
  ...repairGroupWholesale,
  fields: [repairHours, repairDollarsUsd],
};

const repairGroupWholesaleCad: FieldsGroup = {
  ...repairGroupWholesale,
  fields: [repairHours, repairDollarsCad],
};

const paintGroupWholesale: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [paintHours, paintDollars],
  label: 'Paint',
  classes: {
    mainContainer: 'flex flex-col w-28',
    label: 'mb-3',
    fieldsContainer: 'flex flex-col gap-3',
  },
};

const paintGroupWholesaleUsd: FieldsGroup = {
  ...paintGroupWholesale,
  fields: [paintHours, paintDollarsUsd],
};

const paintGroupWholesaleCad: FieldsGroup = {
  ...paintGroupWholesale,
  fields: [paintHours, paintDollarsCad],
};

const partGroupLeftColGroupWholesale: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [partHours, partDollars],
  classes: {
    mainContainer: 'flex flex-col w-28',
    fieldsContainer: 'flex flex-col gap-3',
  },
};

const partGroupLeftColGroupWholesaleUsd: FieldsGroup = {
  ...partGroupLeftColGroupWholesale,
  fields: [partHours, partDollarsUsd],
};

const partGroupLeftColGroupWholesaleCad: FieldsGroup = {
  ...partGroupLeftColGroupWholesale,
  fields: [partHours, partDollarsCad],
};

const partGroupRightColGroupWholesale: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [partCostUsd, costRetailFlagUsd],
  classes: {
    mainContainer: 'flex flex-col w-28',
    fieldsContainer: 'flex flex-col gap-3',
  },
};

const partGroupRightColGroupWholesaleUsd: FieldsGroup = {
  ...partGroupRightColGroupWholesale,
  fields: [partCostUsd, costRetailFlagUsd],
};

const partGroupRightColGroupWholesaleCad: FieldsGroup = {
  ...partGroupRightColGroupWholesale,
  fields: [partCostCad, costRetailFlagCad],
};

const partGroupWholesale: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [partGroupLeftColGroupWholesale, partGroupRightColGroupWholesale],
  label: 'Part',
  classes: {
    mainContainer: 'flex flex-col w-72',
    label: 'mb-3',
    fieldsContainer: 'flex flex-row gap-14',
  },
};

const partGroupWholesaleUsd: FieldsGroup = {
  ...partGroupWholesale,
  fields: [partGroupLeftColGroupWholesaleUsd, partGroupRightColGroupWholesaleUsd],
};

const partGroupWholesaleCad: FieldsGroup = {
  ...partGroupWholesale,
  fields: [partGroupLeftColGroupWholesaleCad, partGroupRightColGroupWholesaleCad],
};

export const wholesaleFormUsdFields: GenFormFields = [repairGroupWholesaleUsd, paintGroupWholesaleUsd, partGroupWholesaleUsd];
export const wholesaleFormFieldsUsdGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: wholesaleFormUsdFields,
  classes: {
    fieldsContainer: 'grid grid-cols-2 md:grid-cols-4 gap-3 my-8',
  },
};

export const wholesaleFormCadFields: GenFormFields = [repairGroupWholesaleCad, paintGroupWholesaleCad, partGroupWholesaleCad];

export const wholesaleFormFieldsCadGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: wholesaleFormCadFields,
  classes: {
    fieldsContainer: 'grid grid-cols-2 md:grid-cols-4 gap-3 my-8',
  },
};

export const pricingPlansFormFieldsNextInspect: Field[] = [
  repairHoursMultiplier,
  paintHoursMultiplier,
  partCostMultiplier,
  partHoursMultiplier,
  partCostUsd,
  repairDollarsUsd,
  costRetailFlagUsd,
];

export const pricingPlansFormFieldsWholesale: Field[] = [
  repairHours,
  paintHours,
  partHours,
  partCostUsd,
  repairDollarsUsd,
  costRetailFlagUsd,
  paintDollarsUsd,
  partDollarsUsd,
  repairDollarsCad,
  partCostCad,
  costRetailFlagCad,
  paintDollarsCad,
  partDollarsCad,
];

export const deletePricingPlansModalFields: Field[] = [
  artChargeableFlag,
  {
    name: 'planId',
    label: 'Plan id',
    disabled: true,
    type: FieldTypes.TEXT,
  },
  {
    name: 'itemCode',
    label: 'Item code',
    disabled: false,
    type: FieldTypes.TEXT,
  },
  {
    name: 'itemDescription',
    label: 'Item Description',
    disabled: false,
    type: FieldTypes.TEXT,
  },
  {
    name: 'subItemCode',
    label: 'Sub item code',
    disabled: false,
    type: FieldTypes.TEXT,
  },
  {
    name: 'severityCode',
    label: 'Severity code',
    disabled: false,
    type: FieldTypes.TEXT,
  },
  {
    name: 'actionCode',
    label: 'Action code',
    disabled: false,
    type: FieldTypes.TEXT,
  },
  {
    name: 'damageCode',
    label: 'Damage code',
    disabled: false,
    type: FieldTypes.TEXT,
  },
];

export const artItemSearchOptional: Field = {
  name: 'itemSearch',
  label: 'Item search',
  type: FieldTypes.SELECT,
  isClearable: true,
  placeholder: 'Type...',
  noOptionsMessage,
};

export const artItemSearch: Field = {
  ...artItemSearchOptional,
  required: true,
};

export const itemDescriptionOptional: Field = {
  name: 'itemDescription',
  label: 'Item description',
  disabled: false,
  type: FieldTypes.TEXT,
  validateOnChange: true,
};

export const itemDescription: Field = {
  ...itemDescriptionOptional,
  required: true,
};

export const artDamageDescription: Field = {
  name: 'damageDescription',
  label: 'Damage description',
  type: FieldTypes.SELECT,
  isClearable: true,
  required: true,
};

export const artSeverityDescription: Field = {
  name: 'severityDescription',
  label: 'Severity description',
  type: FieldTypes.SELECT,
  isClearable: true,
  required: true,
};

export const artWholesaleSeverityDescription: Field = {
  ...artSeverityDescription,
  required: false,
};

export const artActionDescription: Field = {
  name: 'actionDescription',
  label: 'Action description',
  disabled: true,
  type: FieldTypes.TEXT,
  required: true,
};

export const artActionCode: Field = {
  name: 'actionCode',
  label: 'Action description',
  disabled: true,
  type: FieldTypes.SELECT,
  isClearable: true,
  required: true,
};

export const artNextInspectFormFieldsGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [artItemSearch, itemDescription, artDamageDescription, artActionDescription, artSeverityDescription, artChargeableFlag],
  classes: {
    fieldsContainer: 'grid grid-cols-2 gap-y-4 gap-x-12',
  },
};

export const artWholesaleFormFieldsGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [artItemSearchOptional, artActionCode, artWholesaleSeverityDescription, itemDescriptionOptional],
  classes: {
    fieldsContainer: 'grid grid-cols-2 gap-y-4 gap-x-12',
  },
};
