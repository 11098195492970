import { useQuery } from '@tanstack/react-query';
import { ColumnFilter, ColumnSort } from '@tanstack/react-table';

import { fetchModelPlans } from '../../../common/queries/modelPlans/modelPlans.api';
import { FetchModelPlansResponse } from '../../../common/queries/modelPlans/modelPlans.types';
import { QueryKey } from '../../../constants/queries';
import { ClientSubTab, ClientWholesaleSubTab } from '../shared/types';

export interface UseModelPlansProps {
  subTab: ClientSubTab;
  offset: number;
  limit: number;
  filters: ColumnFilter[];
  sort: ColumnSort[];
}

export interface UseModelPlans {
  data: FetchModelPlansResponse | undefined;
  error: Error | null;
  isFetching: boolean;
  isFetched: boolean;
  isSuccess: boolean;
}

export const useModelPlans = (props: UseModelPlansProps): UseModelPlans => {
  const { subTab, offset, limit, filters, sort } = props;
  const definedFilters = filters.filter((filter) => filter.value !== undefined && filter.value !== null);
  return useQuery<FetchModelPlansResponse, Error>({
    queryKey: [QueryKey.FETCH_MODEL_PLANS, offset, limit, filters, sort],
    queryFn: ({ signal }) =>
      fetchModelPlans({
        offset,
        limit,
        filter: definedFilters,
        sort,
        signal,
      }),
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: subTab === ClientWholesaleSubTab.MODEL_PLANS,
  });
};
