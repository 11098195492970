import { CrossIcon } from '@prism2/icons-react/prism';
import { FormikProps, FormikValues } from 'formik';
import { FC } from 'react';

import type { OptionsType } from '../../../../../../types/field';
import type { YearsMap, YearForm } from '../../shared/modelPlan.form.types';

import { UpdateModelPlanItemReturnValues } from '../../../../../../common/queries/modelPlans/modelPlans.types';
import { SelectField } from '../../../../../../components/FormField/SelectField/SelectField';
import { SwitchField } from '../../../../../../components/FormField/SwitchField/SwitchField';
import { REMINDER_TRIMS } from '../../shared/constants';

type UpdateModelPlanFormItemProps = {
  formik: FormikProps<FormikValues>;
  optionsModels: OptionsType[];
  yearsMap: YearsMap;
  item: YearForm;
  index: number;
  isFetchingTrims: boolean;
  yearsOptions: OptionsType[];
  onChangeYear: (option: OptionsType, index: number) => void;
  onRemoveYear: (item: YearForm) => void;
  onTrimsFocus: (year: string) => void;
};

export const UpdateModelPlanFormItem: FC<UpdateModelPlanFormItemProps> = ({
  formik,
  optionsModels,
  yearsMap,
  item,
  index,
  yearsOptions,
  onChangeYear,
  onRemoveYear,
  onTrimsFocus,
  isFetchingTrims,
}: UpdateModelPlanFormItemProps): JSX.Element => {
  const { model, years } = formik.values as UpdateModelPlanItemReturnValues;

  const isTrimsDisable = !years || !years[index]?.year || isFetchingTrims;
  const isDefaultDisable = !years || !years[index]?.year;
  const isShowReminderTrims = model && years && years[index]?.year && (years[index]?.trims?.length || 0) === 0 && !years[index].isDefault;
  const isFetching = Boolean((years && years[index].year && yearsMap[years[index].year]?.isFetching) || false);

  return (
    <div className="flex flex-col justify-center items-center border rounded w-fit p-2 pr-8 relative mt-3" data-testid={`year-${index}`}>
      <div className="flex flex-wrap gap-2 w-full col-span-2">
        <SelectField
          name={`years.${index}.year`}
          label="Year"
          classContainer="w-28"
          options={yearsOptions}
          formik={formik}
          onChange={(option) => onChangeYear(option, index)}
        />
        <SelectField
          formik={formik}
          name="model"
          label="Model"
          classContainer="w-72"
          options={optionsModels}
          defaultValue={model}
          disabled={true}
        />
        <SelectField
          name={`years.${index}.trims`}
          label="Trims"
          classContainer="w-80"
          isMulti={true}
          onFocus={() => onTrimsFocus(String(years && years[index]?.year))}
          isFetching={isFetching && !isFetchingTrims}
          options={(years && years[index]?.year && yearsMap[years[index]?.year]?.options) || []}
          formik={formik}
          disabled={isTrimsDisable}
          formikBasedValue={years[index]?.trims.length > 0 ? ([...years[index]?.trims] as unknown as OptionsType[]) : []}
        />
        <SwitchField
          name={`years.${index}.isDefault`}
          label="Default"
          vertical={true}
          formik={formik}
          value={years && years[index].isDefault}
          disabled={isDefaultDisable}
        />
        {isShowReminderTrims && (
          <p className="flex justify-center w-full px-2 bg-yellow-300 rounded" data-testid={`reminder-base-trims-${index}`}>
            {REMINDER_TRIMS}
          </p>
        )}
      </div>
      <CrossIcon
        onClick={() => onRemoveYear(item)}
        data-testid={`remove-year-icon-${index}`}
        className="absolute top-2 right-2 text-gray-300 font-thin w-5 cursor-pointer"
      />
    </div>
  );
};
