import { FC } from 'react';

import { parseFormik } from '../../../../common/utilities/parseFormik';
import { CommonForm } from '../../../../components/CommonForm/CommonForm';
import { FormGenerator } from '../../../../components/FormGenerator/FormGenerator';
import { config } from '../../../../config';
import { ClientGroupType } from '../../../../types/clients';
import { PricingPlan } from '../../../../types/pricingPlans';
import { useCommonNextInspectForm } from '../../hooks/useCommonNextInspectForm';
import { useCommonNextInspectFormik } from '../../hooks/useCommonNextInspectFormik';
import { useLineItemMutation } from '../../hooks/useLineItemMutations';
import { useUSDFlatFeeFlag } from '../../hooks/useUSDFlatFeeFlag';
import { useUSDPartCostRetailFlag } from '../../hooks/useUSDPartCostRetailFlag';
import { pricingPlansFormFieldsNextInspect } from '../../shared/fields';
import { getStatusOfSaveButtonDisabled } from '../../utils/getStatusOfSaveButtonDisabled';
import { ClientsTrackNotification } from '../ClientsTrackNotification/ClientsTrackNotification';
import { PricingItemModalMode } from '../LineItemModal/shared/types';
import { removeFieldsFromFormikForCurrentClient } from '../LineItemModal/shared/utils';

export interface CommonNextInspectFormProps {
  onClose: () => void;
  lineItem?: PricingPlan;
  mode: PricingItemModalMode;
  sourceGroupCodes: string[];
  groupCode: string;
  planId: string;
  groupType: ClientGroupType;
  itemSearch?: string;
}

export const CommonNextInspectForm: FC<CommonNextInspectFormProps> = ({
  onClose,
  lineItem,
  mode,
  sourceGroupCodes,
  groupType,
  groupCode,
  planId,
  itemSearch,
}: CommonNextInspectFormProps): JSX.Element => {
  const uniqueKey: Partial<PricingPlan> = {
    itemCode: lineItem?.itemCode,
    subItemCode: lineItem?.subItemCode,
    severityCode: lineItem?.severityCode,
    actionCode: lineItem?.actionCode,
    damageCode: lineItem?.damageCode,
    sequenceNumber: lineItem?.sequenceNumber,
  };

  const onSubmit = () => {
    const currentClientValues = removeFieldsFromFormikForCurrentClient(formik.values, groupType);
    const parsedValues = parseFormik<Partial<PricingPlan>>(currentClientValues, pricingPlansFormFieldsNextInspect);

    const body = {
      ...parsedValues,
      ...(mode === PricingItemModalMode.UPDATE && { uniqueKey }),
      groupType,
      planId,
    };

    mutate(body);
  };

  const { isUpdateLoading, isCreateLoading, mutate } = useLineItemMutation({ mode, onSuccess: onClose });
  const formik = useCommonNextInspectFormik({ lineItem, mode, onSubmit, itemSearch });
  const form = useCommonNextInspectForm({ groupType, groupCode, formik, mode });

  useUSDFlatFeeFlag(formik);
  useUSDPartCostRetailFlag(formik);

  const isBasicTier = planId === config.BASIC_TIER.PLAN_ID;
  const isLoading = isUpdateLoading || isCreateLoading;
  const isSaveButtonDisabled = getStatusOfSaveButtonDisabled({ formik, form });

  return (
    <div className="border-dashed border-2 p-8 react-select-reset">
      <CommonForm isSaveButtonDisabled={isSaveButtonDisabled} isLoading={isLoading} onClose={onClose} formik={formik}>
        <FormGenerator formik={formik} config={form.config} fieldsConfig={form.fieldsConfig} />
        {isBasicTier && (
          <div className="w-full">
            <ClientsTrackNotification sourceGroupCodes={sourceGroupCodes} />
          </div>
        )}
      </CommonForm>
    </div>
  );
};
