import { FormikProps } from 'formik';

import type { OptionsType } from '../../types/field';

const isOptionsType = (value: OptionsType[]): value is OptionsType[] => {
  return Array.isArray(value) && value.every((item) => typeof item === 'object' && typeof item.value === 'string');
};

/**
 * Extracts the updated fields from a formik form
 */
export const extractUpdatedFromFormik = <T extends Record<string, unknown>>(formik: FormikProps<T>): T => {
  const { initialValues, values } = formik;
  const updateFields = {} as T;

  for (const property in initialValues) {
    if (initialValues[property] !== values[property]) {
      if (property === 'categoryCodes') {
        const options = values[property] as OptionsType[];
        (updateFields[property] as string[]) = (isOptionsType(options) ? options : ([] as OptionsType[]))
          .map((item: OptionsType) => String(item.value))
          .filter((item: string) => typeof item === 'string');
      } else {
        updateFields[property] = values[property];
      }
    }
  }

  return updateFields;
};
