import { FormikProps, FormikValues } from 'formik';

import { ClientNEXTInspectSubTab, ClientSubTab } from '../../../../../shared/types';
import { useCreateNextInspectClientMutation } from '../../../hooks/useCreateNextInspectClientMutation';
import { useUpgradeClientMutation } from '../../../hooks/useUpgradeClientMutation';

export interface UseClientMutationsParams {
  clientSubTab: ClientSubTab;
  formik: FormikProps<FormikValues>;
  onClose: () => void;
}

export interface UseClientMutationResult {
  isLoading: boolean;
  mutate: (value: FormikValues) => void;
}

export const useClientMutation = ({ clientSubTab, ...params }: UseClientMutationsParams): UseClientMutationResult => {
  if (clientSubTab === ClientNEXTInspectSubTab.DEMO) {
    return useUpgradeClientMutation(params.onClose);
  }
  return useCreateNextInspectClientMutation(params);
};
