import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { fetchMakes } from '../../../../../../common/queries/catalogProxy/catalogProxy.api';
import { FetchMakesParams } from '../../../../../../common/queries/catalogProxy/catalogProxy.types';
import { showErrorMessage } from '../../../../../../common/utilities/notification';
import { QueryKey } from '../../../../../../constants/queries';
import { OptionsType } from '../../../../../../types/field';

type UseFetchMakesList = {
  makesOptions: OptionsType[];
  isFetchingMakes: boolean;
};

export const useFetchMakesList = (year?: string): UseFetchMakesList => {
  const enabled = Boolean(year);
  const { isFetching, data } = useQuery([QueryKey.FETCH_MAKES, year], () => fetchMakes({ year } as FetchMakesParams), {
    staleTime: Infinity,
    enabled,
    onError: showErrorMessage,
  });

  const options = useMemo(() => data?.map(({ make }) => ({ value: make, label: make })) || [], [data]);

  return { isFetchingMakes: isFetching, makesOptions: options };
};
