import { FormikProps, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

export const updateModelPlanValidationSchema = yup.object({
  year: yup.string().matches(/^[0-9]+$/, 'Only numbers are allowed for this field'),
  model: yup.string().required('Model is required'),
  trims: yup.array().of(
    yup.object({
      label: yup.string(),
      value: yup.string(),
    }),
  ),
  isDefault: yup.boolean(),
  years: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      year: yup.string().required('Year is required'),
      trims: yup.array().of(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        }),
      ),
      default: yup.boolean(),
    }),
  ),
});

export interface UseModelPlanFormikParams<FormValues, Schema extends ObjectShape> {
  onSubmit: (values: FormValues) => void;
  validationSchema?: yup.ObjectSchema<Schema>;
  initialValues: FormValues;
}

export type UseModelPlanFormik<FormValues> = FormikProps<FormValues>;

export const useModelPlanFormik = <FormValues extends FormikValues, Schema extends ObjectShape>({
  onSubmit,
  initialValues,
  validationSchema,
}: UseModelPlanFormikParams<FormValues, Schema>): UseModelPlanFormik<FormValues> =>
  useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit,
  });
