import { useQueryClient, useMutation } from '@tanstack/react-query';
import { FC, memo } from 'react';
import { toast } from 'react-toastify';

import { fetchClientUpdate } from '../../../../common/queries/clients/clients.api';
import { FetchClientUpdateParams } from '../../../../common/queries/clients/clients.types';
import { Spinner } from '../../../../components/Spinner/Spinner';
import { QueryKey } from '../../../../constants/queries';
import { Client, TransferState } from '../../../../types/clients';

export type PublishProps = Readonly<{
  client: Client;
}>;

const Publish: FC<PublishProps> = ({ client }: PublishProps): JSX.Element => {
  const queryClient = useQueryClient();
  const isPublishButtonDisabled =
    client.transferState === TransferState.AWAITING_PUBLISH || client.transferState === TransferState.PUBLISHED;

  const { mutate, isLoading } = useMutation({
    mutationFn: (params: FetchClientUpdateParams) => fetchClientUpdate(params),
    onError: (error?: Error) => toast(error?.message, { type: 'error' }),
    onSuccess: async () => {
      void toast('Data will be published overnight', { type: 'success' });
      await queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
      await queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENT_ITEM, client.groupCode] });
    },
  });

  const onPublish = () => {
    const groupCode = client.groupCode as string;
    const planId = client.planId as string;
    mutate({
      updateFields: {
        transferState: TransferState.AWAITING_PUBLISH,
      },
      groupCode,
      planId,
    });
  };

  return (
    <div className="flex flex-col items-center">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <p className="mb-2 font-bold text-center">
            <span className="text-blue-800">Transfer state: </span>
            {client.transferState}
          </p>
          <button className="prism-btn w-20" data-testid="publish-btn" disabled={isPublishButtonDisabled} onClick={onPublish}>
            Publish
          </button>
        </>
      )}
    </div>
  );
};

const MemoizedPublish = memo(Publish);
export { MemoizedPublish as Publish };
