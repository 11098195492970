import { InfoIcon, CrossSmallIcon } from '@prism2/icons-react/prism';
import classnames from 'classnames';
import { FC } from 'react';

type Theme = 'info' | 'error';

type AlertProps = {
  text: string;
  size?: number;
  theme?: Theme;
  className?: string;
};

export const Alert: FC<AlertProps> = ({ text, size = 18, theme = 'info', className }: AlertProps): JSX.Element => {
  const textSize = size <= 18 ? 'text-xs' : 'text';
  const iconSize = `${size}px`;

  const isInfo = theme === 'info';
  const isError = theme === 'error';

  const Icon = isInfo ? InfoIcon : CrossSmallIcon;
  const color = isInfo ? 'darkblue' : 'red';

  return (
    <div
      data-testid="alert"
      className={classnames(className, 'flex flex-row rounded-md items-center px-2 py-1', {
        'bg-blue-100': isInfo,
        'bg-red-100': isError,
      })}
    >
      <Icon color={color} height={iconSize} className="mx-2" />
      <p data-testid="alert-text" className={`break-word ${textSize}`}>
        {text}
      </p>
    </div>
  );
};
