import { Reorder } from 'framer-motion';
import { FC } from 'react';

import type { SequenceRowItem } from '../../shared/types';

import { Direction } from '../../../../types/common';
import { SequenceItem } from '../SequenceItem/SequenceItem';

export type SequenceListProps = {
  onChangeSequenceOrder: (items: Array<SequenceRowItem>) => void;
  sequenceRowItems: Array<SequenceRowItem>;
};

export const SequenceList: FC<SequenceListProps> = ({ onChangeSequenceOrder, sequenceRowItems }: SequenceListProps): JSX.Element => {
  // move the sequence item up or down in the list
  const move = (index: number, direction: Direction): void => {
    const targetIndex = direction === Direction.Up ? index - 1 : index + 1;

    if (targetIndex < 0 || targetIndex >= sequenceRowItems.length) {
      return;
    }

    const copy = [...sequenceRowItems];
    const updated = copy[index];
    copy[index] = copy[targetIndex];
    copy[targetIndex] = updated;

    onChangeSequenceOrder(copy);
  };

  return (
    <div data-testid="sequence-list" className="flex flex-col gap-2 w-full p-2 mb-8">
      <div data-testid="update-sequence-items-modal-title" className="text-blue-800 text-center prism-heading-3 mb-4">
        Update the sequence numbers:
      </div>
      <Reorder.Group axis="y" values={sequenceRowItems} onReorder={onChangeSequenceOrder}>
        {sequenceRowItems?.map((item, index) => (
          <Reorder.Item key={item.id} value={item} className={`w-full flex justify-center items-center mb-2 cursor-grabbing`}>
            <SequenceItem {...item} key={item.id} index={index} move={move} />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </div>
  );
};
