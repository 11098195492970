import { ColumnFilter, ColumnSort } from '@tanstack/react-table';

export type HttpMethod = 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH' | 'OPTIONS' | 'HEAD';

export interface Offset {
  offset: number;
}

export interface SubTab {
  subTab: string;
}

export interface Limit {
  limit: number;
}

export interface SearchTerm {
  searchTerm: string;
}

export interface Filter {
  filter: ColumnFilter[];
}

export interface Sort {
  sort: ColumnSort[];
}

export interface Cancellable {
  signal?: AbortSignal;
}

export interface Paginated<TEntity> {
  items: TEntity[];
  limit: number;
  count: number;
}

export const enum Direction {
  Up = 'up',
  Down = 'down',
}

export enum ColorThemes {
  PRIMARY = 'blue',
  GREEN = 'green',
}

export type ColorTheme = `${ColorThemes}`;
