import { useQuery } from '@tanstack/react-query';

import { fetchPricingPlansSource } from '../../../common/queries/pricingPlans/pricingPlans.api';
import { FetchPricingPlansSourceResponse } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { QueryKey } from '../../../constants/queries';

interface UsePricingPlansSource {
  data: FetchPricingPlansSourceResponse | undefined;
  error: Error | null;
  isFetching: boolean;
  isError: boolean;
}

export const usePricingPlansSource = (isBasicTier: boolean): UsePricingPlansSource => {
  const { isFetching, isError, data, error } = useQuery<FetchPricingPlansSourceResponse, Error>({
    queryKey: [QueryKey.FETCH_PRICING_PLANS_SOURCE],
    queryFn: () => fetchPricingPlansSource(),
    enabled: isBasicTier,
    refetchOnMount: 'always',
  });

  return { isFetching, isError, data, error };
};
