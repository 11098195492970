import { FormikProps } from 'formik';
import { ReactNode } from 'react';

import { BlockScreenLoading } from '../BlockScreenLoading/BlockScreenLoading';
import { FormButtons } from '../FormButtons/FormButtons';

export type CommonFormProps<T> = Readonly<{
  isSaveButtonDisabled: boolean;
  isLoading: boolean;
  onClose: () => void;
  formik: FormikProps<T>;
  children: ReactNode;
}>;

export const CommonForm = <T,>({ children, formik, isLoading, isSaveButtonDisabled, onClose }: CommonFormProps<T>): JSX.Element => {
  const onFormReset = (): void => formik.resetForm();

  return (
    <form data-testid="common-form" onSubmit={formik.handleSubmit} onReset={onFormReset}>
      {children}
      <FormButtons isSaveButtonDisabled={isSaveButtonDisabled} onCancelButtonClick={onClose} />
      {isLoading && <BlockScreenLoading />}
    </form>
  );
};
