import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { TabOption } from '../../../types/field';

import { useNavigator } from '../../../common/modules/navigation/useNavigator';
import {
  CLIENT_SUB_TABS,
  CLIENT_TAB_TO_HUMAN_READABLE,
  CLIENT_TABS,
  ClientSubTabs,
  ClientTabs,
  HUMAN_READABLE_TO_CLIENT_TAB,
  TAB_TO_SUB_TABS,
} from '../shared/constants';
import { ClientSubTab, ClientTab, ClientTabHumanReadable } from '../shared/types';

export type UseSyncParamsWithTabsState = Readonly<{
  tab: TabOption<ClientTab>;
  subTab: TabOption<ClientSubTab>;
  tabConfig: { tab: ClientTab; subTab: ClientSubTab };
}>;

export const useSyncParamsWithTabsState = (): UseSyncParamsWithTabsState => {
  const { tab: paramTab, subTab: paramSubTab } = useParams();

  const { navigateToTab } = useNavigator();

  const [tabConfig, setTabConfig] = useState<{ tab: ClientTab; subTab: ClientSubTab }>({
    tab: CLIENT_TABS[0].value,
    subTab: CLIENT_SUB_TABS[0].value,
  });

  useLayoutEffect(() => {
    const tabValue =
      paramTab && Object.values(ClientTabHumanReadable).includes(paramTab as ClientTabHumanReadable)
        ? HUMAN_READABLE_TO_CLIENT_TAB[paramTab as ClientTabHumanReadable]
        : CLIENT_TABS[0].value;
    const subTabValue: ClientSubTab =
      paramSubTab && TAB_TO_SUB_TABS[tabValue].some((subTab) => subTab.value === paramSubTab)
        ? (paramSubTab as ClientSubTab)
        : TAB_TO_SUB_TABS[tabValue][0].value;

    if (tabValue !== paramTab || subTabValue !== paramSubTab) {
      navigateToTab(CLIENT_TAB_TO_HUMAN_READABLE[tabValue], subTabValue, true);
    }

    setTabConfig({ tab: tabValue, subTab: subTabValue });
  }, [paramTab, paramSubTab]);

  const { tab: tabValue, subTab: subTabValue } = tabConfig;
  const tab = { value: tabValue, label: ClientTabs[tabValue] };
  const subTab = { value: subTabValue, label: ClientSubTabs[subTabValue] };

  return { tab, subTab, tabConfig };
};
