import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { useNavigator } from '../../../../../../common/modules/navigation/useNavigator';
import { createModelPlan } from '../../../../../../common/queries/modelPlans/modelPlans.api';
import { CreateModelPlanItemRequestParams } from '../../../../../../common/queries/modelPlans/modelPlans.types';
import phrases from '../../../../../../constants/en_US.json';
import { QueryKey } from '../../../../../../constants/queries';
import { ClientWholesaleSubTab } from '../../../../shared/types';

interface UseCreateModelPlanMutationResponse {
  mutate: (values: CreateModelPlanItemRequestParams) => void;
  isSubmitLoading: boolean;
}

export interface UseCreateModelPanMutationParams {
  onClose: () => void;
}

export const useCreateModelPlanMutation = ({ onClose }: UseCreateModelPanMutationParams): UseCreateModelPlanMutationResponse => {
  const queryClient = useQueryClient();
  const { navigateToClientsWholesale } = useNavigator();

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: CreateModelPlanItemRequestParams) => {
      return createModelPlan(values);
    },
    onError: (e: Error) => {
      toast(e.message ?? phrases.modelPlanAddedError, { type: 'error' });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_MODEL_PLANS] });
      void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_PLAN_DESCRIPTION] });
      toast(phrases.modelPlanAddedSuccess, { type: 'success' });
      onClose();
      navigateToClientsWholesale(ClientWholesaleSubTab.MODEL_PLANS);
    },
  });

  return { mutate, isSubmitLoading: isLoading };
};
