import { useQuery } from '@tanstack/react-query';

import { getDescription } from '../../../common/queries/artProxy/artProxy.api';
import { ARTGetDescriptionResponse } from '../../../common/queries/artProxy/artProxy.types';
import { ArtProxyAction, QueryKey } from '../../../constants/queries';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';

export interface UseItemSearchForPrepopulate {
  isFetching: boolean;
  itemSearch?: string;
}

export type UseItemSearchForPrepopulateParams = Readonly<{
  mode: PricingItemModalMode;
  itemCode?: string;
  subItemCode?: string;
}>;

export const useItemSearchForPrepopulate = ({
  mode,
  itemCode,
  subItemCode,
}: UseItemSearchForPrepopulateParams): UseItemSearchForPrepopulate => {
  const queryFn = async (): Promise<ARTGetDescriptionResponse> => {
    return await getDescription({ itemCode, subItemCode });
  };

  const { data, isFetching } = useQuery<ARTGetDescriptionResponse>({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_BY_ID, itemCode, subItemCode],
    queryFn,
    keepPreviousData: false,
    enabled: mode === PricingItemModalMode.UPDATE,
  });

  return { itemSearch: data?.description, isFetching };
};
