import React, { useCallback, useState } from 'react';

import { Client } from '../../../types/clients';
import { TabOption } from '../../../types/field';
import { CommonClientModal } from '../components/CommonClientModal/CommonClientModal';
import { ClientNEXTInspectSubTab, ClientSubTab, ClientTab } from '../shared/types';

export interface UseClientModal {
  openClientModal: () => void;
  onUpgradeClick: (clientSettingsItem: Partial<Client>) => void;
  ClientModal: React.FC;
}

interface UseClientModalProps {
  tab: TabOption<ClientTab>;
  subTab: TabOption<ClientSubTab>;
}

export const useClientModal = ({ tab, subTab }: UseClientModalProps): UseClientModal => {
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [clientToUpgrade, setClientToUpgrade] = useState<Partial<Client> | undefined>();

  const closeClientModal = useCallback(() => setIsClientModalOpen(false), []);
  const openClientModal = useCallback(() => setIsClientModalOpen(true), []);
  const onUpgradeClick = (clientSettingsItem: Partial<Client>): void => {
    setClientToUpgrade(clientSettingsItem);
    openClientModal();
  };

  const isSubTabDemo = subTab.value === ClientNEXTInspectSubTab.DEMO;

  const fieldsToPrepopulate = isSubTabDemo ? clientToUpgrade : undefined;

  const ClientModal: React.FC = () => (
    <CommonClientModal
      client={fieldsToPrepopulate as Partial<Client>}
      clientTab={tab.value}
      clientSubTab={subTab.value}
      isOpen={isClientModalOpen}
      onClose={closeClientModal}
    />
  );

  return { openClientModal, onUpgradeClick, ClientModal };
};
