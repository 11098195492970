import { PricingType } from '../../../../../../../types/clients';

/**
 * (NEXTInspect only)
 * Pricing types AS400 and Standard have different fields compared to Infobahn and NoPricing.
 * When navigating from a pricing type with one set of fields to another with a different set,
 * the view is affected. This method identifies if such a change has occurred
 */
export const isPricingTypeChangeAffectingView = (
  previousPricingType: PricingType | undefined,
  pricingType: PricingType | undefined,
): boolean => {
  if (!previousPricingType) return false;
  if (!pricingType) return true;

  const standardFields = [PricingType.AS400, PricingType.Standard];
  const infobahnFields = [PricingType.Infobahn, PricingType.NoPricing];

  return (
    (standardFields.includes(pricingType) && infobahnFields.includes(previousPricingType)) ||
    (infobahnFields.includes(pricingType) && standardFields.includes(previousPricingType))
  );
};
