import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { createPricingPlanItem, updatePricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.api';
import {
  CreatePricingPlanItemBody,
  CreatePricingPlanItemParams,
  UpdatePricingPlanItemBody,
  UpdatePricingPlanItemParams,
} from '../../../common/queries/pricingPlans/pricingPlans.types';
import phrases from '../../../constants/en_US.json';
import { QueryKey } from '../../../constants/queries';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { replaceFieldsNameWithLabelInMessage } from '../utils/replaceFieldsNameWithLabelInMessage';

export interface UseLineItemMutationProps {
  mode: PricingItemModalMode;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface UseLineItemMutationObject {
  mutate: (values: LineItemMutationBody) => void;
  isUpdateLoading: boolean;
  isCreateLoading: boolean;
}

export type LineItemMutationBody = UpdatePricingPlanItemBody | CreatePricingPlanItemBody;

export const useLineItemMutation = ({ mode, onError, onSuccess }: UseLineItemMutationProps): UseLineItemMutationObject => {
  // temporary solution
  const { lineItem: lineItemPhrases } = phrases as Record<'lineItem', Record<string, Record<'success' | 'error', string>>>;

  const queryClient = useQueryClient();
  const onRequestSuccess = () => {
    void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_PRICING_PLANS] });
    toast(lineItemPhrases[mode].success, { type: 'success' });
    if (onSuccess) {
      onSuccess();
    }
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message ? replaceFieldsNameWithLabelInMessage(e.message) : lineItemPhrases[mode].error;
    toast(msg, { type: 'error' });
    if (onError) {
      onError();
    }
  };

  const { mutate: createPricingPlan, isLoading: isCreateLoading } = useMutation({
    mutationFn: (body: CreatePricingPlanItemParams) => createPricingPlanItem(body),
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  const { mutate: updateLineItem, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (body: UpdatePricingPlanItemParams) => updatePricingPlanItem(body),
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  const mutate = (values: LineItemMutationBody): void => {
    if (mode === 'Update') {
      return updateLineItem(values as UpdatePricingPlanItemParams);
    }
    return createPricingPlan(values as CreatePricingPlanItemParams);
  };

  return {
    isUpdateLoading,
    isCreateLoading,
    mutate,
  };
};
