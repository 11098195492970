import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import imageNotFoundIcon from '../../../../assets/icons/image-not-found-icon.svg';
import { fetchDamageGuidelineImages } from '../../../../common/queries/guides/guides.api';
import { Loading } from '../../../../components/Loading/Loading';
import { QueryKey } from '../../../../constants/queries';
import { Image, ImageUploadStatus } from '../../shared/types';
import { convertToBase64Image } from '../../shared/utils';

export interface GuidelineImageProps {
  image: Image;
  size?: number;
}

export const GuidelineImage: FC<GuidelineImageProps> = ({ image, size }: GuidelineImageProps): JSX.Element | null => {
  const s3Key = image.s3Key as string;
  const isImageFromS3 = !!s3Key;
  const isUploadingFailed = image.status === ImageUploadStatus.FAIL;

  const { isError, isFetching, data } = useQuery({
    queryKey: [QueryKey.FETCH_DAMAGE_GUIDELINE_IMAGE, s3Key],
    queryFn: () => fetchDamageGuidelineImages({ key: s3Key }),
    enabled: isImageFromS3,
    retry: false,
  });

  const getImageSource = (): string | undefined => {
    if (isImageFromS3 && isError) return imageNotFoundIcon;
    if (isImageFromS3 && data) return convertToBase64Image(data?.data as unknown as string);
    return image.objectUrl;
  };

  const borderStyle = isUploadingFailed ? 'border-2 border-red-600' : '';
  const sizeStyle = size ? `w-${size} h-${size}` : 'w-24 h-24';

  return isFetching ? (
    <Loading />
  ) : (
    <img
      data-testid="guideline-image"
      className={`rounded pointer-events-none ${sizeStyle} ${borderStyle}`}
      src={getImageSource()}
      alt="section-image"
    />
  );
};
