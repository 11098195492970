/**
 * Given an array of objects and a field, returns the maximum value of that field.
 * If the array is empty, returns undefined.
 *
 * @param arr - The array of objects.
 * @param field - The field for which the maximum value is sought.
 * @returns The maximum value of the field in the array or undefined if the array is empty.
 */
export const maxBy = <T, K extends keyof T>(arr: T[], field: K): T[K] | undefined => {
  return arr.reduce(
    (maxValue, currentItem) => (currentItem[field] > maxValue ? currentItem[field] : maxValue),
    arr[0]?.[field] as unknown as T[K],
  );
};
