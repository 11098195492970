import type { ClientSettings } from '../../../types/clientSettings';

export type ClientSettingsTableData = Omit<ClientSettings, 'sellerAccounts'> & {
  sellerAccounts: string;
};

export enum ClientTab {
  NextInspect = 'N',
  Wholesale = 'W',
}

export enum ClientTabHumanReadable {
  NextInspect = 'next-inspect',
  Wholesale = 'wholesale',
}

export enum ClientNEXTInspectSubTab {
  ACTIVE_MANHEIM = 'active-manheim',
  ACTIVE_CLIENT_SOURCED = 'active-client-sourced',
  DEMO = 'demo',
}

export enum ClientWholesaleSubTab {
  ACCOUNTS = 'accounts',
  MODEL_PLANS = 'model-plans',
}

export type ClientSubTab = ClientNEXTInspectSubTab | ClientWholesaleSubTab;

export enum ClientType {
  DEALER = 'dealer',
  CONSUMER = 'consumer',
}

export enum ClientSettingsTab {
  DEALER = ClientType.DEALER,
  CONSUMER = ClientType.CONSUMER,
}
