import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { fetchTrims } from '../../../../../../common/queries/catalogProxy/catalogProxy.api';
import { FetchTrimsParams } from '../../../../../../common/queries/catalogProxy/catalogProxy.types';
import { QueryKey } from '../../../../../../constants/queries';
import { OptionsType } from '../../../../../../types/field';

type UseFetchTrimsList = {
  trimsOptions: OptionsType[];
  isFetchingTrims: boolean;
};

type UseFetchTrimsListParams = Partial<{
  make: string;
  model: string;
  year: string;
  disableFetching: boolean;
}>;

export const useFetchTrimsList = ({ year, make, model, disableFetching }: UseFetchTrimsListParams): UseFetchTrimsList => {
  const enabled = Boolean(make && year && model && disableFetching);
  const { isFetching, data } = useQuery(
    [QueryKey.FETCH_TRIMS, make, model, year],
    () => fetchTrims({ year, model, make } as FetchTrimsParams),
    {
      staleTime: 0,
      enabled,
    },
  );

  const options = useMemo(() => data?.map(({ trim }) => ({ value: trim.normalizedName, label: trim.normalizedName })) || [], [data]);

  return { isFetchingTrims: isFetching, trimsOptions: options };
};
