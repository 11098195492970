import { FormikProps, FormikValues } from 'formik';

import { PricingPlanItem } from '../../../../../common/queries/pricingPlans/pricingPlans.types';
import { ClientGroupType } from '../../../../../types/clients';
import { PricingPlan } from '../../../../../types/pricingPlans';

/** Check fields on null or empty string because formik set number to string after change */
export const getStatusCurrencyCadFields = (formik: FormikProps<Partial<PricingPlanItem>>): boolean => {
  return (
    (formik.values.cad?.partCostRetailFlag === null || formik.values.cad?.partCostRetailFlag === '') &&
    (formik.values.cad?.partCost === null || (formik.values.cad?.partCost as unknown as string) === '') &&
    (formik.values.cad?.partDollars === null || (formik.values.cad?.partDollars as unknown as string) === '') &&
    (formik.values.cad?.paintDollars === null || (formik.values.cad?.paintDollars as unknown as string) === '') &&
    (formik.values.cad?.repairDollars === null || (formik.values.cad?.repairDollars as unknown as string) === '')
  );
};

export const changedCurrencyUsdFields = (formik: FormikProps<Partial<PricingPlan>>): boolean => {
  return (
    formik.values.usd?.partCost !== formik.initialValues.usd?.partCost ||
    formik.values.usd?.partDollars !== formik.initialValues.usd?.partDollars ||
    formik.values.usd?.paintDollars !== formik.initialValues.usd?.paintDollars ||
    formik.values.usd?.repairDollars !== formik.initialValues.usd?.repairDollars ||
    formik.values.usd?.partCostRetailFlag !== formik.initialValues.usd?.partCostRetailFlag
  );
};

export const wholesaleKeys = [
  'actionCode',
  'actionDescription',
  'itemCode',
  'itemDescription',
  'severityCode',
  'severityDescription',
  'sequenceNumber',
  'subItemCode',
  'paintHours',
  'partHours',
  'repairHours',
  'usd.partCost',
  'usd.partCostRetailFlag',
  'usd.repairDollars',
  'usd.paintDollars',
  'usd.partDollars',
  'cad.paintDollars',
  'cad.partCost',
  'cad.partCostRetailFlag',
  'cad.partDollars',
  'cad.repairDollars',
  'sequenceNumbersOrder',
  'sequenceNumber',
];

export const nextInspectKeys = [
  'actionCode',
  'actionDescription',
  'itemCode',
  'itemDescription',
  'severityCode',
  'severityDescription',
  'sequenceNumber',
  'subItemCode',
  'usd.partCost',
  'usd.partCostRetailFlag',
  'usd.repairDollars',
  'paintHours',
  'partHours',
  'repairHours',
  'damageCode',
  'damageDescription',
  'chargeableFlag',
  'flatFeeFlag',
  'partCostMultiplier',
  'paintHoursMultiplier',
  'partHoursMultiplier',
  'repairHoursMultiplier',
];

export const removeFieldsFromFormikForCurrentClient = (
  formikValues: FormikValues,
  groupType: ClientGroupType | undefined,
): FormikValues => {
  const currentClientKeys = groupType === ClientGroupType.Wholesale ? wholesaleKeys : nextInspectKeys;
  const newObj: FormikValues = {};

  // Traverse through the nested keys
  for (const key of currentClientKeys) {
    // Check if the key exists in the current object
    const nestedKeys = key.split('.');

    // If the value is an object and not null, create a new object
    let currentObj: FormikValues = formikValues;
    let newCurrentObj: FormikValues = newObj;

    for (const nestedKey of nestedKeys) {
      if (currentObj[nestedKey] !== undefined) {
        if (typeof currentObj[nestedKey] === 'object' && currentObj[nestedKey] !== null) {
          newCurrentObj[nestedKey] = { ...currentObj[nestedKey] } as FormikValues;
          currentObj = currentObj[nestedKey] as FormikValues;
          newCurrentObj = newCurrentObj[nestedKey] as FormikValues;
        } else {
          // Assign the value to the corresponding key in the new object
          newCurrentObj[nestedKey] = currentObj[nestedKey] as FormikValues;
        }
      }
    }
  }

  return newObj;
};
