import { useQuery } from '@tanstack/react-query';

import { getSeverityOptions } from '../../../common/queries/artProxy/artProxy.api';
import { ArtProxyAction, QueryKey } from '../../../constants/queries';
import { ClientGroupType } from '../../../types/clients';
import { OptionsType } from '../../../types/field';
import { ARTHookParams } from '../shared/types';

export interface UseDamageSeverityData {
  data: OptionsType[] | undefined;
  isFetching: boolean;
}

export const useDamageSeverityData = ({ formik, groupCode, groupType }: ARTHookParams): UseDamageSeverityData => {
  const { itemCode, subItemCode, damageCode } = formik.values;
  const queryFn = async (): Promise<OptionsType[]> => {
    const { items } = await getSeverityOptions({ groupCode, itemCode, subItemCode, damageCode });
    return items.map((item) => ({ value: item.code, label: item.description }));
  };
  return useQuery<OptionsType[]>({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_DAMAGE_SEVERITIES, groupCode, itemCode, subItemCode, damageCode],
    queryFn,
    keepPreviousData: true,
    enabled: Boolean(itemCode && subItemCode && damageCode && groupCode) && groupType !== ClientGroupType.Wholesale,
  });
};
