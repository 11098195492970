import { UseWizardStep1Params } from './useWizardStep1Config';
import { GenFormFieldsConfig } from '../../../../../../../../components/FormGenerator/FormGenerator';
import { useClientSettingsAccounts } from '../../../../../../hooks/useClientSettingsAccounts';
import { getPricingTypeConfig } from '../../../../fields/pricingType';

export type UseWizardStep1FieldsParams = UseWizardStep1Params;
export type UseWizardStep1Fields = GenFormFieldsConfig;

/** Returns map of dynamic props to field (Also applies hooks)  */
export const useWizardStep1Fields = (params: UseWizardStep1FieldsParams): UseWizardStep1Fields => {
  const accounts = useClientSettingsAccounts({
    groupName: params.formik.values.groupName as string,
    isFiltered: true,
  });
  return { pricingType: getPricingTypeConfig(params, accounts) };
};
