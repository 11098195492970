import { ColumnFilter } from '@tanstack/react-table';
import { useCallback, useEffect, useRef } from 'react';

import type { InferTypeFromConfig, ParamConfigMap } from './useSearchParamsState';
import type { SetFiltersParams } from './useTableFilters';

import { usePreviousValue } from './usePreviousValue';
import { convertFiltersDefinitionsToSearchParams, useSearchParamsState } from './useSearchParamsState';
import { SyncStatus } from '../../constants/sync';
import { isDefined } from '../../types/typeguards';

type UseSyncSearchStateWithFiltersParams<T extends ParamConfigMap> = {
  paramsDefinition?: T;
  isFilter: boolean;
  setFilters: (params: SetFiltersParams) => void;
  openFilters: () => void;
  closeFilters: () => void;
};

type UseSyncFiltersWithSearchParams = (filters: ColumnFilter[]) => void;

export const useSyncFiltersWithSearchParams = <T extends ParamConfigMap>({
  paramsDefinition,
  isFilter,
  setFilters,
  openFilters,
  closeFilters,
}: UseSyncSearchStateWithFiltersParams<T>): UseSyncFiltersWithSearchParams => {
  const syncStatus = useRef<SyncStatus>(SyncStatus.DEFAULT);
  const { searchParamsState, setSearchParamsState } = useSearchParamsState(paramsDefinition);
  const prevIsFilter = usePreviousValue(isFilter);
  const trackedValues = paramsDefinition ? Object.keys(paramsDefinition).map((key) => searchParamsState[key]) : [];

  useEffect(() => {
    if (syncStatus.current === SyncStatus.SKIP_SYNC_VALUES) {
      syncStatus.current = SyncStatus.DEFAULT;
      return;
    }
    if (!paramsDefinition) return;

    if (!Object.keys(searchParamsState).length) {
      closeFilters();
      setFilters({ newFilters: [], skipSync: true });
    }

    syncStatus.current = SyncStatus.SKIP_SYNC_SEARCH_PARAMS;

    const filterValues: Array<{ id: string; value: InferTypeFromConfig<T[string]> }> = [];
    let hasFilters = false;

    Object.keys(paramsDefinition).forEach((key) => {
      if (isDefined(searchParamsState[key])) {
        filterValues.push({ id: key, value: searchParamsState[key] });
        hasFilters = true;
      }
    });

    if (!isFilter && !prevIsFilter && hasFilters) {
      openFilters();
    }
    setFilters({ newFilters: filterValues, skipSync: true });
  }, [paramsDefinition, prevIsFilter, trackedValues.join(',')]);

  const syncFiltersWithSearchParams = useCallback(
    (filters: ColumnFilter[]) => {
      const newSearchParamsState = paramsDefinition ? convertFiltersDefinitionsToSearchParams<T>({ filters, paramsDefinition }) : {};
      syncStatus.current = SyncStatus.SKIP_SYNC_VALUES;

      setSearchParamsState(newSearchParamsState, false);
    },
    [paramsDefinition],
  );

  return syncFiltersWithSearchParams;
};
