import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface UseSmartModalVisibility {
  isModalOpen: boolean;
  onModalClose: () => void;
  setIsOpenModal: (value: boolean) => void;
}

export const useSmartModalVisibility = (): UseSmartModalVisibility => {
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const toastCount = useRef(0);

  const onModalClose = () => {
    if (isNotificationOpen) {
      return;
    }
    setIsOpenModal(false);
  };

  useEffect(() => {
    const unsubscribe = toast.onChange((toastItem) => {
      if (toastItem.status === 'added') {
        toastCount.current += 1;
      } else if (toastItem.status === 'removed') {
        toastCount.current -= 1;

        if (toastCount.current === 0) {
          setIsNotificationOpen(false);
        }
      }

      if (toastCount.current > 0) {
        setIsNotificationOpen(true);
      }
    });

    return () => {
      unsubscribe?.();
      toast.dismiss();
      toastCount.current = 0;
    };
  }, []);

  return { onModalClose, setIsOpenModal, isModalOpen };
};
