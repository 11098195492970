import { CrossIcon } from '@prism2/icons-react/prism';
import { FormikProps, FormikValues } from 'formik';

import { SelectField } from '../../../../../../components/FormField/SelectField/SelectField';
import { SwitchField } from '../../../../../../components/FormField/SwitchField/SwitchField';
import { OptionsType } from '../../../../../../types/field';
import { REMINDER_TRIMS } from '../constants';
import { ModelPlanFormValues, YearForm, YearsMap } from '../modelPlan.form.types';

type CreateModelPlanFormItemProps = {
  formik: FormikProps<FormikValues>;
  optionsMakes: OptionsType[];
  optionsModels: OptionsType[];
  yearsMap: YearsMap;
  item: YearForm;
  index: number;
  yearsOptions: OptionsType[];
  onChangeYear: (option: OptionsType, index: number) => void;
  onRemoveYear: (item: YearForm) => void;
};

export const CreateModelPlanFormItem = ({
  formik,
  optionsMakes,
  optionsModels,
  yearsMap,
  item,
  index,
  yearsOptions,
  onChangeYear,
  onRemoveYear,
}: CreateModelPlanFormItemProps) => {
  const { make, model, years }: ModelPlanFormValues = formik.values;
  // Show message when Trims is empty and Default is false
  const isShowReminderTrims = model && years && years[index]?.year && (years[index]?.trims?.length || 0) === 0 && !years[index].isDefault;

  return (
    <div
      className="flex flex-col justify-center items-center border raunded p-2 pr-8 relative mt-3"
      key={item.year}
      data-testid={`year-${index}`}
    >
      <div className="flex flex-wrap gap-2 w-full col-span-2">
        <SelectField
          name={`years.${index}.year`}
          label="Year"
          classContainer="w-28"
          options={yearsOptions}
          formik={formik}
          onChange={(option) => onChangeYear(option, index)}
        />
        <SelectField
          formik={formik}
          name="make"
          label="Make"
          classContainer="w-52"
          options={optionsMakes}
          defaultValue={make}
          disabled={true}
        />
        <SelectField
          formik={formik}
          name="model"
          label="Model"
          classContainer="w-72"
          options={optionsModels}
          defaultValue={model}
          disabled={true}
        />
        <SelectField
          name={`years.${index}.trims`}
          label="Trims"
          classContainer="w-80"
          isMulti={true}
          isFetching={Boolean(!years || yearsMap[years[index].year]?.isFetching)}
          options={years && years[index] && yearsMap[years[index].year]?.options}
          formik={formik}
          disabled={!years || !years[index]?.year}
        />
        <SwitchField
          name={`years.${index}.isDefault`}
          label="Default"
          vertical={true}
          formik={formik}
          value={!years || years[index].isDefault}
          disabled={!years || !years[index]?.year}
        />
        {isShowReminderTrims && (
          <p className="flex justify-center w-full px-2 bg-yellow-300 rounded" data-testid={`reminder-base-trims-${index}`}>
            {REMINDER_TRIMS}
          </p>
        )}
      </div>
      <CrossIcon
        onClick={() => onRemoveYear(item)}
        data-testid={`remove-year-icon-${index}`}
        className="absolute top-2 right-2 text-gray-300 font-thin w-5 cursor-pointer"
      />
    </div>
  );
};
