import type {
  CreateNextInspectClientParams,
  FetchClientItemParams,
  FetchClientItemResponse,
  FetchClientUpdateParams,
  FetchClientUpdateResponse,
  FetchClientUpgradeParams,
  FetchClientUpgradeResponse,
  FetchGroupDescriptionParams,
  FetchGroupDescriptionResponse,
  FetchNextInspectClientsParams,
  FetchNextInspectClientsResponse,
  FetchWholesaleClientsParams,
  FetchWholesaleClientsResponse,
} from './clients.types';

import { CreateNextInspectClientResponse, CreateWholesaleClientParams, CreateWholesaleClientResponse } from './clients.types';
import { config } from '../../../config';
import { ClientNEXTInspectSubTab } from '../../../pages/ClientListPage/shared/types';
import { fetchJson } from '../../utilities/api/fetchJson';
import { appendFilterToURL } from '../../utilities/appendFilterToURL';
import { appendSortToURL } from '../../utilities/appendSortToURL';
import { appendToQueryIfExists } from '../../utilities/appendToQueryIfExists';

export const createNextInspectClient = (params: CreateNextInspectClientParams): Promise<CreateNextInspectClientResponse> => {
  const { client } = params;
  const url = new URL(`${config.API_URL}/next-inspect/clients`);
  return fetchJson(url, 'POST', client);
};

export const createWholesaleClient = (params: CreateWholesaleClientParams): Promise<CreateWholesaleClientResponse> => {
  const { client } = params;
  const url = new URL(`${config.API_URL}/wholesale/clients`);
  return fetchJson(url, 'POST', client);
};

export const fetchNextInspectClients = (params: FetchNextInspectClientsParams): Promise<FetchNextInspectClientsResponse> => {
  const { subTab, limit, offset, searchTerm, sort, filter, signal } = params;
  const pricingType = subTab === ClientNEXTInspectSubTab.ACTIVE_MANHEIM ? 'manheim' : 'clientSourced';

  const url = new URL(`${config.API_URL}/next-inspect/clients`);
  appendToQueryIfExists(url, 'pricingType', pricingType);
  appendToQueryIfExists(url, 'searchTerm', searchTerm);
  appendToQueryIfExists(url, 'offset', offset);
  appendToQueryIfExists(url, 'limit', limit);
  appendFilterToURL(url, filter);
  appendSortToURL(url, sort);

  return fetchJson(url, 'GET', undefined, signal);
};

export const fetchWholesaleClients = (params: FetchWholesaleClientsParams): Promise<FetchWholesaleClientsResponse> => {
  const { limit, offset, searchTerm, sort, filter, signal } = params;

  const url = new URL(`${config.API_URL}/wholesale/clients`);
  appendToQueryIfExists(url, 'searchTerm', searchTerm);
  appendToQueryIfExists(url, 'offset', offset);
  appendToQueryIfExists(url, 'limit', limit);
  appendFilterToURL(url, filter);
  appendSortToURL(url, sort);

  return fetchJson(url, 'GET', undefined, signal);
};

export const fetchClientItem = (params: FetchClientItemParams): Promise<FetchClientItemResponse> => {
  const { groupCode, planId } = params;
  const url = new URL(`${config.API_URL}/clients/${groupCode}/${planId}`);
  return fetchJson(url, 'GET');
};

export const fetchClientUpdate = (params: FetchClientUpdateParams): Promise<FetchClientUpdateResponse> => {
  const { updateFields, groupCode, planId } = params;
  const url = new URL(`${config.API_URL}/clients/${groupCode}/${planId}`);
  return fetchJson(url, 'PATCH', updateFields);
};

export const fetchClientUpgrade = (params: FetchClientUpgradeParams): Promise<FetchClientUpgradeResponse> => {
  const { client } = params;
  const url = new URL(`${config.API_URL}/clients/upgrade`);
  return fetchJson(url, 'POST', client);
};

export const fetchGroupDescription = (params: FetchGroupDescriptionParams): Promise<FetchGroupDescriptionResponse> => {
  const { groupCode } = params;
  const url = new URL(`${config.API_URL}/clients/${groupCode}/group-description`);
  return fetchJson(url, 'GET');
};
