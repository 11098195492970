import { useQuery } from '@tanstack/react-query';

import type { ClientSettings } from '../../../types/clientSettings';

import { fetchClientSettings } from '../../../common/queries/clientSettingsProxy/clientSettingsProxy.api';
import { QueryKey } from '../../../constants/queries';
import { Paginated } from '../../../types/common';

export interface UseClientSettingsParams {
  client: string;
  onSuccess?: (clientSettings: Paginated<ClientSettings>) => void;
  onError?: () => void;
}

interface UseClientSettings {
  data: Paginated<ClientSettings> | undefined;
  isFetching: boolean;
}

export const useClientSettings = ({ client, onSuccess, onError }: UseClientSettingsParams): UseClientSettings => {
  const { data, isFetching } = useQuery({
    queryKey: [QueryKey.CLIENT_SETTINGS_PROXY, client],
    queryFn: () => fetchClientSettings({ client }),
    onSuccess,
    onError,
    staleTime: Infinity,
    retry: false,
  });
  return { data, isFetching };
};
