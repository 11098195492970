import { FC, Fragment, ReactNode } from 'react';

import { ErrorInfo } from '../ErrorInfo/ErrorInfo';

interface ErrorWrapperProps {
  error?: Error | null;
  children: ReactNode;
}

/**
 * Handles rendering an error message or its children
 * Note: For more complex cases where errors might occur during rendering,
 * it is recommended to use the ErrorBoundary component instead
 */
export const ErrorWrapper: FC<ErrorWrapperProps> = ({ error, children }: ErrorWrapperProps): JSX.Element => {
  if (error) return <ErrorInfo message={error.message} />;
  return <Fragment>{children}</Fragment>;
};
