import { FormikProps, FormikValues } from 'formik';
import { useEffect, useState } from 'react';

import type { OptionsType } from '../../../../../../types/field';
import type { UpdateModelPlanItem, YearForm, YearsMap } from '../../shared/modelPlan.form.types';

import { INIT_DATA } from '../../Modal/form/useCreateModelPlanFormik';
import { useFetchModelsList } from '../../shared/hooks/useFetchModelsList';
import { useFetchTrimsList } from '../../shared/hooks/useFetchTrimsList';

type UseUpdateModelPlanHandlersProps = {
  formik: FormikProps<FormikValues>;
};

export const useUpdateModelPlanHandlers = ({ formik }: UseUpdateModelPlanHandlersProps) => {
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [optionsModels, setOptionsModels] = useState<OptionsType[]>([]);
  const [yearsMap, setYearsMap] = useState<YearsMap>({});
  const [disableFetching, setDisableFetching] = useState(false);

  const { year, make, model, years } = formik.values as UpdateModelPlanItem;

  const { isFetchingModels, modelsOptions } = useFetchModelsList({ make, year });
  const { isFetchingTrims, trimsOptions } = useFetchTrimsList({
    make,
    year: selectedYear,
    model,
    disableFetching,
  });

  useEffect(() => {
    if (year && make) {
      setOptionsModels(modelsOptions);
    }

    if (selectedYear && trimsOptions) {
      const updatedYearsMap: YearsMap = {
        ...yearsMap,
        [selectedYear]: { options: trimsOptions as { label: string; value: string }[], isFetching: isFetchingTrims },
      };
      setYearsMap(updatedYearsMap);
      setDisableFetching(false);
    }
  }, [modelsOptions, trimsOptions, isFetchingTrims]);

  const onTrimsFocus = (selectedYear: string) => {
    if (!(selectedYear in yearsMap)) {
      setSelectedYear(selectedYear);
      setDisableFetching(true);
    }
  };

  const onChangeBaseYear = (option: OptionsType) => {
    setSelectedYear(option?.value as string);
    setYearsMap({});
    void formik.setValues({ ...INIT_DATA, year: option?.value as string, make });
  };

  const onChangeModel = (option: OptionsType) => {
    void formik.setValues({ ...INIT_DATA, make, year, model: option?.value as string });

    const updatedYearsMap: YearsMap = {
      [selectedYear]: { options: trimsOptions as { label: string; value: string }[], isFetching: true },
    };

    setYearsMap(updatedYearsMap);
    setDisableFetching(true);
  };

  const onRemoveYear = (itemToRemove: YearForm) => {
    delete yearsMap[itemToRemove.year];
    void formik.setFieldValue('years', (years as YearForm[])?.filter((item) => item.year !== itemToRemove.year));
  };

  const onChangeYear = (option: OptionsType, index: number) => {
    const selectedYear = option?.value as string;

    void formik.setFieldValue(`years[${index}]`, { year: selectedYear, isDefault: false, trims: [] });
    const updatedYearsMap: YearsMap = {
      ...yearsMap,
      [selectedYear]: { options: trimsOptions as { label: string; value: string }[], isFetching: true },
    };

    setYearsMap(updatedYearsMap);
    setSelectedYear(selectedYear);
    setDisableFetching(true);
  };

  return {
    isFetchingTrims,
    optionsModels,
    yearsMap,
    isFetchingModels,
    onChangeBaseYear,
    onChangeModel,
    onRemoveYear,
    onChangeYear,
    onTrimsFocus,
  };
};
