import { GenFormConfig, GenFormFieldsConfig } from '../../../components/FormGenerator/FormGenerator';
import { ClientGroupType } from '../../../types/clients';
import { TabOption } from '../../../types/field';
import { CurrencyTab, PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { useAllActionsField } from '../fields/useAllActionsField';
import { useAllSeveritiesField } from '../fields/useAllSeveritiesField';
import { useItemSearchField } from '../fields/useItemSearchField';
import { artWholesaleFormFieldsGroup, wholesaleFormFieldsCadGroup, wholesaleFormFieldsUsdGroup } from '../shared/fields';
import { ARTHookParams } from '../shared/types';

export type UseCommonWholesaleFormParams = ARTHookParams & {
  groupType: ClientGroupType.Wholesale;
  currencyTab: TabOption<CurrencyTab>;
  mode: PricingItemModalMode;
};

export interface UseCommonWholesaleForm {
  config: GenFormConfig;
  fieldsConfig: GenFormFieldsConfig;
}

export const useCommonWholesaleForm = (params: UseCommonWholesaleFormParams): UseCommonWholesaleForm => {
  return {
    config: {
      container: 'grid grid-cols-1',
      fields: [
        artWholesaleFormFieldsGroup,
        params.currencyTab.label === CurrencyTab.USD ? wholesaleFormFieldsUsdGroup : wholesaleFormFieldsCadGroup,
      ],
    },
    fieldsConfig: {
      itemSearch: useItemSearchField(params),
      severityDescription: useAllSeveritiesField(params),
      actionCode: useAllActionsField(params),
    },
  };
};
