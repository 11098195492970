import { useQuery } from '@tanstack/react-query';

import { fetchModelPlan } from '../../../common/queries/modelPlans/modelPlans.api';
import { FetchModelPlanResponse, ModelPlanItemInitData } from '../../../common/queries/modelPlans/modelPlans.types';
import { QueryKey } from '../../../constants/queries';

export type UseModelPlanParams = Readonly<{
  planId: string;
  enabled?: boolean;
}>;

export type UseModelPlan = Readonly<{
  data: ModelPlanItemInitData | undefined;
  error: Error | null;
  isFetching: boolean;
  isError: boolean;
  isFetched: boolean;
  isSuccess: boolean;
}>;

export const useModelPlan = ({ planId, enabled }: UseModelPlanParams): UseModelPlan => {
  return useQuery<FetchModelPlanResponse, Error>({
    queryKey: [QueryKey.FETCH_MODEL_PLAN_ITEM, planId],
    queryFn: () => fetchModelPlan({ planId }),
    keepPreviousData: true,
    staleTime: Infinity,
    retry: false,
    cacheTime: 0,
    enabled,
  });
};
