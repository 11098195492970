import * as yup from 'yup';

import { PricingPlan } from '../../../types/pricingPlans';

export const initData: Partial<PricingPlan & { itemSearch: string }> = {
  itemSearch: '',
  itemDescription: '',
  itemCode: '',
  subItemCode: '',
  actionDescription: '',
  severityCode: '',
  chargeableFlag: '',
  actionCode: '',
  partHours: 0,
  paintHours: 0,
  repairHours: 0,
  flatFeeFlag: false,
  partCostMultiplier: 0,
  paintHoursMultiplier: 0,
  partHoursMultiplier: 0,
  repairHoursMultiplier: 0,
  usd: {
    partCost: 0,
    partDollars: 0,
    repairDollars: 0,
    paintDollars: 0,
    partCostRetailFlag: '',
  },
};

const baseSchema = {
  itemCode: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field'),
  actionDescription: yup.string().uppercase().nullable(),
  partHours: yup.number().nullable(),
  paintHours: yup.number().nullable(),
  repairHours: yup.number().nullable(),
  flatFeeFlag: yup.string().nullable(),
  partCostMultiplier: yup.number().nullable(),
  paintHoursMultiplier: yup.number().nullable(),
  partHoursMultiplier: yup.number().nullable(),
  repairHoursMultiplier: yup.number().nullable(),
  usd: yup.object({
    partCost: yup.number().nullable(),
    partDollars: yup.number().nullable(),
    repairDollars: yup.number().nullable(),
    paintDollars: yup.number().nullable(),
    partCostRetailFlag: yup.string().nullable(),
  }),
  cad: yup
    .object({
      partCost: yup.number().nullable(),
      partDollars: yup.number().nullable(),
      repairDollars: yup.number().nullable(),
      paintDollars: yup.number().nullable(),
      partCostRetailFlag: yup.string().nullable(),
    })
    .nullable(),
};

export const validationSchema = yup.object({
  ...baseSchema,
  itemDescription: yup.string().required(''),
  subItemCode: yup
    .string()
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
    .required(''),
  actionCode: yup.string().uppercase().required(''),
  severityCode: yup.string().uppercase().required(''),
  chargeableFlag: yup.string().required(''),
});

export const validationSchemaNextInspectUpdate = yup.object({
  ...baseSchema,
  itemSearch: yup.string().required(''),
  itemDescription: yup.string().required(''),
  subItemCode: yup.string().matches(/^[0-9]+$/, 'Only numbers are allowed for this field'),
  severityCode: yup.string().uppercase(),
  chargeableFlag: yup.string().nullable(),
  actionCode: yup.string().uppercase(),
});

export const validationSchemaWholesale = yup.object(baseSchema).shape({
  ...baseSchema,
  itemCode: yup
    .string()
    .matches(/^[0-9]+$/, 'Only numbers are allowed for this field')
    .nullable(),
  actionCode: yup.string().uppercase().nullable(),
  itemDescription: yup.string().nullable(),
  itemSearch: yup.string().nullable(),
});
