import { flexRender, Table } from '@tanstack/react-table';
import { FormikValues } from 'formik';
import { FC, Fragment } from 'react';

import { HeaderCell } from './HeaderCell/HeaderCell';
import { ClientNEXTInspectSubTab } from '../../pages/ClientListPage/shared/types';

interface CommonTableProps<T> {
  table: Table<T>;
  isFilter?: boolean;
  ExpandedComponent?: FC<{ initialValues: FormikValues; subTab: ClientNEXTInspectSubTab }>;
  subTab?: ClientNEXTInspectSubTab;
  thStyle?: string;
}

export const CommonTable = <T,>({ table, isFilter, ExpandedComponent, subTab, thStyle }: CommonTableProps<T>): JSX.Element => {
  return (
    <table data-testid="common-table" className="prism-table w-full table-fixed border-separate border-spacing-0 relative">
      <thead data-testid="table-head">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} data-testid="titles-th">
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                data-testid="title-th"
                colSpan={header.colSpan}
                style={{ width: header.getSize() }}
                className={`${(header.column.columnDef.meta as Record<string, string>)?.headerClass} ${thStyle || ''}`}
              >
                <HeaderCell header={header} isFilter={isFilter} />
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody data-testid="table-body">
        {table.getRowModel().rows.map((row, index) => (
          <Fragment key={`${row.id}_${index}`}>
            <tr>
              {/* Title row */}
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    key={cell.id}
                    data-testid={`${cell.id}-cell`}
                    className={(cell.column.columnDef.meta as Record<string, string>)?.cellClass}
                  >
                    {flexRender(cell.column.columnDef.cell as string, cell.getContext())}
                  </td>
                );
              })}
            </tr>
            {row.getIsExpanded() && ExpandedComponent && (
              <ExpandedComponent initialValues={row.original as FormikValues} subTab={subTab as ClientNEXTInspectSubTab} />
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};
