import { PlusIcon } from '@prism2/icons-react/prism';
import { FC } from 'react';

import type { OptionsType } from '../../../../../types/field';
import type { CreateModelPlanFormValues, YearForm } from '../shared/modelPlan.form.types';

import { extractModelPlansValuesFromFormik } from './form/extractModelPlansValuesFromFormik';
import { useCreateModelPlanFormik } from './form/useCreateModelPlanFormik';
import { useCreateModelPlanMutation } from './hooks/useCreateModelPlanMutation';
import { useModelPlanListSearch } from './hooks/useModelPlanListSearch';
import { isFormValidForSubmit } from '../../../../../common/utilities/formik';
import { CommonForm } from '../../../../../components/CommonForm/CommonForm';
import { SelectField } from '../../../../../components/FormField/SelectField/SelectField';
import { SwitchField } from '../../../../../components/FormField/SwitchField/SwitchField';
import { QueryKey } from '../../../../../constants/queries';
import { CreateModelPlanFormItem } from '../shared/components/CreateModelPlanFormItem';
import { ValidationErrorMessage } from '../shared/components/ValidationErrorMessage';
import { REMINDER_TRIMS } from '../shared/constants';
import { getYearsOptionsWithDisabled } from '../shared/helpers/getYearsOptionsWithDisabled';
import { onAddNewYear } from '../shared/helpers/onAddNewYear';
import { onChangeBaseTrims } from '../shared/helpers/onChangeBaseTrims';
import { onRemoveYear } from '../shared/helpers/onRemoveYear';
import { useCreateModelPlanHandlers } from '../shared/hooks/useCreateModelPlanHandlers';
import { ValidationStatus, useTrackChangesAndValidateByPricingPlanDesc } from '../shared/hooks/useTrackChangesAndValidateByPricingPlanDesc';

export const CreateModelPlanForm: FC<{ onClose: () => void }> = ({ onClose }): JSX.Element => {
  const { mutate, isSubmitLoading } = useCreateModelPlanMutation({ onClose });

  const { searchModelPlanInput, searchModelPlanOptions, setSearchModelPlanInput, isSearchModelPlanFetching } = useModelPlanListSearch();

  const onSubmit = (values: CreateModelPlanFormValues): void => {
    mutate(extractModelPlansValuesFromFormik(values));
  };

  const formik = useCreateModelPlanFormik({ onSubmit });
  const { year, make, model, trims, isDefault, years } = formik.values;

  const { isValidationFetching, validationStatus, isValidationError, validationErrors } = useTrackChangesAndValidateByPricingPlanDesc({
    formik,
    queryKey: QueryKey.VALIDATE,
  });

  const {
    selectedOption,
    optionsMakes,
    optionsModels,
    yearsMap,
    isFetchingMakes,
    isFetchingModels,
    isFetchingTrims,
    onChangeBaseYear,
    onChangeMake,
    onChangeModel,
    onChangeYear,
    onChangeSourcePlanId,
  } = useCreateModelPlanHandlers({
    formik,
  });

  const yearsOptions = getYearsOptionsWithDisabled(formik);
  const isAllYearsDisabled = yearsOptions?.every((item) => item.isDisabled);

  // Show message when Trims is empty and Default is false
  const isShowReminderTrims = model && !trims?.length && !isDefault;
  const isMakeDisable = !year;
  const isModelDisable = !make;
  const isTrimsDisable = !model;
  const isDefaultDisable = !model;
  const isAddYearDisabled =
    (years && years.length > 0 && years[years.length - 1]?.trims?.length === 0 && !years[years.length - 1]?.isDefault) ||
    (trims && trims.length === 0 && !isDefault) ||
    isAllYearsDisabled;
  const isValidYearsArray = years?.some((item) => !item.year || (item.trims?.length === 0 && !item.isDefault));
  const isLoading = isValidationFetching || isSubmitLoading;
  const isSaveButtonDisabled = Boolean(
    !isFormValidForSubmit(formik) ||
      isLoading ||
      validationStatus !== ValidationStatus.validated ||
      isValidationError ||
      isValidYearsArray ||
      isShowReminderTrims,
  );

  return (
    <CommonForm formik={formik} onClose={onClose} isSaveButtonDisabled={isSaveButtonDisabled} isLoading={isLoading}>
      <SelectField
        name="sourcePlanId"
        label="Copy pricing plan from"
        placeholder="Search..."
        isClearable={true}
        formikBasedValue={selectedOption}
        formik={formik}
        options={searchModelPlanOptions}
        hideArrow={true}
        onChange={onChangeSourcePlanId}
        noOptionsMessage={() => (searchModelPlanInput && !isSearchModelPlanFetching ? 'Clients were not found' : null)}
        onInputChange={setSearchModelPlanInput}
        isFetching={isSearchModelPlanFetching && !!searchModelPlanInput}
      />
      <div className="flex flex-col justify-center items-center border raunded p-2 mt-3" data-testid="primary-year">
        <div className="flex flex-wrap gap-2 w-full col-span-2">
          <SelectField name="year" label="Year" classContainer="w-28" options={yearsOptions} formik={formik} onChange={onChangeBaseYear} />
          <SelectField
            name="make"
            label="Make"
            classContainer="w-52"
            formik={formik}
            options={optionsMakes}
            isFetching={isFetchingMakes}
            disabled={isMakeDisable}
            onChange={onChangeMake}
            formikBasedValue={make ? { value: make, label: make } : null}
          />
          <SelectField
            name="model"
            label="Model"
            classContainer="w-72"
            formik={formik}
            options={optionsModels}
            isFetching={isFetchingModels}
            disabled={isModelDisable}
            onChange={onChangeModel}
            formikBasedValue={model ? { value: model, label: model } : null}
          />
          <SelectField
            name="trims"
            label="Trims"
            classContainer="w-80"
            isMulti={true}
            formik={formik}
            isFetching={years && years.length ? false : isFetchingTrims}
            options={yearsMap[year as string]?.options}
            disabled={isTrimsDisable}
            onChange={(option) => onChangeBaseTrims(formik, option)}
            formikBasedValue={trims!.length > 0 ? [...(trims as OptionsType[])] : []}
          />
          <SwitchField name="isDefault" label="Default" vertical={true} formik={formik} disabled={isDefaultDisable} />
          {isShowReminderTrims && (
            <p className="flex justify-center w-full px-2 bg-yellow-300 rounded" data-testid="reminder-base-trims">
              {REMINDER_TRIMS}
            </p>
          )}
        </div>
      </div>

      {(years as YearForm[]).map((item: YearForm, index: number) => (
        <CreateModelPlanFormItem
          key={item.year}
          formik={formik}
          yearsMap={yearsMap}
          optionsMakes={optionsMakes}
          optionsModels={optionsModels}
          item={item}
          index={index}
          yearsOptions={yearsOptions}
          onChangeYear={onChangeYear}
          onRemoveYear={(item) => onRemoveYear(formik, item)}
        />
      ))}

      <button data-testid="add-year-btn" className="prism-btn my-3" onClick={() => onAddNewYear(formik)} disabled={isAddYearDisabled}>
        <PlusIcon /> Add new year
      </button>
      <ValidationErrorMessage formik={formik} errors={validationErrors} />
    </CommonForm>
  );
};
