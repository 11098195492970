import { FormikProps } from 'formik';

import { useFlatFeeFlag } from './useFlatFeeFlag';
import { PricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { TabOption } from '../../../types/field';
import { CurrencyTab } from '../components/LineItemModal/shared/types';

// For pricing plan Wholesale
export const useCurrencyFlatFeeFlag = (formik: FormikProps<Partial<PricingPlanItem>>, currencyTab: TabOption<CurrencyTab>): void => {
  const currency = currencyTab.label === CurrencyTab.USD ? 'usd' : 'cad';
  const repairDollars = formik.values[currency]?.repairDollars;
  useFlatFeeFlag(formik, repairDollars);
};
