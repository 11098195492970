import { FC } from 'react';

import { NextInspectWizard } from './components/NextInspectWizard/NextInspectWizard';
import { WholesaleForm } from './components/WholesaleForm/WholesaleForm';
import { getCommonClientModalTitle } from './shared/utils';
import { Modal } from '../../../../components/Modal/Modal';
import { Client, ClientGroupType } from '../../../../types/clients';
import { ClientNEXTInspectSubTab, ClientSubTab, ClientTab } from '../../shared/types';

export interface CommonClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientTab: ClientTab;
  clientSubTab: ClientSubTab;
  /** data for pre-population */
  client: Partial<Client>;
}

/** Modal wrapper, should decide which form to render */
export const CommonClientModal: FC<CommonClientModalProps> = ({
  isOpen,
  onClose,
  clientTab,
  clientSubTab,
  client,
}: CommonClientModalProps): JSX.Element => {
  const groupType = clientSubTab === ClientNEXTInspectSubTab.DEMO ? ClientGroupType.NextInspect : (clientTab as unknown as ClientGroupType);
  const fieldsToPrepopulate = { ...client, groupType };

  const title = getCommonClientModalTitle(clientSubTab);

  const renderForm = (): JSX.Element => {
    if (clientTab === ClientTab.Wholesale) {
      return <WholesaleForm clientTab={clientTab} onClose={onClose} />;
    }
    return (
      <NextInspectWizard clientTab={clientTab} clientSubTab={clientSubTab} onClose={onClose} fieldsToPrepopulate={fieldsToPrepopulate} />
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div data-testid="new-client-modal" tabIndex={0}>
        <div data-testid="title" className="text-blue-800 text-center prism-heading-3 mb-4">
          {title}
        </div>
        <div data-testid="body" className="border-dashed border-2 p-10 react-select-reset">
          {renderForm()}
        </div>
      </div>
    </Modal>
  );
};
