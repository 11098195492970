import type {
  FetchClientSettingsAccountsParams,
  FetchClientSettingsJSONShemaResponse,
  FetchClientSettingsParams,
  FetchClientSettingsResponse,
  FetchClientSettingsUpdateParams,
  FetchClientSettingsUpdateResponse,
} from './clientSettingsProxy.types';

import { FetchClientSettingsAccountsResponse } from './clientSettingsProxy.types';
import { config } from '../../../config';
import { ClientSettings } from '../../../types/clientSettings';
import { Paginated } from '../../../types/common';
import { fetchJson } from '../../utilities/api/fetchJson';

export const fetchClientSettings = (params: FetchClientSettingsParams): Promise<FetchClientSettingsResponse> => {
  const { client } = params;
  const url = new URL(`${config.API_URL}/client-settings-proxy/${client}`);
  return fetchJson<Paginated<ClientSettings>>(url, 'GET');
};

export const fetchClientSettingsUpdate = (params: FetchClientSettingsUpdateParams): Promise<FetchClientSettingsUpdateResponse> => {
  const { values } = params;
  const url = new URL(`${config.API_URL}/client-settings-proxy`);
  return fetchJson(url, 'POST', values);
};

export const fetchClientSettingsAccounts = (params?: FetchClientSettingsAccountsParams): Promise<FetchClientSettingsAccountsResponse> => {
  const { isFiltered } = params || {};
  const url = new URL(`${config.API_URL}/client-settings-proxy?isFiltered=${isFiltered ? 'true' : 'false'}`);
  return fetchJson(url, 'GET');
};

export const fetchClientSettingsJSONShema = (): Promise<FetchClientSettingsJSONShemaResponse> => {
  const url = new URL(`${config.API_URL}/client-settings-proxy/schema`);
  return fetchJson(url, 'GET');
};
