export const uiSchema = {
  id: {
    'ui:widget': 'hidden',
  },
  pricing: {
    groupCode: {
      'ui:disabled': true,
    },
  },
  user: {
    sourceId: {
      'ui:disabled': true,
    },
    source: {
      'ui:disabled': true,
    },
  },
  email: {
    body: {
      'ui:widget': 'textarea',
    },
  },
};
