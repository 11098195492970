import { FC } from 'react';
import { Link } from 'react-router-dom';

export interface HeaderProps {
  title: string;
}
export const Header: FC<HeaderProps> = ({ title }: HeaderProps): JSX.Element => {
  const dataTestId = title.split(' ').join('-').toLocaleLowerCase();

  return (
    <div
      className="w-full flex flex-row mb-5 px-10 py-2 white bg-blue-800 items-center justify-between"
      data-testid={`${dataTestId}-title`}
    >
      <div className="flex flex-row items-center gap-10">
        <Link className="prism-heading-1 text-white border-r-1" to="/">
          Pricing Admin
        </Link>
        <div className="min-h-[1em] w-px self-stretch bg-white"></div>
        <h2 className="prism-heading-2 text-white">{title}</h2>
      </div>
    </div>
  );
};
