import { PlusIcon } from '@prism2/icons-react/prism';
import { FormikProps, FormikValues } from 'formik';
import { FC } from 'react';

import { UpdateModelPlanFormItem } from './components/UpdateModelPlanFormItem';
import { useUpdateModelPlanHandlers } from './hooks/useUpdateModelPlanHandlers';
import { isFormValidForSubmit } from '../../../../../common/utilities/formik';
import { BlockScreenLoading } from '../../../../../components/BlockScreenLoading/BlockScreenLoading';
import { SelectField } from '../../../../../components/FormField/SelectField/SelectField';
import { SwitchField } from '../../../../../components/FormField/SwitchField/SwitchField';
import { Loading } from '../../../../../components/Loading/Loading';
import { QueryKey } from '../../../../../constants/queries';
import { ValidationErrorMessage } from '../shared/components/ValidationErrorMessage';
import { REMINDER_TRIMS } from '../shared/constants';
import { getYearsOptionsWithDisabled } from '../shared/helpers/getYearsOptionsWithDisabled';
import { onAddNewYear } from '../shared/helpers/onAddNewYear';
import { onChangeBaseTrims } from '../shared/helpers/onChangeBaseTrims';
import { ValidationStatus, useTrackChangesAndValidateByPricingPlanDesc } from '../shared/hooks/useTrackChangesAndValidateByPricingPlanDesc';
import { UpdateModelPlanItem, YearForm } from '../shared/modelPlan.form.types';

type TableRowUpdateModelPlanProps = {
  formik: FormikProps<FormikValues>;
  planId: string;
  isLoading: boolean;
};

export const TableRowUpdateModelPlan: FC<TableRowUpdateModelPlanProps> = ({ formik, planId, isLoading }: TableRowUpdateModelPlanProps) => {
  const { year, model, trims, isDefault, years } = formik.values as UpdateModelPlanItem;

  const { isValidationFetching, validationStatus, isValidationError, validationErrors } = useTrackChangesAndValidateByPricingPlanDesc({
    planId,
    formik,
    queryKey: QueryKey.VALIDATE,
  });

  const {
    optionsModels,
    yearsMap,
    isFetchingModels,
    isFetchingTrims,
    onChangeBaseYear,
    onChangeModel,
    onRemoveYear,
    onChangeYear,
    onTrimsFocus,
  } = useUpdateModelPlanHandlers({ formik });

  const yearsOptions = getYearsOptionsWithDisabled(formik);

  const isAllYearsDisabled = yearsOptions?.every((item) => item.isDisabled);

  const isAddYearDisabled =
    !years ||
    (years.length > 0 &&
      years[years.length - 1]?.trims &&
      years[years.length - 1]?.trims.length === 0 &&
      !years[years.length - 1]?.isDefault) ||
    !trims ||
    (trims.length === 0 && !isDefault) ||
    isAllYearsDisabled;

  const isModelDisable = !year;
  const isTrimsDisable = !model || isFetchingTrims;
  const isDefaultDisable = !model;
  const isShowReminderTrims = model && (!trims || trims.length === 0) && !isDefault;

  const isValidateLoading = isValidationFetching;
  const isValidYearsArray = years?.some((item) => !item.year || (item.trims?.length === 0 && !item.isDefault));
  const isSaveButtonDisabled = Boolean(
    !isFormValidForSubmit(formik) ||
      isLoading ||
      validationStatus !== ValidationStatus.validated ||
      isValidationError ||
      isValidYearsArray ||
      isShowReminderTrims,
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      {isValidateLoading && <BlockScreenLoading />}
      <div className="flex flex-col justify-center border rounded w-fit p-2 mt-3 pr-8" data-testid="primary-year">
        <div className="flex flex-wrap gap-2">
          <SelectField name="year" label="Year" classContainer="w-28" options={yearsOptions} formik={formik} onChange={onChangeBaseYear} />
          <SelectField
            name="model"
            label="Model"
            classContainer="w-72"
            formik={formik}
            options={optionsModels}
            isFetching={isFetchingModels && !isLoading && !isValidateLoading}
            disabled={isModelDisable}
            onChange={onChangeModel}
            formikBasedValue={model ? { value: model, label: model } : null}
          />
          <SelectField
            name="trims"
            label="Trims"
            classContainer="w-80"
            isMulti={true}
            formik={formik}
            onFocus={() => onTrimsFocus(year as string)}
            isFetching={Boolean(yearsMap[year as string]?.isFetching) && !isLoading && !isValidateLoading}
            options={yearsMap[year as string]?.options}
            disabled={isTrimsDisable}
            onChange={(option) => onChangeBaseTrims(formik, option)}
            formikBasedValue={trims && trims.length > 0 ? [...trims] : []}
          />
          <SwitchField name="isDefault" label="Default" vertical={true} formik={formik} disabled={isDefaultDisable} />
          {isShowReminderTrims && (
            <p className="flex justify-center w-full px-2 bg-yellow-300 rounded" data-testid="reminder-base-trims">
              {REMINDER_TRIMS}
            </p>
          )}
        </div>
      </div>
      {(years as YearForm[]).map((item: YearForm, index: number) => (
        <UpdateModelPlanFormItem
          key={item.year}
          formik={formik}
          yearsMap={yearsMap}
          item={item}
          index={index}
          isFetchingTrims={isFetchingTrims}
          yearsOptions={yearsOptions}
          onChangeYear={onChangeYear}
          onRemoveYear={onRemoveYear}
          onTrimsFocus={onTrimsFocus}
          optionsModels={optionsModels}
        />
      ))}

      <button data-testid="add-year-btn" className="prism-btn my-3" onClick={() => onAddNewYear(formik)} disabled={isAddYearDisabled}>
        <PlusIcon /> Add new year
      </button>
      <ValidationErrorMessage formik={formik} errors={validationErrors} />
      <div data-testid="button-actions" className="flex flex-wrap items-center gap-4 mt-4">
        <button onClick={() => formik.resetForm()} type="button" data-testid="cancel-btn" className="prism-btn w-20">
          Cancel
        </button>
        <button type="submit" data-testid="save-btn" className="prism-btn w-20" disabled={isSaveButtonDisabled}>
          Save
        </button>
        {isLoading && !isValidateLoading && <Loading />}
      </div>
    </form>
  );
};
