import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { useNavigator } from '../../../../../common/modules/navigation/useNavigator';
import { createNextInspectClient } from '../../../../../common/queries/clients/clients.api';
import { removeFieldsFromFormik } from '../../../../../common/utilities/removeFiledsFromFormik';
import phrases from '../../../../../constants/en_US.json';
import { QueryKey } from '../../../../../constants/queries';
import { Client, PricingType } from '../../../../../types/clients';
import { CLIENT_MITCHELL_AND_CALC_FIELDS_TO_REMOVE } from '../../../shared/constants';
import { ClientNEXTInspectSubTab } from '../../../shared/types';
import { UseClientMutationsParams } from '../components/NextInspectWizard/hooks/useClientMutation';

export type UseCreateClientMutationParams = Pick<UseClientMutationsParams, 'formik' | 'onClose'>;

export const useCreateNextInspectClientMutation = ({ onClose, formik }: UseCreateClientMutationParams) => {
  const queryClient = useQueryClient();
  const { navigateToClientSettings } = useNavigator();

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: Partial<Client>) => {
      if ([PricingType.Infobahn, PricingType.NoPricing].includes(formik.values.pricingType as PricingType)) {
        // delete Mitchell and Pricing calculation fields from formik
        const requiredValues = removeFieldsFromFormik(values, CLIENT_MITCHELL_AND_CALC_FIELDS_TO_REMOVE);
        return createNextInspectClient({ client: requiredValues });
      }
      return createNextInspectClient({ client: values });
    },
    onError: (e: Error) => toast(e.message ?? phrases.clientAddedError, { type: 'error' }),
    onSuccess: ({ clientSettingsId }) => {
      void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      toast(phrases.clientAddedSuccess, { type: 'success' });
      onClose();

      if ([PricingType.Infobahn, PricingType.NoPricing].includes(formik.values.pricingType as PricingType)) {
        navigateToClientSettings(clientSettingsId, ClientNEXTInspectSubTab.ACTIVE_MANHEIM);
      }
    },
  });

  return { mutate, isLoading };
};
