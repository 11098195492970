import { useQuery } from '@tanstack/react-query';

import { fetchClientSettingsAccounts } from '../../../common/queries/clientSettingsProxy/clientSettingsProxy.api';
import { FetchClientSettingsAccountsResponse } from '../../../common/queries/clientSettingsProxy/clientSettingsProxy.types';
import { QueryKey } from '../../../constants/queries';
import { ClientNEXTInspectSubTab, ClientSubTab } from '../shared/types';

interface UseClientSettingsParams {
  subTab: ClientSubTab;
}

interface UseClientSettings {
  data: FetchClientSettingsAccountsResponse | undefined;
  error: Error | null;
  isFetching: boolean;
  isFetched: boolean;
  isSuccess: boolean;
}

export const useClientSettings = ({ subTab }: UseClientSettingsParams): UseClientSettings => {
  return useQuery({
    queryKey: [QueryKey.FETCH_CLIENT_SETTINGS_ACCOUNTS],
    queryFn: () => fetchClientSettingsAccounts({ isFiltered: true }),
    enabled: subTab === ClientNEXTInspectSubTab.DEMO,
    cacheTime: 0,
  });
};
