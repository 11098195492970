type Procedure = (...args: never[]) => void;

export const debounce = <F extends Procedure>(func: F, waitFor: number): ((...args: Parameters<F>) => ReturnType<F> | void) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<F>): ReturnType<F> | void => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func(...args), waitFor);
  };
};
