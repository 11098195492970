import { useCallback, useState } from 'react';

import type { TabOption } from '../../../types/field';

import { PRICING_LINE_ITEM_CURRENCY_TABS } from '../components/LineItemModal/shared/constants';
import { CurrencyTab } from '../components/LineItemModal/shared/types';

export interface UseCurrencyTab {
  currencyTab: TabOption<CurrencyTab>;
  setCurrencyTab: (tab: TabOption<CurrencyTab>) => void;
  onTabChange: (tab: TabOption<CurrencyTab>) => void;
}

export const useCurrencyTab = (): UseCurrencyTab => {
  const [currencyTab, setCurrencyTab] = useState<TabOption<CurrencyTab>>(PRICING_LINE_ITEM_CURRENCY_TABS[0]);

  const onTabChange = useCallback(
    (tab: TabOption<CurrencyTab>): void => {
      setCurrencyTab(tab);
    },
    [currencyTab],
  );

  return { currencyTab, setCurrencyTab, onTabChange };
};
