import type { YearForm } from '../modelPlan.form.types';
import type { FormikProps, FormikValues } from 'formik';

export const onRemoveYear = (formik: FormikProps<FormikValues>, item: YearForm) => {
  const { years } = formik.values;
  if (years && Array.isArray(years)) {
    void formik.setFieldValue(
      'years',
      years.filter((yearItem: YearForm) => yearItem.year !== item.year),
    );
  }
};
