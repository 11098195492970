import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

import { PricingPlan } from '../../../types/pricingPlans';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { validationSchema, validationSchemaNextInspectUpdate } from '../shared/schema';
import { getCommonPricingFormInitValues } from '../utils/getCommonPricingFormInitValues';

export interface UseCommonNextInspectFormikParams {
  lineItem?: PricingPlan;
  mode: PricingItemModalMode;
  onSubmit: () => void;
  itemSearch?: string;
}

export type UseCommonNextInspectFormik = FormikProps<Partial<PricingPlan>>;

export const useCommonNextInspectFormik = ({
  lineItem,
  mode,
  onSubmit,
  itemSearch,
}: UseCommonNextInspectFormikParams): UseCommonNextInspectFormik => {
  const initialValues = getCommonPricingFormInitValues({ pricingPlanItem: lineItem, mode, itemSearch });

  const uniqueKey: Partial<PricingPlan> = {
    itemCode: lineItem?.itemCode ?? '',
    subItemCode: lineItem?.subItemCode ?? '',
    severityCode: lineItem?.severityCode ?? '',
    actionCode: lineItem?.actionCode ?? '',
  };

  return useFormik<Partial<PricingPlan>>({
    initialValues,
    validateOnChange: true,
    validationSchema: () =>
      yup.lazy((value: Partial<PricingPlan>) => {
        const keys = Object.keys(uniqueKey) as (keyof Partial<PricingPlan>)[];
        const isUniqueKeyChanged = keys.some((key) => uniqueKey[key] !== value[key]);

        if (mode !== PricingItemModalMode.UPDATE || isUniqueKeyChanged) {
          return validationSchema;
        }
        return validationSchemaNextInspectUpdate;
      }),
    enableReinitialize: true,
    onSubmit,
  });
};
