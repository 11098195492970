import { RJSFSchema } from '@rjsf/utils';

import { DecodedUser } from '../../../common/modules/auth/types';
import { DEFAULT_USER_EMAIL } from '../../../constants/variables';
import { ClientSettings } from '../../../types/clientSettings';

export const getSourceIdFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem('user')!) as DecodedUser;
  return user?.sub?.split(':')?.[1] ?? DEFAULT_USER_EMAIL;
};

export const mapInitialData = (initialData?: ClientSettings, schema?: RJSFSchema): ClientSettings | undefined => {
  const mappedData = { ...initialData } as ClientSettings;

  Object.keys(schema?.properties ?? {}).forEach((property) => {
    if (!(property in mappedData) && property !== 'dataSource') {
      //@ts-expect-error: mapping error
      mappedData[property] = null;
    }
  });

  return mappedData;
};
