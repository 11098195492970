import { catchApiError } from './fetchExceptionFilter';
import { HttpMethod } from '../../../types/common';
import { setAuthHeaders } from '../../modules/auth/utils';

export const fetchJson = async <TResponse>(
  url: string | URL,
  method: HttpMethod,
  body?: unknown,
  signal?: AbortSignal,
): Promise<TResponse> => {
  const response = await fetch(url, {
    method,
    headers: {
      ...setAuthHeaders(),
      'Content-Type': 'application/json',
    },
    signal,
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    return await catchApiError(response);
  }

  return (await response.json()) as TResponse;
};
