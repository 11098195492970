import { FormikProps } from 'formik';

export const checkIfFormikChangedByUser = <T>({
  formik,
  checkOnlyUserInput = true,
}: {
  formik: FormikProps<T>;
  checkOnlyUserInput?: boolean;
}): boolean => {
  // NOTE: at the most cases we should treat  form is dirty when it's "polluted" by ui input
  const reallyDirty = checkOnlyUserInput ? Boolean(Object.keys(formik.touched).length) && formik.dirty : formik.dirty;
  return formik.isValid && reallyDirty;
};

export const isFormValidForSubmit = <T>(formik: FormikProps<T>): boolean => {
  return formik.dirty && formik.isValid;
};
