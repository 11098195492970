import { useQuery } from '@tanstack/react-query';

import { fetchClientSettingsAccounts } from '../../../common/queries/clientSettingsProxy/clientSettingsProxy.api';
import { QueryKey } from '../../../constants/queries';
import { PricingType } from '../../../types/clients';

export interface UseClientSettingsAccountsParams {
  groupName: string;
  enabled?: boolean;
  isFiltered?: boolean;
}

export interface AccountsOption {
  label: string;
  value: string;
  client?: string;
  pricingType?: string;
}

export interface UseClientSettingsAccounts {
  accountOptions: AccountsOption[] | undefined;
  isFetching: boolean;
}

export const useClientSettingsAccounts = ({ enabled, isFiltered }: UseClientSettingsAccountsParams): UseClientSettingsAccounts => {
  const { data, isFetching } = useQuery({
    queryKey: [QueryKey.FETCH_CLIENT_SETTINGS_ACCOUNTS],
    queryFn: () => fetchClientSettingsAccounts({ isFiltered }),
    enabled,
  });

  const accountOptions = data?.map(({ description, client, pricing, sellerAccounts }) => ({
    value: description!,
    label: description!,
    client: client,
    pricingType: pricing?.disabled ? PricingType.NoPricing : pricing?.type,
    sellerAccount: sellerAccounts?.[0],
  }));

  return { accountOptions, isFetching };
};
