import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { fetchClientUpgrade } from '../../../../../common/queries/clients/clients.api';
import phrases from '../../../../../constants/en_US.json';
import { QueryKey } from '../../../../../constants/queries';
import { Client } from '../../../../../types/clients';

export const useUpgradeClientMutation = (onClose: () => void) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: Partial<Client>) => fetchClientUpgrade({ client: values }),
    onError: (e: Error) => toast(e.message ?? phrases.clientAddedError, { type: 'error' }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
      void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENT_SETTINGS_ACCOUNTS] });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      toast(phrases.clientAddedSuccess, { type: 'success' });
      onClose();
    },
  });

  return { mutate, isLoading };
};
