import { FormikProps, FormikValues } from 'formik';
import { useEffect, useState } from 'react';

import { Field, OptionsType } from '../../../../../types/field';
import { useClientListSearch } from '../../../hooks/useClientListSearch';
import { ClientSubTab, ClientTab } from '../../../shared/types';

export const getSourceGroupCodeField = (
  formik: FormikProps<FormikValues>,
  clientTabValue: ClientTab,
  clientSubTab?: ClientSubTab,
): Partial<Field> => {
  const { searchClientOptions, searchClientInput, setSearchClientInput, isSearchClientFetching } = useClientListSearch(
    clientTabValue,
    clientSubTab,
  );
  // hack. will be changed after form fields refactoring(SelectField)
  const [selectedOption, setSelectedOption] = useState<OptionsType | null>(null);

  useEffect(() => {
    if (!formik.values.sourceGroupCode) {
      setSelectedOption(null);
    }
  }, [formik.values.sourceGroupCode]);

  const onChange = (option: OptionsType) => {
    formik.setFieldValue('sourceGroupCode', option?.value);
    setSelectedOption(option);
  };

  return {
    placeholder: 'Search...',
    isClearable: true,
    formikBasedValue: selectedOption,
    onChange,
    options: searchClientOptions,
    hideArrow: true,
    noOptionsMessage: () => (searchClientInput && !isSearchClientFetching ? 'Clients were not found' : null),
    onInputChange: setSearchClientInput,
    isFetching: isSearchClientFetching && !!searchClientInput,
  };
};
