import { FormikProps } from 'formik';
import { SingleValue } from 'react-select';

import { ClientGroupType } from '../../../types/clients';
import { Field, OptionsType } from '../../../types/field';
import { PricingPlan } from '../../../types/pricingPlans';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { useAllSeverities } from '../hooks/useAllSeverities';

export interface UseAllSeveritiesFieldParams {
  formik: FormikProps<Partial<PricingPlan>>;
  mode: PricingItemModalMode;
  groupType?: ClientGroupType;
}

export const useAllSeveritiesField = ({ formik, groupType, mode }: UseAllSeveritiesFieldParams): Partial<Field> => {
  const { options, isFetching } = useAllSeverities(groupType);
  const onChange = getSeverityDescriptionOnChangeCallback(formik);
  const formikBasedValue = getSeverityDescriptionFormikBasedValue(formik);
  return {
    disabled: mode === PricingItemModalMode.UPDATE ? true : isFetching,
    formikBasedValue,
    isFetching,
    onChange,
    options,
  };
};

export const getSeverityDescriptionFormikBasedValue = (formik: FormikProps<Partial<PricingPlan>>) => {
  const { severityCode, severityDescription } = formik.values;
  if (!severityCode || !severityDescription) {
    return null;
  }
  return {
    value: severityCode,
    label: severityDescription,
  };
};

export const getSeverityDescriptionOnChangeCallback = (
  formik: FormikProps<Partial<PricingPlan>>,
): ((option: SingleValue<OptionsType>) => void) => {
  return (option: SingleValue<OptionsType>): void => {
    const severityDescriptionValue = getSeverityDescriptionValueFromOption(option);
    formik.setValues({
      ...formik.values,
      ...severityDescriptionValue,
    });
  };
};

export const getSeverityDescriptionValueFromOption = (option: SingleValue<OptionsType>) => {
  if (!option?.value) {
    return {
      severityCode: '',
      severityDescription: '',
    };
  }
  return {
    severityCode: option.value as string,
    severityDescription: option.label,
  };
};
