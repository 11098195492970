import { BaseFieldProps } from '../../../types/field';
import { Loading } from '../../Loading/Loading';
import { Label } from '../Label/Label';
import { getValueByName } from '../utils';

export interface TextFieldProps extends BaseFieldProps {
  classContainer?: string;
  isFetching?: boolean;
  placeholder?: string;
  error?: string;
  validateOnChange?: boolean;
}

export const TextField = ({
  formik,
  name,
  label,
  isFetching,
  disabled,
  classContainer,
  defaultValue,
  placeholder,
  onChange,
  error,
  childComponent,
  validateOnChange,
  required,
}: TextFieldProps): JSX.Element => {
  const disabledStyles = disabled ? 'text-gray-500 bg-gray-50 border-gray-300' : '';
  const inputStyles = `rounded w-full ${disabledStyles}`;
  const isError = (formik?.errors[name] && formik.touched[name]) || error;
  const errorToRender = formik?.errors[name] && formik.touched ? formik.errors[name] : error;

  const handleChange = (newValue: string) => {
    if (validateOnChange) {
      formik?.setFieldTouched(name, true);
    }

    if (onChange) {
      return onChange(newValue as never);
    }
    return formik?.setFieldValue(name, newValue);
  };

  return (
    <div className={`relative ${classContainer ?? ''}`}>
      {label && <Label name={name} label={label} disabled={disabled} required={required} />}
      {isFetching && (
        <div className="absolute top-8 right-4">
          <Loading />
        </div>
      )}
      <input
        disabled={disabled}
        data-testid={`${name}-field`}
        type="text"
        className={inputStyles}
        name={name}
        value={defaultValue ?? (getValueByName(name, formik) as unknown as string | number) ?? ''}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={formik?.handleBlur}
        placeholder={placeholder}
      />
      {isError && (
        <span className="text-red-500 text-sm absolute top-full left-0" data-testid={`${name}-error`}>
          {errorToRender as string}
        </span>
      )}
      {childComponent}
    </div>
  );
};
