import { FormikProps, FormikValues, useFormik } from 'formik';
import * as yup from 'yup';

import { Client } from '../../../../../../../types/clients';
import { getValidationSchemaBuilder } from '../utils/getValidationSchemaBuilder';

export const INIT_DATA = {
  groupCode: '',
  groupName: '',
  client: '',
  sourceGroupCode: '',
  pricingType: '',
  isMitchellPaint: false,
  isMitchellPart: false,
  isMitchellRetail: false,
  isModelPricing: true,
  damageQty: false,
  damageSize: false,
  shouldCopyPricingPlan: true,
} as Client;

export interface UseNextInspectWizardFormikParams {
  fieldsToPrepopulate?: Partial<Client>;
  onSubmit: (values: FormikValues) => void;
}

export type UseNextInspectWizardFormik = FormikProps<Partial<Client>>;

/** Formik for "Active" and "Demo" sub-tabs */
export const useNextInspectWizardFormik = ({
  fieldsToPrepopulate,
  onSubmit,
}: UseNextInspectWizardFormikParams): UseNextInspectWizardFormik => {
  const flatFeeFlag = !(
    fieldsToPrepopulate?.isMitchellPaint ||
    fieldsToPrepopulate?.isMitchellPart ||
    fieldsToPrepopulate?.isMitchellRetail
  );

  return useFormik<Partial<Client>>({
    validationSchema: () => yup.lazy(getValidationSchemaBuilder()),
    initialValues: { ...INIT_DATA, flatFeeFlag, ...fieldsToPrepopulate },
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit,
  });
};
