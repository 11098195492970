import { AuthService } from '../../modules/auth/AuthService';

export const catchApiError = async (response: Response): Promise<never> => {
  if (response.status === 401) {
    AuthService.getInstance().reAuthorize();
    throw new Error('Unauthorized error');
  }

  if (response.status === 403) {
    AuthService.getInstance().reAuthorize();
    throw new Error('Forbidden error');
  }

  const exception: unknown = await response.json();
  throw new Error((exception as Error).message || response.statusText);
};
