import { useQuery } from '@tanstack/react-query';

import { fetchPricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.api';
import { FetchPricingPlanItemResponse } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { QueryKey } from '../../../constants/queries';
import { ClientGroupType } from '../../../types/clients';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';

type UsePricingPlanItem = {
  isFetchingPricingPlanItem: boolean;
  pricingPlanItem?: FetchPricingPlanItemResponse;
};

type UsePricingPlanItemParams = Readonly<{
  groupType: ClientGroupType;
  mode: PricingItemModalMode;
  sequenceNumber?: number;
  planId?: string;
  itemCode?: string;
  subItemCode?: string;
  severityCode?: string;
  actionCode?: string | null;
  damageCode?: string;
  isEnabled?: boolean;
}>;

export const usePricingPlanItem = ({
  groupType,
  sequenceNumber,
  planId,
  itemCode,
  subItemCode,
  severityCode,
  actionCode,
  damageCode,
  isEnabled,
}: UsePricingPlanItemParams): UsePricingPlanItem => {
  const enabled = isEnabled && groupType === ClientGroupType.Wholesale;

  const queryFn = async (): Promise<FetchPricingPlanItemResponse> => {
    return await fetchPricingPlanItem({
      sequenceNumber: sequenceNumber || 0,
      planId: planId || '',
      itemCode: itemCode || '',
      subItemCode: subItemCode || '',
      severityCode: severityCode || '',
      actionCode: actionCode || '',
      damageCode: damageCode || '',
    });
  };

  const { data, isFetching } = useQuery<FetchPricingPlanItemResponse>({
    queryKey: [QueryKey.FETCH_PRICING_PLAN_ITEM, planId, itemCode, subItemCode, severityCode, actionCode, sequenceNumber, isEnabled],
    queryFn,
    keepPreviousData: false,
    enabled,
    cacheTime: 0,
  });

  return { pricingPlanItem: data, isFetchingPricingPlanItem: isFetching };
};
