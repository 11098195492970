import { FormikProps } from 'formik';
import { useEffect } from 'react';

import { PricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { PricingPlan } from '../../../types/pricingPlans';

export const usePartCostRetailFlag = (
  formik: FormikProps<Partial<PricingPlan>> | FormikProps<Partial<PricingPlanItem>>,
  currency: string,
  partCost: number | null | undefined,
): void => {
  useEffect(() => {
    if (!formik.dirty) {
      return;
    }
    const value = !partCost || partCost <= 0 ? '' : 'R';
    formik.setFieldValue(`${currency}.partCostRetailFlag`, value);
  }, [partCost]);
};
