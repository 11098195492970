import type { UpdateModelPlanItem } from '../../shared/modelPlan.form.types';
import type { FormikProps } from 'formik';

import { updateModelPlanValidationSchema, useModelPlanFormik } from '../../shared/useModelPlanFormik';

export type UseModelPlanTableRowFormikParams = {
  onSubmit: (values: UpdateModelPlanItem) => void;
  initialValues: UpdateModelPlanItem;
};

export type UseModelPlanTableRowFormik = FormikProps<UpdateModelPlanItem>;

export const useModelPlanTableRowFormik = ({ onSubmit, initialValues }: UseModelPlanTableRowFormikParams): UseModelPlanTableRowFormik =>
  useModelPlanFormik({ onSubmit, initialValues, validationSchema: updateModelPlanValidationSchema });
