import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

import { getAllCurrentParams } from './useSearchParamsState';
import { SyncStatus } from '../../constants/sync';
import { DEFAULT_PAGINATION } from '../../pages/ClientListPage/shared/constants';

type UseSyncPaginationWithSearchParams = {
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
};
export const useSyncPaginationWithSearchParams = (): UseSyncPaginationWithSearchParams => {
  const [paginationState, setPaginationState] = useState<PaginationState>(() => DEFAULT_PAGINATION);
  const syncStatus = useRef(SyncStatus.DEFAULT);
  const [searchParams, setSearchParams] = useSearchParams();

  const dependencyPagination = `${searchParams.get('pageIndex') || ''}-${searchParams.get('pageSize') || ''}`;

  useEffect(() => {
    if (syncStatus.current === SyncStatus.SKIP_SYNC_VALUES) {
      syncStatus.current = SyncStatus.DEFAULT;
      return;
    }
    syncStatus.current = SyncStatus.SKIP_SYNC_SEARCH_PARAMS;

    setPaginationState({
      pageIndex: Number(searchParams.get('pageIndex')) || DEFAULT_PAGINATION.pageIndex,
      pageSize: Number(searchParams.get('pageSize')) || DEFAULT_PAGINATION.pageSize,
    });
  }, [dependencyPagination]);

  useEffect(() => {
    if (syncStatus.current === SyncStatus.SKIP_SYNC_SEARCH_PARAMS) {
      syncStatus.current = SyncStatus.DEFAULT;
      return;
    }

    syncStatus.current = SyncStatus.SKIP_SYNC_VALUES;
    const newPaginationState = {
      pageIndex: String(paginationState?.pageIndex || DEFAULT_PAGINATION.pageIndex),
      pageSize: String(paginationState?.pageSize || DEFAULT_PAGINATION.pageSize),
    };
    setSearchParams({ ...getAllCurrentParams(searchParams), ...newPaginationState } as URLSearchParamsInit, { replace: true });
  }, [paginationState]);

  return {
    pagination: paginationState,
    setPagination: setPaginationState,
  };
};
