import type {
  CreateModelPlanItemRequestParams,
  CreateModelPlanResponse,
  FetchModelPlanParams,
  FetchModelPlanResponse,
  FetchModelPlansParams,
  FetchModelPlansResponse,
  FetchModelPlanValidationParams,
  FetchModelPlanValidationResponse,
  UpdateModelPlanItemRequestParams,
  UpdateModelPlanResponse,
} from './modelPlans.types';

import { config } from '../../../config';
import { fetchJson } from '../../utilities/api/fetchJson';
import { appendFilterToURL } from '../../utilities/appendFilterToURL';
import { appendSortToURL } from '../../utilities/appendSortToURL';
import { appendToQueryIfExists } from '../../utilities/appendToQueryIfExists';

export const fetchModelPlans = (params: FetchModelPlansParams): Promise<FetchModelPlansResponse> => {
  const { offset, limit, planDescription, filter, sort, signal } = params;

  const url = new URL(`${config.API_URL}/model-plans`);
  appendToQueryIfExists(url, 'planDescription', planDescription);
  appendToQueryIfExists(url, 'offset', offset);
  appendToQueryIfExists(url, 'limit', limit);
  appendFilterToURL(url, filter);
  appendSortToURL(url, sort);

  return fetchJson(url, 'GET', undefined, signal);
};

export const fetchModelPlan = (params: FetchModelPlanParams): Promise<FetchModelPlanResponse> => {
  const { planId } = params;
  const url = new URL(`${config.API_URL}/model-plans/${planId}`);
  return fetchJson(url, 'GET');
};

export const fetchModelPlanUpdate = (params: UpdateModelPlanItemRequestParams): Promise<UpdateModelPlanResponse> => {
  const { planId, updatedFields } = params;
  const url = new URL(`${config.API_URL}/model-plans/${planId}`);
  return fetchJson(url, 'PUT', updatedFields);
};

export const createModelPlan = (params: CreateModelPlanItemRequestParams): Promise<CreateModelPlanResponse> => {
  const url = new URL(`${config.API_URL}/model-plans`);
  return fetchJson(url, 'POST', params);
};

export const fetchModelPlanValidation = (params: FetchModelPlanValidationParams): Promise<FetchModelPlanValidationResponse> => {
  const { make, model, years } = params;
  const url = new URL(`${config.API_URL}/model-plans/validate`);
  const body = { make, model, years };
  return fetchJson(url, 'POST', body);
};
