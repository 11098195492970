import jwtDecode from 'jwt-decode';

import { AuthServiceProps, AuthTokens, DecodedUser, TokenRequestBody } from './types';
import { toUrlEncoded } from './utils';

export enum LocalStorageKey {
  USER = 'user',
  AUTH = 'auth',
  PRE_AUTH_URI = 'preAuthUri',
}

export enum AuthGrantType {
  AUTHORIZATION_CODE = 'authorization_code',
  REFRESH_TOKEN = 'refresh_token',
}

export class AuthService {
  private static instance: AuthService | undefined;
  private readonly props: AuthServiceProps;
  private errorHandler: (error: Error | unknown) => void;

  private constructor(props: AuthServiceProps) {
    this.props = props;
    this.errorHandler = () => undefined;
  }

  static createInstance(props: AuthServiceProps): AuthService {
    if (AuthService.instance) throw new Error('AuthService already created');
    AuthService.instance = new AuthService(props);
    return AuthService.instance;
  }

  static getInstance(): AuthService {
    if (!AuthService.instance) throw new Error('AuthService not created yet');
    return AuthService.instance;
  }

  /** Preparation and code parsing */
  async onApplicationStart(): Promise<void> {
    const code = this.getCodeFromLocation(window.location);
    if (!code) return;

    try {
      await this.fetchToken(code);
      this.restoreUri();
    } catch (error: unknown) {
      this.removeItem(LocalStorageKey.USER);
      this.removeItem(LocalStorageKey.AUTH);
      this.removeCodeFromLocation();
      this.errorHandler(error);
    }
  }

  setErrorHandler(func: (error: Error | unknown) => void) {
    this.errorHandler = func;
  }

  isAuthenticated(): boolean {
    const tokens = this.getItem(LocalStorageKey.AUTH, '{}') as AuthTokens;
    return !!Object.keys(tokens).length;
  }

  /** Clean user data and redirect user to OKTA OAuth form */
  reAuthorize(): void {
    this.removeItem(LocalStorageKey.USER);
    this.removeItem(LocalStorageKey.AUTH);
    this.authorize();
  }

  /** This will do a full page reload and to the OAuth2 provider's login page and then redirect back to redirectUri */
  authorize(): boolean {
    const { clientId, provider, authorizeEndpoint, redirectUri, scopes, audience, extraParams } = this.props;

    this.setItem(LocalStorageKey.PRE_AUTH_URI, location.href);

    const query: Record<string, string> = {
      clientId,
      scope: scopes.join(' '),
      responseType: 'code',
      ...(audience && { audience }),
      ...extraParams,
    };
    if (redirectUri) {
      query.redirectUri = redirectUri;
    }

    // responds with a 302 redirect
    const url = `${authorizeEndpoint || `${provider}`}?${toUrlEncoded(query)}`;
    window.location.replace(url);

    return true;
  }

  /** This happens after a full page reload. Read the code from localstorage */
  async fetchToken(code: string, isRefresh = false): Promise<Partial<AuthTokens>> {
    const { clientId, clientSecret, contentType, provider, tokenEndpoint, redirectUri } = this.props;
    const grantType = AuthGrantType.AUTHORIZATION_CODE;

    let payload: TokenRequestBody = {
      clientId,
      ...(clientSecret ? { clientSecret } : {}),
      redirectUri,
      grantType,
    };
    if (isRefresh) {
      payload = {
        ...payload,
        grantType: AuthGrantType.REFRESH_TOKEN,
        refresh_token: code,
      };
    } else {
      payload = {
        ...payload,
        code,
      };
    }

    try {
      const response = await fetch(`${tokenEndpoint || `${provider}/token`}`, {
        headers: {
          'Content-Type': contentType || 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: toUrlEncoded(payload as unknown as Record<string, string>),
      });
      const json = (await response.json()) as AuthTokens;

      if (response.status === 200) {
        if (isRefresh && !json.refresh_token) {
          json.refresh_token = payload.refresh_token || '';
        }
        if (json.id_token) {
          this.setUser(json.id_token);
        }
        this.setAuthTokens(json);
        return Promise.resolve(json);
      }
      return Promise.reject(json);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  logout(defaultRoute?: string, shouldEndSession = false) {
    this.removeItem(LocalStorageKey.USER);
    this.removeItem(LocalStorageKey.AUTH);

    if (shouldEndSession) {
      const { clientId, provider, logoutEndpoint, redirectUri } = this.props;
      const query = {
        client_id: clientId,
        post_logout_redirect_uri: redirectUri,
      };
      const url = `${logoutEndpoint || `${provider}/logout`}?${toUrlEncoded(query as unknown as Record<string, string>)}`;
      window.location.replace(url);
      return true;
    }

    if (defaultRoute) {
      window.location.replace(defaultRoute);
    } else {
      window.location.reload();
    }
    return true;
  }

  setUser(id_token: string): DecodedUser | unknown {
    if (!id_token) return {};
    const decoded = jwtDecode(id_token);
    this.setItem(LocalStorageKey.USER, decoded);
    return decoded;
  }

  setAuthTokens(auth: AuthTokens): void {
    window.localStorage.setItem(LocalStorageKey.AUTH, JSON.stringify(auth));
  }

  setItem(key: string, obj: unknown) {
    window.localStorage.setItem(key, JSON.stringify(obj));
  }

  getItem(key: string, defValue = '{}') {
    return JSON.parse(window.localStorage.getItem(key) || defValue) as unknown;
  }

  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }

  restoreUri(): void {
    const uri = this.getItem(LocalStorageKey.PRE_AUTH_URI) as string;
    this.removeItem(LocalStorageKey.PRE_AUTH_URI);

    if (uri !== null) window.location.replace(uri);
    this.removeCodeFromLocation();
  }

  getCodeFromLocation(location: Location): string | null {
    const split = location.toString().split('?');
    if (split.length < 2) {
      return null;
    }

    const pairs = split[1].split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      if (key === 'code') {
        return decodeURIComponent(value || '');
      }
    }

    return null;
  }

  removeCodeFromLocation(): void {
    const [base, search] = window.location.href.split('?');
    if (!search) {
      return;
    }

    const newSearch = search
      .split('&')
      .map((param) => param.split('='))
      .filter(([key]) => key !== 'code')
      .map((keyAndVal) => keyAndVal.join('='))
      .join('&');

    window.history.replaceState(window.history.state, 'null', base + (newSearch.length ? `?${newSearch}` : ''));
  }
}
