import { FormikProps } from 'formik';

import { usePartCostRetailFlag } from './usePartCostRetailFlag';
import { PricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { TabOption } from '../../../types/field';
import { CurrencyTab } from '../components/LineItemModal/shared/types';

// For pricing plan Wholesale
export const useCurrencyPartCostRetailFlag = (formik: FormikProps<Partial<PricingPlanItem>>, currencyTab: TabOption<CurrencyTab>): void => {
  const currency = currencyTab.label === CurrencyTab.CAD ? 'cad' : 'usd';
  const partCost = formik.values[currency]?.partCost;
  usePartCostRetailFlag(formik, currency, partCost);
};
