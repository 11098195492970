import { ClientNEXTInspectSubTab, ClientSubTab, ClientWholesaleSubTab } from './types';
import { FieldsGroup } from '../../../components/FormGenerator/components/FormFieldsGroup';
import { GenFormField } from '../../../components/FormGenerator/FormGenerator';
import { CategoryCode, ClientGroupType, InspectionType } from '../../../types/clients';
import { Field, FieldTypes, OptionsType } from '../../../types/field';

/**
 * ___________ Client fields _______________
 */

const client: Field = {
  name: 'client',
  label: 'Client',
  type: FieldTypes.TEXT,
  disabled: true,
};

const manufactureCode: Field = {
  name: 'manufacturerCode',
  label: 'Manufacture code',
  type: FieldTypes.TEXT,
};

export const groupNameText: Field = {
  name: 'groupName',
  label: 'Group name',
  type: FieldTypes.TEXT,
  disabled: false,
  required: true,
};

export const groupNamePreview: Field = {
  name: 'groupName',
  label: 'Group name',
  type: FieldTypes.TEXT,
  disabled: true,
};

const groupCodePreview: Field = {
  name: 'groupCode',
  label: 'Group code',
  type: FieldTypes.TEXT,
  disabled: true,
  validateOnChange: true,
  classContainer: 'h-fit',
};

export const groupCode: Field = {
  name: 'groupCode',
  label: 'Group code',
  type: FieldTypes.TEXT,
  validateOnChange: true,
  required: true,
};

export const groupType: Field = {
  name: 'groupType',
  label: 'Group type',
  disabled: true,
  type: FieldTypes.SELECT,
  options: [
    {
      label: 'NEXT Inspect',
      value: ClientGroupType.NextInspect,
    },
    {
      label: 'Wholesale',
      value: ClientGroupType.Wholesale,
    },
  ],
};

export const pricingType: Field = {
  name: 'pricingType',
  label: 'Pricing type',
  type: FieldTypes.SELECT,
  isClearable: true,
  required: true,
  options: [
    {
      label: 'Manheim Standard',
      value: 'Manheim Standard',
    },
    {
      label: 'Client sourced',
      value: 'Infobahn',
    },
    {
      label: 'No Pricing',
      value: 'No Pricing',
    },
  ],
};

export const sourceGroupCode: Field = {
  name: 'sourceGroupCode',
  label: 'Copy pricing plan from',
  isClearable: true,
  disabled: false,
  type: FieldTypes.SELECT,
  required: true,
};

export const sourcePlanId: Field = {
  name: 'sourcePlanId',
  label: 'Copy pricing plan from',
  isClearable: true,
  disabled: false,
  type: FieldTypes.SELECT,
};

export const wholesaleSourceGroupCode: Field = {
  name: 'sourceGroupCode',
  label: 'Pricing plan',
  isClearable: true,
  disabled: false,
  type: FieldTypes.SELECT,
  classContainer: 'mt-4',
  required: true,
};

export const shouldCopyPricingPlan: Field = {
  name: 'shouldCopyPricingPlan',
  label: '',
  type: FieldTypes.TABS,
  classContainer: 'h-[41px]',
  options: [
    {
      label: 'Copy',
      value: true,
    },
    {
      label: 'Link',
      value: false,
    },
  ],
};

/** same field as "sourceGroupCode" but with different label */
export const copyDamageGuidelineFrom: Field = {
  name: 'sourceGroupCode',
  label: 'Copy damage guidelines from',
  isClearable: true,
  disabled: false,
  type: FieldTypes.SELECT,
  required: true,
};

export const sourceGroupCodePreview: Field = {
  name: 'sourceGroupCode',
  label: 'Source group code',
  type: FieldTypes.TEXT,
  disabled: true,
};

export const planDescription: Field = {
  name: 'planDescription',
  label: 'Plan description',
  disabled: false,
  type: FieldTypes.TEXT,
  required: true,
};

// groupNamePreview and planDescription fields in left column of create client modal
export const groupNamePreviewAndPlanDescriptionGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: [groupNamePreview, planDescription],
  classes: {
    fieldsContainer: 'flex flex-col gap-4',
  },
};

export const sellerAccount: Field = {
  name: 'sellerAccount',
  label: 'Seller account',
  disabled: false,
  type: FieldTypes.NUMBER,
  placeholder: '',
  required: true,
};

export const categoryCodeOptions: OptionsType[] = [
  {
    label: 'FLT - Ars Fleet',
    value: CategoryCode.FLT,
  },
  {
    label: 'MIS - Miscellaneous',
    value: CategoryCode.MIS,
  },
  {
    label: 'RNT - Ars Rental',
    value: CategoryCode.RNT,
  },
  {
    label: 'REP - Rental Repurchase',
    value: CategoryCode.REP,
  },
  {
    label: 'CML - Ford Credit Commercial Lending Units',
    value: CategoryCode.CML,
  },
  {
    label: 'CMR - Commercial Lease Repossessions',
    value: CategoryCode.CMR,
  },
  {
    label: 'COP - Customer Option Plan',
    value: CategoryCode.COP,
  },
  {
    label: 'RCR - Red Carpet Lease (Repossessed)',
    value: CategoryCode.RCR,
  },
  {
    label: 'RCT - Red Carpet Lease (Terminated)',
    value: CategoryCode.RCT,
  },
  {
    label: 'RTR - Retail Repo',
    value: CategoryCode.RTR,
  },
  {
    label: 'UNK - Unknown Category',
    value: CategoryCode.UNK,
  },
  {
    label: 'CSV - Company Service',
    value: CategoryCode.CSV,
  },
  {
    label: 'AT - Agilent Technologies',
    value: CategoryCode.AT,
  },
  {
    label: 'CF - Consolidated Freight',
    value: CategoryCode.CF,
  },
  {
    label: 'GAV - Guaranteed Auction Value',
    value: CategoryCode.GAV,
  },
  {
    label: 'HP - Hewlet Pakard',
    value: CategoryCode.HP,
  },
  {
    label: 'CCD - Central Collections Department',
    value: CategoryCode.CCD,
  },
  {
    label: 'WHR - Wholesale Lease Repo',
    value: CategoryCode.WHR,
  },
  {
    label: 'CBB - Company Buyback',
    value: CategoryCode.CBB,
  },
  {
    label: 'DRR - Dealer Rental Repossession',
    value: CategoryCode.DRR,
  },
  {
    label: 'FUB - First Union Bank',
    value: CategoryCode.FUB,
  },
  {
    label: 'RSK - Risk (Enterprise)',
    value: CategoryCode.RSK,
  },
];

const categoryCodes: Field = {
  name: 'categoryCodes',
  label: 'Category codes',
  type: FieldTypes.SELECT,
  classContainer: 'col-span-2',
  isMulti: true,
  options: categoryCodeOptions,
};

const inspectionType: Field = {
  name: 'inspectionType',
  label: 'Inspection type',
  type: FieldTypes.SELECT,
  options: [
    {
      label: 'N/A',
      value: InspectionType.EMPTY,
    },
    {
      label: 'A1',
      value: InspectionType.A1,
    },
    {
      label: 'L1',
      value: InspectionType.L1,
    },
  ],
};

export const createClientFields: Record<ClientSubTab, GenFormField[]> = {
  [ClientNEXTInspectSubTab.ACTIVE_MANHEIM]: [groupType, { ...pricingType, required: true }],
  [ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED]: [groupType, { ...pricingType, required: true }],
  [ClientNEXTInspectSubTab.DEMO]: [groupType, pricingType],
  [ClientWholesaleSubTab.ACCOUNTS]: [groupType, groupNameText],
  // TODO mock data like for ClientWholesaleSubTab.ACCOUNTS
  [ClientWholesaleSubTab.MODEL_PLANS]: [groupType, groupNameText],
};

export const nextInspectExpandedManheimFields: Field[] = [groupCode, sourceGroupCodePreview, client];
export const nextInspectExpandedManheimGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: nextInspectExpandedManheimFields,
  classes: {
    fieldsContainer: 'grid grid-cols-4 gap-4 mb-2',
  },
};

export const nextInspectExpandedClientSourcedFields: Field[] = [groupCode, client];
export const nextInspectExpandedClientSourcedGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: nextInspectExpandedClientSourcedFields,
  classes: {
    fieldsContainer: 'grid grid-cols-4 gap-4 mb-5',
  },
};

export const wholesaleExpandedFields: Field[] = [groupCodePreview, groupNamePreview, manufactureCode, categoryCodes, inspectionType];
export const wholesaleExpandedGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: wholesaleExpandedFields,
  classes: {
    fieldsContainer: 'grid grid-cols-6 gap-4 mb-2',
  },
};

/**
 * ___________ Rates fields _______________
 */

const paintRate: Field = {
  name: 'paintRate',
  label: 'Paint rate',
  type: FieldTypes.NUMBER,
  disabled: true,
  icon: 'dollar',
};

const bodyRate: Field = {
  name: 'bodyRate',
  label: 'Body/Metal rate',
  type: FieldTypes.NUMBER,
  disabled: true,
  icon: 'dollar',
};

const mechanicalRate: Field = {
  name: 'mechanicalRate',
  label: 'Mechanical rate',
  type: FieldTypes.NUMBER,
  disabled: true,
  icon: 'dollar',
};

const partRate: Field = {
  name: 'partRate',
  label: 'Part rate',
  type: FieldTypes.NUMBER,
  disabled: true,
  icon: 'dollar',
};

export const ratesFields: Field[] = [paintRate, bodyRate, mechanicalRate, partRate];

export const ratesGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: ratesFields,
  classes: {
    fieldsContainer: 'grid grid-cols-6 gap-4 mb-2',
  },
};

/**
 * ___________ Mitchell Flags _______________
 */

const isMitchellPaint: Field = {
  name: 'isMitchellPaint',
  label: 'Paint hours',
  type: FieldTypes.SWITCH,
  defaultChecked: false,
};

const isMitchellPart: Field = {
  name: 'isMitchellPart',
  label: 'Part hours',
  type: FieldTypes.SWITCH,
  defaultChecked: false,
};

const isMitchellRetail: Field = {
  name: 'isMitchellRetail',
  label: 'Part cost retail',
  type: FieldTypes.SWITCH,
  defaultChecked: false,
};

export const flatFeeFlag: Field = {
  name: 'flatFeeFlag',
  label: 'Flat fee',
  type: FieldTypes.SWITCH,
};

export const mitchellFields: Field[] = [isMitchellPaint, isMitchellPart, isMitchellRetail];
export const mitchellGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: mitchellFields,
  label: 'Mitchell',
  classes: {
    mainContainer: 'mt-3 flex flex-col mr-10',
    label: 'mb-2 font-bold',
  },
};
export const mitchellFieldsWithFlatFee: Field[] = [...mitchellFields, flatFeeFlag];
export const mitchellWithFlatFeeGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: mitchellFieldsWithFlatFee,
  label: 'Mitchell',
  classes: {
    mainContainer: 'mt-3 flex flex-col mr-10',
    label: 'mb-2 font-bold',
  },
};

/**
 * ___________ Model flags _______________
 */
const isModelPricing: Field = {
  name: 'isModelPricing',
  label: 'Model pricing',
  type: FieldTypes.SWITCH,
  defaultChecked: false,
};

export const modelFields: Field[] = [isModelPricing];

export const modelPricingFieldsGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: modelFields,
  label: 'Model pricing',
  classes: {
    mainContainer: 'mt-3 flex flex-col mr-10',
    label: 'mb-2 font-bold',
  },
};

export const enabledForBackfill: Field = {
  name: 'enabledForBackfill',
  label: 'Active',
  type: FieldTypes.SWITCH,
  defaultChecked: false,
  classContainer: 'flex justify-start',
  colorTheme: 'green',
};

/**
 * ___________ Default damage flags _______________
 */

const isDefaultDamageRuleHideItem: Field = {
  name: 'shouldHideItem',
  label: 'Hide item',
  type: FieldTypes.SWITCH,
  defaultChecked: false,
};

const isDefaultDamageRuleRepairItem: Field = {
  name: 'shouldRepairItem',
  label: 'Repair item',
  type: FieldTypes.SWITCH,
  defaultChecked: true,
};

const isDefaultDamageRuleHideImage: Field = {
  name: 'shouldHideImage',
  label: 'Hide image',
  type: FieldTypes.SWITCH,
  defaultChecked: true,
};
export const defaultDamageRuleFields: Field[] = [isDefaultDamageRuleHideItem, isDefaultDamageRuleRepairItem, isDefaultDamageRuleHideImage];
export const defaultDamageRuleGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  fields: defaultDamageRuleFields,
  label: 'Default damage rule',
  classes: {
    mainContainer: 'mt-3 flex flex-col mr-10',
    label: 'mb-2 font-bold',
  },
};
/**
 * ___________ Pricing calculation flags _______________
 */

const damageQty: Field = {
  name: 'damageQty',
  label: 'Damage qty',
  type: FieldTypes.SWITCH,
  defaultChecked: false,
};

const damageSize: Field = {
  name: 'damageSize',
  label: 'Damage size',
  type: FieldTypes.SWITCH,
  defaultChecked: false,
};

export const pricingCalculationFields: Field[] = [damageQty, damageSize];
export const pricingCalculationGroup: FieldsGroup = {
  type: FieldTypes.GROUP,
  label: 'Pricing calculation',
  fields: pricingCalculationFields,
  classes: {
    mainContainer: 'mt-3 flex flex-col mr-10',
    label: 'mb-2 font-bold',
  },
};

export const nextInspectFlags = [...mitchellFields, ...pricingCalculationFields];
export const wholesaleFlags = [...mitchellFields, ...modelFields, ...defaultDamageRuleFields];
