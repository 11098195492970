import { Table } from '@tanstack/react-table';

import { config } from '../../config';

interface PaginationProps<T> {
  table: Table<T>;
}

export const Pagination = <T,>({ table }: PaginationProps<T>): JSX.Element => {
  return (
    <div className="flex items-center gap-2" data-testid="pagination">
      <button
        className="prism-btn"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
        data-testid="start-btn"
        title="First page"
      >
        {'<<'}
      </button>
      <button
        className="prism-btn"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
        data-testid="backward-btn"
        title="Previous page"
      >
        {'<'}
      </button>
      <button
        className="prism-btn"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
        data-testid="forward-btn"
        title="Next page"
      >
        {'>'}
      </button>
      <button
        className="prism-btn"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
        data-testid="end-btn"
        title="Last page"
      >
        {'>>'}
      </button>
      <span className="flex items-center gap-1" data-testid="page-index">
        <div>Page</div>
        <strong>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </strong>
      </span>
      <select
        value={table.getState().pagination.pageSize}
        onChange={(e) => table.setPageSize(Number(e.target.value))}
        className="rounded"
        data-testid="show-more-opt"
      >
        {config.PAGE_SIZE_ARRAY.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};
