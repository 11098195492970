import { useQuery } from '@tanstack/react-query';
import { ColumnFilter, ColumnSort } from '@tanstack/react-table';

import type { FetchWholesaleClientsResponse } from '../../../common/queries/clients/clients.types';

import { fetchWholesaleClients } from '../../../common/queries/clients/clients.api';
import { QueryKey } from '../../../constants/queries';
import { ClientSubTab, ClientWholesaleSubTab } from '../shared/types';

export interface UseWholesaleClientsParams {
  subTab: ClientSubTab;
  offset: number;
  limit: number;
  filters: ColumnFilter[];
  sort: ColumnSort[];
}

export interface UseWholesaleClients {
  data: FetchWholesaleClientsResponse | undefined;
  isFetching: boolean;
  error: Error | null;
}

export const useWholesaleClients = ({ subTab, offset, limit, sort, filters }: UseWholesaleClientsParams): UseWholesaleClients => {
  return useQuery({
    queryKey: [QueryKey.FETCH_CLIENTS, offset, limit, subTab, sort, filters],
    queryFn: ({ signal }) => fetchWholesaleClients({ offset, limit, sort, filter: filters, signal }),
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: [ClientWholesaleSubTab.ACCOUNTS].includes(subTab as ClientWholesaleSubTab),
  });
};
