import dayjs from 'dayjs';
import { FC, ReactElement } from 'react';
import ReactJson from 'react-json-view';

import { FetchHistoryResponse } from '../../../../common/queries/pricingPlans/pricingPlans.types';
import { getEvent } from '../../utils/getEvent';

type ChangesListProps = {
  items?: FetchHistoryResponse['items'];
};

export const ChangesList: FC<ChangesListProps> = ({ items }): ReactElement => (
  <div className="border-l border-l-gray-200">
    {items?.map((item, index) => {
      const { author, date, event, data } = item;
      const formattedDate = dayjs(date).format('MM/DD/YYYY');

      return (
        <div key={`${item.id}${index}`} className="mt-6 px-5">
          <hr className="bg-primary-dark mb-3" />
          <div className="flex items-center relative ">
            <span className="h-3 w-3 bg-gray-200 rounded-full mr-2 absolute left-0 translate-x-[-26px]"></span>
            <div className="flex justify-between flex-wrap">
              <div className="mr-3">{getEvent(event)}</div>
              <div>{formattedDate}</div>
            </div>
          </div>
          <div>by {author}</div>
          <div>
            <ReactJson
              src={data}
              iconStyle="triangle"
              enableClipboard={false}
              name="changes"
              collapsed={true}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </div>
        </div>
      );
    })}
  </div>
);
