import { FormikProps, FormikValues } from 'formik';

import { UseWizardStep2ConfigParams } from './useWizardStep2Config';
import { GenFormFieldsConfig } from '../../../../../../../../components/FormGenerator/FormGenerator';
import { getFlatFeeField } from '../../../../fields/flatFeeFlag';
import { getGroupCodeField } from '../../../../fields/groupCode';
import { getSourceGroupCodeField } from '../../../../fields/sourceGroupCode';

export type UseWizardStep2FieldsParams = Pick<UseWizardStep2ConfigParams, 'formik' | 'clientTab' | 'clientSubTab'>;
export type UseWizardStep2Fields = GenFormFieldsConfig;

/** Returns map of dynamic props to field (Also applies hooks)  */
export const useWizardStep2Fields = ({ formik, clientTab, clientSubTab }: UseWizardStep2FieldsParams): UseWizardStep2Fields => {
  return {
    flatFeeFlag: getFlatFeeField(formik),
    groupCode: getGroupCodeField(formik),
    sourceGroupCode: getSourceGroupCodeField(formik, clientTab, clientSubTab),
    ...getMitchelFlagsConfig(formik),
  };
};

export const getMitchelFlagsConfig = (formik: FormikProps<FormikValues>) => {
  const fields = ['isMitchellPaint', 'isMitchellPart', 'isMitchellRetail'];

  return fields.reduce((acc, field) => {
    acc[field] = {
      onChange: (value: boolean) => {
        const flatFeeFlag = !fields.some((fieldName) => (fieldName === field ? value : formik.values[fieldName]));
        formik.setValues({
          ...formik.values,
          flatFeeFlag,
          [field]: value,
        });
      },
    };
    return acc;
  }, {} as GenFormFieldsConfig);
};
