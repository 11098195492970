import { AuthTokens } from './types';

export const toSnakeCase = (str: string): string => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

export const toUrlEncoded = (obj: Record<string, string>): string => {
  return Object.keys(obj)
    .map((k) => encodeURIComponent(toSnakeCase(k)) + '=' + encodeURIComponent(obj[k]))
    .join('&');
};

export function setAuthHeaders(headers?: Record<string, string>) {
  const authData = localStorage.getItem('auth');

  if (authData) {
    const auth = JSON.parse(authData) as AuthTokens;
    return {
      ...headers,
      Authorization: `${auth.token_type} ${auth.access_token}`,
    };
  }

  return headers;
}
