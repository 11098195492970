import { Client, PricingType } from '../../../../../../../types/clients';
import { validationSchemaAddClientInfobahn, validationSchemaAddNextInspectClient } from '../../../../../shared/schema';

/** Returns lazy builder for yup schema validation for "Create/Upgrade client" modal */
export const getValidationSchemaBuilder = () => {
  return (values: Partial<Client>) => {
    const isNoPricingOrInfobahn = [PricingType.Infobahn, PricingType.NoPricing].includes(values.pricingType as PricingType);
    return isNoPricingOrInfobahn ? validationSchemaAddClientInfobahn : validationSchemaAddNextInspectClient;
  };
};
