import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { fetchModelPlanUpdate } from '../../../../../common/queries/modelPlans/modelPlans.api';
import { UpdateModelPlanItemRequestParams } from '../../../../../common/queries/modelPlans/modelPlans.types';
import { QueryKey } from '../../../../../constants/queries';

interface UseUpdateModelPlan {
  refetchOnSuccess?: (QueryKey | string | null)[];
  hideSuccessMessage?: boolean;
}

interface UseUpdateModelPlanReturn {
  mutate: (values: UpdateModelPlanItemRequestParams) => void;
  isLoading: boolean;
}

export const useUpdateModelPlan = ({ refetchOnSuccess, hideSuccessMessage }: UseUpdateModelPlan): UseUpdateModelPlanReturn => {
  const queryClient = useQueryClient();

  const showError = (error?: Error) => toast(error?.message, { type: 'error' });
  const showSuccess = () => toast('The data has been updated', { type: 'success' });

  const { mutate, isLoading } = useMutation({
    mutationFn: (params: UpdateModelPlanItemRequestParams) => fetchModelPlanUpdate(params),
    onError: showError,
    onSuccess: async () => {
      !hideSuccessMessage && showSuccess();
      if (!refetchOnSuccess || !refetchOnSuccess.length) return;
      for (const key of refetchOnSuccess) {
        await queryClient.invalidateQueries({ queryKey: [key] });
      }
    },
  });

  return { mutate, isLoading };
};
