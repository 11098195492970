import { FC, useState } from 'react';

import { DateField } from '../../../../components/DateField/DateField';
import { Label } from '../../../../components/FormField/Label/Label';
import { Modal } from '../../../../components/Modal/Modal';
import { Spinner } from '../../../../components/Spinner/Spinner';
import { useHandleHistoryModalState } from '../../hooks/useHandleHistoryModalState';
import { useHistory } from '../../hooks/useHistory';
import { ChangesList } from '../ChangesList/ChangesList';

type HistoryModalProps = Readonly<{
  planId: string;
  isOpen: boolean;
  onClose: () => void;
}>;

export const HistoryModal: FC<HistoryModalProps> = ({ planId, isOpen, onClose }: HistoryModalProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const { from, to, search, onSelectedDateFrom, onSelectedDateTo, onChangeSearch, onResetFormData } = useHandleHistoryModalState(() => {
    setCurrentPage(1);
    setOffset(0);
  });

  const { data, isFetching } = useHistory({ planId, isOpen, from, to, search, limit, offset });
  const countOfItems = data?.count || 0;
  const totalPages = Math.ceil(countOfItems / limit);

  const onResetData = () => {
    setOffset(0);
    setCurrentPage(1);
    onResetFormData();
    onClose();
  };

  const onPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    setOffset((prevOffset) => prevOffset - limit);
  };

  const onNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setOffset((prevOffset) => prevOffset + limit);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage >= totalPages;

  return (
    <Modal isOpen={isOpen} sizeClass="max-w-sm" onClose={onResetData}>
      <div data-testid="history-modal">
        <div tabIndex={0} data-testid="title" className="text-blue-800 text-center prism-heading-3 mb-4">
          History of changes
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex justify-between flex-wrap gap-3">
            <DateField
              label="From"
              name="from"
              selectedDate={from}
              setSelectedDate={onSelectedDateFrom}
              selectsStart
              startDate={from}
              endDate={to}
              className="start-date w-40 sm:w-[250px]"
            />
            <DateField
              label="To"
              name="to"
              selectedDate={to}
              setSelectedDate={onSelectedDateTo}
              selectsEnd
              startDate={from}
              endDate={to}
              className="end-date w-40 sm:w-[250px]"
            />
          </div>

          <div className="mb-4">
            <Label name="search" label="Search" />
            <input
              data-testid="history-search"
              className="w-full rounded"
              name="search"
              type="text"
              value={search}
              onChange={(e) => onChangeSearch(e)}
            />
          </div>

          {isFetching ? <Spinner /> : <ChangesList items={data?.items} />}
          <div className="flex justify-around items-center mt-4">
            <button className="prism-btn w-32 sm:w-40" data-testid="previous-btn" onClick={onPreviousPage} disabled={isFirstPage}>
              Previous
            </button>
            {!isFetching && Boolean(totalPages) && (
              <span>
                {currentPage} of {totalPages}
              </span>
            )}
            <button className="prism-btn w-32 sm:w-40" data-testid="next-btn" onClick={onNextPage} disabled={isLastPage}>
              Next
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
