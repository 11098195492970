import React, { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from './useAuth';
import { BlockScreenLoading } from '../../../components/BlockScreenLoading/BlockScreenLoading';
import config from '../../../config/api';

export const AuthComponent: FC = (): JSX.Element | null => {
  const [loginError, setLoginError] = useState<string>();
  const [authLoading, setAuthLoading] = useState(true);
  const { authService } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    authService.setErrorHandler((e) => {
      setLoginError((e as Error).message || '');
      navigate(config.routes.CLIENT_LIST);
    });
    if (!authService.isAuthenticated() && config.routes.LOGIN_CALLBACK !== location.pathname) {
      void authService.authorize();
      return;
    }
    setAuthLoading(false);
  }, []);

  if (loginError) {
    return <h1>{loginError}</h1>;
  }

  if (authLoading) {
    return <BlockScreenLoading />;
  }

  return <Outlet />;
};
