import type {
  ARTGetActionDescriptionResponse,
  ARTGetActionDescriptionsBody,
  ARTGetAllActionsResponse,
  ARTGetAllSeveritiesBody,
  ARTGetAllSeveritiesResponse,
  ARTGetChargeabilitiesBody,
  ARTGetChargeabilitiesResponse,
  ARTGetDamagesBody,
  ARTGetDamagesParams,
  ARTGetDamagesResponse,
  ARTGetDescriptionBody,
  ARTGetDescriptionParams,
  ARTGetDescriptionResponse,
  ARTGetDescriptionsParams,
  ARTGetItemDescriptionBody,
  ARTGetItemDescriptionParams,
  ARTGetItemDescriptionResponse,
  ARTGetSeveritiesBody,
  ARTGetSeveritiesParams,
  ARTGetSeveritiesResponse,
} from './artProxy.types';

import { ARTGetAllActionsBody } from './artProxy.types';
import { config } from '../../../config';
import { ArtProxyAction } from '../../../constants/queries';
import { fetchJson } from '../../utilities/api/fetchJson';

const artProxyUrl = new URL(`${config.API_URL}/art-proxy`);

export const getItemDescription = (body: ARTGetItemDescriptionParams): Promise<ARTGetItemDescriptionResponse> => {
  const { signal, ...rest } = body;
  const requestBody: ARTGetItemDescriptionBody = {
    action: ArtProxyAction.ITEM_SEARCH,
    limit: 100,
    offset: 0,
    ...rest,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody, signal);
};

export const getDescription = (body: ARTGetDescriptionParams): Promise<ARTGetDescriptionResponse> => {
  const requestBody: ARTGetDescriptionBody = {
    action: ArtProxyAction.ITEM_BY_ID,
    ...body,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};

export const getDamageOptions = (body: ARTGetDamagesParams): Promise<ARTGetDamagesResponse> => {
  const requestBody: ARTGetDamagesBody = {
    action: ArtProxyAction.ITEM_DAMAGES,
    ...body,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};

export const getSeverityOptions = (body: ARTGetSeveritiesParams): Promise<ARTGetSeveritiesResponse> => {
  const requestBody: ARTGetSeveritiesBody = {
    action: ArtProxyAction.ITEM_DAMAGE_SEVERITIES,
    ...body,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};

export const getActionDescriptions = (body: ARTGetDescriptionsParams): Promise<ARTGetActionDescriptionResponse> => {
  const requestBody: ARTGetActionDescriptionsBody = {
    action: ArtProxyAction.ITEM_DAMAGE_SEVERITY_ACTIONS,
    ...body,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};

export const getChargeabilities = (body: ARTGetDescriptionsParams): Promise<ARTGetChargeabilitiesResponse> => {
  const requestBody: ARTGetChargeabilitiesBody = {
    action: ArtProxyAction.CHARGEABILITIES,
    ...body,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};

export const getAllActions = (): Promise<ARTGetAllActionsResponse> => {
  const requestBody: ARTGetAllActionsBody = {
    action: ArtProxyAction.ACTIONS,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};

export const getAllSeverities = (): Promise<ARTGetAllSeveritiesResponse> => {
  const requestBody: ARTGetAllSeveritiesBody = {
    action: ArtProxyAction.SEVERITIES,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};
