import { ClipboardCheckedIcon, EditIcon, MinusCircleOIcon } from '@prism2/icons-react/prism';
import { CellContext, ColumnDefResolved, HeaderContext } from '@tanstack/react-table';

import { startCase } from '../../../common/utilities/startCase';
import { PricingPlan } from '../../../types/pricingPlans';

export const renderPricingPlanCell = (info: CellContext<PricingPlan, unknown>) => {
  return info.getValue();
};

interface DataCell {
  accessorKey: string;
  header: string;
  enableColumnFilter: boolean;
  cell: typeof renderPricingPlanCell;
  footer: (props: HeaderContext<PricingPlan, unknown>) => string;
  minSize: number;
  maxSize: number;
}

const generateDataColumn = ({
  accessorKey,
  header,
  enableColumnFilter = true,
  cell = renderPricingPlanCell,
  footer = (props) => props.column.id,
  minSize,
  maxSize,
}: Partial<DataCell>) => {
  return {
    accessorKey,
    header: header || startCase(accessorKey),
    enableColumnFilter,
    cell,
    footer,
    minSize,
    maxSize,
  };
};

const columnsData: Record<string, Partial<DataCell>> = {
  planId: {
    accessorKey: 'planId',
    enableColumnFilter: false,
    minSize: 40,
    maxSize: 50,
  },
  itemCode: {
    accessorKey: 'itemCode',
    minSize: 40,
    maxSize: 50,
  },
  subItemCode: {
    accessorKey: 'subItemCode',
    minSize: 40,
    maxSize: 50,
  },
  itemDescription: {
    accessorKey: 'itemDescription',
    minSize: 50,
    maxSize: 80,
  },
  actionCode: {
    accessorKey: 'actionCode',
    minSize: 40,
    maxSize: 50,
  },
  severityCode: {
    accessorKey: 'severityCode',
    minSize: 40,
    maxSize: 50,
  },
  severityDescription: {
    accessorKey: 'severityDescription',
    minSize: 50,
    maxSize: 80,
  },
  damageCode: {
    accessorKey: 'damageCode',
    minSize: 40,
    maxSize: 50,
  },
  damageDescription: {
    accessorKey: 'damageDescription',
    minSize: 50,
    maxSize: 80,
  },
  sequenceNumber: {
    accessorKey: 'sequenceNumber',
    minSize: 40,
    maxSize: 50,
  },
  partCost: {
    header: 'Part Cost',
    accessorKey: 'usd.partCost',
    minSize: 40,
    maxSize: 50,
  },
  partHours: {
    accessorKey: 'partHours',
    minSize: 40,
    maxSize: 50,
  },
  partDollars: {
    header: 'Part Dollars',
    accessorKey: 'usd.partDollars',
    minSize: 40,
    maxSize: 50,
  },
  repairHours: {
    accessorKey: 'repairHours',
    minSize: 40,
    maxSize: 50,
  },
  repairDollars: {
    header: 'Repair Dollars',
    accessorKey: 'usd.repairDollars',
    minSize: 40,
    maxSize: 50,
  },
  paintHours: {
    accessorKey: 'paintHours',
    minSize: 40,
    maxSize: 50,
  },
  paintDollars: {
    header: 'Paint Dollars',
    accessorKey: 'usd.paintDollars',
    minSize: 40,
    maxSize: 50,
  },
};

const nextInspectColumnsNames = [
  'planId',
  'itemCode',
  'subItemCode',
  'itemDescription',
  'severityCode',
  'severityDescription',
  'damageCode',
  'damageDescription',
  'actionCode',
  'sequenceNumber',
];

const wholesaleColumnsNames = [
  'planId',
  'itemCode',
  'subItemCode',
  'itemDescription',
  'actionCode',
  'severityCode',
  'sequenceNumber',
  'partCost',
  'partHours',
  'partDollars',
  'repairHours',
  'repairDollars',
  'paintHours',
  'paintDollars',
];

export const pricingPlansColsFactory = (
  update: (variables: PricingPlan) => void,
  copy: (variables: PricingPlan) => void,
  remove: (variables: PricingPlan) => void,
  isNextInspectClient: boolean,
): ColumnDefResolved<PricingPlan>[] => {
  const dataColumns = (isNextInspectClient ? nextInspectColumnsNames : wholesaleColumnsNames).map((columnName) =>
    generateDataColumn(columnsData[columnName]),
  );

  return [
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: (info: CellContext<PricingPlan, unknown>) => (
        <div className="flex gap-1 justify-center">
          <button className="prism-btn" data-testid="update-btn" title="Update line item" onClick={() => update(info.row.original)}>
            <EditIcon />
          </button>
          <button className="prism-btn" data-testid="copy-btn" title="Copy line item" onClick={() => copy(info.row.original)}>
            <ClipboardCheckedIcon />
          </button>
          <button className="prism-btn-red" data-testid="remove-btn" title="Remove line item" onClick={() => remove(info.row.original)}>
            <MinusCircleOIcon />
          </button>
        </div>
      ),
      meta: {
        headerClass: 'left-0 sticky',
        cellClass: 'left-0 sticky bg-white',
      } as Record<string, string>,
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
      minSize: 50,
      maxSize: 80,
    },
    ...dataColumns,
  ];
};
