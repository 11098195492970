import { toast } from 'react-toastify';

import phrases from '../../constants/en_US.json';

export const showInfoMessage = (message: string): void => {
  toast(message, { type: 'success' });
};

export const showErrorMessage = (error: Error | string): void => {
  if (error instanceof Error) {
    toast(error.message || phrases.unknownError, { type: 'error' });
    return;
  }

  toast(error, { type: 'error' });
};
