import { useEffect } from 'react';

import { Alert } from '../../../components/Alert/Alert';
import { Field } from '../../../types/field';
import { useChargeabilities } from '../hooks/useChargeabilities';
import { ARTHookParams } from '../shared/types';

export const getChargeabilitiesField = (params: ARTHookParams): Partial<Field> => {
  const { formik } = params;
  const { chargeableFlag } = formik.values;
  const { data, isFetching } = useChargeabilities(params);

  // there are rows in our database where the 'chargeableFlag' field is empty.
  // As a UX improvement, we need to inform the user that this field is auto-loaded from ART
  const isChargeableFlagLoadedFromART = chargeableFlag && formik.initialValues.chargeableFlag !== chargeableFlag;

  useEffect(() => {
    formik.setFieldValue('chargeableFlag', data?.code);
  }, [data]);

  return {
    isFetching,
    defaultValue: chargeableFlag && data ? data.description : undefined,
    ...(isChargeableFlagLoadedFromART && {
      childComponent: (
        <div className="mt-4 absolute">
          <Alert text={'Automatically populated from the ART API'} />
        </div>
      ),
    }),
  };
};
