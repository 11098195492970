import type { FormikValues } from 'formik';

// Solution to remove some fields from object that will be sent to the backend
// This might be change to common function that will clean up formik values (requires additional investigation)
export const extractWholesaleClientFromFormik = (values: FormikValues): FormikValues => {
  const clone = { ...values };

  if (typeof clone.sourceGroupCode === 'string' && clone.sourceGroupCode.trim() === '') {
    clone.sourceGroupCode = undefined;
  }

  return clone;
};
