import { OptionsType } from '../../../../../../types/field';

export const getYearOptions = (currentYearInput?: number): OptionsType[] => {
  const startYear = 2013;

  const currentYear = currentYearInput ? currentYearInput : new Date().getFullYear() + 1;

  return Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
    const year = startYear + i;
    return {
      label: String(year),
      value: String(year),
    };
  });
};
