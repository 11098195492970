import { FC, ReactNode } from 'react';

import { Modal } from '../Modal/Modal';

export interface DialogModalProps {
  isOpen: boolean;
  onClose: () => void;
  submitButtonText?: string;
  onSubmitButton?: (...args: unknown[]) => void;
  cancelButtonText?: string;
  onCancelButton?: () => void;
  children: ReactNode;
}

export const DialogModal: FC<DialogModalProps> = (props: DialogModalProps): JSX.Element => {
  const { isOpen, onClose, submitButtonText = 'Submit', onSubmitButton, cancelButtonText = 'Cancel', onCancelButton, children } = props;

  const onCancelHandler = (): void => {
    if (onCancelButton) onCancelButton();
    onClose();
  };

  const onSubmitHandler = (): void => {
    if (onSubmitButton) onSubmitButton();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div data-testid="dialog-modal" className="flex flex-col">
        <div data-testid="dialog-modal-content" className="flex flex-col">
          {children}
        </div>

        <div data-testid="dialog-modal-buttons" className="flex gap-4 items-center justify-center">
          <button data-testid="dialog-modal-cancel-btn" className="prism-btn" onClick={onCancelHandler}>
            {cancelButtonText}
          </button>
          <button data-testid="dialog-modal-submit-btn" className="prism-btn fill" onClick={onSubmitHandler}>
            {submitButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
