import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { fetchModelPlanValidation } from '../../../../../../common/queries/modelPlans/modelPlans.api';
import { FetchModelPlanValidationResponse, ValidationError, YearAPI } from '../../../../../../common/queries/modelPlans/modelPlans.types';
import { QueryKey } from '../../../../../../constants/queries';
import { UseModelPlanFormik } from '../../Modal/form/useCreateModelPlanFormik';
import { UseModelPlanTableRowFormik } from '../../TableRow/hooks/useModelPlanTableRowFormik';

export enum ValidationStatus {
  validated = 'Validated',
  validating = 'Validating',
  notValidated = 'NotValidated',
}

type UseTrackChangesAndValidateByPricingPlanDesc = {
  isValidationError: boolean;
  validationStatus: ValidationStatus;
  isValidationFetching: boolean;
  validationErrors: ValidationError[] | null;
};

type UseTrackChangesAndValidateByPricingPlanDescParams = Readonly<{
  formik: UseModelPlanTableRowFormik | UseModelPlanFormik;
  planId?: string;
  skipValidation?: boolean;
  queryKey?: QueryKey;
}>;

export const useTrackChangesAndValidateByPricingPlanDesc = ({
  planId,
  formik,
  queryKey,
}: UseTrackChangesAndValidateByPricingPlanDescParams): UseTrackChangesAndValidateByPricingPlanDesc => {
  const [validationStatusState, setValidationStatusState] = useState(ValidationStatus.notValidated);
  const [validationErrors, setValidationErrors] = useState<ValidationError[] | null>(null);

  const { year, make, model, trims, isDefault, years } = formik.values;

  const emptyItems = years?.filter((item) => item.trims?.length === 0 && !item.isDefault);
  const isEnabled = !!model && (!!trims?.length || !!isDefault) && emptyItems?.length === 0;

  if (validationErrors?.length && !trims?.length && !isDefault) setValidationErrors(null);

  const yearsArray =
    years?.map((item) => ({
      year: parseInt(item.year, 10),
      trims: (item.trims || [])?.map((trim) => trim.value),
      isDefault: item.isDefault,
    })) || [];

  yearsArray.push({
    year: parseInt(year!, 10),
    trims: (trims || [])?.map((trim) => trim.value),
    isDefault: isDefault || false,
  });

  const generateKeysFromArray = yearsArray.flatMap((obj) => [obj.year, obj.trims || [], obj.isDefault]);
  const { isFetching, isError } = useQuery({
    queryKey: [queryKey, model, make, isDefault, ...generateKeysFromArray],
    queryFn: () => {
      setValidationErrors(null);
      return fetchModelPlanValidation({ make: make as string, model: model as string, years: yearsArray as Array<YearAPI> });
    },
    onError: (error: Error) => {
      setValidationStatusState(ValidationStatus.notValidated);
      toast(error?.message, { type: 'error' });
    },
    onSuccess: (data: FetchModelPlanValidationResponse) => {
      if (data.isValid) return setValidationStatusState(ValidationStatus.validated);

      // remove error with the same planId of our current model plan
      const validationErrors = data?.details?.filter((item) => item.planId !== planId);

      if (!validationErrors?.length) {
        setValidationStatusState(ValidationStatus.validated);
        return;
      }

      setValidationStatusState(ValidationStatus.notValidated);
      setValidationErrors(validationErrors);
    },
    enabled: isEnabled,
    cacheTime: 0,
  });

  return { isValidationFetching: isFetching, validationStatus: validationStatusState, isValidationError: isError, validationErrors };
};
