export const removeFieldsFromFormik = <T>(values: Partial<T>, removeFields: (keyof T)[]): Partial<T> => {
  const result: Partial<T> = {};

  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      if (!removeFields.includes(key as keyof T)) {
        result[key] = values[key];
      }
    }
  }

  return result;
};
