import { FormikProps, FormikValues } from 'formik';

import { getWizardStep1Fields } from './getWizardStep1Fields';
import { useWizardStep1Fields } from './useWizardStep1Fields';
import { GenFormConfig, GenFormFieldsConfig } from '../../../../../../../../components/FormGenerator/FormGenerator';
import { ClientSubTab, ClientTab } from '../../../../../../shared/types';
import { SetConfirmWindowStateFn } from '../../hooks/useConfirmWindowConfig';

export interface UseWizardStep1Params {
  formik: FormikProps<FormikValues>;
  clientTab: ClientTab;
  clientSubTab: ClientSubTab;
  setConfirmWindowState: SetConfirmWindowStateFn;
}

export interface UseWizardStep1 {
  config: GenFormConfig;
  fieldsConfig: GenFormFieldsConfig;
}

export const useWizardStep1Config = (params: UseWizardStep1Params): UseWizardStep1 => {
  return {
    config: getWizardStep1Fields(params.clientSubTab),
    fieldsConfig: useWizardStep1Fields(params),
  };
};
