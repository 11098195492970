import { FormikProps, FormikValues } from 'formik';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import type { ValidationError } from '../../../../../../common/queries/modelPlans/modelPlans.types';

import { getModelPlanRoute } from '../../../../../../common/modules/navigation/navigation';

type ValidationErrorMessageProps = Readonly<{
  formik: FormikProps<FormikValues>;
  errors: ValidationError[] | null;
  className?: string;
}>;

export const ValidationErrorMessage: FC<ValidationErrorMessageProps> = ({
  formik,
  errors,
}: ValidationErrorMessageProps): JSX.Element | null => {
  if (!errors) {
    return <div data-testid="err-messages-container" className="h-9 p-2"></div>;
  }
  const errorMessages = errors.map(({ planDescription, planId }, index) => (
    <Link className="underline" to={getModelPlanRoute(planId)} target="_blank">
      <span className="font-bold">Plan Id: </span>
      <span data-testid={`${planId}_${index}_planId`}>{planId} - </span>
      <span className="font-bold">Description</span>: <span data-testid={`${planId}_${index}_plan-description`}>{planDescription}</span>
    </Link>
  ));

  const { trims } = formik.values;

  let errorPrefixMsg;

  if (Array.isArray(trims) && trims?.length) {
    errorPrefixMsg = trims.length > 1 ? 'These Trims already exist in model plans:' : 'This Trim already exists in model plans:';
  } else {
    errorPrefixMsg = errors.length > 1 ? 'These plans already exist:' : 'This plan already exists:';
  }

  return (
    <div data-testid="err-messages-container" className="flex justify-around flex-wrap p-2 bg-red-100 rounded-md gap-3">
      <p className="text-xs leading-5" data-testid="err-messages-prefix">
        {errorPrefixMsg}
      </p>

      <div data-testid="err-messages-details" className="flex flex-col items-end text-xs leading-5">
        {errorMessages.map((errorMessage, index) => (
          <p key={index}>{errorMessage}</p>
        ))}
      </div>
    </div>
  );
};
