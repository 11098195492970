import { FormikProps, FormikValues } from 'formik';

import { FormGenerator, GenFormConfig, GenFormFieldsConfig } from '../../../../components/FormGenerator/FormGenerator';
import { Loading } from '../../../../components/Loading/Loading';

type TableRowProps = {
  formik: FormikProps<FormikValues>;
  isSaveButtonDisabled: boolean;
  isLoading: boolean;
  formConfig: GenFormConfig;
  fieldsConfig?: GenFormFieldsConfig;
} & { children?: React.ReactNode };

export const TableRow: React.FC<TableRowProps> = ({
  formConfig,
  fieldsConfig,
  formik,
  isLoading,
  isSaveButtonDisabled,
  children,
}: TableRowProps) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormGenerator formik={formik} config={formConfig} fieldsConfig={fieldsConfig} />
      {children}
      <div data-testid="button-actions" className="flex flex-wrap items-center gap-4 mt-4">
        <button onClick={() => formik.resetForm()} type="button" data-testid="cancel-btn" className="prism-btn w-20">
          Cancel
        </button>
        <button type="submit" data-testid="save-btn" className="prism-btn w-20" disabled={isSaveButtonDisabled}>
          Save
        </button>
        {isLoading && <Loading />}
      </div>
    </form>
  );
};
