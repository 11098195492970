import * as yup from 'yup';

const baseClientSchema = {
  planId: yup.string(),
  groupType: yup.string().required(''),
  groupCode: yup.string().max(4, 'Must be 4 characters max').required(''),
  groupName: yup.string().required(''),
  planDescription: yup.string().max(30, 'Must be 30 characters max').nullable(),
  accountNumber: yup.string().nullable(),
  paintRate: yup.number().nullable(),
  bodyRate: yup.number().nullable(),
  mechanicalRate: yup.number().nullable(),
  partRate: yup.number().nullable(),
  isMitchellPaint: yup.boolean(),
  isMitchellPart: yup.boolean(),
  isMitchellRetail: yup.boolean(),
};

export const validationSchemaNextInspectClientRow = yup.object({
  ...baseClientSchema,
  sourceGroupCode: yup.string().nullable(),
});

export const validationSchemaWholesaleClientRow = yup.object({
  ...baseClientSchema,
  planDescription: yup.string().nullable(),
  sourceGroupCode: yup.string().nullable(),
  manufactureCode: yup.string().max(10, 'Must be 10 characters max').nullable(),
  categoryCodes: yup
    .array()
    .of(
      yup.object({
        label: yup.string(),
        value: yup.string(),
      }),
    )
    .nullable(),
  inspectionType: yup.string().nullable(),
  isModelPart: yup.boolean(),
  isModelPaint: yup.boolean(),
  isModelRetail: yup.boolean(),
  shouldHideItem: yup.boolean(),
  shouldRepairItem: yup.boolean(),
  shouldHideImage: yup.boolean(),
});

export const validationSchemaAddNextInspectClient = yup.object({
  ...baseClientSchema,
  sellerAccount: yup.number().nullable().required(''),
  pricingType: yup.string().required(''),
  sourceGroupCode: yup.string().required(''),
});

export const validationSchemaAddWholesale = yup.object({
  groupCode: yup.string().max(4, 'Must be 4 characters max').required(''),
  groupName: yup.string().required(''),
  groupType: yup.string().required(''),
  isMitchellPaint: yup.boolean(),
  isMitchellPart: yup.boolean(),
  isMitchellRetail: yup.boolean(),
  isModelPricing: yup.boolean(),
  shouldHideImage: yup.boolean(),
  shouldHideItem: yup.boolean(),
  shouldRepairItem: yup.boolean(),
  sourceGroupCode: yup.string().nullable(),
  shouldCopyPricingPlan: yup.boolean(),
  planDescription: yup.string().max(30, 'Must be 30 characters max').required(''),
});

export const validationSchemaAddClientInfobahn = yup.object({
  ...baseClientSchema,
  sellerAccount: yup.number().nullable().required(''),
  pricingType: yup.string().required(''),
  sourceGroupCode: yup.string().required(''),
  groupCode: yup.string(),
});

export const validationSchemaModelPlan = yup.object({
  planDescription: yup.string().required(''),
});
