import { CancelCircleOIcon } from '@prism2/icons-react/prism';
import { Reorder } from 'framer-motion';
import { nanoid } from 'nanoid';
import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import noImage from '../../../../assets/icons/no-image.png';
import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import { Image, ImageUploadStatus } from '../../shared/types';
import { GuidelineImage } from '../GuidelineImage/GuidelineImage';

const MAX_FILE_SIZE = 6291456; // 10MB = 6291456 Bytes (in binary)
const FILE_FORMAT = { 'image/jpeg': ['.jpg'] };

export interface DropzoneProps {
  images?: Image[];
  isDisabled: boolean;
  onChange: (images: Array<Image>) => void;
}

export interface FileError {
  message: string;
}

export interface FileRejection {
  file: File;
  errors: FileError[];
}

export const Dropzone: FC<DropzoneProps> = ({ images, isDisabled, onChange }): JSX.Element => {
  const [isRemoveImageModalOpen, setIsRemoveImageModalOpen] = useState(false);
  const [imageToRemove, setImageToRemove] = useState<Image | undefined>(undefined);

  const onDropAccepted = (files: File[]) => {
    const addedImages: Image[] = files.map((file) => ({
      objectUrl: URL.createObjectURL(file),
      status: ImageUploadStatus.NONE,
      id: nanoid(),
      file,
    }));
    onChange([...images!, ...addedImages]);

    const msg = files.length === 1 ? `${files.length} file has been added` : `${files.length} files have been added`;
    toast.success(msg);
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const msg = fileRejections.length === 1 ? `${fileRejections.length} file has error` : `${fileRejections.length} files have errors!`;
    toast.error(msg);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    maxSize: MAX_FILE_SIZE,
    accept: FILE_FORMAT,
    disabled: isDisabled,
    onDropAccepted,
    onDropRejected,
  });

  const onRemoveImageClick = (image: Image) => {
    setImageToRemove(image);
    setIsRemoveImageModalOpen(true);
  };

  const onRemoveImageModalClose = (): void => {
    setIsRemoveImageModalOpen(false);
  };

  const onRemoveImageModalConfirm = (): void => {
    const filtered = images?.filter((img) => img.id !== imageToRemove?.id);
    onChange(filtered as Image[]);
    setIsRemoveImageModalOpen(false);
  };

  return (
    <>
      <DialogModal
        isOpen={isRemoveImageModalOpen}
        onClose={onRemoveImageModalClose}
        submitButtonText="Confirm"
        onSubmitButton={onRemoveImageModalConfirm}
      >
        <div data-testid="delete-img-title" className="prism-heading-2 self-center">
          Do you really want to delete this image?
        </div>
        <div className="flex justify-center mt-5 mb-7">
          <GuidelineImage image={imageToRemove as Image} size={48} />
        </div>
      </DialogModal>

      <div
        {...getRootProps({
          className: `dropzone bg-slate-100 flex flex-col flex-wrap justify-center items-center w-full border-2 ${
            isDisabled ? '' : 'border-dashed'
          } rounded p-5`,
        })}
        data-testid="dropzone"
      >
        <input {...getInputProps()} className="border w-24 h-24 bg-primary-dark" />
        {!isDisabled && (
          <p data-testid="dropzone-title" className="text-primary-dark mb-3">
            Drag 'n' drop files here, or{' '}
            <span onClick={open} className="underline cursor-pointer">
              browse
            </span>
          </p>
        )}
        <div className="flex flex-wrap justify-center items-center gap-2">
          {!images?.length && (
            <img src={noImage} className="w-24 h-24 pointer-events-none" alt="No Images Found" data-testid="images-not-found-icon" />
          )}
          {/* This component can't move from this for correct work drag and drop images*/}
          <Reorder.Group
            as="ol"
            axis="x"
            values={images!}
            onReorder={onChange}
            className="flex flex-wrap justify-center items-center gap-2"
            data-testid="dropzone-items"
          >
            {images?.map((image) => {
              return (
                <Reorder.Item
                  drag={!isDisabled}
                  key={image.id}
                  value={image}
                  whileDrag={{ scale: 1.1 }}
                  className={`flex w-24 h-24 justify-center items-center ${isDisabled ? '' : 'cursor-grab'}  relative`}
                  data-testid="dropzone-item"
                >
                  <GuidelineImage image={image} />
                  {!isDisabled && (
                    <button
                      data-testid="remove-img-btn"
                      onClick={() => onRemoveImageClick(image)}
                      className="opacity-1 absolute left-20 -top-1 w-5 h-5 text-red-600 hover:bg-red-200 bg-white rounded-full"
                    >
                      <CancelCircleOIcon />
                    </button>
                  )}
                </Reorder.Item>
              );
            })}
          </Reorder.Group>
        </div>
      </div>
    </>
  );
};
