import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { fetchModels } from '../../../../../../common/queries/catalogProxy/catalogProxy.api';
import { FetchModelsParams } from '../../../../../../common/queries/catalogProxy/catalogProxy.types';
import { showErrorMessage } from '../../../../../../common/utilities/notification';
import { QueryKey } from '../../../../../../constants/queries';
import { OptionsType } from '../../../../../../types/field';

type UseFetchModelsList = {
  modelsOptions: OptionsType[];
  isFetchingModels: boolean;
};

export const useFetchModelsList = ({ make, year }: { make?: string; year?: string }): UseFetchModelsList => {
  const enabled = !!make && !!year;
  const { isFetching, data } = useQuery([QueryKey.FETCH_MODELS, make, year], () => fetchModels({ make, year } as FetchModelsParams), {
    staleTime: Infinity,
    enabled,
    onError: showErrorMessage,
  });

  const options = useMemo(() => data?.map(({ model }) => ({ value: model.marketingName, label: model.marketingName })) || [], [data]);

  return { isFetchingModels: isFetching, modelsOptions: options };
};
