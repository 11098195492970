import { GenFormConfig } from '../../../../../components/FormGenerator/FormGenerator';
import { ClientGroupType } from '../../../../../types/clients';
import { useTabs } from '../../../hooks/useTabs';
import {
  defaultDamageRuleGroup,
  enabledForBackfill,
  mitchellGroup,
  mitchellWithFlatFeeGroup,
  modelPricingFieldsGroup,
  nextInspectExpandedClientSourcedGroup,
  nextInspectExpandedManheimGroup,
  pricingCalculationGroup,
  ratesGroup,
  wholesaleExpandedGroup,
} from '../../../shared/fields';
import { ClientNEXTInspectSubTab } from '../../../shared/types';

export const getExpandedClientFormConfig = (groupType: ClientGroupType): GenFormConfig => {
  const { subTab } = useTabs();

  const formConfig: GenFormConfig = {
    container: 'flex flex-wrap flex-row gap-4 mb-2',
    fields: [],
  };

  switch (groupType) {
    case ClientGroupType.NextInspect:
      if (subTab.value === ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED) {
        formConfig.fields.push(nextInspectExpandedClientSourcedGroup);
      } else {
        formConfig.fields.push(nextInspectExpandedManheimGroup, ratesGroup, mitchellWithFlatFeeGroup, pricingCalculationGroup);
      }
      break;
    case ClientGroupType.Wholesale:
      formConfig.fields.push(
        enabledForBackfill,
        wholesaleExpandedGroup,
        ratesGroup,
        defaultDamageRuleGroup,
        mitchellGroup,
        modelPricingFieldsGroup,
      );
  }

  return formConfig;
};
