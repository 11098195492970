import { useQuery } from '@tanstack/react-query';
import { ColumnFilter, ColumnSort } from '@tanstack/react-table';

import { fetchPricingPlans } from '../../../common/queries/pricingPlans/pricingPlans.api';
import { FetchPricingPlansResponse } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { QueryKey } from '../../../constants/queries';
import { ClientGroupType } from '../../../types/clients';

type UsePricingPlansParams = Readonly<{
  planId: string;
  groupType: ClientGroupType;
  pageIndex: number;
  pageSize: number;
  sorting: ColumnSort[] | undefined;
  debouncedFilters: ColumnFilter[];
}>;

type UsePricingPlans = Readonly<{
  data: FetchPricingPlansResponse | undefined;
  error: Error | null;
  isFetching: boolean;
  isError: boolean;
}>;

export const usePricingPlans = ({
  planId,
  groupType,
  pageIndex,
  pageSize,
  sorting,
  debouncedFilters,
}: UsePricingPlansParams): UsePricingPlans => {
  const definedFilters = debouncedFilters.filter((filter) => filter.value !== undefined && filter.value !== null);

  const { isFetching, isError, data, error } = useQuery<FetchPricingPlansResponse, Error>({
    queryKey: [QueryKey.FETCH_PRICING_PLANS, planId, groupType, pageIndex, pageSize, sorting, debouncedFilters],
    queryFn: () =>
      fetchPricingPlans({
        planId: planId,
        groupType: groupType,
        offset: pageIndex * pageSize,
        limit: pageSize,
        sort: sorting,
        filter: definedFilters,
      }),
    enabled: Boolean(groupType),
    keepPreviousData: true,
  });

  return { isFetching, isError, data, error };
};
