import type { TabOption } from '../../../types/field';

import { useSyncParamsWithTabsState } from './useSyncParamsWithTabsState';
import { useNavigator } from '../../../common/modules/navigation/useNavigator';
import { CLIENT_TAB_TO_HUMAN_READABLE, TAB_TO_DEFAULT_SUB_TAB } from '../shared/constants';
import { ClientSubTab, ClientTab } from '../shared/types';

export type UseTabs = Readonly<{
  tab: TabOption<ClientTab>;
  setTab: (tab: TabOption<ClientTab>) => void;
  subTab: TabOption<ClientSubTab>;
  setSubTab: (subTab: TabOption<ClientSubTab>) => void;
  onTabChange?: (tab: ClientTab) => void;
}>;

export const useTabs = (): UseTabs => {
  const { navigateToTab } = useNavigator();
  const { tab, subTab, tabConfig } = useSyncParamsWithTabsState();

  const setTab = (newTab: TabOption<ClientTab>): void => {
    const subTabValue = TAB_TO_DEFAULT_SUB_TAB[newTab.value].value;
    navigateToTab(CLIENT_TAB_TO_HUMAN_READABLE[newTab.value], subTabValue);
  };

  const setSubTab = (newSubTab: TabOption<ClientSubTab>): void => {
    navigateToTab(CLIENT_TAB_TO_HUMAN_READABLE[tabConfig.tab], newSubTab.value);
  };

  return { tab, subTab, setTab, setSubTab };
};
