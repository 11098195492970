import { InfoIcon } from '@prism2/icons-react/prism';
import { FC } from 'react';

interface ClientsTrackNotificationProps {
  sourceGroupCodes: string[];
}

export const ClientsTrackNotification: FC<ClientsTrackNotificationProps> = ({
  sourceGroupCodes,
}: ClientsTrackNotificationProps): JSX.Element => {
  const listIsEmpty = !sourceGroupCodes?.length;
  const text = listIsEmpty ? (
    <>There are no clients with pricing plans copied from basic tier</>
  ) : (
    <>
      Please be advised that the following clients
      <b data-testid="clients-source-group-codes">{` ${sourceGroupCodes.join(', ')} `}</b>
      pricing plans were created from basic tier which includes this pricing row. You will need to update the client's pricing plan directly
      for this change.
    </>
  );

  return (
    <>
      <div
        data-testid="client-track-notification"
        className={`flex w-full flex-row bg-blue-100 rounded-md ${listIsEmpty ? 'px-3 py-4 items-center' : 'p-3 items-start'}`}
      >
        <InfoIcon color="darkblue" height="18px" className="mr-2" />
        <span data-testid="track-notification-text" className="text-xs break-word flex-1">
          {text}
        </span>
      </div>
    </>
  );
};
