import { FormikProps, FormikValues } from 'formik';

import { GenFormFieldsConfig } from '../../../../../../../components/FormGenerator/FormGenerator';
import { ClientTab } from '../../../../../shared/types';
import { getGroupCodeField } from '../../../fields/groupCode';
import { getSourceGroupCodeField } from '../../../fields/sourceGroupCode';
import { getMitchelFlagsConfig } from '../../NextInspectWizard/form/wizard2/useWizardStep2Fields';

export type UseFormFieldsParams = Readonly<{
  formik: FormikProps<FormikValues>;
  clientTab: ClientTab;
}>;

export type UseFormFields = GenFormFieldsConfig;

/** Returns map of dynamic props to field (Also applies hooks)  */
export const useFormFields = ({ formik, clientTab }: UseFormFieldsParams): UseFormFields => {
  return {
    ...getMitchelFlagsConfig(formik),
    sourceGroupCode: getSourceGroupCodeField(formik, clientTab),
    groupCode: getGroupCodeField(formik),
  };
};
