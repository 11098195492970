import { catchApiError } from './fetchExceptionFilter';
import { HttpMethod } from '../../../types/common';
import { setAuthHeaders } from '../../modules/auth/utils';

interface FetchMultipartParams {
  method: HttpMethod;
  url: string | URL;
  files: Record<string, File>;
}

export const fetchMultipart = async <TResponse>(params: FetchMultipartParams): Promise<TResponse> => {
  const formData = new FormData();

  for (const name of Object.keys(params.files)) {
    formData.append(name, params.files[name], params.files[name].name);
  }

  const response = await fetch(params.url, {
    method: params.method,
    body: formData,
    headers: {
      ...setAuthHeaders(),
    },
  });

  if (!response.ok) {
    return await catchApiError(response);
  }

  return (await response.json()) as TResponse;
};
