import { FormikProps } from 'formik';

import { PricingPlan } from '../../../types/pricingPlans';
import { UseCommonNextInspectForm } from '../hooks/useCommonNextInspectForm';

export const getStatusOfSaveButtonDisabled = ({
  formik,
  form,
}: {
  formik: FormikProps<Partial<PricingPlan>>;
  form: UseCommonNextInspectForm;
}): boolean => {
  return Boolean(
    !formik.dirty ||
      !formik.isValid ||
      form.fieldsConfig.itemSearch.isFetching ||
      form.fieldsConfig.damageDescription.isFetching ||
      form.fieldsConfig.severityDescription.isFetching ||
      form.fieldsConfig.actionDescription.isFetching ||
      form.fieldsConfig.chargeableFlag.isFetching,
  );
};
