import { getCommonPricingFormInitValues } from './getCommonPricingFormInitValues';
import { PricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { initData } from '../shared/schema';
import { PricingPlanFormikValues } from '../shared/types';

export type GetPricingPlanItemFormInitValuesParams = Readonly<{
  pricingPlanItem?: PricingPlanItem;
  mode: PricingItemModalMode;
  itemSearch?: string;
}>;

export const getPricingPlanItemFormInitValues = ({
  pricingPlanItem,
  mode,
  itemSearch,
}: GetPricingPlanItemFormInitValuesParams): Partial<PricingPlanFormikValues> => {
  if (mode === PricingItemModalMode.ADD) {
    return {
      ...initData,
      cad: {
        partCost: 0,
        partDollars: 0,
        repairDollars: 0,
        paintDollars: 0,
        partCostRetailFlag: '',
      },
    };
  }

  return getCommonPricingFormInitValues({ pricingPlanItem, mode, itemSearch });
};
