import { ArrowDownIcon, ArrowUpIcon } from '@prism2/icons-react/prism';
import { FC } from 'react';

import { ReorderIcon } from '../../../../components/ReorderIcon/ReorderIcon';
import { Direction } from '../../../../types/common';

export interface SequenceItemProps {
  move: (index: number, direction: Direction) => void;
  itemDescription: string;
  index: number;
}

export const SequenceItem: FC<SequenceItemProps> = ({ itemDescription, index, move }: SequenceItemProps): JSX.Element => {
  return (
    <div
      data-testid="sequence-item"
      className="w-full flex items-center justify-between py-2 px-5 bg-white border border-gray-200 rounded-lg shadow-sm gap-5"
    >
      <div className="flex items-center gap-5">
        <p className="text-center">{index + 1}</p>

        <div className="flex items-center gap-2">
          <button
            data-testid="sequence-item-down-btn"
            aria-label="Move item down in sequence"
            className="prism-btn"
            type="button"
            onClick={() => move(index, Direction.Down)}
          >
            <ArrowDownIcon className="text-primary-dark w-5" />
          </button>

          <button
            data-testid="sequence-item-up-btn"
            aria-label="Move item up in sequence"
            className="prism-btn"
            type="button"
            onClick={() => move(index, Direction.Up)}
          >
            <ArrowUpIcon className="text-primary-dark w-5" />
          </button>
        </div>
      </div>

      <p data-testid="sequence-item-description" className="text-left">
        {itemDescription}
      </p>

      <div className="flex items-center gap-5">
        <ReorderIcon size={15} />
      </div>
    </div>
  );
};
