import classnames from 'classnames';
import { FC } from 'react';

import { ColorTheme, ColorThemes } from '../../../types/common';

export interface LabelProps {
  label: string;
  name: string;
  disabled?: boolean;
  colorTheme?: ColorTheme;
  required?: boolean;
}

export const Label: FC<LabelProps> = ({ label, name, disabled, colorTheme, required }: LabelProps): JSX.Element => {
  const classNames = classnames('prism-label', {
    'text-gray-500': disabled,
    'text-black': !disabled && colorTheme === ColorThemes.PRIMARY,
    'text-green-600': !disabled && colorTheme === ColorThemes.GREEN,
    'after:content-["*"] after:ml-0.5 after:text-red-500 block': required && !disabled,
  });
  return (
    <label data-testid={`${name}-label`} key={name} className={classNames}>
      {label}
    </label>
  );
};
