const FAKE_API_URL = 'https://6362f93666f75177ea3b597c.mockapi.io/api/v1';
const API_URL = 'https://api.pricing-admin-dev.awsvintagenp.manheim.com/v1';

const config = {
  routes: {
    PRICING_PLANS: '/clients/:groupCode/pricing-plans/:planId',
    CLIENT_LIST: '/clients/:tab/:subTab',
    MODEL_PLANS: '/model-plans/:planId',
    DAMAGE_GUIDELINES: '/damage-guidelines/:id',
    CLIENT_SETTINGS_CREATE: '/client-settings',
    CLIENT_SETTINGS: '/client-settings/:id',
    LOGIN_CALLBACK: '/login/callback',
    ROOT: '/',
    NOT_EXIST: '*',
  },
  FAKE_API_URL: process.env.REACT_APP_FAKE_API_URL || FAKE_API_URL,
  PAGE_SIZE_ARRAY: [10, 25, 50],
  BASIC_GROUP_CODE: 'NXTB',
  BASIC_TIER: {
    PLAN_ID: '7779',
    GROUP_CODE: 'NXTB',
    GROUP_NAME: 'NEXT INS BASIC',
  },
  USE_PUBLISHING: process.env.REACT_APP_ENVIRONMENT !== 'prod',
  get API_URL(): string {
    return process.env.REACT_APP_API_URL ?? API_URL;
  },
};

export default config;
