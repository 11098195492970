import { Switch } from '@headlessui/react';
import { CheckmarkIcon } from '@prism2/icons-react/prism';
import classnames from 'classnames';

import { ColorTheme, ColorThemes } from '../../../types/common';
import { BaseFieldProps } from '../../../types/field';
import { Label } from '../Label/Label';
import { getValueByName } from '../utils';

export interface SwitchFieldProps extends BaseFieldProps {
  labelClass?: string;
  classContainer?: string;
  colorTheme?: ColorTheme;
  disabled?: boolean;
  vertical?: boolean;
  value?: boolean;
}

export const SwitchField = (props: SwitchFieldProps): JSX.Element => {
  const {
    formik,
    name,
    disabled,
    label,
    vertical,
    showSwitchText,
    onChange,
    classContainer = 'justify-between',
    colorTheme = ColorThemes.PRIMARY,
  } = props;
  const value = (getValueByName(name, formik) as unknown as boolean) || props.value;
  const transition = 'transition duration-200 ease-in-out';

  const neutral = disabled || !value;

  const lineClassNames = classnames(
    'h-[24px] w-[55px] rounded-full',
    {
      'cursor-pointer': !disabled,
      'bg-neutral-300': neutral,
      'bg-blue-700': colorTheme === ColorThemes.PRIMARY && !neutral,
      'bg-green-600': colorTheme === ColorThemes.GREEN && !neutral,
    },
    transition,
  );

  const circleClassNames = [
    `border border-neutral-300 flex justify-center items-center`,
    `${value ? 'translate-x-6' : '-translate-x-0'} -translate-y-1`,
    disabled ? 'bg-neutral-400' : 'bg-white',
    transition,
    `p-2 inline-block h-[32px] w-[32px] rounded-full pointer-events-none shadow-lg`,
  ];

  const containerClassNames = classnames(classContainer, 'flex', {
    'flex-col': vertical,
    'items-center gap-1': !vertical,
  });

  const textClassName = classnames('w-7 ml-2', {
    'text-neutral-300': disabled,
    'text-blue-700': value && colorTheme === ColorThemes.PRIMARY,
    'text-green-600': value && colorTheme === ColorThemes.GREEN,
    'text-black': !value && !disabled,
  });

  const checkMarkClassNames = classnames({
    'text-neutral-300': disabled,
    'text-blue-700': !disabled && colorTheme === ColorThemes.PRIMARY,
    'text-green-600': !disabled && colorTheme === ColorThemes.GREEN,
  });

  const handleOnChange = (checked: never) => {
    formik?.setFieldTouched(name, true);
    if (onChange) {
      onChange(checked);
      return;
    }
    formik?.setFieldValue(name, checked);
  };

  return (
    <div data-testid={`${name}-main-container`} className={containerClassNames}>
      {label && <Label name={name} label={label} colorTheme={value ? colorTheme : ColorThemes.PRIMARY} disabled={disabled} />}
      <div data-testid={`${name}-field-container`} className={`flex items-center justify-left py-2 ${vertical ? '' : 'ml-2'}`}>
        <Switch
          data-testid={`${name}-switch`}
          disabled={disabled}
          checked={value ?? false}
          onChange={handleOnChange}
          className={lineClassNames}
        >
          <div data-testid={`${name}-circle-container`} aria-hidden="true" className={circleClassNames.join(' ')}>
            {value && <CheckmarkIcon data-testid={`${name}-checkmark`} className={checkMarkClassNames} />}
          </div>
        </Switch>
        {showSwitchText && (
          <span data-testid={`${name}-text`} className={textClassName}>
            {value ? 'YES' : 'NO'}
          </span>
        )}
      </div>
    </div>
  );
};
