import type { ModelPlanItemInitData } from '../../../../../../common/queries/modelPlans/modelPlans.types';
import type { UpdateModelPlanItem } from '../../shared/modelPlan.form.types';

export const convertModelPlanItemToFormikValues = (modelPlanItem: ModelPlanItemInitData): UpdateModelPlanItem => {
  const { make, model, years: yearsInit } = modelPlanItem;

  const [{ year, trims, isDefault }, ...years] = yearsInit;
  return {
    make,
    model,
    year: year?.toString(),
    trims: trims?.map((trim) => ({ label: trim, value: trim })) || [],
    isDefault,
    years:
      years?.map((item) => ({
        ...item,
        year: item.year.toString(),
        trims: item.trims?.map((trim) => ({ label: trim, value: trim })) || [],
        isDefault: item.isDefault,
      })) || [],
  };
};
