import { FilterIcon, PlusIcon } from '@prism2/icons-react/prism';
import { Table } from '@tanstack/react-table';
import classNames from 'classnames';
import { FC, useCallback, useEffect } from 'react';

import { ClientTableRow } from './components/ClientTableRow/ClientTableRow';
import { useModelPlanModal } from './components/ModelPlan/Modal/hooks/useModelPlanModal';
import { ModelPlanTableRow } from './components/ModelPlan/TableRow/ModelPlanTableRow';
import { useClientModal } from './hooks/useClientModal';
import { useSyncClientsTableWithSearchParams } from './hooks/useSyncClientsTableWithSearchParams';
import { useTabs } from './hooks/useTabs';
import { CLIENT_TABS, SUB_TAB_TO_CAN_FILTER, TAB_TO_SUB_TABS } from './shared/constants';
import { ClientNEXTInspectSubTab, ClientSubTab, ClientTab, ClientWholesaleSubTab } from './shared/types';
import { useNavigator } from '../../common/modules/navigation/useNavigator';
import { CommonTable } from '../../components/CommonTable/CommonTable';
import { ErrorWrapper } from '../../components/ErrorWrapper/ErrorWrapper';
import { Page } from '../../components/Page/Page';
import { Pagination } from '../../components/Pagination/Pagination';
import { TabMenu } from '../../components/TabMenu/TabMenu';
import { TabOption } from '../../types/field';

const ACCOUNT_PLANS_TABS = [
  ClientWholesaleSubTab.ACCOUNTS,
  ClientNEXTInspectSubTab.ACTIVE_MANHEIM,
  ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED,
];

export const MODEL_PLANS_SEARCH_PARAM_FILTERS = {
  planId: { type: 'string' },
  groupCode: { type: 'string' },
} as const;

export const ACCOUNTS_SEARCH_PARAM_FILTERS = {
  planId: { type: 'string' },
  planDescription: { type: 'string' },
  groupCode: { type: 'string' },
  groupName: { type: 'string' },
} as const;

export const ClientListPage: FC = (): JSX.Element => {
  const { tab, setTab, subTab, setSubTab } = useTabs();
  const { navigateToCreateClientSettings } = useNavigator();

  const { openClientModal, onUpgradeClick, ClientModal } = useClientModal({ tab: tab, subTab });
  const { openModelPlanModal, ModelPlanModal } = useModelPlanModal();

  const { isFilter, searchInput, closeFilters, isLoading, error, setSearchInput, table, toggleFilter, pagination, setPagination } =
    useSyncClientsTableWithSearchParams({ subTabValue: subTab.value, onUpgradeClick });

  const { pageSize } = pagination;

  const cleanTableConfiguration = useCallback((): void => {
    setPagination({ pageSize, pageIndex: 0 });
    setSearchInput('');
    closeFilters();
    table.resetSorting();
    table.resetColumnFilters();
  }, [setPagination, setSearchInput, closeFilters, pageSize]);

  const onTabChange = useCallback(
    (tab: TabOption<ClientTab>): void => {
      setTab(tab);
      cleanTableConfiguration();
    },
    [setTab, cleanTableConfiguration],
  );

  const onSubTabChange = useCallback(
    (subTab: TabOption<ClientSubTab>): void => {
      setSubTab(subTab);
      cleanTableConfiguration();
    },
    [setSubTab, cleanTableConfiguration],
  );

  const onAddBtnClick = useCallback((): void => {
    if (subTab.value === ClientNEXTInspectSubTab.DEMO) {
      return navigateToCreateClientSettings(subTab.value);
    }
    if (ACCOUNT_PLANS_TABS.includes(subTab.value)) {
      return openClientModal();
    }
    openModelPlanModal();
  }, [subTab.value, navigateToCreateClientSettings, openClientModal, openModelPlanModal]);

  useEffect(() => {
    const isSubTabDemo = subTab.value === ClientNEXTInspectSubTab.DEMO;
    if (isSubTabDemo) return table.resetPagination();
    table.toggleAllRowsExpanded(false);
  }, [subTab.value, pagination, table]);

  const isSubTabModelPlans = subTab.value === ClientWholesaleSubTab.MODEL_PLANS;
  const addBtnTitle = isSubTabModelPlans ? 'Add model plan' : 'Add client';
  const subTabs = TAB_TO_SUB_TABS[tab.value];
  const isSearchVisible = subTab.value === ClientNEXTInspectSubTab.DEMO;
  const isFilterable = SUB_TAB_TO_CAN_FILTER[subTab.value];
  const isNextInspectTab = tab.value === ClientTab.NextInspect;

  const ExpandedComponent = subTab.value === ClientWholesaleSubTab.MODEL_PLANS ? ModelPlanTableRow : ClientTableRow;

  return (
    <Page id="client-list" title="Clients" isLoading={isLoading}>
      <ErrorWrapper error={error}>
        <ClientModal />
        <ModelPlanModal />

        <div data-testid="client-list-navigation" className="sticky top-0 z-10 bg-white pt-2 pb-2">
          <div
            className={classNames('flex gap-y-2 flex-col justify-between', {
              'min-[1220px]:flex-row': isNextInspectTab,
              'min-[1008px]:flex-row': !isNextInspectTab,
            })}
          >
            <div data-testid="client-list-tabs" className="flex flex-wrap flex-col gap-y-2 mr-2">
              <TabMenu container="h-10" tabs={CLIENT_TABS} selectedTab={tab} onTabChange={onTabChange} />
              <TabMenu container="h-10" tabs={subTabs} selectedTab={subTab} onTabChange={onSubTabChange} />
            </div>

            <div data-testid="client-table-filters" className="flex flex-col-reverse gap-y-2 gap-x-2 justify-end">
              <div className="flex flex-row gap-2">
                <Pagination table={table as Table<unknown>} />
                {isFilterable && (
                  <button
                    onClick={toggleFilter}
                    data-testid="filter-btn"
                    title="Filter"
                    className={classNames('prism-btn', { 'prism-btn bg-blue-100': isFilter })}
                  >
                    <FilterIcon className="h-6" />
                  </button>
                )}
              </div>

              <div
                className={classNames('flex gap-x-2', {
                  'min-[1220px]:justify-end': isNextInspectTab,
                  'min-[1008px]:justify-end': !isNextInspectTab,
                })}
              >
                {isSearchVisible && (
                  <input
                    data-testid="search-input"
                    className="rounded"
                    id="filled-search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search"
                    type="search"
                  />
                )}

                <button onClick={onAddBtnClick} data-testid="add-client-btn" className="prism-btn">
                  <PlusIcon />
                  {addBtnTitle}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full" data-testid="client-table">
          <CommonTable
            isFilter={isFilter}
            table={table as Table<unknown>}
            ExpandedComponent={ExpandedComponent}
            subTab={subTab.value as ClientNEXTInspectSubTab}
            thStyle={classNames('sticky top-[202px] z-10', {
              'min-[1220px]:top-[104px]': isNextInspectTab,
              'min-[1008px]:top-[104px]': !isNextInspectTab,
            })}
          />
        </div>
      </ErrorWrapper>
    </Page>
  );
};
