import { ClientGroupType, PricingType } from '../../../../../types/clients';
import { OptionsType } from '../../../../../types/field';
import { UseClientSettingsAccounts } from '../../../hooks/useClientSettingsAccounts';
import { isStep2Edited } from '../components/NextInspectWizard/form/utils/helpers';
import { isPricingTypeChangeAffectingView } from '../components/NextInspectWizard/utils/isPricingTypeChangeAffectingView';
import { GetWizardStep1Params } from '../shared/types';

export const getPricingTypeConfig = (params: GetWizardStep1Params, accounts: UseClientSettingsAccounts) => {
  const { formik, clientTab, setConfirmWindowState } = params;

  const { accountOptions } = accounts;
  const account = accountOptions?.find(({ value }) => value === formik.values.groupName);

  const isNextInspectClient = (clientTab as unknown as ClientGroupType) === ClientGroupType.NextInspect;

  const onChange = (option: OptionsType): void => {
    const previousPricingType = formik.values.pricingType as PricingType | undefined;
    const pricingType = (option?.value ?? '') as PricingType;

    const isStep2HasBeenChanged = isStep2Edited(formik);
    const isPricingTypeChangeWillAffectView = isPricingTypeChangeAffectingView(previousPricingType, pricingType);

    if (isStep2HasBeenChanged && isPricingTypeChangeWillAffectView) {
      setConfirmWindowState({ field: 'pricingType', values: { pricingType }, isOpen: true });
      return;
    }

    formik.setFieldValue('pricingType', option?.value);
  };

  return {
    isClearable: true,
    disabled: !!account,
    ...(isNextInspectClient && { onChange }),
  };
};
