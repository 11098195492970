import { GenFormConfig } from '../../../../../../../../components/FormGenerator/FormGenerator';
import { createClientFields } from '../../../../../../shared/fields';
import { ClientSubTab } from '../../../../../../shared/types';

export const getWizardStep1Fields = (clientSubTab: ClientSubTab): GenFormConfig => {
  return {
    container: 'grid grid-cols-2 gap-4 mb-2',
    fields: createClientFields[clientSubTab] ?? [],
  };
};
