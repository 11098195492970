import { useQuery } from '@tanstack/react-query';

import { getAllSeverities } from '../../../common/queries/artProxy/artProxy.api';
import { ArtProxyAction, QueryKey } from '../../../constants/queries';
import { ClientGroupType } from '../../../types/clients';
import { OptionsType } from '../../../types/field';

export interface UseAllSeveritiesData {
  options: OptionsType[];
  isFetching: boolean;
}

export function useAllSeverities(groupType: ClientGroupType | undefined): UseAllSeveritiesData {
  const queryFn = async (): Promise<OptionsType[]> => {
    const { items } = await getAllSeverities();
    return items.map((item) => ({ value: item.code, label: item.description }));
  };

  const { data, isFetching } = useQuery<OptionsType[]>({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.SEVERITIES],
    queryFn,
    keepPreviousData: true,
    enabled: groupType === ClientGroupType.Wholesale,
  });
  return {
    options: data ?? [],
    isFetching,
  };
}
