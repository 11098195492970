import { CalendarIcon } from '@prism2/icons-react/prism';
import classnames from 'classnames';
import { FC } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { Label } from '../FormField/Label/Label';

type DateFieldProps = Readonly<{
  label: string;
  name: string;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  className?: string;
  maxDate?: Date;
}>;

export const DateField: FC<DateFieldProps> = ({
  label,
  name,
  selectedDate,
  setSelectedDate,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  maxDate = new Date(),
  className,
}: DateFieldProps): JSX.Element => {
  return (
    <div className="flex flex-col">
      {label && <Label name={name} label={label} />}
      <DatePicker
        className={classnames('rounded', className)}
        dateFormat="dd/MM/yyyy"
        selected={selectedDate}
        placeholderText={label}
        autoFocus={false}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        onChange={(date) => setSelectedDate(date)}
        showIcon
        maxDate={maxDate}
        minDate={selectsEnd ? startDate : null}
        startDate={startDate}
        endDate={endDate}
        icon={<CalendarIcon className="absolute right-2 top-1/2 transform -translate-y-1/2" />}
      />
    </div>
  );
};
