import { FormikProps } from 'formik';
import { useEffect } from 'react';

import { PricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { PricingPlan } from '../../../types/pricingPlans';

export const useFlatFeeFlag = (
  formik: FormikProps<Partial<PricingPlan>> | FormikProps<Partial<PricingPlanItem>>,
  repairDollars: number | null | undefined,
): void => {
  useEffect(() => {
    if (!formik.dirty) {
      return;
    }
    const value = repairDollars! > 0;
    const { flatFeeFlag } = formik.values;
    if (value === flatFeeFlag) {
      return;
    }
    formik.setFieldValue('flatFeeFlag', value);
  }, [repairDollars]);
};
