import type {
  FetchMakesParams,
  FetchModelsParams,
  FetchModelsResponse,
  FetchMakesResponse,
  FetchTrimsParams,
  FetchTrimsResponse,
} from './catalogProxy.types';

import { config } from '../../../config';
import { fetchJson } from '../../utilities/api/fetchJson';

export const fetchMakes = (params: FetchMakesParams): Promise<FetchMakesResponse> => {
  const { year } = params;

  const url = new URL(`${config.API_URL}/catalog-proxy/${year}/makes`);

  return fetchJson(url, 'GET');
};
export const fetchModels = (params: FetchModelsParams): Promise<FetchModelsResponse> => {
  const { make, year } = params;

  const url = new URL(`${config.API_URL}/catalog-proxy/${year}/${make}/models`);

  return fetchJson(url, 'GET');
};

export const fetchTrims = (params: FetchTrimsParams): Promise<FetchTrimsResponse> => {
  const { make, year, model } = params;

  const url = new URL(`${config.API_URL}/catalog-proxy/${year}/${encodeURIComponent(make)}/${encodeURIComponent(model)}/trims`);

  return fetchJson(url, 'GET');
};
