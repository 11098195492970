import { FormikValues } from 'formik';
import { FC, useEffect } from 'react';

import { useFormConfig } from './form/useFormConfig';
import { useWholesaleFormik } from './hooks/useWholesaleFormik';
import { extractWholesaleClientFromFormik } from './utils/extractWholesaleClientFromFormik';
import { isFormValidForSubmit } from '../../../../../../common/utilities/formik';
import { CommonForm } from '../../../../../../components/CommonForm/CommonForm';
import { FormGenerator } from '../../../../../../components/FormGenerator/FormGenerator';
import { ClientTab } from '../../../../shared/types';
import { useWholesaleCreateClientMutation } from '../../hooks/useWholesaleCreateClientMutation';

export type WholesaleFormProps = Readonly<{
  clientTab: ClientTab.Wholesale;
  onClose: () => void;
}>;

export const WholesaleForm: FC<WholesaleFormProps> = ({ clientTab, onClose }: WholesaleFormProps): JSX.Element => {
  const onSubmit = (values: FormikValues): void => clientMutation.mutate(extractWholesaleClientFromFormik(values));

  const formik = useWholesaleFormik({ onSubmit });
  const clientMutation = useWholesaleCreateClientMutation({ onClose });
  const config = useFormConfig({ formik, clientTab });

  const isLoading = clientMutation.isLoading;
  const isGroupCodeValidationError = !!config.fieldsConfig.groupCode.error;
  const isSaveButtonDisabled = isGroupCodeValidationError || !isFormValidForSubmit(formik) || isLoading;

  // we need to revalidate the validation schema due to a Yup issue,
  // yup doesn't update the schema internally
  useEffect(() => {
    void formik.validateForm();
  }, [formik.values]);

  return (
    <CommonForm formik={formik} onClose={onClose} isSaveButtonDisabled={isSaveButtonDisabled} isLoading={isLoading}>
      <FormGenerator formik={formik} config={config.config} fieldsConfig={config.fieldsConfig} />
    </CommonForm>
  );
};
