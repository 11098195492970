import {
  ArrowUpIcon,
  CheckmarkIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CrossIcon,
  DamagesIcon,
  GearIcon,
  ListIcon,
} from '@prism2/icons-react/prism';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Fragment } from 'react';

import { ClientSettingsTableData } from './types';
import { getFirstValueFromString } from './utils';
import { ModelPlanListItem } from '../../../common/queries/modelPlans/modelPlans.types';
import { InfoTip } from '../../../components/InfoTip/InfoTip';
import { config } from '../../../config';
import { Client, ClientGroupType, PricingType, TransferState } from '../../../types/clients';

export const renderCell = <T extends Client = Client>(info: CellContext<T, unknown>): JSX.Element => {
  if (typeof info.getValue() === 'undefined') {
    return <CrossIcon className="text-primary-dark w-5" data-testid="state:false" />;
  }

  if (typeof info.getValue() === 'boolean') {
    return info.getValue() ? (
      <CheckmarkIcon className="text-primary-dark w-5" data-testid="state:true" />
    ) : (
      <CrossIcon className="text-primary-dark w-5" data-testid="state:false" />
    );
  } else if (info.column.id === 'groupCode') {
    return info.row.original.groupDescription ? (
      (info.getValue() as JSX.Element)
    ) : (
      <div className="relative w-full flex flex-row items-center gap-5">
        <InfoTip text="Group code is not validated" />
        <div>{info.getValue() as string}</div>
      </div>
    );
  } else if (info.column.id === 'transferState') {
    return [TransferState.NEW, TransferState.DRAFT, TransferState.AWAITING_PUBLISH].includes(info.getValue() as TransferState) ? (
      <CrossIcon className="text-primary-dark w-5" data-testid="state:false" />
    ) : (
      <CheckmarkIcon className="text-primary-dark w-5" data-testid="state:true" />
    );
  } else {
    return info.getValue() as JSX.Element;
  }
};

const renderExpandButton = <T,>(info: CellContext<T, unknown>): JSX.Element => {
  return (
    <Fragment>
      {info.row.getIsExpanded() ? (
        <button onClick={info.row.getToggleExpandedHandler()} className="prism-btn" data-testid="expand-btn" title="Expand line item">
          <ChevronDownIcon className="text-primary-dark w-4" />
        </button>
      ) : (
        <button onClick={info.row.getToggleExpandedHandler()} className="prism-btn" data-testid="expand-btn" title="Expand line item">
          <ChevronRightIcon className="text-primary-dark w-4" />
        </button>
      )}
    </Fragment>
  );
};

export const commonClientListCols: ColumnDef<Client>[] = [
  {
    accessorKey: 'groupCode',
    header: 'Group code',
    cell: renderCell,
    footer: (props) => props.column.id,
    minSize: 90,
    maxSize: 90,
    enableSorting: true,
    enableColumnFilter: true,
  },
];

export const nextInspectManheimCols: ColumnDef<Client>[] = [
  {
    accessorKey: 'planId',
    cell: renderCell,
    header: 'Plan id',
    footer: (props) => props.column.id,
    minSize: 80,
    maxSize: 80,
    enableSorting: true,
    enableColumnFilter: true,
  },
  ...commonClientListCols,
  {
    accessorKey: 'clientDescription',
    cell: renderCell,
    header: 'Description',
    footer: (props) => props.column.id,
    minSize: 90,
    maxSize: 90,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'mitchellHybrid',
    cell: renderCell,
    header: 'Hybrid',
    footer: (props) => props.column.id,
    minSize: 70,
    maxSize: 70,
    enableSorting: true,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'flatFeeFlag',
    cell: renderCell,
    header: 'Flat fee',
    footer: (props) => props.column.id,
    minSize: 70,
    maxSize: 70,
    enableSorting: true,
    enableColumnFilter: false,
  },
];

export const nextInspectManheimColsWithTransferState: ColumnDef<Client>[] = [
  {
    accessorKey: 'planId',
    cell: renderCell,
    header: 'Plan id',
    footer: (props) => props.column.id,
    minSize: 80,
    maxSize: 80,
    enableSorting: true,
    enableColumnFilter: true,
  },
  ...commonClientListCols,
  {
    accessorKey: 'clientDescription',
    cell: renderCell,
    header: 'Description',
    footer: (props) => props.column.id,
    minSize: 90,
    maxSize: 90,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'mitchellHybrid',
    cell: renderCell,
    header: 'Hybrid',
    footer: (props) => props.column.id,
    minSize: 70,
    maxSize: 70,
    enableSorting: true,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'flatFeeFlag',
    cell: renderCell,
    header: 'Flat fee',
    footer: (props) => props.column.id,
    minSize: 70,
    maxSize: 70,
    enableSorting: true,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'transferState',
    cell: renderCell,
    header: 'Transfer state',
    footer: (props) => props.column.id,
    minSize: 70,
    maxSize: 70,
    enableSorting: true,
    enableColumnFilter: false,
  },
];

export const nextInspectClientSourcedCols: ColumnDef<Client>[] = [
  {
    accessorKey: 'planId',
    cell: renderCell,
    header: 'Plan id',
    footer: (props) => props.column.id,
    minSize: 80,
    maxSize: 80,
    enableSorting: true,
    enableColumnFilter: true,
  },
  ...commonClientListCols,
  {
    accessorKey: 'clientDescription',
    cell: renderCell,
    header: 'Description',
    footer: (props) => props.column.id,
    minSize: 90,
    maxSize: 90,
    enableSorting: false,
    enableColumnFilter: false,
  },
];

export const nextInspectClientSourcedColsWithTransferState: ColumnDef<Client>[] = [
  {
    accessorKey: 'planId',
    cell: renderCell,
    header: 'Plan id',
    footer: (props) => props.column.id,
    minSize: 80,
    maxSize: 80,
    enableSorting: true,
    enableColumnFilter: true,
  },
  ...commonClientListCols,
  {
    accessorKey: 'clientDescription',
    cell: renderCell,
    header: 'Description',
    footer: (props) => props.column.id,
    minSize: 90,
    maxSize: 90,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'transferState',
    cell: renderCell,
    header: 'Transfer state',
    footer: (props) => props.column.id,
    minSize: 70,
    maxSize: 70,
    enableSorting: true,
    enableColumnFilter: false,
  },
];

export const wholesaleListCols: ColumnDef<Client>[] = [
  {
    accessorKey: 'enabledForBackfill',
    cell: renderCell,
    header: 'Active',
    footer: (props) => props.column.id,
    minSize: 60,
    maxSize: 60,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'planId',
    cell: renderCell,
    header: 'Plan id',
    footer: (props) => props.column.id,
    minSize: 80,
    maxSize: 80,
    enableSorting: true,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'planDescription',
    cell: renderCell,
    header: 'Plan description',
    footer: (props) => props.column.id,
    minSize: 150,
    maxSize: 150,
    enableSorting: true,
    enableColumnFilter: true,
  },
  ...commonClientListCols,
  {
    accessorKey: 'groupName',
    cell: renderCell,
    header: 'Group name',
    footer: (props) => props.column.id,
    minSize: 90,
    maxSize: 90,
    enableSorting: true,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'mitchellHybrid',
    cell: renderCell,
    header: 'Mitchell',
    footer: (props) => props.column.id,
    minSize: 90,
    maxSize: 90,
    enableSorting: true,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'isModelPricing',
    cell: renderCell,
    header: 'Model pricing',
    footer: (props) => props.column.id,
    minSize: 90,
    maxSize: 90,
    enableSorting: true,
    enableColumnFilter: false,
  },
];

export const clientSettingsCols: ColumnDef<ClientSettingsTableData>[] = [
  {
    accessorKey: 'description',
    cell: renderCell,
    header: 'Description',
    footer: (props) => props.column.id,
    minSize: 100,
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'tier',
    cell: renderCell,
    header: 'Tier',
    footer: (props) => props.column.id,
    minSize: 100,
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorKey: 'sellerAccounts',
    cell: renderCell,
    header: 'Seller accounts',
    footer: (props) => props.column.id,
    minSize: 100,
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) => Boolean(row.vehicleCondition?.questionnaireId),
    cell: renderCell,
    header: 'Questionnaire ID',
    footer: (props) => props.column.id,
    minSize: 100,
    maxSize: 100,
    enableSorting: false,
    enableColumnFilter: false,
  },
];

export const modelPlansListCols: ColumnDef<ModelPlanListItem>[] = [
  {
    accessorKey: 'planId',
    cell: (info) => info.getValue(),
    header: 'Plan id',
    footer: (props) => props.column.id,
    minSize: 30,
    maxSize: 30,
    enableSorting: true,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'planDescription',
    cell: (info) => info.getValue(),
    header: 'Plan description',
    footer: (props) => props.column.id,
    minSize: 180,
    maxSize: 180,
    enableSorting: true,
    enableColumnFilter: true,
  },
];

export const nextInspectActiveManheimColsFactory = (
  onPlanIdButtonClick: (planId: string, groupCode: string) => void,
  onDamageGuideLineClick: (value: string) => void,
  onClientSettingsClick: (value: string) => void,
): ColumnDef<Client>[] => {
  const cols = config.USE_PUBLISHING ? nextInspectManheimColsWithTransferState : nextInspectManheimCols;
  return [
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: (info) => {
        return (
          <div className="w-full flex flex-wrap justify-center gap-1">
            {renderExpandButton(info)}
            <button
              className="prism-btn"
              data-testid="pricing-plans-btn"
              title="Pricing plans"
              onClick={() => onPlanIdButtonClick(info.row.original.planId!, info.row.original.groupCode!)}
            >
              <ListIcon />
            </button>
            <button
              className="prism-btn"
              data-testid="damage-guidelines-btn"
              title="Damage guidelines"
              disabled={info.row.original.groupType === ClientGroupType.Wholesale}
              onClick={() => onDamageGuideLineClick(info.row.original.planId!)}
            >
              <DamagesIcon />
            </button>
            <button
              className="prism-btn"
              data-testid="client-settings-btn"
              title="Client settings"
              disabled={info.row.original.groupType === ClientGroupType.Wholesale}
              onClick={() => onClientSettingsClick(info.row.original.client!)}
            >
              <GearIcon />
            </button>
          </div>
        );
      },
      meta: { headerClass: 'left-0 sticky', cellClass: 'left-0 sticky bg-white' } as Record<string, string>,
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
      minSize: 80,
      maxSize: 90,
    },
    ...cols,
  ];
};

export const nextInspectActiveClientSourcedColsFactory = (
  onDamageGuideLineClick: (value: string) => void,
  onClientSettingsClick: (value: string) => void,
): ColumnDef<Client>[] => {
  const cols = config.USE_PUBLISHING ? nextInspectClientSourcedColsWithTransferState : nextInspectClientSourcedCols;
  return [
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: (info) => {
        return (
          <div className="w-full flex flex-wrap justify-center gap-1">
            {renderExpandButton(info)}
            <button
              className="prism-btn"
              data-testid="damage-guidelines-btn"
              title="Damage guidelines"
              disabled={info.row.original.groupType === ClientGroupType.Wholesale}
              onClick={() => onDamageGuideLineClick(info.row.original.planId!)}
            >
              <DamagesIcon />
            </button>
            <button
              className="prism-btn"
              data-testid="client-settings-btn"
              title="Client settings"
              disabled={info.row.original.groupType === ClientGroupType.Wholesale}
              onClick={() => onClientSettingsClick(info.row.original.client!)}
            >
              <GearIcon />
            </button>
          </div>
        );
      },
      meta: { headerClass: 'left-0 sticky', cellClass: 'left-0 sticky bg-white' } as Record<string, string>,
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
      minSize: 40,
      maxSize: 50,
    },
    ...cols,
  ];
};

export const nextInspectDemoColsFactory = (
  onClientSettingsClick: (value: string) => void,
  onUpgradeClick: (clientSettingsItem: Partial<Client>) => void,
): ColumnDef<ClientSettingsTableData>[] => {
  return [
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: (info) => {
        return (
          <div className="w-full flex flex-wrap justify-center gap-1">
            <button
              className="prism-btn"
              data-testid="client-settings-btn"
              title="Client settings"
              onClick={() => onClientSettingsClick(info.row.original.client!)}
            >
              <GearIcon />
            </button>
            <button
              className="prism-btn"
              data-testid="upgrade-btn"
              title="Upgrade"
              onClick={() =>
                onUpgradeClick({
                  client: info.row.original.client,
                  groupName: info.row.original.description,
                  pricingType: info.row.original.pricing?.type,
                  sellerAccount: getFirstValueFromString(info.row.original.sellerAccounts),
                })
              }
            >
              <ArrowUpIcon />
            </button>
          </div>
        );
      },
      meta: { headerClass: 'left-0 sticky', cellClass: 'left-0 sticky bg-white' } as Record<string, string>,
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
      minSize: 80,
      maxSize: 90,
    },
    ...clientSettingsCols,
  ];
};

export const wholesaleAccountsColsFactory = (onPlanIdButtonClick: (planId: string, groupCode: string) => void): ColumnDef<Client>[] => {
  return [
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: (info) => {
        const { pricingType } = info.row.original;
        const isPricingPlansDisabled = pricingType === PricingType.Infobahn;

        return (
          <div className="w-full flex flex-wrap justify-center gap-1">
            {renderExpandButton(info)}
            <button
              className="prism-btn"
              data-testid="pricing-plans-btn"
              title="Pricing plans"
              disabled={isPricingPlansDisabled}
              onClick={() => onPlanIdButtonClick(info.row.original.planId!, info.row.original.groupCode!)}
            >
              <ListIcon />
            </button>
          </div>
        );
      },
      meta: { headerClass: 'left-0 sticky', cellClass: 'left-0 sticky bg-white' } as Record<string, string>,
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
      minSize: 60,
      maxSize: 70,
    },
    ...wholesaleListCols,
  ];
};

export const wholesaleModelPlansColsFactory = (onPlanIdButtonClick: (planId: string) => void): ColumnDef<ModelPlanListItem>[] => {
  return [
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: (info) => {
        return (
          <div className="w-full flex flex-wrap justify-center gap-1">
            {renderExpandButton(info)}
            <button
              className="prism-btn"
              data-testid="pricing-plans-btn"
              title="Pricing plans"
              onClick={() => onPlanIdButtonClick(info.row.original.planId)}
            >
              <ListIcon />
            </button>
          </div>
        );
      },
      meta: {
        headerClass: 'left-0 sticky',
        cellClass: 'left-0 sticky bg-white',
      } as Record<string, string>,
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
      minSize: 30,
      maxSize: 30,
    },
    ...modelPlansListCols,
  ];
};
