import { useQuery } from '@tanstack/react-query';
import { ColumnFilter, ColumnSort } from '@tanstack/react-table';

import type { FetchNextInspectClientsResponse } from '../../../common/queries/clients/clients.types';

import { fetchNextInspectClients } from '../../../common/queries/clients/clients.api';
import { QueryKey } from '../../../constants/queries';
import { ClientNEXTInspectSubTab, ClientSubTab } from '../shared/types';

export interface UseNextInspectClientsParams {
  subTab: ClientSubTab;
  offset: number;
  limit: number;
  filters: ColumnFilter[];
  sort: ColumnSort[];
}

export interface UseNextInspectClients {
  data: FetchNextInspectClientsResponse | undefined;
  isFetching: boolean;
  error: Error | null;
}

export const useNextInspectClients = ({ subTab, offset, limit, sort, filters }: UseNextInspectClientsParams): UseNextInspectClients => {
  return useQuery({
    queryKey: [QueryKey.FETCH_CLIENTS, offset, limit, subTab, sort, filters],
    queryFn: ({ signal }) => fetchNextInspectClients({ subTab, offset, limit, sort, filter: filters, signal }),
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: [ClientNEXTInspectSubTab.ACTIVE_MANHEIM, ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED].includes(
      subTab as ClientNEXTInspectSubTab,
    ),
  });
};
