import { FC, memo, useCallback } from 'react';

import { PricingItemModalMode } from './shared/types';
import { Modal } from '../../../../components/Modal/Modal';
import { Spinner } from '../../../../components/Spinner/Spinner';
import { ClientGroupType } from '../../../../types/clients';
import { PricingPlan } from '../../../../types/pricingPlans';
import { useItemSearchForPrepopulate } from '../../hooks/useItemSearchForPrepopulate';
import { usePricingPlanItem } from '../../hooks/usePricingPlanItem';
import { CommonNextInspectForm } from '../CommonNextInspectForm/CommonNextInspectForm';
import { CommonWholesaleForm } from '../CommonWholesaleForm/CommonWholesaleForm';

export interface LineItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  lineItem?: PricingPlan;
  mode: PricingItemModalMode;
  sourceGroupCodes: string[];
  groupCode: string; // TODO:Fix - might be not defined
  planId: string;
  groupType: ClientGroupType;
  setIsOpenModal: (value: boolean) => void;
}

const LineItemModal: FC<LineItemModalProps> = (props: LineItemModalProps): JSX.Element => {
  const { setIsOpenModal, groupType, mode, isOpen, onClose, lineItem, planId } = props;
  const { itemCode, subItemCode, severityCode, actionCode, sequenceNumber, damageCode } = (lineItem as PricingPlan) || {};
  const { pricingPlanItem, isFetchingPricingPlanItem } = usePricingPlanItem({
    groupType,
    mode,
    sequenceNumber,
    planId,
    itemCode,
    severityCode,
    subItemCode,
    actionCode: actionCode as string,
    damageCode,
    isEnabled: isOpen && Boolean(lineItem),
  });
  const { itemSearch, isFetching } = useItemSearchForPrepopulate({ mode, itemCode, subItemCode });
  const handleClose = useCallback(() => {
    props.setIsOpenModal(false);
  }, [setIsOpenModal]);

  const renderForm = (): JSX.Element => {
    // !groupType for model plans case
    if (!groupType || groupType === ClientGroupType.Wholesale)
      return <CommonWholesaleForm {...props} itemSearch={itemSearch} pricingPlanItem={pricingPlanItem} onClose={handleClose} />;
    return <CommonNextInspectForm {...props} itemSearch={itemSearch} onClose={handleClose} />;
  };

  const isLoading = isFetching || isFetchingPricingPlanItem;

  return (
    <Modal isOpen={isOpen} sizeClass="max-w-md" onClose={onClose}>
      <div data-testid="line-item-modal">
        <div data-testid="line-item-modal-title" className="text-blue-800 text-center prism-heading-3 mb-4">
          {mode} line item
        </div>
        {isLoading ? <Spinner /> : <div data-testid="line-item-modal-content">{renderForm()}</div>}
      </div>
    </Modal>
  );
};

const MemoizedLineItemModal = memo(LineItemModal);
export { MemoizedLineItemModal as LineItemModal };
