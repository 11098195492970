import { FC, ReactNode } from 'react';

import { BlockScreenLoading } from '../BlockScreenLoading/BlockScreenLoading';
import { Header } from '../Header/Header';

interface PageProps {
  id: string; // page unique identifier
  title: string;
  isLoading?: boolean;
  children?: ReactNode;
}

export const Page: FC<PageProps> = ({ id, isLoading, title, children }: PageProps): JSX.Element => {
  return (
    <div data-testid={`${id}-page`}>
      <Header title={title} />
      <div className="xl:container items-center pb-4 px-4">
        {children}
        {isLoading && <BlockScreenLoading />}
      </div>
    </div>
  );
};
