import { FormikProps, FormikValues } from 'formik';

import { ClientGroupType, PricingType } from '../../../../../types/clients';
import { useClientSettingsAccounts } from '../../../hooks/useClientSettingsAccounts';
import { useGroupDescription } from '../../../hooks/useGroupDescription';

export const getGroupCodeField = (formik: FormikProps<FormikValues>) => {
  const isInfobahn = formik.values.pricingType === PricingType.Infobahn;

  const accounts = useClientSettingsAccounts({
    groupName: formik.values.groupName as string,
    isFiltered: true,
  });

  const groupDescription = useGroupDescription({
    groupCode: formik.values.groupCode as string,
    onSuccess: (data) => {
      const account = accounts.accountOptions?.find((acc) => acc.value === data?.groupDescription);
      const groupName = account ? data?.groupDescription : data.groupDescription ?? '';

      if (formik.values.groupType === ClientGroupType.Wholesale) {
        return formik.setValues({ ...formik.values, ...{ groupName } });
      }
    },
  });

  return {
    disabled: isInfobahn,
    isFetching: groupDescription.isFetching,
    error: groupDescription.error,
    ...(isInfobahn && { placeholder: 'Group code will be autogenerated' }),
  };
};
