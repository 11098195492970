import type { AuthServiceProps } from '../common/modules/auth/types';

import config from './api';

export const authConfig: AuthServiceProps = {
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  location: window.location,
  provider: process.env.REACT_APP_PROVIDER || '',
  redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin + '/login/callback',
  scopes: process.env.REACT_APP_SCOPES ? process.env.REACT_APP_SCOPES.split(' ') : [],
  tokenEndpoint: config.API_URL + '/auth/token',
  extraParams: {
    adaptor: process.env.REACT_APP_ADAPTOR || '',
  },
};
