import { FormikProps, FormikValues } from 'formik';

import { getWizardStep2Fields } from './getWizardStep2Fields';
import { useWizardStep2Fields } from './useWizardStep2Fields';
import { GenFormConfig, GenFormFieldsConfig } from '../../../../../../../../components/FormGenerator/FormGenerator';
import { ClientSubTab, ClientTab } from '../../../../../../shared/types';
import { SetConfirmWindowStateFn } from '../../hooks/useConfirmWindowConfig';

export interface UseWizardStep2ConfigParams {
  formik: FormikProps<FormikValues>;
  clientTab: ClientTab;
  clientSubTab: ClientSubTab;
  setConfirmWindowState: SetConfirmWindowStateFn;
}

export interface UseWizardStep2Config {
  config: GenFormConfig;
  fieldsConfig: GenFormFieldsConfig;
}

export const useWizardStep2Config = (params: UseWizardStep2ConfigParams): UseWizardStep2Config => {
  return {
    config: getWizardStep2Fields(params),
    fieldsConfig: useWizardStep2Fields(params),
  };
};
