import type { SequenceNumberOrder } from '../../../types/pricingPlans';
import type { Sequence, SequenceRowItem } from '../shared/types';

import { PricingItemModalMode } from '../components/LineItemModal/shared/types';

/**
 * Calculates the changes in sequence numbers between two lists: visual and received
 * @param items - An array of objects, where each object represents an item with its details
 * @param sequences - An array of pricing plans received from conflicted request
 * @param mode - enum of PricingItemModalMode
 */
export const calculateSequenceChanges = (
  items: Array<SequenceRowItem>,
  sequences: Array<Sequence>,
  mode: PricingItemModalMode,
): Array<SequenceNumberOrder> =>
  items.reduce<Array<SequenceNumberOrder>>((acc, current, index) => {
    const { id, isCurrentItem } = current;
    const previousItem = sequences.find((sequenceItem) => sequenceItem.id === id);

    // Ignore item with isCurrentItem is false
    if (!isCurrentItem) {
      if (previousItem) {
        acc.push({
          oldNumber: previousItem.sequenceNumber,
          newNumber: index + 1,
          isCurrentItem: false,
        });
      }
    } else {
      // Add the item with isCurrentItem is true for UPDATE mode
      if (mode === PricingItemModalMode.UPDATE) {
        acc.push({
          oldNumber: current.sequenceNumber,
          newNumber: index + 1,
          isCurrentItem: true,
        });
      }
    }

    return acc;
  }, []);
