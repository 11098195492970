import { QueryFunction, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useDebounceValue } from '../../../common/hooks/useDebounceValue';
import { getItemDescription } from '../../../common/queries/artProxy/artProxy.api';
import { ArtProxyAction, QueryKey } from '../../../constants/queries';
import { OptionsType } from '../../../types/field';

export interface UseItemDescriptionSearchData {
  setSearchInput: (str: string) => void;
  data: OptionsType[];
  isFetching: boolean;
}

export function useItemDescriptionSearch(groupCode: string): UseItemDescriptionSearchData {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearch = useDebounceValue(searchInput);

  const queryFn: QueryFunction<OptionsType[]> = async ({ signal }): Promise<OptionsType[]> => {
    const { items } = await getItemDescription({ description: debouncedSearch, groupCode, signal });
    return items.map((item) => ({ label: item.description, value: `${item.itemCode}_${item.subItemCode}` }));
  };

  const { data, isFetching } = useQuery<OptionsType[]>({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_SEARCH, debouncedSearch],
    queryFn,
    keepPreviousData: true,
    enabled: Boolean(debouncedSearch),
  });
  const options = debouncedSearch && data && !isFetching ? data : [];

  return {
    setSearchInput,
    data: options,
    isFetching,
  };
}
