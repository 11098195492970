import { type FC, memo } from 'react';

import { convertModelPlanItemToFormikValues } from './helpers/convertModelPlanItemToFormikValues';
import { useModelPlanFormActions } from './hooks/useModelPlanFormActions';
import { useModelPlanTableRowFormik } from './hooks/useModelPlanTableRowFormik';
import { TableRowUpdateModelPlan } from './TableRowUpdateModelPlan';
import { ModelPlanItemInitData } from '../../../../../common/queries/modelPlans/modelPlans.types';

export type ModelPlanTableRowFormProps = {
  initialValues: ModelPlanItemInitData;
  planId: string;
};

const ModelPlanTableRowForm: FC<ModelPlanTableRowFormProps> = ({ initialValues, planId }: ModelPlanTableRowFormProps): JSX.Element => {
  const { onSubmit, isLoading } = useModelPlanFormActions({
    initialValues,
  });

  const formik = useModelPlanTableRowFormik({
    initialValues: convertModelPlanItemToFormikValues(initialValues),
    onSubmit,
  });

  return <TableRowUpdateModelPlan formik={formik} planId={planId} isLoading={isLoading} />;
};

const MemoizedModelPlanTableRowForm = memo(ModelPlanTableRowForm);
export { MemoizedModelPlanTableRowForm as ModelPlanTableRowForm };
