import React, { useCallback, useState } from 'react';

import { CreateModelPlanModal } from '../CreateModelPlanModal';

export interface UseModalPlanModal {
  openModelPlanModal: () => void;
  ModelPlanModal: React.FC;
}

export const useModelPlanModal = (): UseModalPlanModal => {
  const [isModalPlanModalOpen, setIsModalPlanModalOpen] = useState(false);

  const closeModalPlanModal = useCallback(() => setIsModalPlanModalOpen(false), []);
  const openModelPlanModal = useCallback(() => setIsModalPlanModalOpen(true), []);

  const ModelPlanModal: React.FC = () => <CreateModelPlanModal isOpen={isModalPlanModalOpen} onClose={closeModalPlanModal} />;

  return { openModelPlanModal, ModelPlanModal };
};
