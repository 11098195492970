import { FormikValues } from 'formik';
import { FC, Fragment } from 'react';

import { isWizardStep2Visible } from './form/utils/helpers';
import { useWizardStep1Config } from './form/wizard1/useWizardStep1Config';
import { useWizardStep2Config } from './form/wizard2/useWizardStep2Config';
import { useClientMutation } from './hooks/useClientMutation';
import { useConfirmWindowConfig } from './hooks/useConfirmWindowConfig';
import { useNextInspectWizardFormik } from './hooks/useNextInspectWizardFormik';
import { extractNextInspectClientFromFormik } from './utils/extractNextInspectClientFromFormik';
import { isFormValidForSubmit } from '../../../../../../common/utilities/formik';
import { CommonForm } from '../../../../../../components/CommonForm/CommonForm';
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal';
import { FormGenerator } from '../../../../../../components/FormGenerator/FormGenerator';
import { Client } from '../../../../../../types/clients';
import { ClientSubTab, ClientTab } from '../../../../shared/types';

export interface NextInspectFormProps {
  clientTab: ClientTab;
  clientSubTab: ClientSubTab;
  onClose: () => void;
  fieldsToPrepopulate: Partial<Client>;
}

export const NextInspectWizard: FC<NextInspectFormProps> = ({
  clientTab,
  clientSubTab,
  onClose,
  fieldsToPrepopulate,
}: NextInspectFormProps): JSX.Element => {
  const onSubmit = (values: FormikValues): void => clientMutation.mutate(extractNextInspectClientFromFormik(values));

  const formik = useNextInspectWizardFormik({ fieldsToPrepopulate, onSubmit });
  const clientMutation = useClientMutation({ formik, onClose, clientSubTab });

  const { setConfirmWindowState, dialogModalProps } = useConfirmWindowConfig(formik);
  const wizardStep1Form = useWizardStep1Config({ formik, clientTab, clientSubTab, setConfirmWindowState });
  const wizardStep2Form = useWizardStep2Config({ formik, clientTab, clientSubTab, setConfirmWindowState });

  const isLoading = clientMutation.isLoading;
  const isStep2Visible = isWizardStep2Visible(formik);
  const isSaveButtonDisabled = !isFormValidForSubmit(formik) || isLoading;

  return (
    <Fragment>
      <DialogModal {...dialogModalProps} data-testid="confirmation-modal-window" submitButtonText="Confirm">
        <div className="text-center mb-7 prism-heading-2 self-center" data-testid="confirmation-modal-title">
          All changes from step 2 will be lost. <br /> Do you want to continue?
        </div>
      </DialogModal>
      <CommonForm isSaveButtonDisabled={isSaveButtonDisabled} isLoading={isLoading} onClose={onClose} formik={formik}>
        <h3 className="mb-2">Step 1: Choose group type and pricing type for new client</h3>
        <FormGenerator formik={formik} config={wizardStep1Form.config} fieldsConfig={wizardStep1Form.fieldsConfig} />
        {isStep2Visible && (
          <>
            <h3 data-testid="step2-title" className="mb-2 my-3">
              Step 2: Fill the information
            </h3>
            <FormGenerator formik={formik} config={wizardStep2Form.config} fieldsConfig={wizardStep2Form.fieldsConfig} />
          </>
        )}
      </CommonForm>
    </Fragment>
  );
};
