import { PaginationState, Table, Updater } from '@tanstack/react-table';

import { useClientSettings } from './useClientSettings';
import { useModelPlans } from './useModelPlans';
import { useNextInspectClients } from './useNextInspectClients';
import { useSearchInput } from './useSearchInput';
import { useTable } from './useTable';
import { useWholesaleClients } from './useWholesaleClients';
import { useSyncPaginationWithSearchParams } from '../../../common/hooks/useSyncPagingWithSearchParams';
import { useSyncSortWithSearchParams } from '../../../common/hooks/useSyncSortWithSearchParams';
import { useTableFilters } from '../../../common/hooks/useTableFilters';
import { Client } from '../../../types/clients';
import { ACCOUNTS_SEARCH_PARAM_FILTERS, MODEL_PLANS_SEARCH_PARAM_FILTERS } from '../ClientListPage';
import { DEFAULT_PAGINATION } from '../shared/constants';
import { ClientSettingsTableData, ClientSubTab, ClientWholesaleSubTab } from '../shared/types';

type UseSyncCommonTableWithSearchParamsArgs = Readonly<{
  subTabValue: ClientSubTab;
  onUpgradeClick: (client: Partial<Client>) => void;
}>;

type UseSyncCommonTableWithSearchParams = Readonly<{
  isLoading: boolean;
  searchInput: string;
  setPagination: (updaterOrValue: Updater<PaginationState>) => void;
  toggleFilter: () => void;
  pagination: PaginationState;
  isFilter: boolean;
  closeFilters: () => void;
  error: Error | null;
  table: Table<Client> | Table<ClientSettingsTableData>;
  setSearchInput: (value: string) => void;
}>;

export const useSyncClientsTableWithSearchParams: ({
  subTabValue,
  onUpgradeClick,
}: UseSyncCommonTableWithSearchParamsArgs) => UseSyncCommonTableWithSearchParams = ({
  subTabValue,
  onUpgradeClick,
}: UseSyncCommonTableWithSearchParamsArgs) => {
  const { sorting, setSorting } = useSyncSortWithSearchParams();
  const { pagination, setPagination } = useSyncPaginationWithSearchParams();

  const { searchInput, setSearchInput } = useSearchInput({
    onChange: () => {
      setPagination(DEFAULT_PAGINATION);
    },
  });

  const paramsDefinition =
    subTabValue === ClientWholesaleSubTab.ACCOUNTS ? ACCOUNTS_SEARCH_PARAM_FILTERS : MODEL_PLANS_SEARCH_PARAM_FILTERS;

  const { isFilter, filters, setFilters, closeFilters, toggleFilter, debouncedFilters } = useTableFilters({
    onChange: () => setPagination(DEFAULT_PAGINATION),
    paramsDefinition,
  });

  const { pageIndex, pageSize } = pagination;
  const queryParams = { offset: pageIndex * pageSize, limit: pageSize, subTab: subTabValue, sort: sorting, filters: debouncedFilters };

  const { isFetching: nextInspectIsFetching, data: nextInspectData, error: nextInspectError } = useNextInspectClients(queryParams);
  const { isFetching: wholesaleIsFetching, data: wholesaleData, error: wholesaleError } = useWholesaleClients(queryParams);
  const { isFetching: modelPlansIsFetching, data: modelPlansData, error: modelPlansError } = useModelPlans(queryParams);
  const {
    data: clientSettingsData,
    isFetching: clientSettingsIsFetching,
    error: clientSettingsError,
  } = useClientSettings({ subTab: subTabValue });

  const isLoading = nextInspectIsFetching || wholesaleIsFetching || clientSettingsIsFetching || modelPlansIsFetching;
  const error = nextInspectError || wholesaleError || clientSettingsError || modelPlansError;

  const table = useTable({
    subTab: subTabValue,
    nextInspectData,
    wholesaleData,
    modelPlansData,
    clientSettingsData,
    pagination,
    setPagination,
    searchInput,
    setSearchInput,
    sorting,
    setSorting,
    filters,
    setFilters: (newFilters) => setFilters({ newFilters }),
    onUpgradeClick,
  });

  return {
    isFilter,
    closeFilters,
    toggleFilter,
    setPagination,
    pagination,
    table,
    setSearchInput,
    isLoading,
    error,
    searchInput,
  };
};
