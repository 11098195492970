import { useMutation } from '@tanstack/react-query';

import { fetchClientUpdate } from '../../../common/queries/clients/clients.api';
import { FetchClientUpdateParams } from '../../../common/queries/clients/clients.types';

export type UseUpdateClientParams = Readonly<{
  onSuccess?: () => Promise<void>;
  onError?: () => void;
}>;

export type UseUpdateClient = Readonly<{
  mutate: (params: FetchClientUpdateParams) => void;
  isLoading: boolean;
}>;

export const useUpdateClient = ({ onSuccess, onError }: UseUpdateClientParams): UseUpdateClient => {
  return useMutation({
    mutationFn: (params) => fetchClientUpdate(params),
    onSuccess,
    onError,
  });
};
