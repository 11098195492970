import { RJSFSchema } from '@rjsf/utils';
import equals from 'fast-deep-equal';
import { useEffect, useMemo, useState } from 'react';

import type { TabOption } from '../../../types/field';

import { APP_NAME } from '../../../constants/variables';
import { ClientSettings } from '../../../types/clientSettings';
import { Paginated } from '../../../types/common';
import { CLIENT_SETTINGS_TABS } from '../../ClientListPage/shared/constants';
import { ClientType } from '../../ClientListPage/shared/types';
import { getSourceIdFromLocalStorage, mapInitialData } from '../shared/utils';

export const useClientTypeTabsAndData = (
  data: Paginated<ClientSettings> | undefined,
  jsonSchemaForClientSettings: RJSFSchema | undefined,
) => {
  const [clientType, setClientType] = useState<ClientType>(ClientType.DEALER);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [newTab, setNewTab] = useState<TabOption<ClientType>>(CLIENT_SETTINGS_TABS[0]);

  const currentData = useMemo(() => {
    return data?.items?.length && data?.items?.length > 1
      ? data?.items?.find((settings) => settings.clientType === clientType)
      : data?.items?.[0];
  }, [data, clientType]);
  const [formData, setFormData] = useState(currentData);

  const sourceId = useMemo(getSourceIdFromLocalStorage, []);
  const currentDataWithCorrectSourceId = { ...currentData, user: { source: APP_NAME, sourceId } } as ClientSettings;

  const initialFormData = mapInitialData(currentData, jsonSchemaForClientSettings);

  useEffect(() => {
    if (jsonSchemaForClientSettings && currentData) {
      setFormData(mapInitialData(currentDataWithCorrectSourceId, jsonSchemaForClientSettings) as ClientSettings);
    }
  }, [currentData, jsonSchemaForClientSettings]);

  useEffect(() => {
    if (data?.items?.length && data?.items?.length === 1 && clientType !== data?.items?.[0].clientType) {
      setClientType(data?.items?.[0].clientType);
    }
  }, [data]);

  const updateClientType = (newTab: TabOption<ClientType>) => {
    setClientType(newTab?.value.toLocaleLowerCase() as ClientType);
  };

  const currentTab = CLIENT_SETTINGS_TABS.find((tab) => tab.label.toLocaleLowerCase() === clientType);

  const tabs = CLIENT_SETTINGS_TABS.map((tab) => ({
    ...tab,
    isDisabled: !data?.items?.find((settings) => settings.clientType === tab.value),
  }));

  const handleTabChange = (newTab: TabOption<ClientType>) => {
    if (newTab.value === currentTab?.value) return;
    if (equals(formData, initialFormData)) {
      updateClientType(newTab);
    } else {
      setIsDiscardModalOpen(true);
      setNewTab(newTab);
    }
  };

  const handleChange = ({ formData }: { formData: ClientSettings }) => {
    setFormData(formData);
  };

  return {
    handleTabChange,
    isDiscardModalOpen,
    newTab,
    setIsDiscardModalOpen,
    tabs,
    setFormData,
    handleChange,
    updateClientType,
    currentTab,
    formData,
  };
};
