import { RJSFSchema } from '@rjsf/utils';
import { useQuery } from '@tanstack/react-query';

import { fetchClientSettingsJSONShema } from '../../../common/queries/clientSettingsProxy/clientSettingsProxy.api';
import { FetchClientSettingsJSONShemaResponse } from '../../../common/queries/clientSettingsProxy/clientSettingsProxy.types';
import { QueryKey } from '../../../constants/queries';

interface UseClientSettingsJsonSchema {
  data: { schema: RJSFSchema } | undefined;
  isFetching: boolean;
  error: Error | null;
  isError: boolean;
}

export const useClientSettingsJsonSchema = (): UseClientSettingsJsonSchema => {
  const { data, isFetching, error, isError } = useQuery<FetchClientSettingsJSONShemaResponse, Error>({
    queryKey: [QueryKey.FETCH_CLIENT_SETTINGS_JSON_SCHEMA],
    queryFn: () => fetchClientSettingsJSONShema(),
    cacheTime: 0,
  });

  return { data, isFetching, error, isError };
};
