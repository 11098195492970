import { useQuery } from '@tanstack/react-query';

import { getChargeabilities } from '../../../common/queries/artProxy/artProxy.api';
import { ARTGetChargeabilitiesResponse } from '../../../common/queries/artProxy/artProxy.types';
import { ArtProxyAction, QueryKey } from '../../../constants/queries';
import { ClientGroupType } from '../../../types/clients';
import { ARTHookParams } from '../shared/types';

export interface Description {
  code: string;
  description: string;
  default?: boolean;
}

export interface UseActionsChargeabilities {
  data: ARTGetChargeabilitiesResponse | undefined;
  isFetching: boolean;
}

export const useChargeabilities = ({ formik, groupCode, groupType }: ARTHookParams): UseActionsChargeabilities => {
  const { itemCode, subItemCode, damageCode, severityCode } = formik.values;
  const body = { groupCode, itemCode, subItemCode, damageCode, severityCode };
  const queryFn = (): Promise<ARTGetChargeabilitiesResponse> => getChargeabilities(body);

  return useQuery<ARTGetChargeabilitiesResponse>({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.CHARGEABILITIES, groupCode, itemCode, subItemCode, damageCode, severityCode],
    queryFn,
    enabled: Boolean(itemCode && subItemCode && damageCode && severityCode && groupCode) && groupType !== ClientGroupType.Wholesale,
    keepPreviousData: true,
    cacheTime: 0,
  });
};
