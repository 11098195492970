import { FormikProps, FormikValues } from 'formik';

import { Field, FieldTypes } from '../../../types/field';
import { FormField } from '../../FormField/FormField';
import { GenFormFieldsConfig } from '../FormGenerator';

export interface FieldsGroup {
  type: FieldTypes.GROUP;
  label?: string;
  fields: GroupField[];
  classes?: GroupClasses;
}

export type GroupField = Field | FieldsGroup;

export interface GroupClasses {
  mainContainer?: string;
  label?: string;
  fieldsContainer?: string;
}

export interface FormFieldsGroupProps extends FieldsGroup {
  formik: FormikProps<FormikValues>;
  fieldsConfig?: GenFormFieldsConfig;
}

export const FormFieldsGroup = ({ formik, label, fields, classes, fieldsConfig }: FormFieldsGroupProps): JSX.Element => {
  const getFieldComponent = (field: GroupField, index: number): JSX.Element => {
    if (field.type === FieldTypes.GROUP) {
      return <FormFieldsGroup key={field.label ?? index} fieldsConfig={fieldsConfig} {...(field as FieldsGroup)} formik={formik} />;
    }
    return <FormField key={field.name} {...field} {...fieldsConfig?.[field.name]} formik={formik} />;
  };

  const fieldsContainer = <div className={classes?.fieldsContainer}>{fields.map(getFieldComponent)}</div>;

  if (!label) {
    return fieldsContainer;
  }

  return (
    <div className={classes?.mainContainer} data-testid={`${label.toLowerCase()}-fields-group`}>
      {label && <label className={classes?.label}>{label}</label>}
      {fieldsContainer}
    </div>
  );
};
