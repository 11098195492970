import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { createWholesaleClient } from '../../../../../common/queries/clients/clients.api';
import phrases from '../../../../../constants/en_US.json';
import { QueryKey } from '../../../../../constants/queries';
import { Client } from '../../../../../types/clients';
import { UseClientMutationsParams } from '../components/NextInspectWizard/hooks/useClientMutation';

export type UseCreateClientMutationParams = Pick<UseClientMutationsParams, 'onClose'>;

export const useWholesaleCreateClientMutation = ({ onClose }: UseCreateClientMutationParams) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: Partial<Client>) => createWholesaleClient({ client: values }),
    onError: (e: Error) => toast(e.message ?? phrases.clientAddedError, { type: 'error' }),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      toast(phrases.clientAddedSuccess, { type: 'success' });
      onClose();
    },
  });

  return { mutate, isLoading };
};
