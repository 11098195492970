import { useEffect } from 'react';

import { Field } from '../../../types/field';
import { useActionDescription } from '../hooks/useActionDescription';
import { ARTHookParams } from '../shared/types';

export const useActionDescriptionField = (params: ARTHookParams): Partial<Field> => {
  const { data, isFetching } = useActionDescription(params);

  useEffect(() => {
    params.formik.setFieldValue('actionCode', data?.code);
  }, [data]);

  return {
    isFetching,
    defaultValue: params.formik.values.actionCode && data ? data.description : undefined,
  };
};
