import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormikProps } from 'formik';
import { toast } from 'react-toastify';

import { LineItemMutationBody } from './useLineItemMutations';
import { createPricingPlanItem, updatePricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.api';
import {
  CreatePricingPlanItemParams,
  CreatePricingPlanItemResponse,
  ItemSequenceNumber,
  UpdatePricingPlanItemParams,
} from '../../../common/queries/pricingPlans/pricingPlans.types';
import { maxBy } from '../../../common/utilities/maxBy';
import phrases from '../../../constants/en_US.json';
import { QueryKey } from '../../../constants/queries';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { PricingPlanItemWithSequenceNumberOrder } from '../shared/types';
import { createInitialSequenceOrder } from '../utils/createInitialSequenceOrder';
import { replaceFieldsNameWithLabelInMessage } from '../utils/replaceFieldsNameWithLabelInMessage';

export type UseCommonWholesaleMutationParams = Readonly<{
  mode: PricingItemModalMode;
  onSuccess: () => void;
  formik: FormikProps<Partial<PricingPlanItemWithSequenceNumberOrder>>;
  setInitialSequences: (data: Array<ItemSequenceNumber>) => void;
}>;

export interface UseCommonWholesaleMutation {
  mutate: (values: LineItemMutationBody) => void;
  isUpdateLoading: boolean;
  isCreateLoading: boolean;
}

export const useCommonWholesaleMutation = ({
  mode,
  onSuccess,
  formik,
  setInitialSequences,
}: UseCommonWholesaleMutationParams): UseCommonWholesaleMutation => {
  const queryClient = useQueryClient();
  // TODO: temporary solution
  const { lineItem: lineItemPhrases } = phrases as Record<'lineItem', Record<string, Record<'success' | 'error', string>>>;

  // sequence numbers should be re-ordered
  const onConflictResponse = (data: Array<ItemSequenceNumber>): void => {
    setInitialSequences(data);

    const sequenceNumbersOrder = createInitialSequenceOrder(data);
    const sequenceNumber = (maxBy(sequenceNumbersOrder, 'newNumber') ?? 0) + 1;

    formik.setValues({
      ...formik.values,
      sequenceNumbersOrder,
      sequenceNumber,
    });

    toast('During the creation of the pricing plan item, a collision occurred. Please update the sequence numbers', { type: 'info' });
  };

  const onRequestSuccess = () => {
    void queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_PRICING_PLANS] });
    toast(lineItemPhrases[mode].success, { type: 'success' });
    onSuccess();
  };

  const onCreateRequestSuccess = (data: CreatePricingPlanItemResponse): void => {
    const isSequenceConflict = data && data.details && Array.isArray(data.details);
    if (isSequenceConflict) return onConflictResponse(data.details);
    onRequestSuccess();
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message ? replaceFieldsNameWithLabelInMessage(e.message) : lineItemPhrases[mode].error;
    toast(msg, { type: 'error' });
  };

  const { mutate: createPricingPlan, isLoading: isCreateLoading } = useMutation({
    mutationFn: (values: CreatePricingPlanItemParams) => createPricingPlanItem(values),
    onError: onErrorRequest,
    onSuccess: onCreateRequestSuccess,
  });

  const { mutate: updateLineItem, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (values: UpdatePricingPlanItemParams) => updatePricingPlanItem(values),
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  const mutate = (values: LineItemMutationBody): void => {
    if (mode === PricingItemModalMode.UPDATE) {
      return updateLineItem(values as UpdatePricingPlanItemParams);
    }
    return createPricingPlan(values as CreatePricingPlanItemParams);
  };

  return {
    isUpdateLoading,
    isCreateLoading,
    mutate,
  };
};
