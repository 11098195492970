import { Field } from '../../../types/field';
import { ARTHookParams } from '../shared/types';

export const getFlatFeeFlagField = (params: ARTHookParams): Partial<Field> => {
  const { formik } = params;

  return {
    disabled: formik.values.usd?.repairDollars === null || formik.values.usd?.repairDollars !== 0,
  };
};
