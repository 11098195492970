export enum CurrencyTab {
  USD = 'USD',
  CAD = 'CAD',
}

export enum PricingItemModalMode {
  COPY = 'Copy',
  ADD = 'Add',
  UPDATE = 'Update',
}
