import { useQuery } from '@tanstack/react-query';

import { getActionDescriptions } from '../../../common/queries/artProxy/artProxy.api';
import { ARTActionDescriptionItem } from '../../../common/queries/artProxy/artProxy.types';
import { ArtProxyAction, QueryKey } from '../../../constants/queries';
import { ClientGroupType } from '../../../types/clients';
import { ARTHookParams } from '../shared/types';

export interface UseActionsDescriptionResult {
  data: ARTActionDescriptionItem | undefined;
  isFetching: boolean;
}

export const useActionDescription = ({ groupCode, groupType, formik }: ARTHookParams): UseActionsDescriptionResult => {
  const { itemCode, subItemCode, damageCode, severityCode } = formik.values;
  const body = { groupCode, itemCode, subItemCode, damageCode, severityCode };

  const queryFn = async (): Promise<ARTActionDescriptionItem> => {
    const { items } = await getActionDescriptions(body);
    return items.find((item) => item.default)!;
  };

  return useQuery<ARTActionDescriptionItem>({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_DAMAGE_SEVERITY_ACTIONS, groupCode, itemCode, subItemCode, damageCode, severityCode],
    queryFn,
    enabled: Boolean(itemCode && subItemCode && damageCode && severityCode && groupCode) && groupType !== ClientGroupType.Wholesale,
    keepPreviousData: true,
    cacheTime: 0,
  });
};
