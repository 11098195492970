import { FormikProps, FormikValues } from 'formik';

export const getFlatFeeField = (formik: FormikProps<FormikValues>) => {
  const onChange = (e: boolean): void => {
    formik.setValues({
      ...formik.values,
      flatFeeFlag: e,
      ...(e && {
        isMitchellRetail: false,
        isMitchellPaint: false,
        isMitchellPart: false,
      }),
    });
  };
  return {
    disabled: formik.values.flatFeeFlag as boolean,
    onChange,
  };
};
