import { FC } from 'react';

export interface ErrorInfoProps {
  message?: string;
}

export const ErrorInfo: FC<ErrorInfoProps> = ({ message }: ErrorInfoProps): JSX.Element => {
  return (
    <div
      className="top-0 left-0 absolute w-full h-full bg-slate-300 flex items-center justify-center opacity-25"
      data-testid="error-message"
    >
      <div className="text-6xl text-blue-800">{message ? message : "Can't fetch data"}</div>
    </div>
  );
};
