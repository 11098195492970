import { FormikProps } from 'formik';
import { SingleValue } from 'react-select';

import { Field, OptionsType } from '../../../types/field';
import { PricingPlan } from '../../../types/pricingPlans';
import { useDamageSeverityData } from '../hooks/useDamageSeverityData';
import { ARTHookParams } from '../shared/types';

export const useSeverityDescriptionField = (params: ARTHookParams): Partial<Field> => {
  const { formik } = params;
  const { damageCode, itemCode, subItemCode } = params.formik.values;
  const { data, isFetching } = useDamageSeverityData(params);
  const formikBasedValue = getSeverityDescriptionFormikBasedValue(formik);
  const onChange = getSeverityDescriptionOnChangeCallback(formik);
  return {
    options: data,
    formikBasedValue,
    isFetching,
    disabled: !damageCode || !itemCode || !subItemCode || isFetching,
    onChange,
  };
};

export const getSeverityDescriptionFormikBasedValue = (formik: FormikProps<Partial<PricingPlan>>) => {
  const { severityCode, severityDescription } = formik.values;
  if (!severityCode || !severityDescription) {
    return null;
  }
  return { value: severityCode, label: severityDescription };
};

export const getSeverityDescriptionOnChangeCallback = (
  formik: FormikProps<Partial<PricingPlan>>,
): ((option: SingleValue<OptionsType>) => void) => {
  return (option: SingleValue<OptionsType>): void => {
    const unsetValues = {
      actionCode: '',
      chargeableFlag: '',
    };
    const severityDescriptionValue = getSeverityDescriptionValueFromOption(option);
    formik.setValues({
      ...formik.values,
      ...severityDescriptionValue,
      ...unsetValues,
    });
  };
};

export const getSeverityDescriptionValueFromOption = (option: SingleValue<OptionsType>) => {
  if (!option?.value) {
    return {
      severityCode: '',
      severityDescription: '',
    };
  }
  return {
    severityCode: option.value as string,
    severityDescription: option.label,
  };
};
