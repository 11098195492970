import { ColumnFilter, isFunction, Updater } from '@tanstack/react-table';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useDebounceValue } from './useDebounceValue';
import { usePreviousValue } from './usePreviousValue';
import { ParamConfigMap } from './useSearchParamsState';
import { useSyncFiltersWithSearchParams } from './useSyncFiltersWithSearchState';

export type UseTableFilterParams<T extends ParamConfigMap> = {
  onFiltersReset?: () => void;
  onChange?: (filters: ColumnFilter[]) => void;
  paramsDefinition?: T;
};

export type UseTableFilter = {
  isFilter: boolean;
  filters: ColumnFilter[];
  setFilters: (params: SetFiltersParams) => void;
  closeFilters: () => void;
  openFilters: () => void;
  toggleFilter: () => void;
  debouncedFilters: ColumnFilter[];
};

export type SetFiltersParams = {
  newFilters: Updater<ColumnFilter[]>;
  skipOnChange?: boolean;
  skipSync?: boolean;
};

export const useTableFilters = <T extends ParamConfigMap>({
  onFiltersReset,
  onChange,
  paramsDefinition,
}: UseTableFilterParams<T>): UseTableFilter => {
  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFiltersState] = useState<ColumnFilter[]>([]);
  const prevFilters = usePreviousValue(filters);
  const debouncedFilters = useDebounceValue(filters);

  const skipOnChangeRef = useRef(true);

  const debouncedFiltersHash = debouncedFilters.map((filter) => filter.value).join('');
  const prevDebouncedHash = usePreviousValue(debouncedFiltersHash);

  useEffect(() => {
    if (skipOnChangeRef.current || (!prevDebouncedHash && !debouncedFiltersHash)) {
      skipOnChangeRef.current = false;
      return;
    }
    onChange?.(debouncedFilters);
  }, [debouncedFiltersHash]);

  const setFilters = ({ newFilters, skipOnChange, skipSync }: SetFiltersParams): void => {
    if (skipOnChange) {
      skipOnChangeRef.current = true;
    }
    const filtersData = isFunction(newFilters) ? newFilters(prevFilters || []) : newFilters;
    setFiltersState(filtersData);

    if (skipSync) return;
    syncFiltersWithSearchParams(filtersData);
  };

  const resetFilters = useCallback((): void => {
    setFilters({ newFilters: [] });
    syncFiltersWithSearchParams([]);
    onFiltersReset?.();
  }, [onFiltersReset, setFilters]);

  const closeFilters = useCallback((): void => {
    setIsFilter(false);
    resetFilters();
  }, [resetFilters]);

  const openFilters = useCallback((): void => {
    setIsFilter(true);
  }, []);

  const syncFiltersWithSearchParams = useSyncFiltersWithSearchParams({
    paramsDefinition,
    isFilter,
    setFilters,
    openFilters,
    closeFilters,
  });

  const toggleFilter = useCallback((): void => {
    setIsFilter((old) => !old);
    resetFilters();
  }, [resetFilters]);

  return {
    isFilter,
    filters,
    setFilters,
    closeFilters,
    toggleFilter,
    openFilters,
    debouncedFilters,
  };
};
