import { FormLabel } from '@chakra-ui/react';
import { EnumOptionsType, WidgetProps } from '@rjsf/utils';
import { MultiValue, Select, SingleValue } from 'chakra-react-select';
import { FC } from 'react';

type ClientSettingsSelectProps = WidgetProps;

export const ClientSettingsSelect: FC<ClientSettingsSelectProps> = (props: ClientSettingsSelectProps): JSX.Element => {
  const { enumOptions: options } = props.options;

  const optionsMap = (options ?? []).reduce((acc, item) => {
    acc.set(item.value as string, item);
    return acc;
  }, new Map<string, EnumOptionsType>());

  const setValues = () => {
    return !Array.isArray(props.value)
      ? optionsMap.get(props.value as string)
      : ((props.value as string[]).map((item: string) => optionsMap?.get(item)) as EnumOptionsType[]);
  };

  const handleOnChange = (newValue: MultiValue<EnumOptionsType> | SingleValue<EnumOptionsType>) => {
    return Array.isArray(newValue)
      ? props.onChange(newValue?.map((item: EnumOptionsType) => item.value as string))
      : props.onChange((newValue as SingleValue<EnumOptionsType>)?.value);
  };
  return (
    <div data-testid="client-settings-select">
      <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
      <Select
        id={props.id}
        isMulti={props.multiple ? true : undefined}
        required={props.required}
        placeholder={props.placeholder}
        options={props.options.enumOptions}
        onChange={handleOnChange}
        value={setValues()}
      />
    </div>
  );
};
