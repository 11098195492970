import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment } from 'react';

import { BaseFieldProps, OptionsType } from '../../../types/field';

export interface TabFieldProps extends BaseFieldProps {
  options?: OptionsType[];
  classContainer?: string;
}

export const TabField = ({ formik, name, options = [], classContainer }: TabFieldProps): JSX.Element => {
  const onTabChange = (tab: OptionsType): void => {
    formik?.setFieldTouched(name, true);
    formik?.setFieldValue(name, tab.value);
  };

  return (
    <div data-testid={`tab-field`} className={`${classContainer || ''} relative`}>
      <Tab.Group>
        <Tab.List className="w-full h-full flex flex-row rounded bg-gray-100">
          {options.map((tab) => (
            <Tab key={tab.label} as={Fragment}>
              {({ selected }) => {
                return (
                  <button
                    data-testid={`tab-${tab.label}`}
                    className={classNames(
                      `w-56 px-5 box-border font-medium first:rounded-r last:rounded-l`,
                      selected
                        ? 'bg-white text-blue-800 rounded border border-gray-500 z-10'
                        : 'bg-gray-100 text-gray-300 hover:text-gray-700 hover:bg-gray-200 first:rounded-l last:rounded-r',
                    )}
                    onClick={() => onTabChange(tab)}
                  >
                    {tab.label}
                  </button>
                );
              }}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
};
