import { Buffer } from 'buffer';

import { marked } from 'marked';
import { nanoid } from 'nanoid';
import TurndownService from 'turndown';

import { GuidelineItem, ImageUploadStatus } from './types';
import { IGuidelineSection } from '../../../types/damageGuidelines';

export const convertToHTML = (data: IGuidelineSection[]): IGuidelineSection[] =>
  data.map(
    (section) =>
      ({
        header: marked.parse(section?.header),
        subHeader: marked.parse(section?.subHeader),
        images: section.images,
      }) as IGuidelineSection,
  );

export const convertToMarkdown = (data: GuidelineItem[]): GuidelineItem[] => {
  const converter = new TurndownService();

  return data
    .filter((section) => !section.isDeleted || !section.header || !section.subHeader)
    .map((section) => ({ ...section, header: converter.turndown(section.header), subHeader: converter.turndown(section.subHeader) }));
};

export const mapRawSectionsToGuidelineItems = (sections: IGuidelineSection[] = []): GuidelineItem[] => {
  return convertToHTML(sections).map((section) => ({
    ...section,
    isDeleted: false,
    images: section?.images
      ? section.images.map((s3Key) => ({
          id: nanoid(),
          status: ImageUploadStatus.SUCCESS,
          s3Key,
        }))
      : [],
  }));
};

export const mapGuidelineItemsToRawSections = (items: GuidelineItem[]): IGuidelineSection[] => {
  const active = items.filter((item) => !item?.isDeleted);
  return convertToMarkdown(active).map((item) => ({
    header: item.header,
    subHeader: item.subHeader,
    images: item.images.map((image) => image.s3Key as string),
  }));
};

export const compare = (a?: IGuidelineSection, b?: IGuidelineSection) => {
  const header = a?.header !== b?.header;
  const subHeader = a?.subHeader !== b?.subHeader;
  const isDeleted = !!a?.isDeleted !== !!b?.isDeleted;
  const isImagesChanged = a?.images.length !== b?.images.length;
  const isImagesReordered = a?.images.length === b?.images.length && a?.images.some((image, index) => image !== b?.images[index]);

  return header || subHeader || isDeleted || isImagesChanged || isImagesReordered;
};

export const isDifference = (a: IGuidelineSection[], b: IGuidelineSection[]) => {
  let isDifference = false;

  if (a.length !== b.length) return true;

  a.forEach((section, index) => {
    if (isDifference) {
      return;
    }
    if (compare(section, b[index])) {
      isDifference = true;
    }
  });

  return isDifference;
};

export const convertToBase64Image = (data: WithImplicitCoercion<string> | string | ArrayBuffer): string =>
  `data:image/jpg;base64,${Buffer.from((data as unknown as string) ?? '').toString('base64')}`;
