import { ColumnSort, OnChangeFn, SortingState } from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

import { convertSortToSearchParams, getAllCurrentParams } from './useSearchParamsState';
import { SyncStatus } from '../../constants/sync';

const getSortedColumns = (searchParams: URLSearchParams): SortingState => {
  const sorting: SortingState = [];
  searchParams.forEach((value, key) => {
    if (key.startsWith('sort-')) {
      sorting.push({
        id: key.replace('sort-', ''),
        desc: value === 'desc',
      });
    }
  });
  return sorting;
};

type UseSyncSortWithSearchParams = Readonly<{
  setSorting: OnChangeFn<ColumnSort[]>;
  sorting: ColumnSort[];
}>;

export const useSyncSortWithSearchParams = (): UseSyncSortWithSearchParams => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const syncStatus = useRef(SyncStatus.DEFAULT);
  const [searchParams, setSearchParams] = useSearchParams();

  const trackedColumns = getSortedColumns(searchParams);
  const dependencySort = trackedColumns.map((value) => `${value.id}-${String(value.desc)}`).join(',');

  useEffect(() => {
    if (syncStatus.current === SyncStatus.SKIP_SYNC_VALUES) {
      syncStatus.current = SyncStatus.DEFAULT;
      return;
    }

    syncStatus.current = SyncStatus.SKIP_SYNC_SEARCH_PARAMS;
    setSorting(trackedColumns);
  }, [dependencySort]);

  useEffect(() => {
    if (syncStatus.current === SyncStatus.SKIP_SYNC_SEARCH_PARAMS) {
      syncStatus.current = SyncStatus.DEFAULT;
      return;
    }

    syncStatus.current = SyncStatus.SKIP_SYNC_VALUES;
    const newSearchParamsState = convertSortToSearchParams({ sorting });
    const currentSearchParams = getAllCurrentParams(searchParams);
    Object.keys(currentSearchParams as Record<string, unknown>).forEach((key) => {
      if (key.startsWith('sort-')) {
        delete currentSearchParams[key];
      }
    });

    setSearchParams({ ...currentSearchParams, ...newSearchParamsState } as URLSearchParamsInit, { replace: true });
  }, [sorting]);

  return {
    sorting,
    setSorting,
  };
};
