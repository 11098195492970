import { FC } from 'react';

import { FetchClientItemResponse } from '../../../../common/queries/clients/clients.types';
import { FetchModelPlanResponse } from '../../../../common/queries/modelPlans/modelPlans.types';
import { config } from '../../../../config';

interface SubHeaderProps {
  isModelPlan: boolean;
  isNextInspectClient: boolean;
  clientData?: FetchClientItemResponse;
  modelPlanData?: FetchModelPlanResponse;
}

export const SubHeader: FC<SubHeaderProps> = ({ isModelPlan, isNextInspectClient, clientData, modelPlanData }: SubHeaderProps) => (
  <div className="h-10 flex flex-col justify-center">
    {isModelPlan ? (
      <ul className="prism-heading-4 flex justify-start gap-7 text-base" data-testid="pricing-plans-subtitle">
        <li data-testid="plan-description">
          <span className="text-blue-800">Plan description:</span> {modelPlanData?.planDescription}
        </li>
      </ul>
    ) : (
      <ul className="prism-heading-4 flex justify-start gap-7 text-base" data-testid="pricing-plans-subtitle">
        <li data-testid="group-code">
          <span className="text-blue-800">Group code:</span> {clientData?.groupCode}
        </li>
        {isNextInspectClient ? (
          <>
            <li data-testid="description">
              <span className="text-blue-800">Description:</span> {clientData?.clientDescription}
            </li>
            {config.USE_PUBLISHING && (
              <li data-testid="transfer-state">
                <span className="text-blue-800">Transfer state:</span> {clientData?.transferState}
              </li>
            )}
          </>
        ) : (
          <>
            <li data-testid="group-name">
              <span className="text-blue-800">Group name:</span> {clientData?.groupName}
            </li>
            <li data-testid="plan-description">
              <span className="text-blue-800">Plan Description:</span> {clientData?.planDescription}
            </li>
            <li data-testid="manufacture-code">
              <span className="text-blue-800">Manufacture code:</span> {clientData?.manufacturerCode}
            </li>
          </>
        )}
      </ul>
    )}
  </div>
);
