import { FormikProps } from 'formik';
import { SingleValue } from 'react-select';

import { Field, OptionsType } from '../../../types/field';
import { PricingPlan } from '../../../types/pricingPlans';
import { useDamageData } from '../hooks/useDamageData';
import { ARTHookParams } from '../shared/types';

export const getDamageDescriptionField = (params: ARTHookParams): Partial<Field> => {
  const { formik } = params;
  const { itemCode, subItemCode } = formik.values;
  const { data, isFetching } = useDamageData(params);
  const formikBasedValue = getDamageDescriptionFormikBasedValue(formik);
  const onChange = getDamageDescriptionOnChangeCallback(formik);
  return {
    options: data,
    formikBasedValue,
    isFetching,
    disabled: !itemCode || !subItemCode || isFetching,
    onChange,
  };
};

export const getDamageDescriptionFormikBasedValue = (formik: FormikProps<Partial<PricingPlan>>) => {
  const { damageCode, damageDescription } = formik.values;
  if (!damageCode || !damageDescription) {
    return null;
  }
  return {
    value: damageCode,
    label: damageDescription,
  };
};

export const getDamageDescriptionOnChangeCallback = (
  formik: FormikProps<Partial<PricingPlan>>,
): ((option: SingleValue<OptionsType>) => void) => {
  return (option: SingleValue<OptionsType>): void => {
    const unsetValues = {
      severityCode: '',
      severityDescription: '',
      actionCode: '',
      chargeableFlag: '',
    };
    const damageDescriptionValue = getDamageDescriptionValueFromOption(option);
    formik.setValues({
      ...formik.values,
      ...damageDescriptionValue,
      ...unsetValues,
    });
  };
};

export const getDamageDescriptionValueFromOption = (option: SingleValue<OptionsType>) => {
  if (!option?.value) {
    return {
      damageCode: '',
      damageDescription: '',
    };
  }
  return {
    damageCode: option.value as string,
    damageDescription: option.label,
  };
};
