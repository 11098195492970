import { OptionsType } from './field';

export interface Client {
  enabledForBackfill?: boolean;
  planId?: string;
  groupType?: ClientGroupType;
  groupCode?: string;
  groupName?: string;
  client?: string;
  clientDescription?: string;
  paintRate?: number;
  bodyRate?: number;
  mechanicalRate?: number;
  partRate?: number;
  isMitchellPart?: boolean;
  isMitchellPaint?: boolean;
  isMitchellRetail?: boolean;
  sourceGroupCode?: string;
  planDescription?: string;
  pricingType?: PricingType | '';
  categoryCodes?: CategoryCode[];
  inspectionType?: InspectionType;
  damageQty?: boolean;
  damageSize?: boolean;
  isModelPricing?: boolean;
  shouldHideItem?: boolean;
  shouldRepairItem?: boolean;
  shouldHideImage?: boolean;
  manufacturerCode?: string;
  flatFeeFlag?: boolean;
  groupDescription?: string;
  sellerAccount?: number;
  shouldCopyPricingPlan?: boolean;
  transferState?: TransferState;
}

export type ClientDTO = Client & { categoryCodes?: OptionsType[] };

export interface GroupDescription {
  groupDescription: string;
}

export enum TransferState {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  AWAITING_PUBLISH = 'AWAITING PUBLISH',
  PUBLISHED = 'PUBLISHED',
}

export enum InspectionType {
  EMPTY = '',
  A1 = 'A1',
  L1 = 'L1',
}

export enum CategoryCode {
  FLT = 'FLT',
  MIS = 'MIS',
  RNT = 'RNT',
  REP = 'REP',
  CML = 'CML',
  CMR = 'CMR',
  COP = 'COP',
  RCR = 'RCR',
  RCT = 'RCT',
  RTR = 'RTR',
  UNK = 'UNK',
  CSV = 'CSV',
  AT = 'AT',
  CF = 'CF',
  GAV = 'GAV',
  HP = 'HP',
  CCD = 'CCD',
  WHR = 'WHR',
  CBB = 'CBB',
  DRR = 'DRR',
  FUB = 'FUB',
  RSK = 'RSK',
}

export enum PricingType {
  Standard = 'Manheim Standard',
  AS400 = 'AS400',
  Infobahn = 'Infobahn',
  NoPricing = 'No Pricing',
}

export enum ClientGroupType {
  NextInspect = 'N',
  Wholesale = 'W',
}

export interface NextInspectClientListItem {
  enabledForBackfill: boolean;
  planId: string;
  groupCode: string;
  pricingType: PricingType;
  client: string;
  clientDescription: string;
  groupDescription: string;
  groupType: ClientGroupType;
  mitchellHybrid: boolean;
  flatFeeFlag: boolean;
  transferState: TransferState;
}

export interface WholesaleClientListItem {
  planId: string;
  groupCode: string;
  groupName: string;
  planDescription: string;
  groupDescription: string;
  groupType: ClientGroupType;
  mitchellHybrid: boolean;
  isModelPricing: boolean;
}
