import { FormikProps } from 'formik';

import { useFlatFeeFlag } from './useFlatFeeFlag';
import { CurrencyFields } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { PricingPlan } from '../../../types/pricingPlans';

// For pricing plan Next Inspect
export const useUSDFlatFeeFlag = (formik: FormikProps<Partial<PricingPlan>>): void => {
  const { repairDollars } = formik.values.usd as CurrencyFields;
  useFlatFeeFlag(formik, repairDollars);
};
