import { FC } from 'react';

import { CreateModelPlanForm } from './CreateModelPlanForm';
import { Modal } from '../../../../../components/Modal/Modal';

export interface CreateModelPlanModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateModelPlanModal: FC<CreateModelPlanModalProps> = ({ isOpen, onClose }: CreateModelPlanModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} sizeClass="max-w-[70%]">
      <div data-testid="new-model-plan-modal">
        <div data-testid="title" className="text-blue-800 text-center prism-heading-3 mb-4">
          Adding new model plan
        </div>
        <div data-testid="body" className="react-select-reset">
          <CreateModelPlanForm onClose={onClose} />
        </div>
      </div>
    </Modal>
  );
};
