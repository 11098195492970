import {
  ColumnDef,
  ColumnFilter,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction, useMemo } from 'react';

import { useNavigator } from '../../../common/modules/navigation/useNavigator';
import { FetchNextInspectClientsResponse, FetchWholesaleClientsResponse } from '../../../common/queries/clients/clients.types';
import { FetchModelPlansResponse } from '../../../common/queries/modelPlans/modelPlans.types';
import { Client } from '../../../types/clients';
import { ClientSettings } from '../../../types/clientSettings';
import {
  nextInspectActiveClientSourcedColsFactory,
  nextInspectActiveManheimColsFactory,
  nextInspectDemoColsFactory,
  wholesaleAccountsColsFactory,
  wholesaleModelPlansColsFactory,
} from '../shared/columns';
import { CLIENT_SUB_TABS } from '../shared/constants';
import { ClientNEXTInspectSubTab, ClientSettingsTableData, ClientSubTab, ClientWholesaleSubTab } from '../shared/types';
import { calculatePageCount, mapClientSettingsToTableData } from '../shared/utils';

export interface UseTableProps {
  subTab: ClientSubTab;
  searchInput: string;
  pagination: { pageIndex: number; pageSize: number };
  onUpgradeClick: (client: Partial<Client>) => void;
  setPagination: OnChangeFn<PaginationState>;
  setSearchInput: (searchInput: string) => void;
  nextInspectData?: FetchNextInspectClientsResponse;
  wholesaleData?: FetchWholesaleClientsResponse;
  modelPlansData?: FetchModelPlansResponse;
  clientSettingsData?: ClientSettings[];
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  filters: ColumnFilter[];
  setFilters: OnChangeFn<ColumnFilter[]>;
}

export const useTable = ({
  subTab,
  nextInspectData,
  wholesaleData,
  modelPlansData,
  clientSettingsData,
  onUpgradeClick,
  pagination,
  searchInput,
  setPagination,
  setSearchInput,
  sorting,
  setSorting,
  filters,
  setFilters,
}: UseTableProps): Table<Client> | Table<ClientSettingsTableData> => {
  const { navigateToPricingPlans, navigateToDamageGuidelines, navigateToClientSettings, navigateToModelPlan } = useNavigator();

  const isSubTabDemo = subTab === CLIENT_SUB_TABS[2].value;
  const subTabToReturn =
    subTab === ClientNEXTInspectSubTab.ACTIVE_MANHEIM
      ? ClientNEXTInspectSubTab.ACTIVE_MANHEIM
      : ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED;

  const navigateToClientSettingsFromActive = (value: string) => navigateToClientSettings(value, subTabToReturn);
  const navigateToDamageGuidelinesFromActive = (value: string) => navigateToDamageGuidelines(value, subTabToReturn);
  const navigateToClientSettingsFromDemo = (value: string) => navigateToClientSettings(value, ClientNEXTInspectSubTab.DEMO);

  const tableColumns = useMemo(() => {
    switch (subTab) {
      case CLIENT_SUB_TABS[0].value:
        return nextInspectActiveManheimColsFactory(
          navigateToPricingPlans,
          navigateToDamageGuidelinesFromActive,
          navigateToClientSettingsFromActive,
        );
      case CLIENT_SUB_TABS[1].value:
        return nextInspectActiveClientSourcedColsFactory(navigateToDamageGuidelinesFromActive, navigateToClientSettingsFromActive);
      case CLIENT_SUB_TABS[2].value:
        return nextInspectDemoColsFactory(navigateToClientSettingsFromDemo, onUpgradeClick);
      case CLIENT_SUB_TABS[3].value:
        return wholesaleAccountsColsFactory(navigateToPricingPlans);
      case CLIENT_SUB_TABS[4].value:
        return wholesaleModelPlansColsFactory(navigateToModelPlan);
      default:
        return [];
    }
  }, [subTab, nextInspectData?.items, wholesaleData?.items, modelPlansData?.items, clientSettingsData]);

  const tableData = useMemo(() => {
    switch (subTab) {
      case CLIENT_SUB_TABS[0].value:
        return nextInspectData?.items ?? [];
      case CLIENT_SUB_TABS[1].value:
        return nextInspectData?.items ?? [];
      case CLIENT_SUB_TABS[2].value:
        return mapClientSettingsToTableData(clientSettingsData?.filter((item) => item.pricing?.groupCode === 'NXTB'));
      case CLIENT_SUB_TABS[3].value:
        return wholesaleData?.items ?? [];
      case CLIENT_SUB_TABS[4].value:
        return modelPlansData?.items ?? [];
      default:
        return nextInspectData?.items ?? [];
    }
  }, [subTab, nextInspectData?.items, wholesaleData?.items, modelPlansData?.items, clientSettingsData]);

  const clientsData = subTab === ClientNEXTInspectSubTab.ACTIVE_MANHEIM ? nextInspectData : wholesaleData;
  const pageCount = calculatePageCount(pagination, subTab === ClientWholesaleSubTab.MODEL_PLANS ? modelPlansData : clientsData);

  const clientsTable: Table<Client> = useReactTable<Client>({
    data: tableData as Client[],
    columns: tableColumns as ColumnDef<Client>[],
    pageCount,
    state: {
      pagination,
      sorting,
      columnFilters: filters,
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: (originalRow, index) => {
      return `${originalRow.planId ?? 'plan_id'}_${index}`;
    },
    onPaginationChange: setPagination,
    getRowCanExpand: () => true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
    enableSorting: true,
    debugTable: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableSortingRemoval: true,
    sortDescFirst: true,
    enableMultiSort: false,
    paginateExpandedRows: true,
  });

  const clientSettingsTable = useReactTable<ClientSettingsTableData>({
    data: tableData as ClientSettingsTableData[],
    state: {
      globalFilter: searchInput,
    },
    columns: tableColumns as ColumnDef<ClientSettingsTableData>[],
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setSearchInput,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (originalRow, index) => {
      return `${originalRow.client ?? 'client'}_${index}`;
    },
    enableSorting: false,
    debugTable: false,
  });

  return isSubTabDemo ? clientSettingsTable : clientsTable;
};
