import { ColumnFilter } from '@tanstack/react-table';

import { removePrefixOfFieldId } from './removePrefixOfFieldId';

/**
 * Append a query filter parameters to a URL if the value is not undefined
 */
export const appendFilterToURL = (url: URL, filter?: ColumnFilter[]): void => {
  if (filter?.length) {
    filter.forEach(({ id, value }) => url.searchParams.append(removePrefixOfFieldId(id), value as string));
  }
};
