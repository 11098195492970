import { FormikProps } from 'formik';

import type { CommonWholesaleFormValues } from '../shared/types';

import { getFormFields } from './getFormFields';
import { useFormFields } from './useFormFields';
import { GenFormConfig, GenFormFieldsConfig } from '../../../../../../../components/FormGenerator/FormGenerator';
import { ClientTab } from '../../../../../shared/types';

export type UseFormConfig = Readonly<{
  config: GenFormConfig;
  fieldsConfig: GenFormFieldsConfig;
}>;

export type UseFormConfigParams = Readonly<{
  formik: FormikProps<CommonWholesaleFormValues>;
  clientTab: ClientTab;
}>;

/** Returns form configuration (static and dynamic props) for "Wholesale" form */
export const useFormConfig = (params: UseFormConfigParams): UseFormConfig => {
  return {
    config: getFormFields({ formik: params.formik }),
    fieldsConfig: useFormFields(params),
  };
};
