import { useQuery } from '@tanstack/react-query';

import { fetchClientItem } from '../../../common/queries/clients/clients.api';
import { FetchClientItemResponse } from '../../../common/queries/clients/clients.types';
import { QueryKey } from '../../../constants/queries';
import { Client, ClientGroupType } from '../../../types/clients';

interface UseClientParams {
  groupCode: string;
  planId: string;
  onError?: (e: Error) => void;
  onSuccess?: (data: FetchClientItemResponse) => void;
}

export interface UseClient {
  data: Client | undefined;
  error: Error | null;
  isFetching: boolean;
  isError: boolean;
  groupTypeLabel: string;
}

export const useClient = ({ groupCode, planId, onError, onSuccess }: UseClientParams): UseClient => {
  const { isFetching, isError, data, error } = useQuery<FetchClientItemResponse, Error>({
    queryKey: [QueryKey.FETCH_CLIENT_ITEM, groupCode, planId],
    queryFn: () =>
      fetchClientItem({
        groupCode,
        planId,
      }),
    keepPreviousData: true,
    staleTime: Infinity,
    retry: false,
    cacheTime: 0,
    enabled: !!groupCode,
    onError,
    onSuccess,
  });

  const groupTypeLabel = data?.groupType === ClientGroupType.NextInspect ? 'NEXT Inspect' : 'Wholesale';

  return { isFetching, isError, data, error, groupTypeLabel };
};
