import { FormikProps, useFormik } from 'formik';

import { PricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { validationSchemaWholesale } from '../shared/schema';
import { PricingPlanItemWithSequenceNumberOrder } from '../shared/types';
import { getPricingPlanItemFormInitValues } from '../utils/getPricingPlanItemFormInitValues';

export type UseCommonWholesaleFormikParams = Readonly<{
  pricingPlanItem?: PricingPlanItem;
  mode: PricingItemModalMode;
  onSubmit: () => void;
  itemSearch?: string;
}>;

export const useCommonWholesaleFormik = ({
  pricingPlanItem,
  mode,
  onSubmit,
  itemSearch,
}: UseCommonWholesaleFormikParams): FormikProps<Partial<PricingPlanItemWithSequenceNumberOrder>> => {
  const initialValues = getPricingPlanItemFormInitValues({ pricingPlanItem, mode, itemSearch });
  return useFormik<Partial<PricingPlanItem>>({
    initialValues,
    validateOnChange: true,
    validationSchema: validationSchemaWholesale,
    enableReinitialize: true,
    onSubmit,
  });
};
