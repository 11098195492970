import { FormikValues } from 'formik';
import { FC, memo } from 'react';
import { toast } from 'react-toastify';

import { Spinner } from '../../../../components/Spinner/Spinner';
import { config } from '../../../../config';
import { ClientGroupType } from '../../../../types/clients';
import { useClient } from '../../hooks/useClient';
import {
  nextInspectClientSourcedCols,
  nextInspectClientSourcedColsWithTransferState,
  nextInspectManheimCols,
  nextInspectManheimColsWithTransferState,
  wholesaleListCols,
} from '../../shared/columns';
import { ClientNEXTInspectSubTab } from '../../shared/types';
import { ClientTableRowForm } from '../ClientTableRowForm/ClientTableRowForm';
import { Publish } from '../Publish/Publish';

export interface ClientTableRowProps {
  initialValues: FormikValues;
  subTab?: ClientNEXTInspectSubTab;
}

const ClientTableRow: FC<ClientTableRowProps> = ({ initialValues, subTab }: ClientTableRowProps): JSX.Element => {
  const clientQuery = useClient({
    groupCode: (initialValues.groupCode as string) ?? '',
    planId: (initialValues.planId as string) ?? '',
    onError: (error?: Error) => toast(error?.message, { type: 'error' }),
  });

  const isNextInspectClient = initialValues.groupType === ClientGroupType.NextInspect;
  const isClientSourcedSubTab = subTab === ClientNEXTInspectSubTab.ACTIVE_CLIENT_SOURCED;

  let rowColumnsLength;

  if (isNextInspectClient) {
    if (isClientSourcedSubTab) {
      rowColumnsLength = config.USE_PUBLISHING ? nextInspectClientSourcedColsWithTransferState.length : nextInspectClientSourcedCols.length;
    } else {
      rowColumnsLength = config.USE_PUBLISHING ? nextInspectManheimColsWithTransferState.length : nextInspectManheimCols.length;
    }
  } else {
    rowColumnsLength = wholesaleListCols.length;
  }

  const clientEntity = { ...initialValues, ...clientQuery.data };

  return (
    <tr data-testid="edit-client" className="bg-slate-100">
      {clientQuery.isFetching ? (
        <td colSpan={rowColumnsLength + 1}>
          <Spinner />
        </td>
      ) : initialValues.groupType === ClientGroupType.NextInspect && config.USE_PUBLISHING ? (
        <>
          <td className="border-r-0" />
          <td
            data-testid="client-details-section"
            colSpan={isClientSourcedSubTab ? rowColumnsLength - 1 : rowColumnsLength - 2}
            className="p-5 border-l-0 border-r-0"
          >
            <ClientTableRowForm client={clientEntity} />
          </td>
          <td data-testid="publish-section" colSpan={isClientSourcedSubTab ? 1 : 2} className="p-5 border-l-0 align-top">
            <Publish client={clientEntity} />
          </td>
        </>
      ) : (
        <>
          <td className="border-r-0" />
          <td data-testid="client-details-section" colSpan={rowColumnsLength} className="p-5 border-l-0">
            <ClientTableRowForm client={clientEntity} />
          </td>
        </>
      )}
    </tr>
  );
};

const MemoizedClientTableRow = memo(ClientTableRow);
export { MemoizedClientTableRow as ClientTableRow };
