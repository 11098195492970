import { usePrevious } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import Select, { PropsValue } from 'react-select';

import { useDebouncedSelectValue } from './hooks/useDebouncedSelectValue';
import { BaseFieldProps, OptionsType } from '../../../types/field';
import { isSingleValue } from '../../../types/typeguards';
import { Loading } from '../../Loading/Loading';
import { Label } from '../Label/Label';
import { getValueByName } from '../utils';

export interface SelectFieldProps extends BaseFieldProps {
  classContainer?: string;
  options?: OptionsType[];
  disabled?: boolean;
  defaultValue?: string | null;
  isFetching?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  debounce?: boolean;
  debounceDelay?: number;
  onFocus?: () => void;
}

export const SelectField: FC<SelectFieldProps> = ({
  formik,
  name,
  label,
  options = [],
  disabled,
  classContainer,
  defaultValue,
  isFetching,
  placeholder,
  onChange,
  onFocus,
  noOptionsMessage,
  onInputChange,
  isSearchable,
  isClearable,
  hideArrow,
  formikBasedValue,
  isMulti,
  debounce,
  debounceDelay,
  required,
}: SelectFieldProps): JSX.Element => {
  const { uiValue, setUiValue, setInnerFormikValue, debouncedFormikValue } = useDebouncedSelectValue({
    options,
    debounceDelay,
    formikBasedValue,
  });
  const previous = usePrevious(debouncedFormikValue);

  const handleChange = (option: PropsValue<OptionsType>) => {
    formik?.setFieldTouched(name, true);

    if (debounce) {
      setUiValue(option);
      setInnerFormikValue(option);
      return;
    }

    if (onChange) {
      return onChange(option as never);
    }

    if (isSingleValue(option)) {
      formik?.setFieldValue(name, option?.value);
      return;
    }

    formik?.setFieldValue(name, option);
  };

  useEffect(() => {
    if (!debounce || (!debouncedFormikValue && !previous)) {
      return;
    }

    if (onChange) {
      return onChange(debouncedFormikValue as never);
    }
    if (isSingleValue(debouncedFormikValue)) {
      formik?.setFieldValue(name, debouncedFormikValue);
    }
  }, [debouncedFormikValue]);

  const valueFromOptions = options?.find((item: OptionsType) => item.value === (getValueByName(name, formik) as unknown as string));
  const value = debounce ? uiValue : formikBasedValue === undefined ? valueFromOptions : formikBasedValue;

  const defaultValueFromOptions = !isMulti ? options?.find((item: OptionsType) => item.value === defaultValue) : null;

  return (
    <div data-testid={`${name}-select`} className={`${classContainer || ''} relative`}>
      {label && <Label name={name} label={label} disabled={disabled} required={required} />}
      <Select
        isClearable={isClearable ?? false}
        isSearchable={isSearchable ?? true}
        placeholder={placeholder}
        inputId={`select-${name}`}
        isDisabled={disabled ?? false}
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        value={value}
        onChange={(newValue) => handleChange(newValue)}
        defaultValue={defaultValueFromOptions}
        components={{
          ...((hideArrow && { DropdownIndicator: () => null, IndicatorSeparator: () => null }) || undefined),
        }}
        noOptionsMessage={noOptionsMessage}
        onInputChange={onInputChange}
        isMulti={isMulti}
        onFocus={onFocus}
      />
      {isFetching && <div className={`absolute top-8 ${hideArrow ? 'right-2' : ' right-11'}`}>{<Loading />}</div>}
    </div>
  );
};
