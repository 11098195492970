import { useNavigator } from '../../../common/modules/navigation/useNavigator';
import { ClientGroupType } from '../../../types/clients';
import { ClientNEXTInspectSubTab, ClientWholesaleSubTab } from '../../ClientListPage/shared/types';

export const useGetNavigation = (groupType: ClientGroupType, groupCode: string | undefined) => {
  const { navigateToClientsWholesale, navigateToClientsNextInspect, navigateToClientSettings } = useNavigator();

  let navigateToClientList: () => void;

  if (groupType === ClientGroupType.NextInspect) {
    navigateToClientList = () => navigateToClientsNextInspect(ClientNEXTInspectSubTab.ACTIVE_MANHEIM);
  } else if (Boolean(groupCode) && groupType === ClientGroupType.Wholesale) {
    navigateToClientList = () => navigateToClientsWholesale(ClientWholesaleSubTab.ACCOUNTS);
  } else {
    navigateToClientList = () => navigateToClientsWholesale(ClientWholesaleSubTab.MODEL_PLANS);
  }

  return { navigateToClientSettings, navigateToClientList };
};
