import type { ModelPlanItemInitData, UpdateModelPlanItemReturnValues } from '../../../../../../common/queries/modelPlans/modelPlans.types';
import type { UpdateModelPlanItem } from '../../shared/modelPlan.form.types';

import { QueryKey } from '../../../../../../constants/queries';
import { useUpdateModelPlan } from '../../../CommonClientModal/hooks/useUpdateModelPlan';

type UseModelPlanFormActionsParams = {
  initialValues: ModelPlanItemInitData;
};

type UseModelPlanFormActions = {
  onSubmit: (values: UpdateModelPlanItem) => void;
  isLoading: boolean;
};

export const useModelPlanFormActions = ({ initialValues }: UseModelPlanFormActionsParams): UseModelPlanFormActions => {
  const { mutate, isLoading } = useUpdateModelPlan({
    refetchOnSuccess: [QueryKey.FETCH_MODEL_PLANS, QueryKey.FETCH_MODEL_PLAN_ITEM],
  });

  const onSubmit = (values: UpdateModelPlanItem): void => {
    const { make, model, year, trims, isDefault, years } = values;

    const yearsArray =
      years?.map((item) => ({
        year: parseInt(item.year, 10),
        trims: (item.trims || []).map((trim) => trim.value),
        isDefault: item.isDefault,
      })) || [];

    yearsArray.push({
      year: parseInt(year!, 10),
      trims: (trims || [])?.map((trim) => trim.value),
      isDefault: isDefault || false,
    });

    mutate({
      updatedFields: {
        make,
        model,
        years: yearsArray,
      } as UpdateModelPlanItemReturnValues,
      planId: initialValues.planId,
    });
  };

  return { onSubmit, isLoading };
};
