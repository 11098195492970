import { FormikProps, FormikValues } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';

import { INIT_DATA } from './useNextInspectWizardFormik';
import { PricingType } from '../../../../../../../types/clients';

export interface ConfirmWindowState {
  isOpen: boolean;
  field: ConfirmWindowFieldName;
  values: ConfirmWindowValues | null;
}

export type ConfirmWindowFieldName = 'pricingType' | 'groupName' | null;

export type SetConfirmWindowStateFn = Dispatch<SetStateAction<ConfirmWindowState>>;

export interface ConfirmWindowValues {
  groupName?: string;
  pricingType?: PricingType | string | undefined;
  client?: string;
}

export interface ConfirmWindowProps {
  isOpen: boolean;
  onSubmitButton: () => void;
  onClose: () => void;
  onCancelButton: () => void;
}

type UseConfirmWindowConfigParams = FormikProps<FormikValues>;

export interface UseConfirmWindowConfig {
  dialogModalProps: ConfirmWindowProps;
  setConfirmWindowState: SetConfirmWindowStateFn;
}

export const useConfirmWindowConfig = (formik: UseConfirmWindowConfigParams): UseConfirmWindowConfig => {
  const [{ isOpen, field, values }, setConfirmWindowState] = useState<ConfirmWindowState>({
    isOpen: false,
    values: null,
    field: null,
  });

  const onPricingTypeChange = () => {
    formik.setValues({
      ...INIT_DATA,
      groupType: formik.values.groupType as string,
      pricingType: values?.pricingType,
    });
  };

  const onGroupNameChange = () => {
    formik.setValues({
      ...INIT_DATA,
      ...values,
    });
  };

  const resetConfirmWindowState = () =>
    setConfirmWindowState({
      values: null,
      field: null,
      isOpen: false,
    });

  const onSubmitButton = (): void => {
    if (field === 'groupName') {
      onGroupNameChange();
    }
    if (field === 'pricingType') {
      onPricingTypeChange();
    }

    resetConfirmWindowState();

    formik.setTouched({});
    formik.setErrors({});
  };

  return {
    dialogModalProps: {
      isOpen,
      onSubmitButton,
      onClose: resetConfirmWindowState,
      onCancelButton: resetConfirmWindowState,
    },
    setConfirmWindowState,
  };
};
