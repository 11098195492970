import type { PricingPlan } from '../../../types/pricingPlans';

import { PricingPlanItem } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { initData } from '../shared/schema';
import { PricingPlanFormikValues } from '../shared/types';

export type GetCommonPricingFormInitValuesParams = Readonly<{
  pricingPlanItem?: PricingPlanItem | PricingPlan;
  mode: PricingItemModalMode;
  itemSearch?: string;
}>;

export const getCommonPricingFormInitValues = ({
  pricingPlanItem,
  mode,
  itemSearch,
}: GetCommonPricingFormInitValuesParams): Partial<PricingPlanFormikValues> => {
  if (mode === PricingItemModalMode.COPY) {
    return {
      ...pricingPlanItem,
      itemCode: '',
      subItemCode: '',
      damageCode: '',
      damageDescription: '',
      severityCode: '',
      severityDescription: '',
      actionCode: '',
      actionDescription: '',
      sequenceNumber: 0,
    };
  }

  if (mode === PricingItemModalMode.UPDATE) {
    return { ...pricingPlanItem, itemSearch: itemSearch ?? '' };
  }

  return initData;
};
