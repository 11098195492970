import { useEffect, useState } from 'react';

import { useDebounceValue } from '../../../common/hooks/useDebounceValue';
import { usePreviousValue } from '../../../common/hooks/usePreviousValue';

type UseSearchInputParams = { onChange?: (value: string) => void };

type UseSearchInput = Readonly<{
  searchInput: string;
  setSearchInput: (value: string) => void;
}>;

export const useSearchInput = ({ onChange }: UseSearchInputParams): UseSearchInput => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedSearchInput = useDebounceValue(searchInput || '');
  const prev = usePreviousValue(debouncedSearchInput || '');

  useEffect(() => {
    if (prev === debouncedSearchInput) {
      return;
    }
    onChange?.(debouncedSearchInput);
  }, [debouncedSearchInput, prev]);

  return { searchInput, setSearchInput };
};
