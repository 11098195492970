import { CreateModelPlanItemRequestParams } from '../../../../../../common/queries/modelPlans/modelPlans.types';
import { CreateModelPlanFormValues } from '../../shared/modelPlan.form.types';

export const extractModelPlansValuesFromFormik = (values: CreateModelPlanFormValues): CreateModelPlanItemRequestParams => {
  const { make, model, year, trims, isDefault, years, sourcePlanId } = values;

  const yearsArray =
    years?.map((item) => ({
      year: parseInt(item.year, 10),
      trims: (item.trims || []).map((trim) => trim.value),
      isDefault: item.isDefault,
    })) || [];

  yearsArray.push({
    year: parseInt(year as string, 10),
    trims: (trims || []).map((trim) => trim.value),
    isDefault: isDefault || false,
  });

  return { make, model, years: yearsArray, sourcePlanId } as CreateModelPlanItemRequestParams;
};
