import { FormikProps, FormikValues, useFormik } from 'formik';

import { Client, ClientGroupType } from '../../../../../../../types/clients';
import { wholesaleFlags } from '../../../../../shared/fields';
import { getFlagsDefaultValues } from '../../../../../shared/utils';
import { CommonWholesaleFormValues } from '../shared/types';
import { getValidationSchema } from '../utils/getValidationSchema';

const INIT_DATA = {
  enabledForBackfill: false,
  groupType: ClientGroupType.Wholesale,
  groupCode: '',
  groupName: '',
  planDescription: '',
  sourceGroupCode: '',
  shouldCopyPricingPlan: true,
  flatFeeFlag: false,
} as unknown as Client;

export type UseWholesaleFormikParams = Readonly<{
  onSubmit: (values: FormikValues) => void;
}>;

export type UseWholesaleFormik = FormikProps<CommonWholesaleFormValues>;

export const useWholesaleFormik = ({ onSubmit }: UseWholesaleFormikParams): UseWholesaleFormik => {
  return useFormik<Partial<Client>>({
    validationSchema: getValidationSchema,
    initialValues: { ...INIT_DATA, ...getFlagsDefaultValues(wholesaleFlags) },
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit,
  });
};
