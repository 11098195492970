import { ChangeEvent, useState } from 'react';

type FormData = Readonly<{
  from: Date | null;
  to: Date | null;
  search: string;
}>;

type UseHandleHistoryModalState = Readonly<{
  from: Date | null;
  to: Date | null;
  search: string;
  onSelectedDateFrom: (date: Date | null) => void;
  onSelectedDateTo: (date: Date | null) => void;
  onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onResetFormData: () => void;
}>;

export const useHandleHistoryModalState = (onChange: () => void): UseHandleHistoryModalState => {
  const [formData, setFormData] = useState<FormData>({
    from: null,
    to: null,
    search: '',
  });

  const { from, to, search } = formData;

  const onSelectedDateFrom = (date: Date | null) => {
    setFormData((prevState) => {
      return { ...prevState, from: date };
    });
    onChange();
  };

  const onSelectedDateTo = (date: Date | null) => {
    setFormData((prevState) => {
      return { ...prevState, to: date };
    });
    onChange();
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => {
      return { ...prevState, search: e.target.value };
    });
    onChange();
  };

  const onResetFormData = () => {
    setFormData({
      from: null,
      to: null,
      search: '',
    });
  };

  return {
    from,
    to,
    search,
    onSelectedDateFrom,
    onSelectedDateTo,
    onChangeSearch,
    onResetFormData,
  };
};
