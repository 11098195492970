import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC } from 'react';
import { toast } from 'react-toastify';

import type { FetchClientUpdateParams } from '../../../../common/queries/clients/clients.types';

import { getExpandedClientFormConfig } from './form/config';
import { getClientExpandedFieldsConfig } from './form/generateFieldsConfig';
import { fetchClientUpdate } from '../../../../common/queries/clients/clients.api';
import { extractUpdatedFromFormik } from '../../../../common/utilities/extractUpdatedFromFormik';
import { isFormValidForSubmit } from '../../../../common/utilities/formik';
import { GenFormFieldsConfig } from '../../../../components/FormGenerator/FormGenerator';
import { QueryKey } from '../../../../constants/queries';
import { ClientGroupType, GroupDescription, type Client } from '../../../../types/clients';
import { useClientTableRowFormik } from '../../hooks/useClientTableRowFormik';
import { useGroupDescription } from '../../hooks/useGroupDescription';
import { TableRow } from '../TableRow/TableRow';

export interface ClientTableRowProps {
  client: Client;
}

export const ClientTableRowForm: FC<ClientTableRowProps> = ({ client }: ClientTableRowProps): JSX.Element => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (params: FetchClientUpdateParams) => fetchClientUpdate(params),
    onError: (error?: Error) => toast(error?.message, { type: 'error' }),
    onSuccess: async () => {
      void toast('The data has been updated', { type: 'success' });
      await queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENTS] });
      await queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENT_ITEM, formik.values.groupCode] });
      await queryClient.invalidateQueries({ queryKey: [QueryKey.CLIENT_SETTINGS_PROXY, formik.values.client] });
    },
  });

  /** extract updated fields and call mutation */
  const onFormSubmit = () => {
    const groupCode = formik.initialValues.groupCode as string;
    const planId = formik.initialValues.planId as string;
    const updateFields = extractUpdatedFromFormik(formik);
    if (Object.keys(updateFields).length === 0) return;
    mutate({
      updateFields: {
        ...updateFields,
        // we forced to send these flags due to the backend logic
        // if we don't send them, they will be set to false
        shouldHideImage: formik.values.shouldHideImage,
        shouldHideItem: formik.values.shouldHideItem,
        shouldRepairItem: formik.values.shouldRepairItem,
      },
      groupCode,
      planId,
    });
  };

  const formik = useClientTableRowFormik({
    groupType: client.groupType as ClientGroupType,
    client,
    onSubmit: onFormSubmit,
  });

  const groupDescriptionQuery = useGroupDescription({
    groupCode: formik.values.groupCode ?? '',
    onSettled: (data: GroupDescription | undefined) => {
      if (data?.groupDescription) {
        formik.setFieldValue('groupDescription', data.groupDescription);
      }
    },
  });

  const numberOfFetchingQueries = queryClient.isFetching({ fetchStatus: 'fetching' });

  const isSaveButtonDisabled = !isFormValidForSubmit(formik) || isLoading || numberOfFetchingQueries > 0;

  const formConfig = getExpandedClientFormConfig(client.groupType!);
  const fieldsConfig: GenFormFieldsConfig = getClientExpandedFieldsConfig({
    formik,
    groupCodeFieldParams: {
      error: groupDescriptionQuery.error,
      isFetching: groupDescriptionQuery.isFetching,
      onChange: (value) => {
        formik.setFieldValue('groupCode', value);
        groupDescriptionQuery.setGroupCodeIsValidated(false);
      },
    },
  });

  return (
    <TableRow
      fieldsConfig={fieldsConfig}
      formik={formik}
      formConfig={formConfig}
      isLoading={isLoading}
      isSaveButtonDisabled={isSaveButtonDisabled}
    />
  );
};
