import dayjs from 'dayjs';

import type {
  CreatePricingPlanItemConflictResponse,
  CreatePricingPlanItemParams,
  CreatePricingPlanItemResponse,
  CreatePricingPlanItemSuccessResponse,
  DeletePricingPlansParams,
  DeletePricingPlansResponse,
  FetchHistoryParams,
  FetchHistoryResponse,
  FetchPricingPlanItemParams,
  FetchPricingPlanItemResponse,
  FetchPricingPlansParams,
  FetchPricingPlansResponse,
  FetchPricingPlansSourceResponse,
  UpdatePricingPlanItemParams,
  UpdatePricingPlanItemResponse,
} from './pricingPlans.types';

import { config } from '../../../config';
import { setAuthHeaders } from '../../modules/auth/utils';
import { catchApiError } from '../../utilities/api/fetchExceptionFilter';
import { fetchJson } from '../../utilities/api/fetchJson';
import { appendFilterToURL } from '../../utilities/appendFilterToURL';
import { appendSortToURL } from '../../utilities/appendSortToURL';
import { appendToQueryIfExists } from '../../utilities/appendToQueryIfExists';

// for copy and create line item use createPricingPlanItem
export const createPricingPlanItem = async (params: CreatePricingPlanItemParams): Promise<CreatePricingPlanItemResponse> => {
  const { planId, ...body } = params;
  const url = new URL(`${config.API_URL}/pricing-plans/${planId}/items`);

  const response = await fetch(url, {
    method: 'POST',
    headers: { ...setAuthHeaders(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  if (response.status === 409) return (await response.json()) as CreatePricingPlanItemConflictResponse;
  if (!response.ok) return await catchApiError(response);

  return (await response.json()) as CreatePricingPlanItemSuccessResponse;
};

export const fetchPricingPlanItem = async (params: FetchPricingPlanItemParams): Promise<FetchPricingPlanItemResponse> => {
  const { sequenceNumber, planId, itemCode, severityCode, subItemCode, actionCode, damageCode } = params;
  const url = new URL(`${config.API_URL}/pricing-plans/${planId}/item`);
  url.searchParams.append('sequenceNumber', sequenceNumber.toString());
  url.searchParams.append('itemCode', itemCode.toString());
  url.searchParams.append('subItemCode', subItemCode.toString());
  url.searchParams.append('actionCode', actionCode.toString());
  url.searchParams.append('severityCode', severityCode.toString());
  url.searchParams.append('damageCode', damageCode.toString());
  return fetchJson(url, 'GET');
};

export const updatePricingPlanItem = (params: UpdatePricingPlanItemParams): Promise<UpdatePricingPlanItemResponse> => {
  const { planId, ...body } = params;
  const url = new URL(`${config.API_URL}/pricing-plans/${planId}/items`);
  return fetchJson(url, 'PATCH', body);
};

export const deletePricingPlans = (params: DeletePricingPlansParams): Promise<DeletePricingPlansResponse> => {
  const { planId, ...body } = params;
  const url = new URL(`${config.API_URL}/pricing-plans/${planId}/items`);
  return fetchJson(url, 'DELETE', body);
};

export const fetchPricingPlans = (params: FetchPricingPlansParams): Promise<FetchPricingPlansResponse> => {
  const { planId, groupType, limit, offset } = params;
  const sort = params.sort ?? [];
  const filter = params.filter ?? [];

  const url = new URL(`${config.API_URL}/pricing-plans/${planId}`);

  appendFilterToURL(url, filter);
  appendSortToURL(url, sort);
  appendToQueryIfExists(url, 'groupType', groupType);
  appendToQueryIfExists(url, 'offset', offset);
  appendToQueryIfExists(url, 'limit', limit);

  return fetchJson(url, 'GET');
};

export const fetchPricingPlansSource = async (): Promise<FetchPricingPlansSourceResponse> => {
  const url = new URL(`${config.API_URL}/pricing-plans/source`);
  return fetchJson(url, 'GET');
};

export const fetchHistory = ({ planId, from, to, search, offset, limit }: FetchHistoryParams): Promise<FetchHistoryResponse> => {
  const url = new URL(`${config.API_URL}/history/pricing-plans/${planId}`);

  const isoDateFrom = from ? dayjs(from).format('YYYY-MM-DD') : '';
  const isoDateTo = to ? dayjs(to).add(1, 'day').format('YYYY-MM-DD') : '';

  appendToQueryIfExists(url, 'from', isoDateFrom);
  appendToQueryIfExists(url, 'to', isoDateTo);
  appendToQueryIfExists(url, 'search', search);
  appendToQueryIfExists(url, 'offset', offset);
  appendToQueryIfExists(url, 'limit', limit);

  return fetchJson(url, 'GET');
};
