import React from 'react';
import { Navigate } from 'react-router-dom';

import config from './api';
import { ClientListPage } from '../pages/ClientListPage/ClientListPage';
import { ClientSettingsPage } from '../pages/ClientSettingsPage/ClientSettingsPage';
import { DamageGuidelinesPage } from '../pages/DamageGuidelinesPage/DamageGuidelinesPage';
import { PricingPlansPage } from '../pages/PricingPlansPage/PricingPlansPage';

export type RouteType = { path: string; element: React.ReactNode | null };

export const routesMap: RouteType[] = [
  {
    path: config.routes.CLIENT_LIST,
    element: <ClientListPage />,
  },
  {
    path: config.routes.PRICING_PLANS,
    element: <PricingPlansPage />,
  },
  {
    path: config.routes.MODEL_PLANS,
    element: <PricingPlansPage />,
  },
  {
    path: config.routes.DAMAGE_GUIDELINES,
    element: <DamageGuidelinesPage />,
  },
  {
    path: config.routes.CLIENT_SETTINGS,
    element: <ClientSettingsPage />,
  },
  {
    path: config.routes.CLIENT_SETTINGS_CREATE,
    element: <ClientSettingsPage />,
  },
  {
    path: config.routes.LOGIN_CALLBACK,
    element: <></>,
  },
  {
    path: config.routes.NOT_EXIST,
    element: <Navigate to="/" />,
  },
  {
    path: config.routes.ROOT,
    element: <Navigate to={config.routes.CLIENT_LIST} />,
  },
];
