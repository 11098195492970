import { ClientGroupType } from '../../../types/clients';
import { Field } from '../../../types/field';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { useAllActions } from '../hooks/useAllActions';

export interface UseAllActionsFieldParams {
  mode: PricingItemModalMode;
  groupType?: ClientGroupType;
}

export const useAllActionsField = ({ groupType, mode }: UseAllActionsFieldParams): Partial<Field> => {
  const { options, isFetching } = useAllActions(groupType);
  return { options, isFetching, disabled: mode === PricingItemModalMode.UPDATE ? true : isFetching };
};
