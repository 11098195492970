import { FC } from 'react';
import ReactQuill from 'react-quill';

import { QUILL_DEFAULT_MODULES, QUILL_DEFAULT_THEME } from '../../shared/constants';

export interface GuidelineTextEditProps {
  isDisabled: boolean;
  value: string;
  placeholder: string;
  onValueChange?: (html: string) => void;
}

export const GuidelineTextEdit: FC<GuidelineTextEditProps> = ({
  value,
  onValueChange,
  isDisabled,
  placeholder,
}: GuidelineTextEditProps): JSX.Element => {
  const editorBgColor = isDisabled ? 'bg-white' : 'bg-gray-100';

  return (
    <div data-testid={`${placeholder.toLowerCase()}-editable-field`} className={`rounded ${editorBgColor} border`}>
      <ReactQuill
        placeholder={placeholder}
        theme={QUILL_DEFAULT_THEME}
        modules={QUILL_DEFAULT_MODULES}
        onChange={onValueChange}
        readOnly={isDisabled}
        value={value}
      />
    </div>
  );
};
