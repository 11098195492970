import { TriangleDownIcon, TriangleUpIcon } from '@prism2/icons-react/prism';
import { flexRender, Header } from '@tanstack/react-table';
import classNames from 'classnames';
import React from 'react';

import { TextFilter } from '../TextFilter/TextFilter';

export interface HeaderCellProps<TD, TV> {
  header: Header<TD, TV>;
  isFilter?: boolean;
}

export const HeaderCell = <TD, TV>({ header, isFilter }: HeaderCellProps<TD, TV>): JSX.Element => {
  const onHeaderSortToggle = (): void => header.column.toggleSorting();

  const isFilterVisible = isFilter && header.column.getCanFilter();

  return (
    <div className="flex flex-col justify-between items-center">
      {header.isPlaceholder ? null : header.column.getCanSort() ? (
        <div
          data-testid={`${header.id}-th`}
          className={classNames([
            { 'cursor-pointer select-none': header.column.getCanSort() },
            { 'flex justify-center items-center': header.column.getIsSorted() },
          ])}
          onClick={onHeaderSortToggle}
        >
          {flexRender(header.column.columnDef.header as string, header.getContext())}
          {{
            asc: <TriangleUpIcon data-testid={`${header.id}-triangle-up-icon-th`} className="ml-2 h-3" />,
            desc: <TriangleDownIcon data-testid={`${header.id}-triangle-down-icon-th`} className="ml-2 h-3" />,
          }[header.column.getIsSorted() as string] ?? null}
        </div>
      ) : (
        <div data-testid={`${header.id}-th`}>{flexRender(header.column.columnDef.header as string, header.getContext())}</div>
      )}

      {isFilterVisible && <TextFilter header={header} />}
    </div>
  );
};
