import { FormikProps, FormikValues } from 'formik';

import type { Client } from '../../../../../types/clients';
import type { OptionsType } from '../../../../../types/field';

import { GenFormFieldsConfig } from '../../../../../components/FormGenerator/FormGenerator';
import { generateFieldsConfig } from '../../../../../components/FormGenerator/utils/generateFieldsConfig';
import { mitchellFields } from '../../../shared/fields';

export type GroupCodeFieldParams = {
  error: string | undefined;
  isFetching: boolean;
  onChange: (value: string) => void;
};

export interface GetClientExpandedFieldsParams {
  formik: FormikProps<FormikValues>;
  groupCodeFieldParams: GroupCodeFieldParams;
}

type MitchellFlags = keyof Pick<Client, 'isMitchellPaint' | 'isMitchellPart' | 'isMitchellRetail'>;

export const getClientExpandedFieldsConfig = ({ formik, groupCodeFieldParams }: GetClientExpandedFieldsParams): GenFormFieldsConfig => {
  return {
    ...generateFieldsConfig<Client>(['groupCode'], groupCodeFieldParams),
    ...generateMitchellFieldsConfigs(formik),
    flatFeeFlag: {
      onChange: getFlatFeeOnChangeFunction(formik),
      disabled: !(formik.values?.isMitchellPaint || formik.values?.isMitchellPart || formik.values?.isMitchellRetail),
    },
    categoryCodes: {
      formikBasedValue: formik.values.categoryCodes as OptionsType[],
    },
  };
};

const generateMitchellFieldsConfigs = (formik: FormikProps<FormikValues>) => {
  return mitchellFields.reduce((acc, { name }) => {
    acc[name] = {
      onChange: getMitchellOnChangeFunction(formik, name as MitchellFlags),
    };
    return acc;
  }, {} as GenFormFieldsConfig);
};

export const getMitchellOnChangeFunction = (formik: FormikProps<FormikValues>, field: MitchellFlags) => {
  return (value: boolean): void => {
    const flatFeeFlag = !mitchellFields.some((mitchellField) => (mitchellField.name === field ? value : formik.values[mitchellField.name]));
    formik.setValues({
      ...formik.values,
      [field]: value,
      flatFeeFlag,
    });
  };
};

export const getFlatFeeOnChangeFunction = (formik: FormikProps<FormikValues>) => {
  return (e: boolean): void => {
    formik.setValues({
      ...formik.values,
      flatFeeFlag: e,
      ...(e && {
        isMitchellRetail: false,
        isMitchellPaint: false,
        isMitchellPart: false,
      }),
    });
  };
};
