import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { useUpdateClient } from '../../../api/hooks/useUpdateClient/useUpdateClient';
import { debounce } from '../../../common/utilities/debounce';
import { showInfoMessage } from '../../../common/utilities/notification';
import phrases from '../../../constants/en_US.json';
import { QueryKey } from '../../../constants/queries';

type UseUpdateBackfillStatusParams = Readonly<{
  initialValue: boolean;
  planId: string;
  groupCode: string;
}>;

export const useUpdateBackfillStatus = ({ planId, initialValue, groupCode }: UseUpdateBackfillStatusParams) => {
  const [value, setValue] = useState(initialValue);
  const queryClient = useQueryClient();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onUpdateSuccess = async (): Promise<void> => {
    showInfoMessage(value ? phrases.enabledForBackfill.enabled : phrases.enabledForBackfill.disabled);
    await queryClient.invalidateQueries({ queryKey: [QueryKey.FETCH_CLIENT_ITEM, groupCode, planId] });
  };

  const { mutate } = useUpdateClient({ onSuccess: onUpdateSuccess });

  const debouncedMutate = useCallback(
    debounce((newValue: boolean) => {
      if (newValue === initialValue) return;
      mutate({ planId, groupCode, updateFields: { enabledForBackfill: newValue } });
    }, 600),
    [planId, initialValue, mutate],
  );

  const handler = (newValue: boolean) => {
    debouncedMutate(newValue);
    setValue(newValue);
  };

  return [value, handler] as const;
};
