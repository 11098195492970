import { FormikValues } from 'formik';

import type { Client } from '../../../../../../../types/clients';

import { PricingType } from '../../../../../../../types/clients';

/** Extracts data from formik before sending to the backend */
export const extractNextInspectClientFromFormik = (values: FormikValues): Partial<Client> => {
  const pricingType = values.pricingType as PricingType;

  // for such cases we should remove fields because of backend validation logic
  if ([PricingType.Infobahn, PricingType.NoPricing].includes(pricingType)) {
    const { groupCode, ...data } = values;
    return data;
  }

  // clone values to avoid modifying the original input.
  const clone = { ...values };

  // ensure that an empty client string is treated as undefined.
  if (typeof clone.client === 'string' && clone.client.trim() === '') {
    clone.client = undefined;
  }

  return clone;
};
