import { FormikProps, FormikValues } from 'formik';
import { useEffect, useState } from 'react';

import type { OptionsType } from '../../../../../../types/field';
import type { CreateModelPlanFormValues, YearsMap } from '../modelPlan.form.types';

import { useFetchMakesList } from './useFetchMakesList';
import { useFetchModelsList } from './useFetchModelsList';
import { useFetchTrimsList } from './useFetchTrimsList';
import { INIT_DATA } from '../../Modal/form/useCreateModelPlanFormik';

type UseCreateModelPlanHandlersProps = {
  formik: FormikProps<FormikValues>;
};

export const useCreateModelPlanHandlers = ({ formik }: UseCreateModelPlanHandlersProps) => {
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [optionsMakes, setOptionsMakes] = useState<OptionsType[]>([]);
  const [optionsModels, setOptionsModels] = useState<OptionsType[]>([]);
  const [yearsMap, setYearsMap] = useState<YearsMap>({});
  const [selectedOption, setSelectedOption] = useState<OptionsType | null>(null);

  const { year, make, model } = formik.values as CreateModelPlanFormValues;

  const { isFetchingMakes, makesOptions } = useFetchMakesList(year);
  const { isFetchingModels, modelsOptions } = useFetchModelsList({ make, year });
  const { isFetchingTrims, trimsOptions } = useFetchTrimsList({
    year: selectedYear.toString(),
    make,
    model,
    disableFetching: true,
  });

  useEffect(() => {
    if (year) {
      setOptionsMakes(makesOptions);
    }

    if (year && make) {
      setOptionsModels(modelsOptions);
    }

    if (year && make && model) {
      const updatedYearsMap: YearsMap = {
        ...yearsMap,
        [selectedYear]: { options: trimsOptions as { label: string; value: string }[], isFetching: isFetchingTrims },
      };
      setYearsMap(updatedYearsMap);
    }
  }, [makesOptions, modelsOptions, trimsOptions]);

  const onChangeBaseYear = (option: OptionsType): void => {
    setSelectedYear(option?.value as string);
    setYearsMap({});
    void formik.setValues({ ...INIT_DATA, year: option?.value as string });
  };

  const onChangeMake = (option: OptionsType) => {
    setYearsMap({});
    void formik.setValues({ ...INIT_DATA, year, make: option?.value as string });
  };

  const onChangeModel = (option: OptionsType) => {
    setYearsMap({});
    void formik.setValues({ ...INIT_DATA, make, year, model: option?.value as string });
  };

  const onChangeYear = (option: OptionsType, index: number) => {
    setSelectedYear(option?.value as string);
    void formik.setFieldValue(`years[${index}]`, { year: option?.value, isDefault: false, trims: [] });
  };

  const onChangeSourcePlanId = (option: OptionsType) => {
    formik.setFieldValue('sourcePlanId', option?.value);
    setSelectedOption(option);
  };

  return {
    selectedOption,
    optionsMakes,
    optionsModels,
    yearsMap,
    isFetchingMakes,
    isFetchingModels,
    isFetchingTrims,
    onChangeBaseYear,
    onChangeMake,
    onChangeModel,
    onChangeYear,
    onChangeSourcePlanId,
  };
};
