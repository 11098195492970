import { useQuery } from '@tanstack/react-query';

import { getDamageOptions } from '../../../common/queries/artProxy/artProxy.api';
import { ArtProxyAction, QueryKey } from '../../../constants/queries';
import { ClientGroupType } from '../../../types/clients';
import { OptionsType } from '../../../types/field';
import { ARTHookParams } from '../shared/types';

export interface UseDamageData {
  data?: OptionsType[] | undefined;
  isFetching: boolean;
}

export const useDamageData = ({ formik, groupType, groupCode }: ARTHookParams): UseDamageData => {
  const { itemCode, subItemCode } = formik.values;

  const queryFn = async (): Promise<OptionsType[]> => {
    const { items } = await getDamageOptions({ groupCode, itemCode, subItemCode });
    return items.sort((a, b) => a.description.localeCompare(b.description)).map((item) => ({ value: item.code, label: item.description }));
  };

  return useQuery<OptionsType[]>({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_DAMAGES, groupCode, itemCode, subItemCode],
    queryFn,
    keepPreviousData: true,
    enabled: Boolean(itemCode && subItemCode && groupCode) && groupType !== ClientGroupType.Wholesale,
  });
};
