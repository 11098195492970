import { UseWizardStep2ConfigParams } from './useWizardStep2Config';
import { FieldsGroup } from '../../../../../../../../components/FormGenerator/components/FormFieldsGroup';
import { GenFormConfig } from '../../../../../../../../components/FormGenerator/FormGenerator';
import { PricingType } from '../../../../../../../../types/clients';
import { FieldTypes } from '../../../../../../../../types/field';
import {
  copyDamageGuidelineFrom,
  groupCode,
  groupNameText,
  mitchellWithFlatFeeGroup,
  pricingCalculationGroup,
  sellerAccount,
  shouldCopyPricingPlan,
  sourceGroupCode,
} from '../../../../../../shared/fields';

export const getWizardStep2Fields = ({ formik }: UseWizardStep2ConfigParams): GenFormConfig => {
  const pricingType = formik.values.pricingType as PricingType;

  const formConfig: GenFormConfig = {
    container: 'grid grid-cols-2 gap-4 mb-2',
    fields: [],
  };

  const flagsFormGroup: FieldsGroup = {
    type: FieldTypes.GROUP,
    fields: [],
    classes: {
      fieldsContainer: 'grid grid-cols-3 col-span-2 gap-4 mb-2',
    },
  };

  if (pricingType === PricingType.Standard) {
    flagsFormGroup.fields.push(mitchellWithFlatFeeGroup, pricingCalculationGroup);
    formConfig.fields.push(
      groupCode,
      groupNameText,
      sellerAccount,
      {
        type: FieldTypes.GROUP,
        fields: [
          shouldCopyPricingPlan,
          {
            ...sourceGroupCode,
            required: true,
          },
        ],
        classes: {
          fieldsContainer: 'gap-4 rounded border-dashed border-2 p-2 mt-3',
        },
      } as unknown as FieldsGroup,
      flagsFormGroup,
    );
  }

  if ([PricingType.Infobahn, PricingType.NoPricing].includes(pricingType)) {
    formConfig.fields.push(groupNameText, sellerAccount, copyDamageGuidelineFrom);
  }

  return formConfig;
};
