import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useDebounceValue } from '../../../common/hooks/useDebounceValue';
import { fetchGroupDescription } from '../../../common/queries/clients/clients.api';
import { QueryKey } from '../../../constants/queries';
import { GroupDescription } from '../../../types/clients';
import { GROUP_CODE_MAX_LENGTH } from '../shared/constants';

export interface UseGroupDescription {
  isFetching: boolean;
  error: string | undefined;
  data?: GroupDescription;
  setGroupCodeIsValidated: (value: boolean) => void;
  groupCodeIsValidated: boolean;
}

export interface UseGroupDescriptionParams {
  onSettled?: (data: GroupDescription | undefined) => void;
  onSuccess?: (data: GroupDescription) => void;
  groupCode: string;
}

export const useGroupDescription = ({ onSettled, groupCode, onSuccess }: UseGroupDescriptionParams): UseGroupDescription => {
  const [groupCodeIsValidated, setGroupCodeIsValidated] = useState(false);

  const debouncedGroupCode = useDebounceValue(groupCode);
  const enabled = !!(debouncedGroupCode && debouncedGroupCode?.length <= GROUP_CODE_MAX_LENGTH);

  const { isFetching, data } = useQuery<GroupDescription>({
    queryKey: [QueryKey.FETCH_GROUP_DESCRIPTION, debouncedGroupCode],
    queryFn: () =>
      fetchGroupDescription({
        groupCode: debouncedGroupCode,
      }),
    enabled,
    onSettled: () => {
      setGroupCodeIsValidated(true);
      if (onSettled) {
        onSettled(data);
      }
    },
    onSuccess,
    keepPreviousData: true,
    staleTime: Infinity,
    retry: false,
    cacheTime: 0,
  });

  const errorMsg = data?.groupDescription || isFetching || !enabled ? undefined : 'Group code is not validated';

  useEffect(() => {
    setGroupCodeIsValidated(true);
  }, [errorMsg]);

  return { isFetching, error: errorMsg, data, setGroupCodeIsValidated, groupCodeIsValidated };
};
