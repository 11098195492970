import { GenFormConfig, GenFormFieldsConfig } from '../../../components/FormGenerator/FormGenerator';
import { ClientGroupType } from '../../../types/clients';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { getChargeabilitiesField } from '../fields/chargeabilities';
import { getDamageDescriptionField } from '../fields/damageDescription';
import { getFlatFeeFlagField } from '../fields/flatFeeFlag';
import { useActionDescriptionField } from '../fields/useActionDescriptionField';
import { useItemSearchField } from '../fields/useItemSearchField';
import { useSeverityDescriptionField } from '../fields/useSeverityDescriptionField';
import { artNextInspectFormFieldsGroup, nextInspectFormFieldsGroup } from '../shared/fields';
import { ARTHookParams } from '../shared/types';

export type UseCommonNextInspectFormParams = ARTHookParams & {
  groupType: ClientGroupType;
  mode: PricingItemModalMode;
};

export interface UseCommonNextInspectForm {
  config: GenFormConfig;
  fieldsConfig: GenFormFieldsConfig;
}

export const useCommonNextInspectForm = (params: UseCommonNextInspectFormParams): UseCommonNextInspectForm => {
  return {
    config: { container: 'grid grid-cols-1', fields: [artNextInspectFormFieldsGroup, nextInspectFormFieldsGroup] },
    fieldsConfig: {
      itemSearch: useItemSearchField(params),
      damageDescription: getDamageDescriptionField(params),
      severityDescription: useSeverityDescriptionField(params),
      actionDescription: useActionDescriptionField(params),
      chargeableFlag: getChargeabilitiesField(params),
      flatFeeFlag: getFlatFeeFlagField(params),
    },
  };
};
