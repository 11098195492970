import { FormikValues } from 'formik';
import { FC, memo } from 'react';

import { ModelPlanTableRowForm } from './ModelPlanTableRowForm';
import { useModelPlan } from '../../../../../api/hooks/useModelPlan/useModelPlan';
import { Spinner } from '../../../../../components/Spinner/Spinner';
import { modelPlansListCols } from '../../../shared/columns';

export type ModelPlanTableRowProps = {
  initialValues: FormikValues;
};

const ModelPlanTableRow: FC<ModelPlanTableRowProps> = ({ initialValues }): JSX.Element => {
  const rowColumnsLength = modelPlansListCols.length;
  const planId = initialValues.planId as string;

  const { isFetching, data } = useModelPlan({
    planId,
  });

  return (
    <tr data-testid="edit-model-plan" className="bg-slate-100">
      {isFetching ? (
        <td colSpan={rowColumnsLength + 1}>
          <Spinner />
        </td>
      ) : (
        data && (
          <>
            <td className="border-r-0" />
            <td data-testid="model-plan-details-section" colSpan={rowColumnsLength} className="p-5 border-l-0">
              <div className="flex justify-between items-center mb-4" data-testid="model-plan-data">
                <div className="font-bold" data-testid="make">
                  {data.make}
                </div>
                <div data-testid="plan-description">{data.planDescription}</div>
              </div>
              <ModelPlanTableRowForm initialValues={data} planId={planId} />
            </td>
          </>
        )
      )}
    </tr>
  );
};

const MemoizedModelPlanTableRow = memo(ModelPlanTableRow);
export { MemoizedModelPlanTableRow as ModelPlanTableRow };
