import { FormikProps } from 'formik';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';

import { ItemSequenceNumber } from '../../../common/queries/pricingPlans/pricingPlans.types';
import { maxBy } from '../../../common/utilities/maxBy';
import { PricingItemModalMode } from '../components/LineItemModal/shared/types';
import { PricingPlanItemWithSequenceNumberOrder, Sequence, SequenceRowItem } from '../shared/types';
import { calculateSequenceChanges } from '../utils/calculateSequenceChanges';

export type UseSequencesParams = {
  formik: FormikProps<Partial<PricingPlanItemWithSequenceNumberOrder>>;
  readonly mode: PricingItemModalMode;
};

export const useSequences = ({ formik, mode }: UseSequencesParams) => {
  const [sequences, _setSequences] = useState<Array<Sequence>>([]);
  const [sequenceRowItems, setSequenceRowItems] = useState<Array<SequenceRowItem>>([]);

  useEffect(() => {
    if (!formik.values.itemDescription) return;

    setSequenceRowItems([
      ...sequenceRowItems.map((item) => {
        return item.isCurrentItem ? { ...item, itemDescription: formik.values.itemDescription! } : item;
      }),
    ]);
  }, [formik.values.itemDescription]);

  const setInitialSequences = (pricingPlans: Array<ItemSequenceNumber>): void => {
    const sequences = pricingPlans.map((s) => ({ ...s, id: nanoid(10), isCurrentItem: false }));
    _setSequences(sequences);

    const { sequenceNumber } = formik.values;
    setSequenceRowItems([
      ...sequences.map((s) => ({
        id: s.id,
        sequenceNumber: s.sequenceNumber,
        itemDescription: s.itemDescription,
        // Flag to know current item for change item Description and find correct item for change order
        isCurrentItem: mode === PricingItemModalMode.UPDATE && s.sequenceNumber === sequenceNumber,
      })),
      ...(mode === PricingItemModalMode.ADD || mode === PricingItemModalMode.COPY
        ? // Add new item for ADD and COPY mode
          [
            {
              id: nanoid(10),
              sequenceNumber: (maxBy(sequences, 'sequenceNumber') ?? 0) + 1,
              itemDescription: formik.values.itemDescription as string,
              isCurrentItem: true,
            },
          ]
        : []),
    ]);
  };

  const onChangeSequenceOrder = (items: Array<SequenceRowItem>): void => {
    const currentIdx = items.findIndex((i) => i.isCurrentItem);
    const sequenceNumbersOrder = calculateSequenceChanges(items, sequences, mode);

    formik.setValues({
      ...formik.values,
      sequenceNumber: currentIdx + 1,
      sequenceNumbersOrder: sequenceNumbersOrder,
    });

    setSequenceRowItems(items);
  };

  return { setInitialSequences, sequenceRowItems, onChangeSequenceOrder };
};
